import { Avatar, Box, Button, IconButton, Modal, Tab, Tabs, Typography } from '@mui/material'
import isEqual from 'lodash/isEqual'
import React, { useState, useEffect, useMemo, memo, useContext, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UserService from '../services/UserService'
import { useAuth } from '../store/auth-context'
import FriendService from '../services/FriendService'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import BlockIcon from '@mui/icons-material/Block'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import BuddySportProfile from '../components/profile/BuddySportProfile'
import { ChatContext } from '../store/chat-context'
import MessageButton from '../components/Friends/MessageButton'
import { Padding } from '@mui/icons-material'
import SportStatus from '../components/sport/SportStatus'

export default function YourSportsPartners() {
	const tabsRef = useRef(null)
	const { user } = useAuth()
	const params = useParams()
	const navigate = useNavigate()
	const [userDetails, setUserDetails] = useState(null)
	const [sportCode, setSportCode] = useState(false)
	const [selectedIndex, setSelectedindex] = useState(0)
	const [friendsList, setFriendsList] = useState([])
	const { chatState } = useContext(ChatContext)

	useEffect(() => {
		getAllFriends()
	}, [])

	useEffect(() => {
		if (!params.alias) {
			navigate(`/p/${user.alias}`, { replace: true })
		}
	}, [params.alias, user.alias, navigate])

	useEffect(() => {
		if (params.alias && !userDetails) {
			UserService.getUserByAlias(params.alias).then(response => {
				setUserDetails(response.data)
				if (!sportCode && response.data.sports.length > 0) {
					navigate(response.data.sports[0].code)
					setSportCode(response.data.sports[0].code)
				}
			})
		}
	}, [params.alias, userDetails, setUserDetails, sportCode, navigate])

	const getAllFriends = () => {
		FriendService.getFriendList().then(response => {
			if (response.status === 200) {
				setFriendsList(response.data)
			}
		})
	}

	const handleSportCodeChange = (event, newValue) => {
		setSportCode(newValue)
	}
	const [fontColor, setFontColor] = useState()
	const handleClick = index => {
		setFontColor(fontColor === 'black' ? '#9FA4B4' : '#9FA4B4')
		setSelectedindex(index)
	}

	const goBack = () => navigate(-1)

	const friendsBySportCode = useMemo(() => {
		if (sportCode === 'All') return friendsList

		return friendsList.filter(friend => !!friend.sports.find(sport => sport.code === sportCode))
	}, [sportCode, friendsList])

	const updateFriendList = (key, friendAlias) => {
		if (key === 'unbuddy') {
			FriendService.removeFriend(friendAlias).then(response => {
				if (response.status === 200) {
					console.log('removed from friend list')
				}
			})
		}
	}

	const handleTabClick = () => {
		if (tabsRef.current) {
			const container = document.querySelector('.MuiTabs-scroller')
			if (container) {
				container.scrollTo({
					left: 0,
					behavior: 'smooth'
				})
			}
		}
	}

	return (
		<React.Fragment>
			<>
				<Box
					sx={{
						py: '20px',
						px: '23px',
						display: 'flex',
						alignItems: 'center',
						background: 'white'
					}}
				>
					<Box sx={{ width: '100%', display: 'flex', gap: '6px', alignItems: 'center' }}>
						<Typography className="fw-700" fontSize={'1.125rem'}>
							Buddies
						</Typography>
					</Box>
				</Box>
				<Box sx={{ backgroundColor: '#B0F1E1', position: 'relative' }}>
					<Box sx={{ backgroundColor: 'white' }}>
						<Tabs
							ref={tabsRef}
							value={sportCode}
							onChange={handleSportCodeChange}
							variant="scrollable"
							scrollButtons="auto"
							indicatorColor="transparent"
						>
							{[...(userDetails?.sports || []), { code: 'All', name: 'All' }]
								.sort((a, b) => {
									if (a.code === sportCode) return -1
									if (b.code === sportCode) return 1
									return a.code.localeCompare(b.code)
								})
								.map((sport, index) => (
									<Tab
										key={sport.code}
										value={sport.code}
										label={sport.name}
										component={Link}
										to={sport.code}
										onClick={() => {
											handleClick(index)
											handleTabClick()
										}}
									/>
								))}
						</Tabs>

						<Box sx={{ backgroundColor: '#B0F1E1', p: '10px' }}>
							<Box
								sx={{
									background: 'white',
									p: sportCode === 'All' ? '14px !important' : '14px',
									borderRadius: '10px'
								}}
							>
								{friendsBySportCode.length > 0 ? (
									<>
										{friendsBySportCode.map((friend, index) => (
											<FriendProfile
												key={friend?.alias}
												friend={friend}
												sportCode={sportCode}
												updateFriendList={updateFriendList}
												index={index}
												count={friendsBySportCode.length - 1}
											/>
										))}
									</>
								) : (
									<Typography>
										{sportCode === 'All'
											? "You don't have any buddy."
											: 'No buddies with similar sport interests.'}
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</>
		</React.Fragment>
	)
}
const style = {
	position: 'absolute',
	minWidth: '100%',
	bottom: '0%',
	left: '50%',
	transform: 'translate(-50%, -0%)',
	bgcolor: 'background.paper',
	borderRadius: '10px 10px 0 0',
	boxShadow: 24
}
const FriendProfile = memo(
	({ friend, sportCode, updateFriendList, index, count }) => {
		const [isOpen, setIsOpen] = useState(false)
		const navigate = useNavigate()
		const handleOpen = e => {
			setIsOpen(true)
		}

		const handleClose = () => setIsOpen(false)
		const sportsData = useMemo(() => {
			return friend.sportProfiles.find(sp => sp.sport.code === sportCode)
		}, [sportCode])

		const shouldShowStatus = useMemo(
			() => sportCode !== 'All' && sportsData?.status !== null,
			[sportCode, sportsData]
		)

		const redirectToFriendProfile = (e, key) => {
			e.preventDefault()
			navigate(`/p/${key}`, { replace: true })
		}

		return (
			<>
				<Box
					sx={{
						borderBottom: index !== count ? '1px solid #9FA4B4' : '',
						marginBottom: index !== count ? '15px' : '',
						paddingBottom: index !== count ? '10px' : ''
					}}
				>
					<Box sx={{ display: 'flex' }} onClick={e => redirectToFriendProfile(e, friend?.alias)}>
						<Box
							sx={{
								minWidth: '87px',
								maxWidth: '87px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								cursor: 'pointer',
								textAlign: 'center'
							}}
						>
							<Typography variant="pb">{friend?.name}</Typography>
							<Avatar
								sx={{ width: '55px', height: '55px', mt: '10px' }}
								alt={friend?.name || 'Test'}
								src={friend?.userImage?.viewUrl || '/broken-img.jpg'}
							/>
						</Box>

						<Box sx={{ width: '100%' }}>
							<Box sx={{ display: 'flex', gap: '12px', justifyContent: 'flex-end' }}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<LocationOnOutlinedIcon
										sx={{ width: '15px', height: '15px', color: '#969BAB' }}
									/>
									<Typography variant="note" fontStyle="normal" sx={{ color: '#969BAB' }}>
										{friend?.location?.name}
									</Typography>
								</Box>
								<MoreHorizOutlinedIcon onClick={handleOpen} />
							</Box>
							{sportCode !== 'All' && <BuddySportProfile friend={friend} sportCode={sportCode} />}
						</Box>
					</Box>
					{shouldShowStatus && <SportStatus status={sportsData?.status} />}
					<MessageButton sportCode={sportCode} friendAlias={friend.alias} />
				</Box>

				<Box sx={{ position: 'relative' }}>
					<Modal
						open={isOpen}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<Box
								sx={{
									display: 'flex',
									py: '20px',
									px: '20px',
									borderBottom: '1px solid gray',
									gap: '20px'
								}}
							>
								<Box sx={{ width: '55px', height: '55px' }}>
									<Avatar
										sx={{ width: '55px', height: '55px' }}
										alt={friend?.name || 'Test'}
										src={friend?.userImage?.viewUrl || '/broken-img.jpg'}
									/>
								</Box>
								<Typography variant="h2">{friend?.name}</Typography>
							</Box>

							{/* <Box
								sx={{
									display: 'flex',
									py: '10px',
									px: '20px',
									alignItems: 'center',
									gap: '20px'
								}}
							>
								<Box
									sx={{
										width: '40px',
										height: '40px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										borderRadius: '100%',
										background: '#EEEFF4',
										p: '10px'
									}}
								>
									<BlockIcon />
								</Box>
								<Box>
									<Typography className="fw-500">Block {friend?.name}</Typography>
									<Typography variant="pregular" sx={{ color: 'gray' }}>
										{friend?.name} won't be able to see you or contact you
									</Typography>
								</Box>
							</Box> */}
							<Box
								sx={{
									display: 'flex',
									py: '10px',
									px: '20px',
									alignItems: 'center',
									gap: '20px',
									cursor: 'pointer'
								}}
								onClick={() => {
									updateFriendList('unbuddy', friend?.alias)
									setIsOpen(false)
								}}
							>
								<Box sx={{ width: '40px', height: '40px' }}>
									<Box
										sx={{
											width: '40px',
											height: '40px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: '100%',
											background: '#EEEFF4',
											p: '10px'
										}}
									>
										<PersonRemoveIcon />
									</Box>
								</Box>
								<Box>
									<Typography className="fw-500">Unbuddy {friend.name}</Typography>
									<Typography variant="pregular" sx={{ color: 'gray' }}>
										Remove {friend.name} as a buddy
									</Typography>
								</Box>
							</Box>
						</Box>
					</Modal>
				</Box>
			</>
		)
	},
	(pp, np) => isEqual(pp, np)
)
