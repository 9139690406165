import baseApi from './baseApiService'
import { PROFILE_URL, USER_URL } from '../constants/ApiUrls'
class ProfileService {
	updateProfile(request) {
		return baseApi.put(PROFILE_URL, request)
	}

	postProfile(request) {
		return baseApi.post(PROFILE_URL, request)
	}

	getUsersProfile(alias, sportCode) {
		return baseApi.get(`${USER_URL}/${alias}/profile/${sportCode}`)
	}

	postStatus(sportCode, request) {
		return baseApi.post(`${PROFILE_URL}/${sportCode}/status`, request)
	}

	deleteStatus(sportCode) {
		return baseApi.delete(`${PROFILE_URL}/${sportCode}/status`)
	}
}

export default new ProfileService()
