import {
	Autocomplete,
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	createFilterOptions
} from '@mui/material'
import eyeicon from '../../assets/icon/eyeicon.svg'
import { useEffect, useState } from 'react'
import SportService from '../../services/SportService'
import { East } from '@mui/icons-material'
import MyButton from '../../components/ui/MyButton'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ProfileService from '../../services/ProfileService'
import JsonUtils from '../../shared/JsonUtils'
import useNotification from '../../hooks/useNotification'

const filter = createFilterOptions()

export default function LookAroundWecome() {
	const navigate = useNavigate()
	const param = useParams()
	const [, sendNotification] = useNotification()
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [questions, setQuestions] = useState([])
	const [selectedQuestion, setSelectedQuestion] = useState({ tags: [] })
	const [answer, setAnswer] = useState('')
	const [userDetails, setUserDetails] = useState({})
	const [tags, setTags] = useState([])
	const location = useLocation()

	const maxLength = 100

	useEffect(() => {
		SportService.getSportDetail(param.sportCode).then(response => {
			const question = response.data.questions.filter(que => que.category === 'Skill')
			setQuestions(question)
		})
	}, [param.sportCode])

	useEffect(() => {
		if (location?.state?.refer === 'BelayHomePage') setIsDeleteModalOpen(true)
	}, [location?.state])

	useEffect(() => {
		if (param.sportCode) {
			ProfileService.getUsersProfile(param.alias, param.sportCode)
				.then(response => {
					// Assuming 'response.data' holds the fetched profile data
					setUserDetails(response.data)
					const IsFilled =
						response.data.questions?.length > 0
							? response.data.questions.filter(que => que?.sportQuestion?.category === 'Skill')
							: []
					if (IsFilled && IsFilled?.length > 0) {
						setSelectedQuestion(IsFilled[0]?.sportQuestion)
						setAnswer(IsFilled[0]?.answer)
						setTags(IsFilled[0]?.tags)
					}
				})
				.catch(error => {
					// Handle any fetch errors here
					console.log('error', error)
				})
		}
	}, [param])

	const handleLetsGo = () => {
		setIsDeleteModalOpen(true)
		navigate(`/look-around/${param.alias}`)
	}
	const handleNext = isSkiped => {
		if (
			selectedQuestion &&
			selectedQuestion.id &&
			!isSkiped &&
			(answer?.length !== 0 || tags.length !== 0)
		) {
			// Cheking for existing availablity of question
			const checkIndex =
				userDetails.questions.length > 0 &&
				userDetails.questions.findIndex(
					que => que.sportQuestion.category === SportService.QuestionCategory.Skill
				)
			var values = {}
			const newQuestion = {
				answer: answer,
				id: selectedQuestion.id,
				tags: tags,
				sportQuestion: selectedQuestion
			}
			if (checkIndex >= 0) {
				userDetails.questions.splice(checkIndex, 1, newQuestion)
				values = {
					...userDetails,
					questions: userDetails.questions,
					sportCode: param.sportCode
				}
			} else {
				values = {
					...userDetails,
					questions: [...userDetails.questions, newQuestion],
					sportCode: param.sportCode
				}
			}

			const clean = JsonUtils.pruneEmpty(values)
			ProfileService.updateProfile(clean)
				.then(response => {
					navigate(`/p/${param.alias}/actionphotos`, {
						state: {
							refer: userDetails?.sport?.released && 'browse-profiles',
							sportCode: param.sportCode,
							actionPhotoRequired: userDetails?.sport?.released
						}
					})
				})
				.catch(error => {
					console.log('ERROR', error.response)
					sendNotification({
						msg:
							error.response?.data?.message !== undefined
								? error.response.data.message
								: 'An Error Occurred',
						variant: 'error'
					})
					// setIsLoading(false)
				})
		} else {
			sendNotification({
				msg: 'Question and answer is required!',
				variant: 'error'
			})
		}
		// else {
		// 	if (location?.state?.refer === 'BelayHomePage') {
		// 		// Beta mode user can not use look around feature
		// 		navigate(`/p/${param.alias}/actionphotos`, {
		// 			state: { refer: 'BelayHomePage', sportCode: param.sportCode }
		// 		})
		// 	} else {
		// 		navigate(`/p/${param.alias}/actionphotos`, {
		// 			state: { refer: 'browse-profiles', sportCode: param.sportCode, actionPhotoRequired: true }
		// 		})
		// 	}
		// }
	}

	return (
		<>
			{!isDeleteModalOpen ? (
				<Box className="look-around-img" sx={{ backgroundSize: 'cover', px: '28px', py: '24px' }}>
					<Box
						sx={{
							background: 'rgba(255, 255, 255, 0.90)',
							borderRadius: '30px',
							px: '15px',
							pt: '13px',
							pb: '22px'
						}}
					>
						<Box sx={{ mt: '15px' }} textAlign="center">
							<Typography variant="h1b" sx={{ color: '#00C6AE', textAlign: 'center' }}>
								Look around
							</Typography>
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<img src={eyeicon} />
							</Box>
						</Box>
						<Box sx={{ pt: '15px' }}>
							<Typography className="fw-500">
								Here you will find people who are also looking to do sports together!
							</Typography>
							<Typography className="fw-500" sx={{ pt: '30px' }}>
								<Typography as="span" fontWeight="700">
									Add some information to your profile&nbsp;
								</Typography>
								to find the action buddies you are looking for!
							</Typography>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '22px' }}>
						<Button
							sx={{
								background: '#00C6AE',
								color: 'white',
								py: '15px',
								px: '25px',
								borderRadius: '100px',
								'&:hover': {
									background: '#00C6AE'
								}
							}}
							onClick={handleLetsGo}
						>
							<Typography variant="h2" color="white">
								{' '}
								Let’s go
							</Typography>
						</Button>
					</Box>
				</Box>
			) : (
				<Box sx={{ backgroundSize: 'cover', px: '28px', py: '24px' }}>
					<Box sx={{ fontSize: '20px' }}>
						<Box sx={{ fontWeight: '700', fontSize: '21px' }}>
							Share more about your{' '}
							<Typography
								variant="p"
								sx={{ fontWeight: '700', fontSize: '21px', color: '#00C6AE' }}
							>
								sport experience
							</Typography>
						</Box>
						<Box sx={{ mt: 3, width: '100%' }}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Questions</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={questions.find(question => question.id === selectedQuestion.id) || ''}
									label="Questions"
									fullWidth
									onChange={e => {
										setSelectedQuestion(e.target.value)
										setTags([])
										setAnswer('')
									}}
									sx={{ borderRadius: '5px' }}
								>
									<MenuItem disabled value={0}>
										<em>Select a question</em>
									</MenuItem>
									{questions.map(question => (
										<MenuItem key={question.id} value={question}>
											{question.question}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							{selectedQuestion ? (
								selectedQuestion.type === SportService.QuestionType.Text ? (
									<Stack mt={2}>
										<TextField
											multiline
											rows={4}
											placeholder="Write your answer"
											inputProps={{ maxLength: maxLength }}
											onChange={e => setAnswer(e.target.value)}
											value={answer}
										/>
										<Typography
											variant="note"
											textAlign="right"
											sx={{ margin: '5px 20px 0px 0px' }}
										>
											{answer?.length}/{maxLength}
										</Typography>
									</Stack>
								) : (
									<Autocomplete
										mt={2}
										multiple
										id="tags-standard"
										value={tags}
										options={selectedQuestion.tags}
										getOptionLabel={option => option}
										onChange={(e, values) => {
											setTags(values)
										}}
										filterOptions={(options, params) => {
											const filtered = filter(options, params)

											const { inputValue } = params
											// Suggest the creation of a new value
											const isExisting = options.some(option => inputValue === option)
											if (inputValue !== '' && !isExisting) {
												filtered.push(inputValue)
											}
											return filtered
										}}
										renderInput={params => (
											<TextField {...params} variant="standard" label="Tags" placeholder="Tags" />
										)}
									/>
								)
							) : (
								<Stack mt={2}>
									<TextField
										multiline
										rows={4}
										placeholder="Write your answer"
										inputProps={{ maxLength: maxLength }}
										onChange={e => setAnswer(e.target.value)}
										value={answer}
									/>
									<Typography variant="note" textAlign="right" sx={{ margin: '5px 20px 0px 0px' }}>
										{answer.length}/{maxLength}
									</Typography>
								</Stack>
							)}

							<Box sx={{ fontSize: '12px', fontWeight: '500', padding: '25px' }}>
								Choose a question that helps other people know more about your skills and
								experiences.
							</Box>

							<Box
								sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
								mt={10}
							>
								<Box display="flex" width="45%" alignItems="center">
									<Box
										sx={{
											backgroundColor: '#00C6AE',
											width: '50%',
											height: '5px',
											borderRadius: '5px 0 0 5px'
										}}
									></Box>
									<Box
										sx={{
											backgroundColor: 'gray',
											width: '50%',
											height: '5px',
											borderRadius: '0 5px 5px 0'
										}}
									></Box>
									<Box ml="10px" sx={{ fontSize: '14px', fontWeight: '500', color: 'gray' }}>
										1/2
									</Box>
								</Box>
								<Box width="120px">
									<MyButton
										type="submit"
										// disabled={isSubmitting}
										onClick={() => handleNext(false)}
										variant="blGreen"
										fullWidth
										sx={{ color: '#fff', height: '50px' }}
									>
										<East sx={{ height: '40px', width: '100%' }}></East>
									</MyButton>
								</Box>
							</Box>

							<Box p={1} fontSize={'12px'} fontWeight={'500'} textAlign={'end'}>
								You can always change later in “Edit”
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</>
	)
}
