import { Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingPage = () => {
	return (
		<Stack
			direction="column"
			textAlign="center"
			alignItems="center"
			marginTop={'50px'}
			height="100%"
			sx={{ height: '100%' }}
		>
			{/* <Typography
				variant="h4"
				noWrap
				fullWidth
				sx={{
					fontFamily: 'Permanent Marker',
					fontWeight: 700,
					letterSpacing: '.3rem',
					color: '#29B4AB',
					textDecoration: 'none'
				}}
			>
				BelayLink
			</Typography> */}

			<div>
				<CircularProgress />

				<Typography variant="body2">Loading...</Typography>
			</div>
		</Stack>
	)
}

export default LoadingPage
