import baseApi from './baseApiService'
import { USER_URL } from '../constants/ApiUrls'

class UserService {
	login(request) {
		return baseApi.post(USER_URL + '/login', request)
	}

	getUserData(token) {
		return baseApi.get(USER_URL + '/me', {
			headers: { Authorization: `Bearer ${token}` }
		})
	}

	createUser(request) {
		return baseApi.post(USER_URL, request)
	}

	getUserByAlias(alias) {
		return baseApi.get(`${USER_URL}/${alias}`)
	}

	updateUser(request) {
		return baseApi.put(USER_URL, request)
	}

	updateCoverPhotos(photos, removedImagesIds) {
		const formData = new FormData()

		photos.forEach((photo, index) => {
			const blob = new Blob([photo], { type: 'application/octet-stream' })
			formData.append(`image`, blob)
		})

		if (removedImagesIds && removedImagesIds.length > 0) {
			formData.append('deleteIds', removedImagesIds.join(','))
		}

		return baseApi.post(`${USER_URL}/coverImages`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}

	updateCoverPhoto(photo, removedImagesIds) {
		const formData = new FormData()

		if (photo) {
			const blob = new Blob([photo], { type: 'application/octet-stream' })
			formData.append(`image`, blob)
		}

		if (removedImagesIds && removedImagesIds.length > 0) {
			formData.append('deleteIds', removedImagesIds.join(','))
		}

		return baseApi.post(`${USER_URL}/coverImages`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}

	getMe() {
		return baseApi.get(`${USER_URL}/me`)
	}

	updateUserData(request) {
		return baseApi.post(`${USER_URL}/data`, request)
	}
}

export default new UserService()
