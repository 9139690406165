import notificationServiceInstance from '../../services/NotificationService'

export const updateNotificationSetting = (
	emailNotification,
	pushNotification,
	friendsActivities,
	newFeatures,
	sendNotification
) => {
	const payload = {
		methods: [
			{
				id: 1,
				isActive: emailNotification,
				name: notificationServiceInstance.PREFERENCE_METHODS.EMAIL
			},
			{
				id: 2,
				isActive: pushNotification,
				name: notificationServiceInstance.PREFERENCE_METHODS.PUSH
			}
		],
		types: [
			{
				id: 1,
				isActive: friendsActivities,
				name: notificationServiceInstance.PREFERENCE_TYPES.FRIENDS_ACTIVITIES
			},
			{
				id: 2,
				isActive: newFeatures,
				name: notificationServiceInstance.PREFERENCE_TYPES.NEW_FEATURES_PROMOTIONS_EVENTS
			}
		]
	}
	notificationServiceInstance
		.updatePreferences(payload)
		.then(() =>
			sendNotification({
				msg: 'Settings Updated!',
				variant: 'success'
			})
		)
		.catch(error => {
			sendNotification({
				msg: error.response.data.message || 'An Error Occurred',
				variant: 'error'
			})
		})
}
