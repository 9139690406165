import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Image from 'mui-image'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'

const ChallengeStakeCard = ({ title, image }) => {
	return (
		<Grid container direction="row">
			<Grid item xs={2} margin="5px">
				<EmojiEventsOutlinedIcon fontSize="large" />
			</Grid>
			<Grid item xs={8} marginTop="10px">
				<div>
					<Image
						src={image}
						duration={null}
						showLoading
						sx={{ border: '1px solid #000000', borderRadius: '10px' }}
					/>
					<Typography align="center" variant="body1">
						{title}
					</Typography>
				</div>
			</Grid>
		</Grid>
	)
}

export default ChallengeStakeCard
