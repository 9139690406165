import * as Yup from 'yup'
import createUserFormModel from './createUserFormModel'

const {
	formField: { gender, location, name, dateOfBirth }
} = createUserFormModel

export default Yup.object().shape({
	[name.name]: Yup.string()
		.required(`${name.requiredErrorMsg}`)
		.test('notEmpty', 'Enter valid name!', function (value) {
			return value?.trim().length > 0 // only spaces are not allowed
		})
		.matches(/^[a-zA-Z. ]+$/, 'Enter valid name!'),
	[gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
	[dateOfBirth.name]: Yup.string()
		.required(`${dateOfBirth.requiredErrorMsg}`)
		.test('valid-date', 'Enter a valid date!', function (value) {
			// You can customize the date format and validation logic based on your needs
			return Yup.date().isValidSync(new Date(value))
		})

	// [location.name]: Yup.object().nullable().required(`${location.requiredErrorMsg}`)
})
