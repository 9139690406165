import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Item = ({ children, ...props }) => {
	return (
		<Grid item xs={6}>
			<Typography variant="link" component="a" sx={{ cursor: 'pointer' }} {...props}>
				{children}
			</Typography>
		</Grid>
	)
}

const Footer = () => {
	const navigate = useNavigate()
	return (
		<Container
			component="footer"
			maxWidth="sm"
			sx={{ height: '80px', paddingTop: 2, paddingBottom: 2, textAlign: 'center' }}
		>
			<Grid container rowGap="2px">
				{/* <Item onClick={() => navigate('/about')}>About us</Item> */}
				<Item onClick={() => navigate('/contact')}>Contact us</Item>
				<Item onClick={() => navigate('/contact')}>Report an issue</Item>
			</Grid>
			<Typography
				variant="note"
				textAlign="left"
				display="block"
				sx={{
					margin: '10px 0px'
				}}
			>
				Copyright ® 2023 BelayLink. All Rights Reserved.
			</Typography>
		</Container>
	)
}

export default Footer
