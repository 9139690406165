import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useField } from 'formik'
import calendarImg from '../../assets/icon/calendar.png'

const SetSportTime = () => {
	const [fieldTimeWeekdayDay] = useField({ name: 'sportTimeWeekdayDay', type: 'checkbox' })
	const [fieldTimeWeekdayEvening] = useField({
		name: 'sportTimeWeekdayEvening',
		type: 'checkbox'
	})
	const [fieldTimeWeekends] = useField({ name: 'sportTimeWeekend', type: 'checkbox' })

	return (
		<Stack spacing={2}>
			<Typography variant="h1b">When do you usually go?</Typography>
			<FormControlLabel control={<Checkbox />} label="Weekday Daytime" {...fieldTimeWeekdayDay} />
			<FormControlLabel
				control={<Checkbox />}
				label="Weekday Evening"
				{...fieldTimeWeekdayEvening}
			/>
			<FormControlLabel control={<Checkbox />} label="Weekend" {...fieldTimeWeekends} />

			<Box pr={'55px'} sx={{ textAlign: 'end' }}>
				<img src={calendarImg} alt="calendar" />
			</Box>
		</Stack>
	)
}

export default SetSportTime
