import { Stack, Typography, Box } from '@mui/material'
import Image from 'mui-image'
import { Link } from 'react-router-dom'
import columImg from '../assets/about-us/colum.png'
import mandyImg from '../assets/about-us/mandy.png'

const Section = ({ children }) => <Box marginBottom="24px">{children}</Box>

const Title = ({ children }) => (
	<Typography
		variant="h1"
		sx={{
			fontFamily: 'Montserrat',
			lineHeight: '24px',
			fontSize: '17px',
			fontWeight: '700',
			color: 'primary.main'
		}}
	>
		{children}
	</Typography>
)

const Description = ({ children }) => (
	<Typography
		variant="body2"
		fullWidth
		sx={{
			fontFamily: 'Montserrat',
			lineHeight: '22px',
			fontSize: '15px',
			fontWeight: '500',
			color: '#474A57'
		}}
	>
		{children}
	</Typography>
)

const HeaderText = ({ children }) => (
	<Typography
		variant="body2"
		fullWidth
		sx={{
			color: '#000',
			fontFamily: 'Montserrat',
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: '22px',
			marginTop: '20px'
		}}
	>
		{children}
	</Typography>
)

const AboutUsPage = () => {
	return (
		<Stack alignItems="center" spacing={5} sx={{ margin: '25px 22px 22px 22px' }}>
			<Section>
				<Title>Mission</Title>
				<Typography variant="h1b">Find people to do sports together anytime</Typography>
			</Section>

			<Section>
				<Title>About Us</Title>

				<Description>
					<p>As sports lovers, we find the happiest moments in sports are shared.</p>
				</Description>

				<HeaderText>Sports Bring People Together</HeaderText>

				<Description>
					<p>
						Sports don’t care about who you are or where you come from. They bring people of all
						backgrounds and age groups together and work towards a common goal, cheer each other on,
						and have each other’s backs.
					</p>
				</Description>

				<HeaderText>Make It Easy To Find Your Squad</HeaderText>

				<Description>
					<p>
						But let’s be real, coordinating with people who have different schedules and skill
						levels can be a real challenge. Especially when you’re new to a place or a sport. We
						want to make it easy for everyone to find their squad, and do the sports they enjoy
						together.
					</p>
				</Description>
			</Section>

			<Section>
				<Title>The Team</Title>
				<Stack direction="row">
					<div style={{ marginRight: '6px' }}>
						<img src={columImg} />

						<Typography variant="h4">Colum Brolly</Typography>

						<Description>
							<p>The tech whiz who turns wild ideas into real things.</p>
							<p>
								When he’s not weaving wonders with code spells, you'll find him at the local bowling
								alley, scoring strikes and enjoying a fun game of darts with the lads, celebrating
								their victories with some Irish dance moves.
							</p>
						</Description>
					</div>

					<div style={{ marginLeft: '6px' }}>
						<img src={mandyImg} />

						<Typography variant="h4">Mandy Chen</Typography>

						<Description>
							<p>
								The sport maniac who is actually a couch potato at heart. Mandy’s secret of an
								active lifestyle is to make plans with friends, and always have fun in every session
								(or suffer together, which is also fun).
								<br /> Besides rock climbing, surfing, and diving, Mandy can be seen working on
								anything but coding.
							</p>
						</Description>
					</div>
				</Stack>
			</Section>

			<Section>
				<Title>Let’s Chat!</Title>

				<Description>
					<p>
						We value your input! Any thoughts, imagination, or struggles you have for finding your
						sports squad, we want to hear it!
					</p>
				</Description>
			</Section>

			<Section>
				<Title>Join the Fun! Be Part of Something Awesome</Title>

				<Description>
					<p>
						If you’re as gung-ho about sports as we are, let’s team up! We are looking for people
						who want to change the game for sports lovers like us.{' '}
						<Link to="/contact">Contact us</Link> or reach Mandy via{' '}
						<a href="https://www.facebook.com/yinyin692" target="_blank" rel="noreferrer">
							Facebook
						</a>
					</p>
				</Description>
			</Section>

			<Section>
				<Description>
					<p>
						✨Many thanks to Li Tang, Irina, Vidit, Yiting, who have helped build this MVP, and all
						of our dear friends and family enlisted as our consultants for this project. You brought
						this project to life! ✨
					</p>
				</Description>
			</Section>
		</Stack>
	)
}

export default AboutUsPage
