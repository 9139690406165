import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ChallengeTypeCard from '../../../components/ui/ChallengeTypeCard'
import { ReactComponent as TargetImg } from '../../..//assets/challenge/target.svg'
import { ReactComponent as BetterTomorrowImg } from '../../../assets/challenge/better-tomorrow.svg'
import { ReactComponent as LookGoodImg } from '../../../assets/challenge/look-good.svg'
import { ReactComponent as JustGoImg } from '../../../assets/challenge/just-go.svg'
import { useField } from 'formik'
import MetaData from '../../../components/MetaData'

const ChallengeTypePage = props => {
	const [typeField, , typeHelpers] = useField('type')

	const typeSelection = [
		{ label: 'Sport challenge', val: 'sport' },
		{ label: 'Fitness', val: 'fitness' },
		{ label: 'Diet', val: 'diet' },
		{ label: 'look good, feel good', val: 'custom' },
		{ label: "Let's just do this!", val: 'custom' }
	]

	const handleTypeClick = val => {
		typeHelpers.setValue(val)
		props.onClick()
	}

	return (
		<React.Fragment>
			<MetaData title={`BelayLink - Challenge Type`} description={'Challenge Type'} />
			<Stack spacing={1}>
				<Box
					display="flex"
					sx={{ bgcolor: 'primary.main', width: '100%', height: '3em' }}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						sx={{ fontWeight: 'bold' }}
						color="white"
						align="center"
						alignSelf={'center'}
						variant="h1"
					>
						Create a Challenge
					</Typography>
				</Box>

				<Button onClick={handleTypeClick.bind(null, typeSelection[0].val)}>
					<ChallengeTypeCard bgcolor="#00C6AE" text={typeSelection[0].label} selection={0} />
				</Button>

				{/* <Typography sx={{ fontWeight: "bold" }}>Or</Typography> */}

				<Button onClick={handleTypeClick.bind(null, typeSelection[1].val)}>
					<ChallengeTypeCard
						bgcolor="#FFD465"
						text={typeSelection[1].label}
						// ChallengeImage={TargetImg}
						selection={1}
					/>
				</Button>

				<Button onClick={handleTypeClick.bind(null, typeSelection[2].val)}>
					<ChallengeTypeCard bgcolor="#FF89BB" text={typeSelection[2].label} selection={2} />
				</Button>

				{/* <Button onClick={handleTypeClick.bind(null, typeSelection[2].val)}>
          <ChallengeTypeCard
            bgcolor="#FF89BB"
            text={typeSelection[2].label}
            ChallengeImage={LookGoodImg}
            selection={2}
          />
        </Button>

        <Button onClick={handleTypeClick.bind(null, typeSelection[3].val)}>
          <ChallengeTypeCard
            bgcolor="#D6FCF7"
            text={typeSelection[3].label}
            selection={3}
          />
        </Button> */}
			</Stack>
		</React.Fragment>
	)
}

export default ChallengeTypePage
