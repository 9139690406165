import React, { useEffect, useState } from 'react'
import Step1 from '../components/registration/Step1'
import Step2 from '../components/registration/Step2'
import Step3 from '../components/registration/Step3'
import Step4 from '../components/registration/Step4'
import SportService from '../services/SportService'
import { Form, Formik } from 'formik'
import registrationFormModel from './RegistrationPage/registrationFormModel'
import registrationInitialValues from './RegistrationPage/registrationInitialValues'
import Step5 from '../components/registration/Step5'
import ProfileService from '../services/ProfileService'
import { useNavigate, useParams } from 'react-router-dom'
import useNotification from '../hooks/useNotification'
import JsonUtils from '../shared/JsonUtils'
import { Box } from '@mui/system'
import { Button, Stack } from '@mui/material'
import { useAuth } from '../store/auth-context'
import LoadingPage from './LoadingPage'

const EditSportProfilePage = props => {
	const [sportDetails, setSportDetails] = useState(null)
	const { formId, formField } = registrationFormModel
	const [profile, setProfile] = useState(null)
	const params = useParams()
	console.log(params)
	const { sportCode } = params
	const { user } = useAuth()

	useEffect(() => {
		ProfileService.getUsersProfile(user.alias, sportCode).then(response => {
			registrationInitialValues.profile = { ...response.data, sportCode: sportCode }
			setProfile(response.data)
		})
	}, [])

	const [, sendNotification] = useNotification()
	const navigate = useNavigate()

	useEffect(() => {
		SportService.getSportDetail('RCL').then(response => {
			setSportDetails(response.data)
		})
	}, [])

	async function submitForm(values, actions) {
		const clean = JsonUtils.pruneEmpty(values)
		console.log(JSON.stringify(clean, null, 2))
		ProfileService.updateProfile(clean.profile)
			.then(response => {
				navigate('/p', { replace: false })
			})
			.catch(error => {
				console.log('ERROR', error.response)
				sendNotification({
					msg:
						error.response.data.message !== undefined
							? error.response.data.message
							: 'An Error Occurred',
					variant: 'error'
				})
			})
		actions.setSubmitting(false)
	}

	const handleSubmit = (values, actions) => {
		submitForm(values, actions)
	}

	const handleCancel = () => {}

	console.log(registrationInitialValues)

	return profile && sportDetails ? (
		<Formik
			initialValues={registrationInitialValues}
			// validationSchema={registrationValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ errors, isSubmitting, values }) => (
				<Form id={formId}>
					<Stack spacing={2}>
						<Step1 sport={sportDetails} />
						<Step2 sport={sportDetails} />
						<Step3 sport={sportDetails} />
						<Step4 sport={sportDetails} />
						<Step5 sport={sportDetails} />

						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Button
								color="inherit"
								disabled={isSubmitting}
								onClick={handleCancel}
								sx={{ mr: 1 }}
								variant="contained"
							>
								Cancel
							</Button>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button sx={{ mr: 1 }} variant="contained" disabled={isSubmitting} type="submit">
								Submit
							</Button>
						</Box>
					</Stack>
				</Form>
			)}
		</Formik>
	) : (
		<LoadingPage />
	)
}

export default EditSportProfilePage
