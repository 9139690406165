import { getMessaging, getToken } from 'firebase/messaging'
import UserService from '../../services/UserService'
import { app } from './firebase-config'

export const Sendrequest = () => {
	console.log('Requesting User Permission……')

	Notification.requestPermission().then(permission => {
		const messaging = getMessaging(app)
		if (permission === 'granted') {
			return getToken(messaging, {
				vapidKey:
					'BAy1TidQIFOukZGOfM1P_deHqu5Fd1zUeqFEvYL2xCziayT3z14ic1DfMT5QijARhICo0D9nHY5hZ5-MmojvU9o'
			})
				.then(currentToken => {
					if (currentToken) {
						console.log('Client Token:', currentToken)
						UserService.updateUserData({ pushNotificationToken: currentToken }) // set FCM token
					} else {
						console.log('Failed to generate the registration token.')
					}
				})
				.catch(err => {
					console.log('An error occurred when requesting to receive the token.', err)
				})
		} else {
			console.log('User Permission Denied')
		}
	})
}
