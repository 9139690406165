const editUserFormModel = {
	formId: 'editUserForm',
	formField: {
		biography: {
			name: 'biography',
			label: 'Biography'
		},
		name: {
			name: 'name',
			label: 'Name*',
			requiredErrorMsg: 'Name is required'
		},
		location: {
			name: 'location',
			label: 'Where Are You',
			requiredErrorMsg: 'Location is required'
		},
		userImage: {
			name: 'userImage',
			label: 'User Image'
		},
		hometown: {
			name: 'hometown',
			label: 'Hometown',
			requiredErrorMsg: 'Location is required'
		}
	}
}

export default editUserFormModel
