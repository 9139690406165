import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Button, Drawer, Stack, Typography } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import FriendService from '../../services/FriendService'
import useNotification from '../../hooks/useNotification'
import { useAuth } from '../../store/auth-context'
import { useLocation, useParams } from 'react-router-dom'
import MessageButton from './MessageButton'
import useUnreadCount from '../../hooks/useUnreadCount'

const getButtonLabel = label => {
	switch (label) {
		case 'SENT_REQUEST':
			return 'Cancel Request'
		case 'RECEIVED_REQUEST':
			return 'Respond'
		case 'FRIENDS':
			return 'Buddies'
		case 'NONE':
		default:
			return 'Buddy'
	}
}
const FriendshipHandler = ({ userDetails, showBackArrow, sportCode }) => {
	const { user, awsSignIn } = useAuth()
	const location = useLocation()
	const [openDrawer, setOpenDrawer] = useState(false)
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [updateFriendStatus, setUpdateFriendStatus] = useState('')
	const [, sendNotification] = useNotification()
	const { refetchNotify } = useUnreadCount()
	const params = useParams()

	useEffect(() => {
		setUpdateFriendStatus(userDetails?.friendStatus)
	}, [userDetails?.friendStatus])

	const openDialog = useCallback(() => setIsDialogOpen(true), [])
	const closeDialog = useCallback((e, shouldRedirect = false) => {
		setIsDialogOpen(false)
		if (shouldRedirect) awsSignIn(location.pathname)
	}, [])

	const handleRequest = useCallback(() => {
		if (updateFriendStatus === 'NONE' || !updateFriendStatus) {
			if (!user) {
				openDialog()
			} else {
				FriendService.sendFriendRequest(userDetails.alias).then(response => {
					if (response.status === 201) {
						setUpdateFriendStatus('SENT_REQUEST')
					}
				})
			}
		} else if (updateFriendStatus === 'SENT_REQUEST') {
			FriendService.cancelSentRequest(userDetails.alias).then(response => {
				if (response.status === 200) {
					setUpdateFriendStatus('NONE')
				}
			})
		} else if (updateFriendStatus === 'RECEIVED_REQUEST') {
			setOpenDrawer(true)
		} else if (updateFriendStatus === 'FRIENDS') {
			setOpenDrawer(true)
		}
	}, [updateFriendStatus])

	const modalOnClick = useCallback(key => {
		if (key === 'Delete') {
			FriendService.rejectFriendRequest(userDetails.alias).then(response => {
				if (response.status === 200) {
					setUpdateFriendStatus('NONE')
				}
			})
		} else if (key === 'Accept') {
			FriendService.acceptFriendRequest(userDetails.alias).then(response => {
				if (response.status === 200) {
					setUpdateFriendStatus('FRIENDS')
					sendNotification({
						msg: `You are now buddies with ${userDetails?.name}`,
						variant: 'success'
					})
					refetchNotify()
				}
			})
		} else if (key === 'unfriend') {
			FriendService.removeFriend(userDetails.alias).then(response => {
				if (response.status === 200) {
					setUpdateFriendStatus('NONE')
				}
			})
		}
		setOpenDrawer(false)
	}, [])

	const buttonLabel = useMemo(() => {
		return getButtonLabel(updateFriendStatus)
	}, [updateFriendStatus])

	return (
		<>
			<Stack direction="row" alignItems={'end'} marginTop={'0px !important'} spacing={1}>
				<Button
					onClick={handleRequest}
					variant={
						buttonLabel === 'Cancel Request' || buttonLabel === 'Unbuddy' ? 'outlined' : 'contained'
					}
					sx={{
						color:
							buttonLabel === 'Cancel Request' || buttonLabel === 'Unbuddy' ? '#00C6AE' : '#FFF',
						padding: '4px 10px',
						'&:hover': {
							background:
								buttonLabel === 'Cancel Request' || buttonLabel === 'Unbuddy' ? '#FFF' : '#00C6AE'
						},
						textAlign: 'center',
						maxHeight: '24px',
						boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
					}}
				>
					{(buttonLabel === 'Cancel Request' || buttonLabel === 'Unbuddy') && (
						<PersonAddDisabledIcon
							sx={{
								width: '16px',
								height: '16px'
							}}
						/>
					)}
					{(buttonLabel === 'Buddy' || buttonLabel === 'Respond') && (
						<PersonAddIcon
							sx={{
								width: '16px',
								height: '16px'
							}}
						/>
					)}
					{buttonLabel === 'Buddies' && (
						<CheckIcon
							sx={{
								width: '16px',
								height: '16px'
							}}
						/>
					)}

					<Typography
						sx={{
							color:
								buttonLabel === 'Cancel Request' || buttonLabel === 'Unbuddy' ? '#00C6AE' : '#FFF',
							paddingLeft: '4px'
						}}
						variant="h4"
					>
						{buttonLabel}
					</Typography>
				</Button>

				<MessageButton
					friendAlias={params.alias}
					refer={showBackArrow}
					sportCode={sportCode}
					userDetails={userDetails}
				/>
			</Stack>
			<Drawer anchor="bottom" open={openDrawer} onClose={() => setOpenDrawer(false)}>
				<Stack
					spacing={2}
					sx={{
						padding: '30px',
						alignItems: 'flex-start',
						color: 'black'
					}}
				>
					{updateFriendStatus !== 'FRIENDS' ? (
						<>
							<Button onClick={() => modalOnClick('Accept')}>
								<PersonAddIcon
									sx={{
										color: '#474A57',
										borderRadius: '50%',
										backgroundColor: 'lightgrey',
										width: '30px',
										height: '30px',
										padding: '3px',
										mr: '10px'
									}}
								/>
								<Typography variant="h3">Confirm Request</Typography>
							</Button>
							<Button onClick={() => modalOnClick('Delete')}>
								<ClearIcon
									sx={{
										color: '#474A57',
										borderRadius: '50%',
										backgroundColor: 'lightgrey',
										width: '30px',
										height: '30px',
										padding: '3px',
										mr: '10px'
									}}
								/>
								<Typography variant="h3">Delete Request</Typography>
							</Button>
						</>
					) : (
						<Button onClick={() => modalOnClick('unfriend')}>
							<ClearIcon
								sx={{
									color: '#474A57',
									borderRadius: '50%',
									backgroundColor: 'lightgrey',
									width: '30px',
									height: '30px',
									padding: '3px',
									mr: '10px'
								}}
							/>
							<Typography variant="h3">Unfriend {userDetails?.name}</Typography>
						</Button>
					)}
				</Stack>
			</Drawer>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				aria-labelledby="redirect-dialog"
				aria-describedby="redirect-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Sign-in to add {userDetails?.name} to your buddy list!
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>Cancel</Button>
					<Button onClick={() => closeDialog(null, true)} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default FriendshipHandler
