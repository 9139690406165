import { Box } from '@mui/material'
import React from 'react'
import boardImg from '../../assets/icon/rockFall.png'
import BottomNavigation from './BottomNavigation'

const PageNotFound = () => {
	return (
		<Box sx={{ display: 'grid', placeItems: 'center', height: '615px', marginTop: '-70px' }}>
			<Box sx={{ textAlign: 'center' }}>
				<Box>
					<img src={boardImg} style={{ width: '50%' }} />
				</Box>
				<Box sx={{ fontWeight: '500' }}>
					Oops! Page not found.
					<br /> How about Look Around?
					<br /> Maybe you will find some
					<br /> interesting people.
				</Box>
				<BottomNavigation lookArround={false} addStatus={false} message={false} />
			</Box>
		</Box>
	)
}

export default PageNotFound
