import React, { useState } from 'react'

export const LoadingContext = React.createContext()

export const LoadingContextProvider = props => {
	const [isLoading, setIsLoading] = useState(false)

	return (
		<LoadingContext.Provider value={[isLoading, setIsLoading]}>
			{props.children}
		</LoadingContext.Provider>
	)
}
