import { Stack, Typography } from '@mui/material'
import React from 'react'
import MyChip from '../ui/MyChip'

export default function UserQuesionnaire({ profile, showLocationTime }) {
	const filterQue = profile?.questions?.filter(
		que => (que.answer !== '' && que.answer !== null) || que.tags.length > 0
	)
	return (
		<>
			{filterQue?.length > 0 &&
				filterQue.map((question, index) => (
					<div key={`question-${index}`} style={{ margin: '10px 0' }}>
						{(question?.answer || question?.tags.length > 0) && (
							<Typography variant="h3">{question.sportQuestion.question}</Typography>
						)}
						{question.sportQuestion.type === 'Tags' ? (
							<Stack direction="row" display="block" mt={'3px'}>
								{question.tags.map((tag, index) => (
									<MyChip key={`tag-${index}`}>{tag}</MyChip>
								))}
							</Stack>
						) : (
							<div style={{ wordWrap: 'break-word', marginTop: '3px' }}>
								<Typography variant="pb">{question.answer}</Typography>
							</div>
						)}
					</div>
				))}

			{showLocationTime &&
				(profile?.sportTimeWeekdayDay ||
					profile?.sportTimeWeekdayEvening ||
					profile?.sportTimeWeekend) && (
					<div>
						<Typography variant="h3" mt={'10px'}>
							When I usually go
						</Typography>
						<Stack direction="row" display="block" mt={'3px'}>
							{profile?.sportTimeWeekdayDay && <MyChip>Weekday daytime</MyChip>}
							{profile?.sportTimeWeekdayEvening && <MyChip>Weekday evening</MyChip>}
							{profile?.sportTimeWeekend && <MyChip>Weekend</MyChip>}
						</Stack>
					</div>
				)}

			{showLocationTime &&
				((profile?.playground && profile?.playground?.length > 0) || profile?.openToPlaces) && (
					<div>
						<Typography variant="h3" mt={'10px'}>
							Where I usually go or want to go
						</Typography>
						<Stack direction="row" display="block" mt={'3px'}>
							{profile?.playground.map((location, index) => (
								<MyChip key={`location-${index}`}>{location.name}</MyChip>
							))}
							{profile?.openToPlaces && <MyChip mt={'3px'}>Flexible</MyChip>}
						</Stack>
					</div>
				)}
		</>
	)
}
