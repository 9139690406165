import { Box, Tab, Tabs, Typography, Grid } from '@mui/material'
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import SportView from '../components/user/SportView'
import UserHeader from '../components/user/UserHeader'
import UserService from '../services/UserService'
import { useAuth } from '../store/auth-context'
import UserQuesionnaire from '../components/user/UserQuestionnaire'
import FlagIcon from '@mui/icons-material/Flag'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import ImageSlider from '../components/slider/ImageSlider'
import { ArrowBackIos } from '@mui/icons-material'
import SportSessionTypes from '../components/user/SportSessionTypes'
import ProfileService from '../services/ProfileService'
import SportStatus from '../components/sport/SportStatus'
import BrowseProfileLRButtons from '../components/ui/BrowseProfileLRButtons'
import BottomNavigation from '../components/ui/BottomNavigation'
import { LoadingContext } from '../store/loading-context'
import Loading from '../components/ui/Loading'
import useShare from '../hooks/useShare'
import SportLocationTiming from '../components/ui/SportLocationTiming'
import { ShareContext } from '../components/ShareContext'

const BrowseProfiles = () => {
	const tabsRef = useRef(null)
	const { user } = useAuth()
	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const [userDetails, setUserDetails] = useState(null)
	const [sportCode, setSportCode] = useState(params.sportCode)
	const [fontColor, setFontColor] = useState()
	const [hasStatus, setHasStatus] = useState(Math.random() < 0.5)
	const [comeFromLookAroundScreen, setComeFromLookAroundScreen] = useState(false)
	const [sportProfile, setSportProfile] = useState(null)
	const [page, setPage] = useState(0)
	const [isLoading, setIsLoading] = useContext(LoadingContext)

	useEffect(() => {
		if (location?.state?.refer === 'look-around') {
			setComeFromLookAroundScreen(true)
			navigate(params.sportCode)
		} else if (location?.state?.refer === 'look-around-welcome') {
			navigate(params.sportCode)
			setPage(0)
			setComeFromLookAroundScreen(false)
			localStorage.removeItem('look-around-data')
		}
	}, [location])

	useEffect(() => {
		if (!params.alias) {
			navigate(`/p/${user.alias}`, { replace: true })
		}
	}, [params.alias, user.alias, navigate])

	useEffect(() => {
		// if (!sportProfile) { // if comment open,  status not visible
		setIsLoading(true)
		ProfileService.getUsersProfile(params.alias, sportCode)
			.then(response => {
				setIsLoading(false)

				setSportProfile(response.data)
				//navigate(sportCode)
			})
			.catch(() => {
				setIsLoading(false)
			})
		// }
	}, [sportCode])

	useEffect(() => {
		if (params.alias && !userDetails) {
			UserService.getUserByAlias(params.alias).then(response => {
				setUserDetails(response.data)
				if (!sportCode && response.data.sports.length > 0) {
					navigate(response.data.sports[0].code)
					setSportCode(response.data.sports[0].code)
				}
			})
		}
	}, [params.alias, userDetails, setUserDetails, , sportCode, navigate])

	const handleSportCodeChange = (event, newValue) => {
		setSportCode(newValue)
	}

	const handleClick = () => {
		setFontColor(fontColor === 'black' ? '#9FA4B4' : '#9FA4B4')
	}

	const goBackToLookArround = () => {
		navigate(`/look-around/${user?.alias}`)
	}

	const handleTabClick = () => {
		if (tabsRef.current) {
			const container = document.querySelector('.MuiTabs-scroller')
			if (container) {
				container.scrollTo({
					left: 0,
					behavior: 'smooth'
				})
			}
		}
	}

	const profileUrl = `${process.env.REACT_APP_URL}/p/${userDetails?.alias}/${sportCode}`

	const shareDetails = useMemo(
		() => ({
			url: profileUrl,
			title: `${userDetails?.name}'s Profile`,
			text: `I found a potential partner for ${sportProfile?.sport?.name} for you on BelayLink.`
		}),
		[profileUrl, userDetails?.name, sportProfile?.sport?.name]
	)

	const handleShareButton = useShare(shareDetails)

	const { updateShareDetails } = useContext(ShareContext)

	useEffect(() => {
		// Update with specific details for this page
		updateShareDetails(shareDetails)

		// Reset to default when the component unmounts
		return () => updateShareDetails()
	}, [shareDetails, updateShareDetails])

	const isStatusAvailable =
		sportProfile?.status &&
		(sportProfile?.status?.dates?.length > 0 ||
			sportProfile?.status?.message !== '' ||
			sportProfile?.status?.playground.length > 0)

	return (
		<React.Fragment>
			<>
				<Box pt={'10px'} id="browserBox">
					{comeFromLookAroundScreen && (
						<ArrowBackIos
							sx={{ marginLeft: '16px', marginBottom: '-14px' }}
							onClick={goBackToLookArround}
						/>
					)}
					{userDetails && (
						<UserHeader
							name={userDetails.name}
							image={userDetails.userImage?.viewUrl}
							age={userDetails.age}
							location={userDetails.location?.name}
							hometown={userDetails.hometown?.name}
							work={userDetails.work}
							noShowAge={userDetails.noShowAge}
							userDetails={userDetails}
							showBackArrow={true}
							sportCode={sportCode}
						/>
					)}
					<Box sx={{ position: 'relative' }}>
						<Box sx={{ backgroundColor: '#B0F1E1', pb: '130px' }}>
							<Box sx={{ marginTop: '15px', backgroundColor: 'white' }}>
								<Tabs
									ref={tabsRef}
									value={sportCode}
									onChange={handleSportCodeChange}
									variant="scrollable"
									scrollButtons="auto"
									indicatorColor="transparent"
								>
									{userDetails?.sports
										.sort(a => (a.code === params['*'] ? -1 : 1))
										.map(sport => (
											<Tab
												key={sport.code}
												value={sport.code}
												label={sport.name}
												component={Link}
												to={sport.code}
												onClick={() => {
													handleClick()
													handleTabClick()
												}}
												selected={params['*'] === sport.code}
											/>
										))}
								</Tabs>
								<Grid sx={{ backgroundColor: '#B0F1E1', padding: '0 10px' }}>
									{sportProfile?.status &&
										(sportProfile?.status?.dates?.length > 0 ||
											sportProfile?.status?.message !== '' ||
											sportProfile?.status?.playground.length > 0) && (
											<SportStatus
												status={sportProfile?.status}
												showInterestedButton={true}
												sportUser={{
													...sportProfile,
													alias: userDetails?.alias,
													name: userDetails?.name
												}}
											/>
										)}
								</Grid>
								<Box sx={{ backgroundColor: '#B0F1E1' }}>
									<Box sx={{ px: '10px', pt: '10px', pb: '10px' }}>
										<Routes>
											{sportCode && sportProfile && (
												<Route
													path=":sportCode"
													element={
														<SportView
															alias={userDetails?.alias}
															sportCode={sportCode}
															minimalView
															showStatus={false}
															sport={false}
															showEdit={false}
															profileDetails={sportProfile} // to egnore the api call
															showPencilIcon={true}
														/>
													}
												/>
											)}
										</Routes>
									</Box>
								</Box>

								{!isStatusAvailable && (
									<Box
										sx={{
											padding: '0 10px',
											// marginTop: '-28px',
											marginBottom: '10px',
											background: '#B0F1E1'
										}}
									>
										<SportSessionTypes
											biography={sportProfile?.biography}
											sessionTypes={sportProfile?.sessionTypes}
										/>
									</Box>
								)}
							</Box>

							{userDetails?.coverImages && userDetails.coverImages?.length > 0 && (
								<Box sx={{ marginBottom: '-23px' }}>
									<ImageSlider SliderData={userDetails.coverImages} />
								</Box>
							)}
							{isStatusAvailable && (
								<Box sx={{ padding: '0 10px', marginBottom: '10px' }}>
									<SportSessionTypes
										biography={sportProfile?.biography}
										sessionTypes={sportProfile?.sessionTypes}
									/>
								</Box>
							)}
							{sportProfile?.questions?.length > 0 &&
								sportProfile?.questions?.filter(
									que => (que.answer !== '' && que.answer !== null) || que.tags?.length > 0
								)?.length > 0 && (
									<Box
										sx={{
											background: 'white',
											// py: '18px',
											// px: '23px',
											p: '15px',
											mx: '10px',
											borderRadius: '10px'
											// mb: hasStatus ? '16px' : 'unset'
										}}
									>
										<UserQuesionnaire profile={sportProfile} />
									</Box>
								)}

							{(sportProfile?.sportTimeWeekdayDay ||
								sportProfile?.sportTimeWeekdayEvening ||
								sportProfile?.sportTimeWeekend ||
								sportProfile?.playground?.length > 0) && (
								<Box sx={{ p: '10px', background: '#B0F1E1' }}>
									{<SportLocationTiming sportProfile={sportProfile} />}
								</Box>
							)}

							<Box
								display="flex"
								justifyContent={'space-between'}
								py={'17px'}
								px={'10px'}
								color={'#9FA4B4'}
							>
								<Box display={'flex'} alignItems={'center'} columnGap={'5px'}>
									<FlagIcon />
									<Typography variant="h3"> Report User </Typography>
								</Box>
								<Box
									display={'flex'}
									alignItems={'center'}
									columnGap={'5px'}
									onClick={handleShareButton}
								>
									<Typography variant="h3"> Share to a friend </Typography>
									<SendOutlinedIcon />
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>

				{user.alias !== params.alias && (
					<BrowseProfileLRButtons
						page={page}
						sportCode={sportCode}
						currentSportView={userDetails}
						comeFromLookAroundScreen={comeFromLookAroundScreen}
						showBackArrow={true}
						showNextPrevButtons={false}
					/>
				)}
				{/*  Profile Navigation */}

				<BottomNavigation lookArround={false} addStatus={false} message={false} />

				{isLoading && <Loading />}
			</>
		</React.Fragment>
	)
}

export default BrowseProfiles
