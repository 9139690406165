import { getUserProfilePhoto } from './UserUtils'

export function getChatNameAndPhoto(participants, user) {
	const otherUsers = participants.filter(participant => participant.alias !== user.alias)
	const photo = getUserProfilePhoto(otherUsers[0])
	const name = otherUsers[0]?.name
	const alias = otherUsers[0]?.alias

	return { name, photo, alias }
}
