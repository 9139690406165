import { MenuItem, Select } from '@mui/material'

const years = Array.from({ length: 100 }, (_, i) => ({
	value: (new Date().getFullYear() - i).toString()
}))

const YearSelect = ({ value, onChange }) => {
	const handleYearChange = event => {
		const year = event.target.value
		onChange(year)
	}

	return (
		<Select value={value} onChange={handleYearChange}>
			{years.map(year => (
				<MenuItem key={year.value} value={year.value}>
					{year.value}
				</MenuItem>
			))}
		</Select>
	)
}

export default YearSelect
