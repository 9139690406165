import createSportProfileFormModel from './createSportProfileFormModel'

const {
	formField: {
		sportCode,
		practicingSince,
		frequency,
		level,
		sportStyles,
		sportTimeWeekdayDay,
		sportTimeWeekdayEvening,
		sportTimeWeekend,
		openToPlaces,
		placesIndoor,
		placesOutdoor,
		playground,
		questions,
		sessionTypes,
		biography,
		pushNotifications,
		emailNotifications,
		newFeatureNotification
	}
} = createSportProfileFormModel

const createSportProfileInitialValues = {
	[sportCode.name]: '',
	[practicingSince.name]: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toJSON(),
	[frequency.name]: 'Regularly',
	[level.name]: '',
	[sportStyles.name]: [],
	[sportTimeWeekdayDay.name]: false,
	[sportTimeWeekdayEvening.name]: false,
	[sportTimeWeekend.name]: false,
	[openToPlaces.name]: false,
	[placesIndoor.name]: false,
	[placesOutdoor.name]: false,
	[playground.name]: false,
	[sportTimeWeekdayDay.name]: false,
	[sportTimeWeekdayDay.name]: false,
	[playground.name]: [],
	[questions.name]: [
		{ answer: '', tags: [], sportQuestion: { id: 0 } },
		{ answer: '', tags: [], sportQuestion: { id: 0 } },
		{ answer: '', tags: [], sportQuestion: { id: 0 } }
	],
	[sessionTypes.name]: [],
	[biography.name]: null,
	[pushNotifications.name]: true,
	[emailNotifications.name]: true,
	[newFeatureNotification.name]: false
}

export default createSportProfileInitialValues
