import React from "react";
import Grid from "@mui/material/Grid";
import SportSkill from "./SportSkill";
import { FieldArray } from "formik";

const SportSkills = (props) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <FieldArray
          name="profile.sportSkills"
          render={(arrayHelpers) => {
            return props.skills.map((skill, index) => (
              <Grid item xs={6} lg={4} key={skill.id}>
                <SportSkill
                  index={index}
                  skill={skill}
                  arrayHelpers={arrayHelpers}
                />
              </Grid>
            ));
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

export default SportSkills;
