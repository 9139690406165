import createUserFormModel from './createUserFormModel'

const {
	formField: { biography, dateOfBirth, location, name, email, avatarImage, noShowAge }
} = createUserFormModel

const createUserInitialValues = {
	[biography.name]: '',
	// [dateOfBirth.name]: new Date(new Date().setFullYear(new Date().getFullYear() - 20)).toJSON(),
	[dateOfBirth.name]: null,
	[location.name]: null,
	[name.name]: '',
	[email.name]: '',
	[avatarImage.name]: null,
	[noShowAge.name]: false
}

export default createUserInitialValues
