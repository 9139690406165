// src/firebase-config.js
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
	apiKey: 'AIzaSyAv5YSpTVXFK9zv7M8JmU1G51l3-T8UahM',
	authDomain: 'belaylink-12866.firebaseapp.com',
	projectId: 'belaylink-12866',
	storageBucket: 'belaylink-12866.appspot.com',
	messagingSenderId: '78394566642',
	appId: '1:78394566642:web:8b9a7ccce5152257886d6b',
	measurementId: 'G-1FCC7KN2NE'
}

export const app = initializeApp(firebaseConfig)
