import * as Yup from 'yup'
import createSportProfileFormModel from './createSportProfileFormModel'

const {
	formField: { sportCode, level, sessionTypes }
} = createSportProfileFormModel

export default [
	Yup.object().shape({
		[sportCode.name]: Yup.string().required(`${sportCode.requiredErrorMsg}`).strict(true)
	}),
	Yup.object(),
	Yup.object().shape({
		[level.name]: Yup.string().required(`${level.requiredErrorMsg}`).strict(true)
	}),
	Yup.object(),
	Yup.object(),
	Yup.object(),
	Yup.object(),
	Yup.object(),
	Yup.object(),
	Yup.object({
		[sessionTypes.name]: Yup.array()
			.min(1, 'At least 1 session is required')
			.max(10, 'You cannot add more than 10 sessions')
			.of(Yup.string().required('session is required'))
	})
]
