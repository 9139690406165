import { FormControlLabel, Checkbox } from "@mui/material";
import { useField } from "formik";
import React from 'react'

const DateOfBirthCheckBox = ({ name, label }) => {
  const [field, , helper] = useField({ name: `${name}`, type: "checkbox" });

  return (
    <FormControlLabel
      sx={{ width: "60%" }}
      control={
        <Checkbox
          {...field}
        />
      }
      label={label}
    />
  );
};

export default DateOfBirthCheckBox;
