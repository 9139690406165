import {
	Button,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	TextField,
	Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useField } from 'formik'
import Image from 'mui-image'
import React, { useEffect, useRef, useState } from 'react'
import ChallengeStakeCard from '../../../components/ChallangeStakeCard'
import MetaData from '../../../components/MetaData'
import ChallengeService from '../../../services/ChallengeService'
import { useAuth } from '../../../store/auth-context'
import LoadingPage from '../../LoadingPage'

const ChallengeStakesPage = () => {
	const [stakes, setStakes] = useState(null)
	const [stakeField, , stakeHelper] = useField('stake')
	const refCustomText = useRef()

	useEffect(() => {
		ChallengeService.getStakes().then(response => {
			setStakes(response.data)
		})
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [stakeField])

	return stakes ? (
		<React.Fragment>
			<MetaData title={`BelayLink - Challenge Stake`} description={'Challenge Stake'} />

			<ChallengeStakeCard title={stakeField.value.name} image={stakeField.value.avatar} />

			{stakes
				.filter(category => !category.custom)
				.map(category => (
					<div>
						<div key={category.categoryId}>
							<Box bgcolor="#FFF4CC">
								<Typography>{category.categoryName}</Typography>
							</Box>
							<ImageList
								sx={{
									gridAutoFlow: 'column',
									gridTemplateColumns: 'repeat(auto-fit, minmax(95px,95px)) !important',
									gridAutoColumns: 'minmax(95px,95px)',
									marginBlockStart: 0,
									marginBlockEnd: 0
								}}
							>
								{category.stakeDetails.map(stake => (
									<ImageListItem key={stake.id} onClick={() => stakeHelper.setValue(stake)}>
										<Image
											src={stake.avatar}
											duration={null}
											showLoading
											sx={{ border: '1px solid #000000', borderRadius: '10px', marginTop: '5px' }}
										/>
										<ImageListItemBar
											title={stake.name}
											position="below"
											sx={{ textAlign: 'center' }}
										/>
									</ImageListItem>
								))}
							</ImageList>
						</div>
					</div>
				))}
			{stakes
				.filter(category => category.custom)
				.map(category => (
					<div key={category.categoryId}>
						<Box bgcolor="#FFF4CC">
							<Typography>{category.categoryName}</Typography>
						</Box>
						<TextField inputRef={refCustomText} sx={{ width: '90%' }}></TextField>
						<Button
							variant="blYellow"
							onClick={() =>
								stakeHelper.setValue({
									...category.stakeDetails[0],
									name: refCustomText.current.value
								})
							}
						>
							Update
						</Button>
					</div>
				))}
		</React.Fragment>
	) : (
		<LoadingPage />
	)
}

export default ChallengeStakesPage
