import { Close } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import { useRef, useState, useEffect } from 'react'
import blankAvatar from '../../assets/user/blank-avatar.png'

const ImageInputBox = ({
	onChange,
	onSwap,
	handleRemove,
	width = 350,
	height = 250,
	suggestionText,
	image,
	index,
	inputSize
}) => {
	const [previewUrl, setPreviewUrl] = useState(null)
	const fileInputRef = useRef(null)

	useEffect(() => {
		if (typeof image === 'string') {
			setPreviewUrl(image)
		} else if (image instanceof File) {
			const reader = new FileReader()
			reader.onload = () => {
				setPreviewUrl(reader.result)
			}
			reader.readAsDataURL(image)
		} else {
			setPreviewUrl(null)
		}
	}, [image])

	const handleClick = () => {
		if (previewUrl && index !== 0) {
			onSwap()
		} else if (!previewUrl) {
			fileInputRef.current.click()
		}
	}

	const handleFileChange = e => {
		const file = e.target.files[0]

		if (!file || !file.type.startsWith('image/')) {
			return
		}

		onChange(file)
	}

	const handleRemoveImage = e => {
		e.stopPropagation()
		setPreviewUrl(null)
		handleRemove()
	}

	const containerStyle = {
		position: 'relative',
		border: '1px dashed #9FA4B4',
		borderRadius: '10px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		cursor: 'pointer',
		// width: `${width}px`,
		// height: `${height}px`
		width: `${width}`,
		height: `${height}`
	}

	const imageStyle = {
		// width: `${width}px`,
		// height: `${height}px`,
		height: height,
		width: width,
		objectFit: 'cover',
		borderRadius: previewUrl ? '10px' : 0
	}

	const blankAvatarSize = index === 0 ? 48 : 28

	return (
		<div style={containerStyle} onClick={handleClick}>
			{previewUrl && <img src={previewUrl} alt="Preview" style={imageStyle} />}
			{previewUrl && (
				<IconButton
					aria-label="remove picture"
					onClick={handleRemoveImage}
					style={{ position: 'absolute', top: 0, right: 0 }}
				>
					<Close style={{ color: 'white', fontSize: '18px' }} />
				</IconButton>
			)}
			<input
				type="file"
				accept="image/*"
				onChange={handleFileChange}
				style={{ display: 'none' }}
				ref={fileInputRef}
			/>
			{!previewUrl && (
				<Stack
					direction="column"
					justifyContent={index === 0 ? 'center' : 'flex-start'}
					height="100%"
					mt={'10px'}
				>
					<IconButton aria-label="upload picture" component="span">
						<img
							src={blankAvatar}
							alt="Blank Avatar"
							width={blankAvatarSize}
							height={blankAvatarSize}
						/>
					</IconButton>
					{suggestionText && (
						<Typography
							variant="note"
							align="center"
							style={{
								margin: inputSize == 'large' ? '0 46px' : '0 15px',
								color: '#969BAB',
								fontSize: '12px'
							}}
						>
							<i>{suggestionText}</i>
						</Typography>
					)}
				</Stack>
			)}
		</div>
	)
}

export default ImageInputBox
