import registrationFormModel from "./registrationFormModel";

const {
  formField: {
    biography,
    dateOfBirth,
    email,
    gender,
    location,
    password,
    passwordConfirm,
    profile: {
      frequency,
      level,
      openToPlaces,
      placesIndoor,
      placesOutdoor,
      playground,
      sportCode,
      sportGots,
      sportSkills,
      sportStyles: [],
      sportTimeWeekdayDay,
      sportTimeWeekdayEvening,
      sportTimeWeekend,
      yearsOfExperience,
    },
    name,
    userImage,
    recaptcha,
  },
} = registrationFormModel;

const registationInitialValues = {
  [biography.name]: "",
  [dateOfBirth.name]: "2000-01-01T16:00:00.000Z",
  [email.name]: "",
  [gender.name]: "",
  [location.name]: null,
  [password.name]: "",
  [passwordConfirm.name]: "",
  profile: {
    [frequency.name]: "",
    [level.name]: "Beginner",
    [openToPlaces.name]: false,
    [placesIndoor.name]: false,
    [placesOutdoor.name]: false,
    [playground.name]: [],
    [sportCode.name]: "RCL",
    sportGots: [],
    sportSkills: [],
    sportStyles: [],
    [sportTimeWeekdayDay.name]: false,
    [sportTimeWeekdayEvening.name]: false,
    [sportTimeWeekend.name]: false,
    [yearsOfExperience.name]: "",
  },
  [name.name]: "",
  [userImage.name] : null,
  [recaptcha.name]: null,
};

export default registationInitialValues;