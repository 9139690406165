import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../store/auth-context'

const NonAuthRoute = ({ component: Component, ...props }) => {
	const location = useLocation()
	const { user, isLoggedIn, setRedirectToAfterSignIn } = useAuth()

	if (user) {
		return <Navigate to={'/home'} replace />
	} else if (isLoggedIn) {
		setRedirectToAfterSignIn(location.pathname)
		return <Navigate to="/signup" replace />
	} else {
		return <Component {...props} />
	}
}

export default NonAuthRoute
