const createChallengeFormModel = {
  formId: "createChallengeForm",
  formField: {
    type: {
      name: "type",
    },
    name: {
      name: "name",
    },
    description: {
      name: "description",
    },
    startDate: {
      name: "startDate",
    },
    endDate: {
      name: "endDate",
    },
    stake: {
      name: "stake",
    },
  },
};

export default createChallengeFormModel;
