import CheckBox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import Image from "mui-image";

const SportCardInput = (props) => {
  const { arrayHelpers, item, name, index } = props;

  const [field, , helper] = useField({
    name: `${name}[${index}].id`,
    type: "checkbox",
  });

  const _onChange = (e) => {
    if (e.target.checked === true) {
      helper.setValue(e.target.value);
    } else {
      arrayHelpers.replace(index, null);
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="140"
          image={item.avatar}
          alt={item.name}
        /> */}
        <Image
          height="140px"
          src={item.avatar}
          alt={item.name}
          duration={null}
          showLoading
        />
        <CardActions>
          <FormControlLabel
            control={<CheckBox color="secondary" />}
            label={item.name}
            {...field}
            onChange={_onChange}
            value={item.id}
          />
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default SportCardInput;
