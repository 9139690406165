import { Avatar, Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import { useAuth } from '../../store/auth-context'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import FriendshipHandler from '../Friends/FriendshipHandler'
import EditIcon from '@mui/icons-material/Edit'

const UserHeader = ({
	image,
	name,
	age,
	hometown,
	location,
	work,
	noShowAge,
	userDetails,
	showBackArrow,
	sportCode,
	showPencilIcon
}) => {
	const { user } = useAuth()
	const params = useParams()
	const [bgImageLoaded, setBgImageLoaded] = useState(false)
	const navigate = useNavigate()
	const urlLocation = useLocation()
	const pathName = urlLocation?.pathname?.split('/')[1]

	useEffect(() => {
		if (image) {
			setBgImageLoaded(false)
			const img = new Image()
			img.src = image
			if (image) {
				img.onload = () => {
					setBgImageLoaded(true)
				}
			} else {
				setBgImageLoaded(true)
			}
			setTimeout(() => {
				setBgImageLoaded(true)
			}, 1000)
		} else {
			setBgImageLoaded(true)
		}
	}, [image])

	const descriptorColor = '#969BAB'
	const Descriptor = ({ Icon, value, labelKey }) => (
		<React.Fragment>
			<Icon
				fontSize="small"
				sx={{
					color: descriptorColor,
					marginTop: ['birthday', 'work'].includes(labelKey) ? '-4px' : 0
				}}
			/>
			<Typography variant="p" sx={{ lineHeight: '22px' }} color={descriptorColor}>
				{value}
			</Typography>
		</React.Fragment>
	)

	return (
		<Grid container direction="row" position={'relative'}>
			<Grid item container xs={4} justifyContent="center">
				<div>
					<Stack alignItems="center">
						{bgImageLoaded ? (
							<Avatar src={image} sx={{ margin: '5px', width: '60px', height: '60px' }} />
						) : (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '70px'
								}}
							>
								<CircularProgress style={{ width: '20px', height: '20px' }} />
							</Box>
						)}
						<Typography variant="h3" fontSize="14px" fontWeight="700">
							{name}
						</Typography>
					</Stack>
				</div>
			</Grid>
			<Grid item xs={8} direction="column" justifyContent="start" display="flex">
				{showPencilIcon && pathName !== 'browse-profiles' && params?.alias === user?.alias && (
					<Button
						id="basic-button"
						sx={{ position: 'absolute', top: '0', right: '0' }}
						aria-haspopup="true"
						onClick={() => navigate(`/p/${user?.alias}/edit`)}
					>
						<EditIcon />
					</Button>
				)}
				<Stack direction="column" spacing={1} height={'100%'} justifyContent={'space-around'}>
					<Stack
						direction="row"
						spacing={1}
						sx={{ marginTop: '3px !important' }}
						alignItems="center"
					>
						{!noShowAge && age > 0 && (
							<Descriptor Icon={CakeOutlinedIcon} value={age} labelKey="birthday" />
						)}
						{location && (
							<Descriptor Icon={LocationOnOutlinedIcon} value={location} labelKey={'location'} />
						)}
						{hometown && (
							<Descriptor Icon={HomeOutlinedIcon} value={hometown} labelKey="hometown" />
						)}
					</Stack>
					{work && (
						<Stack
							direction="row"
							spacing={1}
							sx={{ marginTop: '3px !important' }}
							alignItems="center"
						>
							<Descriptor Icon={BusinessCenterOutlinedIcon} value={work} labelKey={'work'} />
						</Stack>
					)}
					<Box>
						{params?.alias !== user?.alias && (
							<FriendshipHandler
								userDetails={userDetails}
								showBackArrow={showBackArrow}
								sportCode={sportCode}
							/>
						)}
					</Box>
				</Stack>
			</Grid>
		</Grid>
	)
}

export default UserHeader
