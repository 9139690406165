import { Button, Stack, Checkbox } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
import GenderSelect from '../../components/registration/step6/GenderSelect'
import LocationPicker from '../../components/registration/step6/LocationPicker'
import DateOfBirthField from '../../components/ui/form/DateOfBirthField'
import MyTextField from '../../components/ui/form/MyTextField'
import DateOfBirthCheckBox from '../../components/ui/form/DateOfBirthCheckBox'
import ImgUpload from '../../components/ui/ImgUpload'
import UserService from '../../services/UserService'
import createUserInitialValues from './createUserInitialValues'
import createUserValidationSchema from './createUserValidationSchema'
import useNotification from '../../hooks/useNotification'
import MetaData from '../../components/MetaData'
import {
	East as EastIcon,
	Wc as WcIcon,
	Mail as MailIcon,
	Cake as CakeIcon,
	MapOutlined as MapOutlinedIcon,
	CakeOutlined
} from '@mui/icons-material'

const { formId } = createUserInitialValues

const CreateUserPage = () => {
	const { updateUser, user } = useAuth()
	const navigation = useNavigate()
	const [, sendNotification] = useNotification()

	const initialValues = useMemo(() => {
		const userDataString = localStorage.getItem('userData')
		const { user } = userDataString ? JSON.parse(userDataString) : {}
		const { viewUrl } = user?.userImage || {}
		return {
			...createUserInitialValues,
			name: user?.name,
			avatarImage: viewUrl,
			email: user?.email,
			gender: ''
		}
	}, [])

	const handleSubmit = values => {
		const { avatarImage, ...rest } = values

		const request = { ...rest, name: values.name.trim() }

		if (avatarImage && avatarImage !== initialValues.avatarImage) {
			// means user has uploaded a new image
			request.userImage = { uploadBase64Data: avatarImage }
		} else if (avatarImage) {
			// still the social image from Google/Facebook
			request.userImage = { viewUrl: avatarImage }
		}

		UserService.createUser(request)
			.then(response => {
				updateUser(response.data)
				navigation(`/p/${response.data.alias}/createSportProfile`, { state: { refer: 'signUp' } })

				//const redirectTo = localStorage.getItem('redirectTo')
				//localStorage.removeItem('redirectTo')
				//navigation(redirectTo || '/challenge/create')
			})
			.catch(error => {
				sendNotification({
					msg: error.response.data.message || 'An Error Occurred',
					variant: 'error'
				})
			})
	}

	return (
		<React.Fragment>
			<MetaData title={`BelayLink - SignUp`} description={'BelayLink SignUp'} />
			<Formik
				initialValues={initialValues}
				validationSchema={createUserValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, isSubmitting, values }) => (
					<Form id={formId}>
						<Stack spacing={3} direction="column">
							<div align="center" style={{ marginTop: '3vh' }}>
								<ImgUpload name="avatarImage" label="Change profile picture" />
							</div>

							<div align="center">
								<MyTextField name="name" label="Name" required />
							</div>

							{/*
								!initialValues.email &&
								<div align="center">
									<MailIcon color="disabled" /><br/>
									<MyTextField name="email" label="Email" required/>
								</div>
								*/}

							<div align="center">
								<WcIcon color="disabled" />
								<br />
								<GenderSelect />
							</div>

							<div align="center">
								<MapOutlinedIcon color="disabled" />
								<br />
								<LocationPicker name="location" label="Location" required />
							</div>

							<div align="center">
								<CakeOutlined color="disabled" />
								<br />
								<DateOfBirthField name="dateOfBirth" disableFuture={true} required />
								<br />
								<DateOfBirthCheckBox name="noShowAge" label="Don’t show my age" />
							</div>

							<div align="right">
								<Button
									type="submit"
									variant="blGreen"
									sx={{
										width: '30%',
										marginRight: '10%',
										marginBottom: '20px',
										'&:hover': {
											background: '#79ddd1'
										}
									}}
								>
									<EastIcon sx={{ color: '#fff', height: '40px' }}></EastIcon>
								</Button>
							</div>
						</Stack>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	)
}

export default CreateUserPage
