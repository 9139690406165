import React, { useEffect, useState, useRef } from 'react'
//import Autocomplete from '@mui/material/Autocomplete'
import GooglePlaceAutocomplete, { usePlacesWidget } from 'react-google-autocomplete'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
//import TextField from '@mui/material/TextField'
//import LocationService from '../../../services/LocationService'
//import CircularProgress from '@mui/material/CircularProgress'
import { FormControl, TextField, FormLabel, InputAdornment } from '@mui/material'
//import { Search } from '@mui/icons-material'
import { useField } from 'formik'
import ErrorMessageBL from '../../ui/ErrorMessageBL'

const LocationPicker = ({ name, label, required }) => {
	const [field, meta, helper] = useField(name)
	const [locationName, setLocationName] = useState(field.value?.name || null)
	const isLocationSelected = useRef(false)

	const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
		usePlacesService({
			apiKey: process.env.REACT_APP_GOOGLE_API_KEY
		})
	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		onPlaceSelected: place => {
			setLocationName(place.address_components[0].short_name)
			helper.setValue({ placeId: place.place_id, name: place.address_components[0].short_name })
			isLocationSelected.current = true
		}
	})

	return (
		<FormControl
			sx={{ width: '60%' }}
			required={required}
			error={meta.touched && Boolean(meta.error)}
		>
			<TextField
				inputRef={ref}
				label={label}
				name="locationName"
				required={required}
				error={meta.touched && Boolean(meta.error)}
				helperText={meta.touched && meta.error}
				onChange={e => {
					isLocationSelected.current = false
					getPlacePredictions({ input: e.target.value })
					setLocationName(e.target.value)
					helper.setValue('')
				}}
				onBlur={() => {
					if (!isLocationSelected.current) {
						setLocationName('')
					}
				}}
				value={locationName}
			/>

			<TextField
				label="location"
				name="location"
				required
				{...field}
				value={field.value?.name}
				sx={{ display: 'none' }}
			/>
		</FormControl>
	)
}

/*
const LocationPicker = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const [field, meta, helper] = useField("location");

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        LocationService.getLocations().then((response) => {
          setOptions(response.data);
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <FormControl>
      <FormLabel id="location-label" align="center" error={meta.touched && Boolean(meta.error)}>
        City
      </FormLabel>
      <Autocomplete
        id="asynchronous-location"
        {...field}
        onChange={(event, newValue) => {
          helper.setValue(newValue);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.cityNameEn} ${option.cityNameZh}`}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Location"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <ErrorMessageBL name={field.name} />
    </FormControl>
  );
};
*/

export default LocationPicker
