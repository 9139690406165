import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import useNotification from '../../hooks/useNotification'
import UserService from '../../services/UserService'
import { useAuth } from '../../store/auth-context'
import LoadingPage from '../LoadingPage'
import { Sendrequest } from '../../components/firebase/Sendrequest'

const formUserDataFromJwtToken = token => {
	let userData = {}
	const decodedToken = jwt_decode(token)

	if (decodedToken) {
		const { name, email, picture, identities } = decodedToken

		// use social media image as profile image
		let socialImageUrl = ''
		if (identities) {
			const { providerName } = identities[0]
			// Google
			if (providerName === 'Google') {
				socialImageUrl = picture || ''
			}
			// Facebook
			if (providerName === 'Facebook') {
				const { url } = JSON.parse(picture)?.data || {}
				socialImageUrl = url || ''
			}
		}

		userData = {
			name,
			email,
			userImage: {
				viewUrl: socialImageUrl
			}
		}
	}
	return userData
}

const AwsSignInPage = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { login, setExpireTime, exchangeCodeForToken } = useAuth()
	const [, sendNotification] = useNotification()

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		const code = queryParams.get('code')

		if (code) {
			exchangeCodeForToken(code)
				.then(tokens => {
					// Assuming you receive the tokens including id_token and access_token
					const idToken = tokens.id_token
					const refreshToken = tokens.refresh_token
					const { exp } = jwt_decode(idToken) || {}
					setExpireTime(exp)
					localStorage.setItem('token', idToken)
					localStorage.setItem('refreshToken', refreshToken)
					UserService.getUserData(idToken)
						.then(({ data }) => {
							login({ user: data, token: idToken, refreshToken: refreshToken })
							const redirectTo = localStorage.getItem('redirectTo')
							navigate(redirectTo || '/')
							Sendrequest() // set FCM token
						})
						.catch(error => {
							if (error.response) {
								const { status } = error.response

								// user not created
								if (status === 404) {
									login({
										user: formUserDataFromJwtToken(idToken),
										token: idToken,
										refreshToken: refreshToken
									})
									navigate('/signup')
								}
							} else {
								sendNotification({
									msg: error.response.data.message,
									variant: 'error'
								})
							}
						})
				})
				.catch(error => {
					// Handle errors during token exchange
					console.error('Error during token exchange:', error)
					sendNotification({
						msg: 'Authentication failed, please try again',
						variant: 'error'
					})
				})
		}
	}, [location, login, navigate, sendNotification, setExpireTime, exchangeCodeForToken])

	return <LoadingPage />
}

export default AwsSignInPage
