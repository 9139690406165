import React, { createContext, useState } from 'react'

export const ShareContext = createContext()

export const ShareProvider = ({ children }) => {
	const [shareDetails, setShareDetails] = useState({
		url: process.env.REACT_APP_URL,
		title: document.title,
		text: 'Find sports partners at BelayLink!'
	})

	const updateShareDetails = details => {
		setShareDetails({ ...shareDetails, ...details })
	}

	return (
		<ShareContext.Provider value={{ shareDetails, updateShareDetails }}>
			{children}
		</ShareContext.Provider>
	)
}
