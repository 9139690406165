import baseApi from './baseApiService'
import { SPORT_URL, PLACE_URL } from '../constants/ApiUrls'

class PlaceService {
  getPlaceList(sportCode) {
    return baseApi.get(SPORT_URL + `/${sportCode}/` + PLACE_URL)
  }
}

export default new PlaceService()
