import { Button, Stack, Checkbox } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
import GenderSelect from '../../components/registration/step6/GenderSelect'
import LocationPicker from '../../components/registration/step6/LocationPicker'
import DateOfBirthField from '../../components/ui/form/DateOfBirthField'
import MyTextField from '../../components/ui/form/MyTextField'
import DateOfBirthCheckBox from '../../components/ui/form/DateOfBirthCheckBox'
import ImgUpload from '../../components/ui/ImgUpload'
import UserService from '../../services/UserService'
import editUserInitialValues from './editUserInitialValues'
import useNotification from '../../hooks/useNotification'
import MetaData from '../../components/MetaData'
import {
	East as EastIcon,
	Wc as WcIcon,
	Mail as MailIcon,
	Cake as CakeIcon,
	MapOutlined as MapOutlinedIcon,
	HomeOutlined,
	BusinessCenterOutlined,
	CakeOutlined
} from '@mui/icons-material'
import { useEffect } from 'react'
import editUserValidationSchema from './editUserValidationSchema'
import { useState } from 'react'
import MyButton from '../../components/ui/MyButton'
import Loading from '../../components/ui/Loading'
import { LoadingContext } from '../../store/loading-context'

const { formId } = editUserInitialValues

const EditUserProfilePage = () => {
	const { updateUser, user } = useAuth()
	const navigation = useNavigate()
	const [, sendNotification] = useNotification()
	const [isLoading, setIsLoading] = useContext(LoadingContext)

	// useEffect(() => {
	// 	UserService.getMe(user.alias).then(response => {
	// 		editUserInitialValues.values = {
	// 			...response.data
	// 		}
	// 		setUserProfile(response.data)
	// 	})
	// }, [])

	const [initialValues, setInitialValues] = useState(null)

	useEffect(() => {
		UserService.getMe().then(response => {
			setInitialValues(response.data)
		})
	}, [])

	if (!initialValues) {
		return <Loading />
	}

	const handleSubmit = values => {
		window.scroll(0, 0) //to show full loading screen during isLoading True
		const { userImage, ...rest } = values
		const request = { ...rest }

		if (userImage && userImage !== initialValues.userImage) {
			// means user has uploaded a new image
			request.userImage = { uploadBase64Data: userImage }
		}
		setIsLoading(true)
		UserService.updateUser(request)
			.then(response => {
				updateUser({ ...response.data, metaData: user.metaData }) // adding metaData from user context because every time in response getting metaData null
				setIsLoading(false)
				navigation(`/p/${response.data.alias}`)
			})
			.catch(error => {
				setIsLoading(false)

				sendNotification({
					msg: error.response.data.message || 'An Error Occurred',
					variant: 'error'
				})
			})
	}

	return (
		<React.Fragment>
			<MetaData title={`BelayLink - Edit User`} description={'BelayLink Edit User'} />
			<Formik
				initialValues={initialValues}
				validationSchema={editUserValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, isSubmitting, values }) => (
					<Form id={formId}>
						<Stack spacing={3} direction="column">
							<div align="center" style={{ marginTop: '3vh' }}>
								<ImgUpload name="userImage" label="Change profile picture" />
							</div>

							<div align="center">
								<MyTextField name="name" label="Name" required disabled />
							</div>

							{/* <div align="center">
								<WcIcon color="disabled" />
								<br />
								<GenderSelect />
							</div> */}

							<div align="center">
								<MapOutlinedIcon color="disabled" />
								<br />
								<LocationPicker name="location" label="Location" required />
							</div>

							<div align="center">
								<HomeOutlined color="disabled" />
								<br />
								<LocationPicker name="hometown" label="Hometown" required />
							</div>

							<div align="center">
								<BusinessCenterOutlined color="disabled" />
								<br />
								<MyTextField name="work" label="Profession" />
							</div>

							<div align="center">
								<CakeOutlined color="disabled" />
								<br />
								<DateOfBirthField name="dateOfBirth" disabled required />
								<br />
								<DateOfBirthCheckBox name="noShowAge" label="Don’t show my age" />
							</div>

							<div align="right" style={{ marginBottom: '20px' }}>
								<MyButton type="submit" variant="blGreen" sx={{ width: '30%', marginRight: '10%' }}>
									<EastIcon sx={{ color: '#fff', height: '40px' }}></EastIcon>
								</MyButton>
							</div>
						</Stack>
					</Form>
				)}
			</Formik>
			{isLoading && <Loading />}
		</React.Fragment>
	)
}

export default EditUserProfilePage
