import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ErrorMessage, useField } from "formik";
import { FormHelperText } from "@mui/material";
import ErrorMessageBL from "../../ui/ErrorMessageBL";

const frequencyList = ["Occasionally", "Sometimes", "Regularly"];

const FrequencyPlayed = () => {

  const [field, meta] = useField("profile.frequency");

  return (
    <FormControl error={!!meta.error && meta.touched}>
      {/* <FormLabel id="frequency-played-label">Frequency</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="frequency-played-group-label"
        name="frequency-played-group"
        {...field}
      >
        {frequencyList.map((frequency) => (
          <FormControlLabel
            key={frequency}
            value={frequency}
            control={<Radio />}
            label={frequency}
          />
        ))}
      </RadioGroup>
      <ErrorMessageBL name={field.name}/>
    </FormControl>
  );
};

export default FrequencyPlayed;
