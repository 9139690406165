import { Button } from '@mui/material'

const ShareButton = ({ buttonText, text, title, extension }) => {
	const url = `${process.env.REACT_APP_URL}/${extension}`
	const shareDetails = {
		url: url,
		title: title,
		text: text
	}

	const handleShareButton = () => {
		// Check if navigator.share is supported by the browser
		if (navigator.share) {
			navigator
				.share(shareDetails)
				.then(() => {
					console.log('Sharing successfull')
				})
				.catch(() => {
					console.log('Sharing failed')
				})
		} else {
			console.log('Sorry! Your browser does not support Web Share API')
		}
	}

	return (
		<Button variant="blGreen" fullWidth onClick={handleShareButton}>
			{buttonText}
		</Button>
	)
}

export default ShareButton
