import { FmdGoodOutlined } from '@mui/icons-material'
import { Box, Modal, Typography } from '@mui/material'
import MyButton from '../ui/MyButton'
import { useState } from 'react'
import GoogleMaps from '../GoogleMaps'
import UserService from '../../services/UserService'
import { useAuth } from '../../store/auth-context'
import useNotification from '../../hooks/useNotification'

const LocationUpdateModal = ({ handleLocationClose, style }) => {
	const { updateUser, user } = useAuth()
	const [location, setLocation] = useState(user.location)
	const [initialValues] = useState(user)
	const [, sendNotification] = useNotification()

	const handleSubmit = () => {
		const request = { ...initialValues }

		if (location && location !== initialValues.location) {
			request.location = location

			UserService.updateUser(request)
				.then(response => {
					updateUser({ ...response.data, metaData: user.metaData })
				})
				.catch(error => {
					sendNotification({
						msg: error.response.data.message || 'An Error Occurred',
						variant: 'error'
					})
				})
		}
		handleLocationClose()
	}

	return (
		<Modal open={true} onClose={handleLocationClose}>
			<Box sx={style}>
				<FmdGoodOutlined sx={{ width: '40px', height: '40px', color: 'gray' }} />
				<GoogleMaps
					onSelect={(placeId, name) => setLocation({ placeId: placeId, name: name })}
					isClearValueOnClick={false}
					label="Your Location"
					initialValue={location}
				/>
				<MyButton onClick={handleSubmit}>
					<Typography variant="h2" color="white">
						Update
					</Typography>
				</MyButton>
			</Box>
		</Modal>
	)
}

export default LocationUpdateModal
