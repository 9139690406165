import React from 'react'
import { Box, Grid } from '@mui/material'

const SportSessionTypes = ({ biography, sessionTypes }) => {
	return (
		<>
			{(sessionTypes?.length > 0 || biography) && (
				<Grid
					sx={{
						background: 'white',
						p: '10px',
						pl: '15px', //view profile pl:'10px'
						// pt: '10px',
						// pb: '0px',
						borderRadius: '10px'
					}}
				>
					<Box color={'#474A57'} sx={{ fontWeight: '500', fontSize: '15px', marginBottom: '3px' }}>
						Types of sessions I’m up for
					</Box>

					<Grid className="d-flex aic" sx={{ flexWrap: 'wrap' }}>
						{sessionTypes?.length > 0 &&
							sessionTypes?.map((session, index) => {
								return (
									<Box
										key={index}
										sx={{
											background: '#EEEFF4',
											borderRadius: '20px',
											padding: '3px 10px',
											margin: '4px 8px 4px 0',
											fontSize: '12px',
											fontWeight: '600'
										}}
									>
										{session}
									</Box>
								)
							})}
					</Grid>
					{biography && (
						<Grid
							mt="10px"
							// mb="10px"
							// px="5px"
							sx={{ fontWeight: '600', fontSize: '15px', wordBreak: 'break-word' }}
						>
							{biography}
						</Grid>
					)}
				</Grid>
			)}
		</>
	)
}

export default SportSessionTypes
