const createUserFormModel = {
	formId: 'createUserForm',
	formField: {
		biography: {
			name: 'biography',
			label: 'Biography'
		},
		name: {
			name: 'name',
			label: 'Name*',
			requiredErrorMsg: 'Name is required'
		},
		email: {
			name: 'email',
			label: 'Email*',
			requiredErrorMsg: 'Email is required'
		},
		gender: {
			name: 'gender',
			label: 'Gender*',
			requiredErrorMsg: 'Gender is required'
		},
		location: {
			name: 'location',
			label: 'Where Are You',
			requiredErrorMsg: 'Location is required'
		},
		avatarImage: {
			name: 'userImage',
			label: 'User Image'
		},
		dateOfBirth: {
			name: 'dateOfBirth',
			label: 'Cake Day*',
			requiredErrorMsg: 'Cake Day is required'
		},
		noShowAge: {
			name: 'noShowAge',
			label: 'Don’t show my age'
		}
	}
}

export default createUserFormModel
