import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../store/auth-context'

const AuthRoute = ({ component: Component, ...props }) => {
  const location = useLocation()
  const { user, isLoggedIn, awsSignIn, setRedirectToAfterSignIn } = useAuth()

  if (!isLoggedIn) {
    return awsSignIn(location.pathname)
  } else if (!user) {
    setRedirectToAfterSignIn(location.pathname)
    return <Navigate to="/signup" replace />
  } else {
    return <Component {...props} />
  }
}

export default AuthRoute
