const registrationFormModel = {
  formId: "registrationForm",
  formField: {
    biography: {
      name: "biography",
      label: "Biography",
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Cake Day*",
      requiredErrorMsg: "Cake Day is required",
    },
    gender: {
      name: "gender",
      label: "Gender*",
      requiredErrorMsg: "Gender is required",
    },
    email: {
      name: "email",
      label: "Email*",
      validEmailErrorMsg: "Email must be a valid email",
      requiredErrorMsg: "Email is required",
    },
    location: {
      name: "location",
      label: "Where Are You",
      requiredErrorMsg: "Location is required"
    },
    password: {
      name: "password",
      label: "Password",
      requiredErrorMsg: "Password is required"
    },
    passwordConfirm: {
      name: "passwordConfirm",
      label: "Confirm Password",
      mustMatchErrorMsg: "Password does not match",
      requiredErrorMsg: "Confirm Password is required"
    },
    profile: {
      frequency: {
        name: "frequency",
        label: "Frequency*",
        requiredErrorMsg: "Frequency is required",
      },
      level: {
        name: "level",
        label: "Level*",
        requiredErrorMsg: "Level is required",
      },
      openToPlaces: {
        name: "openToPlaces",
        label: "Open to other places"
      },
      placesIndoor: {
        name: "placesIndoor",
        label: "Indoor"
      },
      placesOutdoor: {
        name: "placesOutdoor",
        label: "Outdoor"
      },
      playground: {
        name: "playground",
        label: "Playground"
      },
      sportCode: {
        name: "sportCode",
        label: "Sport Code",
      },
      sportGots: {
        name: "sportGots",
        label: "GOT",
      },
      sportSkills: [{
        id: {
          name: "id"
        }
      }],
      sportStyles: [{
        id: {
          name: "id"
        },
        allOut: {
          name: "allOut"
        },
        warmUp: {
          name: "warmUp"
        }
      }],
      sportTimeWeekdayDay: {
        name: "sportTimeWeekdayDay",
        label: "Weekday (Day)",
      },
      sportTimeWeekdayEvening: {
        name: "sportTimeWeekdayEvening",
        label: "Weekday (Evening)",
      },
      sportTimeWeekend: {
        name: "sportTimeWeekend",
        label: "Weekend",
      },
      yearsOfExperience: {
        name: "yearsOfExperience",
        label: "Years of Practice",
        requiredErrorMsg: "Years of Practice is required",
      }
    },
    name: {
      name: "name",
      label: "Name*",
      requiredErrorMsg: "Name is required",
    },
    userImage : {
      name: "userImage",
      label: "User Image",
    },
    recaptcha: {
      name: "recaptcha",
      label: "reCAPTCHA*",
      requiredErrorMsg: "Verification is required",
    }
  },
};

export default registrationFormModel;