import React, { useEffect } from 'react'

const ContactPage = () => {
	useEffect(() => {
		// Check if the script is already loaded
		if (document.querySelector('script[src="https://tally.so/widgets/embed.js"]')) {
			return
		}

		// Create and append the script
		const script = document.createElement('script')
		script.src = 'https://tally.so/widgets/embed.js'
		script.onload = function () {
			/* global Tally */
			if (typeof Tally !== 'undefined' && Tally.loadEmbeds) {
				Tally.loadEmbeds()
			} else {
				document.querySelectorAll('iframe[data-tally-src]:not([src])').forEach(function (e) {
					e.src = e.dataset.tallySrc
				})
			}
		}
		document.body.appendChild(script)
	}, [])

	return (
		<div>
			<iframe
				data-tally-src="https://tally.so/embed/nP1BDV?alignLeft=1&transparentBackground=1&dynamicHeight=1"
				loading="lazy"
				width="100%"
				height="1424"
				frameborder="0"
				marginheight="0"
				marginwidth="0"
				title="Contact us"
			></iframe>
		</div>
	)
}

export default ContactPage
