import { Toolbar, Box, Alert } from '@mui/material'
import React, { useMemo } from 'react'
import Header from '../components/ui/Header'
import useIsInFacebook from '../hooks/useIsInFacebook'
import { useLocation, useMatch, matchPath, useNavigate } from 'react-router-dom'
import Footer from '../components/ui/Footer'
import { Helmet } from 'react-helmet'

const noHeaderRoutes = ['/your-sports-partners/p/:alias/*', '/notifications']

const Navigation = props => {
	const isInFacebook = useIsInFacebook()
	const location = useLocation()

	const shouldShowHeader = useMemo(
		() => noHeaderRoutes.every(path => !matchPath(path, location.pathname)),
		[location]
	)

	return (
		<React.Fragment>
			<Helmet>
				{<meta name="theme-color" content={shouldShowHeader ? '#00C6AE' : 'white'} />}
			</Helmet>
			<Box
				display="flex"
				flexDirection="column"
				//minHeight="100dvh" // This should be viewport height, not 100%'
				maxHeight="100%"
			>
				{shouldShowHeader && (
					<>
						<Toolbar />
						<Header />
					</>
				)}

				<Box
					flex="1 0 auto" // This will make this box grow and shrink as needed
				>
					{isInFacebook && (
						<Alert severity="warning" sx={{ marginBottom: 4 }}>
							Please open with your browser to sign up
						</Alert>
					)}

					{props.children}
				</Box>

				<Box flexShrink={0}>
					{' '}
					{/* This will prevent the footer from shrinking */}
					{location.pathname === '/' && <Footer />}
				</Box>
			</Box>
		</React.Fragment>
	)
}

export default Navigation
