import { Box, Button, Chip, Modal, TextField, Typography } from '@mui/material'
import DateUtils from '../../shared/DateUtils'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import interestedImg from '../../assets/icon/interested.png'
import { CloseOutlined } from '@mui/icons-material'
import chatServiceInstance from '../../services/ChatService'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
import Loading from '../ui/Loading'
import { LoadingContext } from '../../store/loading-context'
import useNotification from '../../hooks/useNotification'
import { ChatContext } from '../../store/chat-context'

const TagItem = ({ children }) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const truncatedText = children.length > 20 ? children.substring(0, 20) + '...' : children

	const expandText = () => {
		setIsExpanded(!isExpanded)
	}

	return (
		<Typography
			onClick={expandText}
			sx={{
				color: 'black',
				borderRadius: '20px',
				backgroundColor: 'white',
				textTransform: 'capitalize',
				padding: '6px 16px',
				textAlign: 'center'
			}}
			variant="h4"
		>
			{isExpanded ? children : truncatedText}
		</Typography>
	)
}

const SportStatus = status => {
	const { user } = useAuth()
	const navigate = useNavigate()
	const [isInterestedModalOpen, setIsInterestedModalOpen] = useState(false)
	const [interestedMessage, setInterestedMessage] = useState('')
	const [selectedTag, setSelectedTag] = useState('')
	const [isLoading, setIsLoading] = useContext(LoadingContext)
	const [, sendNotification] = useNotification()
	const { chatState, getChannels } = useContext(ChatContext)

	const [interestedTags, setInterestedTags] = useState([
		{ name: 'I’ll be there!', value: 'i_will_be_there', selected: false },
		{ name: 'Can I join?', value: 'can_i_join', selected: false },
		{ name: 'Tell me more!', value: 'tell_me_more', selected: false }
	])

	const [dateChips, setDateChips] = useState([])
	useEffect(() => {
		createDateChips()
	}, [status?.status])

	useEffect(() => {
		if (selectedTag === 'I’ll be there!') {
			setInterestedMessage(
				`Hi ${status?.sportUser?.name}, I just saw you are planning to go ${status?.sportUser?.sport?.name}! I’ll be there! `
			)
		} else if (selectedTag === 'Can I join?') {
			setInterestedMessage(
				`Hi ${status?.sportUser?.name}, I just saw you are planning to go ${status?.sportUser?.sport?.name}! Can I join?`
			)
		} else if (selectedTag === 'Tell me more!') {
			setInterestedMessage(
				`Hi ${status?.sportUser?.name}, I just saw you are planning to go ${status?.sportUser?.sport?.name}! Tell me more!`
			)
		} else {
			setInterestedMessage('')
		}
	}, [interestedTags, isInterestedModalOpen])

	const createDateChips = () => {
		// Sorting Array based on dates
		status.status?.dates.sort((a, b) => {
			{
				const dateA = new Date(a.startDate)
				const dateB = new Date(b.startDate)

				if (dateA < dateB) {
					return -1
				} else if (dateA > dateB) {
					return 1
				} else {
					return 0
				}
			}
		})
		const LABELS = ['Today', 'Tomorrow', 'This Week', 'This Weekend']

		// Inesrting labels

		const insertLabel = status.status?.dates.map(date => {
			const dateLabel = DateUtils.getLabelForDateRange(moment(date.startDate), moment(date.endDate))
			return {
				start: moment(date.startDate),
				end: moment(date.endDate),
				label: LABELS.includes(dateLabel) ? dateLabel : 'custom'
			}
		})
		const getCustomLabelDate = insertLabel.filter(obj => obj.label === 'custom')
		const getweekLabel = insertLabel.filter(obj => obj.label !== 'custom').map(dObj => dObj.label)
		const result = []

		// create date range for custom selected dates

		for (let i = 0; i < getCustomLabelDate.length; i++) {
			const startDate = new Date(getCustomLabelDate[i].start._d)
			const endDate = new Date(startDate)
			while (
				i < getCustomLabelDate.length - 1 &&
				new Date(getCustomLabelDate[i + 1]?.start._d) - endDate === 24 * 60 * 60 * 1000
			) {
				i++
				endDate.setDate(endDate.getDate() + 1)
			}
			result.push({ startDate: startDate.toString(), endDate: endDate.toString() })
		}
		const custumRange = result.map((date, index) =>
			DateUtils.getLabelForDateRange(moment(date.startDate), moment(date.endDate))
		)
		const concatArrays = getweekLabel.concat(custumRange)
		setDateChips(concatArrays)
	}

	const handleInterestedModalClose = e => {
		e.stopPropagation()
		setIsInterestedModalOpen(false)
	}

	const handleModalOpen = e => {
		e.stopPropagation()
		setIsInterestedModalOpen(true)
	}

	const handleSelectTag = (tag, index) => {
		interestedTags.splice(index, 1, { ...tag, selected: !tag.selected })
		setInterestedTags([...interestedTags])
	}

	// const redirectToChannel = async () => {
	// 	setIsLoading(true)
	// 	const channelId = `direct-${status?.sportUser?.alias}-${user?.alias}`
	// 	const payload = { channelName: channelId, content: interestedMessage, type: 'text' }

	// 	if (interestedMessage?.trim() !== '') {
	// 		chatServiceInstance
	// 			.sendMessage(payload)
	// 			.then(response => {
	// 				setIsLoading(false)
	// 				navigate('/messages/' + channelId)
	// 				if (status.indexPosition) {
	// 					localStorage.setItem('look-around-data', JSON.stringify(status.indexPosition))
	// 				}
	// 			})
	// 			.catch(e => {
	// 				setIsLoading(false)
	// 				sendNotification({
	// 					msg: e,
	// 					variant: 'error'
	// 				})
	// 			})
	// 	}
	// }

	const redirectToChannel = () => {
		const friendAlias = status?.sportUser?.alias
		const findChannel = chatState.groups?.find(channel => {
			if (channel.name.includes(friendAlias)) {
				return true
			}
		})
		if (interestedMessage?.trim() !== '') {
			if (findChannel) {
				const payload = { channelName: findChannel.name, content: interestedMessage, type: 'text' }
				chatServiceInstance
					.sendMessage(payload)
					.then(response => {
						setIsLoading(false)
						navigate('/messages/' + findChannel.name)
						if (status.indexPosition) {
							localStorage.setItem('look-around-data', JSON.stringify(status.indexPosition))
						}
					})
					.catch(e => {
						setIsLoading(false)
						sendNotification({
							msg: e,
							variant: 'error'
						})
					})
			} else {
				const payload = {
					type: 'direct',
					userAlias: friendAlias
				}
				chatServiceInstance.createChannel(payload).then(response => {
					console.log('response', response)
					getChannels()
					const payload = { channelName: response.data, content: interestedMessage, type: 'text' }
					if (interestedMessage?.trim() !== '') {
						chatServiceInstance
							.sendMessage(payload)
							.then(res => {
								console.log('res', res)
								setIsLoading(false)
								navigate('/messages/' + response.data)
								if (status.indexPosition) {
									localStorage.setItem('look-around-data', JSON.stringify(status.indexPosition))
								}
							})
							.catch(e => {
								setIsLoading(false)
								sendNotification({
									msg: e,
									variant: 'error'
								})
							})
					}
				})
			}
		}
	}

	{
		isLoading && <Loading />
	}

	return (
		<Box>
			<Box className="anchor">
				<Box
					sx={{
						width: '100%',
						background: '#FFD465',
						height: '100%',
						pt: '10px'
					}}
				></Box>
			</Box>
			<Box>
				<Box
					sx={{
						width: '100%',
						backgroundColor: '#FFD465',
						borderRadius: '10px',
						py: '21px',
						px: '20px'
					}}
				>
					<Box sx={{ gap: '5px', display: 'flex', flexWrap: 'wrap' }}>
						{dateChips?.length > 0 &&
							[...new Set(dateChips)].map((date, index) => {
								return <TagItem key={index}>{date}</TagItem>
							})}

						{status.status?.playground.map(location => (
							<TagItem key={location.placeId}>{location.name}</TagItem>
						))}
					</Box>
					{status?.status && status?.status?.message?.length > 0 && (
						<Box
							sx={{
								mt: dateChips?.length > 0 || status.status?.playground?.length > 0 ? '14px' : '0px',
								wordBreak: 'break-word',
								whiteSpace: 'pre-line'
							}}
						>
							<Typography className="fw-700">{status.status?.message}</Typography>
						</Box>
					)}
					{status?.showInterestedButton && (
						<Box
							mt={'15px'}
							sx={{ position: '', zIndex: '99', width: '100%', bottom: '50px', left: '0' }}
						>
							<Button
								onClick={e => handleModalOpen(e)}
								sx={{
									color: 'white',
									marginTop: '20px',
									borderRadius: '5px',
									border: '1px solid #00C6AE',
									textTransform: 'capitalize',
									paddingX: '20px',
									background: 'white',
									'&:hover': {
										background: '#FFF'
									},
									margin: 'auto',
									// marginBottom: '25px',
									marginTop: '5px',
									display: 'flex',
									justifyContent: 'center',
									boxShadow:
										'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
								}}
								disableElevation
							>
								<Typography
									variant="h3"
									sx={{
										color: '#00C6AE',
										whiteSpace: 'nowrap',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										width: '80%',
										fontWeight: '700'
									}}
								>
									Interested!
								</Typography>
							</Button>
						</Box>
					)}
					<Modal
						open={isInterestedModalOpen}
						onClose={handleInterestedModalClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={successModalStyle} onClick={e => e.stopPropagation()}>
							<Box
								sx={{
									position: 'absolute',
									top: '10px',
									right: '18px',
									color: 'gray'
								}}
							>
								<CloseOutlined onClick={handleInterestedModalClose} />
							</Box>

							<Box
								sx={{
									fontSize: '16px',
									fontWeight: '600',
									marginTop: '5px'
								}}
							>
								<Box sx={{ fontWeight: '700', fontSize: '23px' }}>Interested!</Box>
							</Box>
							<Box>
								<img src={interestedImg} style={{ width: '69px' }} />
							</Box>
							<Box display={'flex'} flexWrap={'wrap'} marginTop="-20px">
								{interestedTags.map((tag, i) => {
									return (
										<Typography
											key={i}
											size="small"
											sx={{
												background: selectedTag === tag.name ? '#61E4C5' : '#EEEFF4',
												color: 'black',
												fontWeight: '500',
												marginRight: '15px',
												marginTop: '15px',
												cursor: 'pointer',
												borderRadius: '20px',
												padding: '6px 16px',
												fontSize: '12px',
												width: 'fit-content'
											}}
											onClick={() => {
												handleSelectTag(tag, i)
												setSelectedTag(tag.name)
											}}
										>
											{tag.name}
										</Typography>
									)
								})}
							</Box>
							<Box sx={{ width: '100%' }}>
								<TextField
									sx={{ width: '100%' }}
									multiline
									rows={3}
									placeholder={`Write a message (Optional)`}
									inputProps={{ maxLength: 250 }}
									onChange={e => setInterestedMessage(e.target.value)}
									value={interestedMessage}
								/>
								<Box
									sx={{
										textAlign: 'end',
										marginTop: '5px',
										marginRight: '3px',
										fontSize: '13px',
										color: 'gray'
									}}
								>
									{interestedMessage.length}/250
								</Box>
							</Box>
							<Button
								sx={{
									boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
									background: '#00C6AE',
									color: 'white',
									fontSize: '16px',
									fontWeight: '700',
									height: '24px',
									'&:hover': {
										backgroundColor: '#00C6AE'
									},
									padding: '18px'
								}}
								onClick={() => redirectToChannel()}
							>
								<Box mt={'-2px'}>Send</Box>
							</Button>
						</Box>
					</Modal>
				</Box>
			</Box>
		</Box>
	)
}

export default SportStatus

const successModalStyle = {
	position: 'absolute',
	top: '50%',
	maxWidth: '541px',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	width: '330px',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	gap: '20px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
