import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

const baseApiInstance = axios.create({
	baseURL: BASE_URL + '/',
	responseType: 'json'
})

const getExpireTime = () => {
	const exp = localStorage.getItem('exp')
	return exp ? JSON.parse(exp) : null
}

const waitForToken = async () => {
	return new Promise((resolve, reject) => {
		let attemptCount = 0

		const checkTokenValidity = () => {
			const exp = getExpireTime()
			if (!exp || !localStorage.getItem('token')) {
				return false
			}
			const currentTime = Math.floor(Date.now() / 1000)

			if (exp && currentTime < exp) {
				return true
			}
			return false
		}

		if (checkTokenValidity()) {
			// Token is already valid, resolve immediately
			resolve()
		} else {
			// Start checking at intervals
			const checkInterval = setInterval(() => {
				attemptCount++
				if (checkTokenValidity()) {
					clearInterval(checkInterval)
					resolve()
				} else if (attemptCount >= 10) {
					// Stop after 10 attempts
					clearInterval(checkInterval)
					reject('Token refresh failed after 10 attempts')
				}
			}, 1000) // Check every second
		}
	})
}

baseApiInstance.interceptors.request.use(
	async config => {
		let token = localStorage.getItem('token')
		const exp = getExpireTime()
		const currentTime = Math.floor(Date.now() / 1000)

		if (exp && currentTime >= exp) {
			// Wait for token to be valid (i.e., wait for it to be refreshed if needed)
			await waitForToken()
			token = localStorage.getItem('token')
		}

		// After waiting, set the header with the (possibly new) token
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	error => Promise.reject(error)
)

export default baseApiInstance
