import baseApi from './baseApiService'
import { TUTORIAL_URL } from '../constants/ApiUrls'

class TutorialService {
	TutorialType = {
		BROWSE_USERS: 'BROWSE_USERS',
		PROFILE_STATUS: 'PROFILE_STATUS',
		LOOK_AROUND: 'LOOK_AROUND',
		VIEW_PROFILE: 'VIEW_PROFILE'
	}

	isTutorialViewed(tutorialType, user, updateUser = false) {
		// Safely check if the tutorialType is in the viewedTutorials array

		const tutorialViewed = user.metaData?.viewedTutorials?.includes(tutorialType) || false

		if (!tutorialViewed) {
			this.postTutorialViewed(tutorialType)
			const updateUserData = {
				...user,
				metaData: {
					...user.metaData,
					viewedTutorials:
						user.metaData && user.metaData.viewedTutorials
							? [...user.metaData.viewedTutorials, tutorialType]
							: [tutorialType]
				}
			}
			if (updateUser) {
				updateUser(updateUserData)
			}
		}
		return tutorialViewed
	}

	postTutorialViewed(tutorialType) {
		return baseApi.post(`${TUTORIAL_URL}/viewed`, {
			tutorial: tutorialType
		})
	}
}

const tutorialServiceInstance = new TutorialService()

export default tutorialServiceInstance
