import { Button, Stack, Typography } from '@mui/material'
import { Navigate, useParams } from 'react-router-dom'
import { ReactComponent as HandShakeImg } from '../../../assets/home-challenge/hand-shake.svg'
import { useAuth } from '../../../store/auth-context'

const WhySignUp = () => {
	const { isLoggedIn, awsSignIn } = useAuth()
	const { alias } = useParams()

	if (isLoggedIn) {
		return <Navigate to={`/c/${alias}`} replace />
	}

	const handleLogInSignUp = signup => {
		console.log(signup)
		awsSignIn(`/c/${alias}`, signup)
	}

	return (
		<Stack alignItems="center" spacing={4} sx={{ margin: '0px 22px 0px 22px' }}>
			<HandShakeImg style={{ borderRadius: '20px' }} />

			<Typography variant="h1b">
				Accept the challenge & <br />
				Keep up with what’s <br />
				happening
			</Typography>

			<ul style={{ padding: 0 }}>
				<Typography variant="body1" textAlign="left">
					<li>Log your progress</li>
					<li>See your friends' progress</li>
					<li>Keep your record</li>
					<li>
						Get notified when challenge <br /> begins and winners selected
					</li>
				</Typography>
			</ul>

			<Stack spacing={1}>
				<Button variant="blGreen" onClick={() => handleLogInSignUp(true)} fullWidth>
					Sign up
				</Button>
				<Typography variant="subtitle1">Already have an account?</Typography>
				<Typography variant="link1" onClick={handleLogInSignUp}>
					Log in
				</Typography>
			</Stack>
		</Stack>
	)
}

export default WhySignUp
