import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

// const firebaseConfig = {
// 	apiKey: 'AIzaSyAv5YSpTVXFK9zv7M8JmU1G51l3-T8UahM',
// 	authDomain: 'belaylink-12866.firebaseapp.com',
// 	projectId: 'belaylink-12866',
// 	storageBucket: 'belaylink-12866.appspot.com',
// 	messagingSenderId: '78394566642',
// 	appId: '1:78394566642:web:8b9a7ccce5152257886d6b',
// 	measurementId: 'G-1FCC7KN2NE'
// }

// const FireBaseapp = initializeApp(firebaseConfig)

// export const messaging = getMessaging(FireBaseapp, {
// 	serviceWorkerUrl: '/firebase-messaging-sw.js'
// })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<App />
	</BrowserRouter>
	// </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
