import { Stack } from '@mui/material'
import { getSportStyleLevelColor } from '../../constants/levelColors'

const SportStyleRating = ({ proficiency }) => {
	const LineItems = []

	for (let i = 0; i < 4; i++) {
		LineItems.push(
			<span
				key={`style-proficiency-${i}`}
				style={{
					borderLeft: `5px solid ${
						i < proficiency ? getSportStyleLevelColor(proficiency) : 'black'
					}`,
					height: '15px',
					borderRadius: '20px 20px'
				}}
			></span>
		)
	}

	return (
		<Stack direction="row" spacing={0.2} alignItems="center">
			{LineItems}
		</Stack>
	)
}

export default SportStyleRating
