import { Avatar, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useField } from 'formik'
import useNotification from '../../hooks/useNotification'
import blankAvatar from '../../assets/user/blank-avatar.png'

const ImgUpload = props => {
	const [field, meta, helpers] = useField(props)
	const [, sendNotification] = useNotification()

	// const loadImage = () => {
	//   const reader = new FileReader();
	//   reader.onloadend = () => {
	//     setPhoto({
	//       file: field.value,
	//       imagePreviewUrl: reader.result,
	//     });
	//   };
	//   reader.readAsDataURL(field.value);
	//   return reader.result;
	// };

	const [avatarPreview, setAvatarPreview] = useState(
		field.value ? field.value.viewUrl : blankAvatar
	)

	const photoUpload = e => {
		e.preventDefault()

		const file = e.target.files[0]

		if (!file) return

		// size(MB) check
		const sizeLimitMB = 15.0
		const size = file?.size / 1024 / 1024
		if (size > sizeLimitMB) {
			sendNotification({
				msg: `Image size must be under ${sizeLimitMB}MB`,
				variant: 'error'
			})
			helpers.setError(`Image size must be under ${sizeLimitMB}MB`)
			return
		}

		const fileReader = new FileReader()
		fileReader.onload = () => {
			if (fileReader.readyState === 2) {
				helpers.setValue(fileReader.result)
				setAvatarPreview(fileReader.result)
			}
		}
		fileReader.readAsDataURL(file)
	}

	// useEffect(() => {
	//   console.log(field.value);
	//   if (field.value) {
	//     const reader = new FileReader();
	//     reader.onloadend = () => {
	//       setAvatarPreview(reader.result);
	//     };
	//     reader.readAsDataURL(field.value);
	//   }
	// }, []);

	return (
		<label htmlFor="photo-upload" className="custom-file-upload fas">
			<div className="img-wrap img-upload">
				{/* <img for="photo-upload" src={photo.imagePreviewUrl} /> */}
				<Avatar
					sx={{ width: '55px', height: '55px' }}
					imgProps={{ htmlFor: 'photo-upload' }}
					src={avatarPreview}
				/>
			</div>
			<input
				hidden
				id="photo-upload"
				type="file"
				accept="image/png, image/jpeg"
				onChange={photoUpload}
			/>
			<Button
				variant="text"
				id="buttonid"
				type="button"
				sx={{
					backdropFilter: 'blur(67.957px)',
					border: 'none',
					boxShadow: 'none',
					fontSize: '12px'
				}}
				onClick={() => {
					document.getElementById('photo-upload').click()
				}}
			>
				{props.label}
			</Button>
		</label>
	)
}

export default ImgUpload
