import * as Yup from "yup";
import editUserFormModel from "./editUserFormModel";

const {
  formField: { location, name },
} = editUserFormModel;

export default Yup.object().shape({
  [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
  [location.name]: Yup.object()
    .nullable()
    .required(`${location.requiredErrorMsg}`),
});
