import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const ChallengeAcceptedDialog = ({ open, handleClose }) => {
	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide"
		>
			<DialogTitle>{'Challenge Accepted'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					The challenge has been accepted.
				</DialogContentText>
			</DialogContent>
		</Dialog>
	)
}

export default ChallengeAcceptedDialog
