import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import EyeIcon from '../../assets/icon/EyeIcon'
import { ChatBubbleOutlineOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
import useUnreadCount from '../../hooks/useUnreadCount'
import TutorialService from '../../services/TutorialService'
import { LoadingContext } from '../../store/loading-context'
import UserService from '../../services/UserService'
import StatusImgGreen from '../../assets/header/statusGreen.png'
import StatusImg from '../../assets/header/status.png'

const BottomNavigation = props => {
	const navigate = useNavigate()
	const { user } = useAuth()
	const { lookArround, addStatus, message, alias = false, sportCode = false } = props

	const { unreadMsgCount } = useUnreadCount()
	const [isLoading, setIsLoading] = useContext(LoadingContext)

	const handleNavigteToLookAround = () => {
		const isVisited = TutorialService.isTutorialViewed(
			TutorialService.TutorialType.LOOK_AROUND,
			user
			// updateUser
		)

		if (!isVisited) {
			setIsLoading(true)
			if (sportCode && alias) {
				navigate(`/welcome/${sportCode}/${alias}`)
				setIsLoading(false)
			} else {
				navigate(`/welcome/${user?.sports[0]?.code}/${user?.alias}`)
				setIsLoading(false)
			}
		} else {
			navigate(`/look-around/${user.alias}`)
		}
	}

	return (
		<>
			<Box
				sx={{ display: 'contents', position: 'fixed', bottom: '0', left: '0px', width: '100px' }}
			>
				<Box
					maxWidth="sm"
					sx={{
						position: 'fixed',
						width: '100%',
						background: 'white',
						zIndex: '5',
						bottom: '0px',
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'center',
						py: '7px'
					}}
					id="profile-navigation"
				>
					<Box
						id="look-around-btn"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							width: '33%'
						}}
						onClick={handleNavigteToLookAround}
					>
						<Grid
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '30px'
							}}
						>
							<EyeIcon sx={{ color: lookArround ? '#00C6AE' : '#9FA4B4' }} />
						</Grid>
						<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-6px' }}>
							<Typography
								variant="pb"
								style={{ marginTop: '3px', color: lookArround ? '#00C6AE' : '#9FA4B4' }}
							>
								Look Around
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							width: '33%'
						}}
						onClick={() => navigate(`/home`)}
						id="message-btn"
					>
						<Grid
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '30px',
								marginTop: '2px'
							}}
						>
							<img
								src={addStatus ? StatusImgGreen : StatusImg}
								alt="icon"
								style={{ width: '25px', height: '25px' }}
							/>
						</Grid>

						<Box mt={'-5px'}>
							<Typography variant="pb" sx={{ color: addStatus ? '#00C6AE' : '#9FA4B4' }}>
								Update Status
							</Typography>
						</Box>
					</Box>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							width: '33%'
						}}
						onClick={() => navigate('/messages')}
						id="message-btn"
					>
						<Grid
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '30px',
								marginTop: '2px'
							}}
						>
							{unreadMsgCount?.data > 0 && (
								<Box
									sx={{
										width: '16px',
										height: '16px',
										borderRadius: '50%',
										background: '#f95a2c',
										position: 'absolute',
										top: '3px',
										right: '65px',
										border: '3px solid white'
									}}
								></Box>
							)}
							<ChatBubbleOutlineOutlined
								style={{ fontSize: '25px', color: message ? '#00C6AE' : '#9FA4B4' }}
							/>
						</Grid>

						<Box mt={'-5px'}>
							<Typography variant="pb" sx={{ color: message ? '#00C6AE' : '#9FA4B4' }}>
								Message
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default BottomNavigation
