import { Avatar, Box, IconButton, Input, Link, Stack, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ChatContext } from '../../store/chat-context'
import { useAuth } from '../../store/auth-context'
import ChatService from '../../services/ChatService'
import { getChatNameAndPhoto } from '../../shared/ChatUtils'
import MessageListContainer from './MessageListContainer'
import { useDeviceInfo } from '../../hooks/useDeviceInfo'
import { Link as RouterLink } from 'react-router-dom'
import useUnreadCount from '../../hooks/useUnreadCount'

const LIMIT = 20

const MessageScreen = () => {
	const navigate = useNavigate()
	const params = useParams()
	const { user } = useAuth()
	const { chatState, addChatsToChannel, loaders, setChatState } = useContext(ChatContext)
	const [page, setPage] = useState(0)
	const [hasMore, setHasMore] = useState(true)
	const [message, setMessage] = useState('')
	const chatContainerRef = useRef(null)
	const prevMessages = useRef([])
	const isLocked = useRef(false)
	const { deviceType, browserName } = useDeviceInfo()
	// console.log('device type and browser name : ', deviceType, browserName)
	const channelName = params.id
	const { refetchMsgCount } = useUnreadCount()
	const location = useLocation()
	const refer = new URLSearchParams(location.search).get('refer')
	const [comeFromLookAroundScreen, setComeFromLookAroundScreen] = useState(false)
	const [comeFromBrowseScreen, setComeFromBrowseScreen] = useState(false)

	useEffect(() => {
		if (refer === 'look-around') {
			setComeFromLookAroundScreen(true)
		} else if (refer === 'browse-profiles') {
			setComeFromBrowseScreen(true)
		}
	}, [])

	useEffect(() => {
		if (params.id !== chatState?.activeChannelId) {
			setChatState(oldState => ({
				...oldState,
				groups: oldState.groups.map(group => {
					if (group?.lastMessage?.channelName === channelName) {
						return {
							...group,
							isUnread: false
						}
					}
					return group
				}),
				activeChannelId: params.id
			}))
		}

		fetchMessages()
		markMessagesRead()
	}, [params.id, page])

	const channelInfo = useMemo(() => {
		if (chatState.groups && !loaders.isGroupLoading) {
			// console.log('chatState.groups ==>', chatState.groups)
			// console.log('params.id ==>', params.id)
			const channel = chatState.groups.find(group => group.name === params.id)
			// console.log('channel ==>', channel)
			return channel
		}
	}, [chatState.groups, loaders])

	const messageList = useMemo(() => {
		return chatState.chats[params.id] || []
	}, [chatState.chats, params.id])

	const scrollToBottom = () => {
		// console.log(' $$$$$ scroll ref ', chatContainerRef)
		const messageId = messageList[messageList.length - 1].messageId
		const container = document.getElementsByClassName('infinite-scroll-component')
		const ele = document.querySelector('#message_item_' + messageId)
		window.ele = ele
		setTimeout(() => {
			ele.scrollIntoView({
				behavior: 'smooth',
				container: container[0],
				block: 'end',
				inline: 'nearest'
			})
		}, 1000)
	}

	useEffect(() => {
		if (messageList?.length !== prevMessages.current?.length && chatState.gotNewMessage) {
			// console.log(' >>>>> Scrolling to Bottom ')
			scrollToBottom()
		}

		prevMessages.current = messageList
	}, [messageList, chatState.gotNewMessage])

	const fetchMessages = async () => {
		const channelName = params.id
		if (!channelName) return

		ChatService.getMessagesForChannel(
			{ page: page, size: LIMIT, sort: 'desc' },
			channelName,
			page
		).then(response => {
			const chats = response.data.content
			const hasMore = !response.data.last

			setHasMore(hasMore)
			addChatsToChannel({ chats, channel: params.id })
		})
	}

	const markMessagesRead = async () => {
		const channelName = params.id
		ChatService.markMessagesRead(channelName)
		refetchMsgCount()
	}

	const handleMessageSend = () => {
		if (isLocked.current || loaders.isGroupLoading) {
			return
		}

		const payload = {
			channelName: params.id,
			content: message,
			type: 'text'
		}

		if (message.trim() !== '') {
			isLocked.current = true
			ChatService.sendMessage(payload)
				.then(response => {
					setMessage('')
				})
				.finally(() => {
					isLocked.current = false
				})
		}
	}

	const handleScroll = () => {
		setPage(prevPage => prevPage + 1)
	}

	const handleOnChange = e => {
		setMessage(e.target.value)
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter') {
			if (!e.shiftKey) {
				e.preventDefault()
				handleMessageSend()
			}
		}
	}

	//Grouping message by date
	const groupedMessages = useMemo(() => {
		let groupedByDate = {}
		messageList.forEach(message => {
			const currentDate = new Date(message.timestamp + 'Z').toISOString().split('T')[0]
			if (!groupedByDate[currentDate]) {
				groupedByDate[currentDate] = []
			}
			groupedByDate[currentDate].push(message)
		})
		return groupedByDate
	}, [messageList])

	const handleGoBack = () => {
		if (location?.state?.refer === 'look-around') {
			navigate(`/look-around/${user?.alias}`)
		} else if (location?.state?.refer === 'browse-profiles') {
			navigate(
				`/browse-profiles/p/${getChatNameAndPhoto(channelInfo.participants, user)?.alias}/${
					location.state.sportCode
				}/${location.state.sportCode}`,
				{ state: { refer: 'look-around' } }
			)
		} else {
			navigate(-1)
		}
	}

	return (
		<Stack
			sx={{
				maxHeight: 'calc(100dvh - 56px)',
				position: 'relative'
			}}
		>
			<Stack
				direction="row"
				px="10px"
				py="10px"
				sx={{
					gap: '15px',
					backgroundColor: '#eae5e5',
					zIndex: '1',
					maxWidth: '600px',
					alignItems: 'center'
				}}
			>
				{channelInfo && (
					<>
						<Box>
							<IconButton onClick={() => handleGoBack()}>
								<ArrowBackIcon />
							</IconButton>
						</Box>
						<IconButton
							component={RouterLink}
							to={`/p/${getChatNameAndPhoto(channelInfo.participants, user)?.alias}`}
							sx={{ paddingY: '10px', paddingX: '0px' }}
						>
							<Avatar
								alt={getChatNameAndPhoto(channelInfo.participants, user)?.name}
								src={getChatNameAndPhoto(channelInfo.participants, user)?.photo}
							/>
						</IconButton>
						<Typography variant="pb">
							<Link
								href={`/p/${getChatNameAndPhoto(channelInfo.participants, user)?.alias}`}
								color="inherit"
								underline="hover"
							>
								{getChatNameAndPhoto(channelInfo.participants, user)?.name}
							</Link>
						</Typography>
					</>
				)}
				{!channelInfo && loaders.isGroupLoading && (
					<Typography variant="pb">Loading ...</Typography>
				)}
			</Stack>
			<MessageListContainer
				messages={groupedMessages}
				messagesLength={messageList.length}
				handleScroll={handleScroll}
				hasMore={hasMore}
				chatContainerRef={chatContainerRef}
			/>
			<Stack
				sx={{
					bottom: '0px',
					maxWidth: '600px',
					flexDirection: 'row',
					gap: '10px',
					backgroundColor: '#eae5e5',
					padding: '10px',
					alignItems: 'center'
				}}
			>
				<Input
					sx={{
						width: '100%',
						'&:focus-within::before': { borderBottom: 'none !important' },
						'&:focus-within::after': { borderBottom: 'none !important' },
						borderRadius: '5px',
						backgroundColor: 'white',
						padding: '6px 10px',
						outline: 'none',
						'&:focus-within::before:hover': { borderBottom: 'none' },
						fontSize: '12px',
						fontWeight: '500',
						minHeight: '35px',
						lineHeight: '1.3'
					}}
					disabled={loaders.isGroupLoading}
					value={message}
					onChange={e => handleOnChange(e)}
					placeholder="Send Message"
					onKeyDown={e => handleKeyDown(e)}
					multiline
					maxRows={4}
				/>
				<SendIcon
					sx={{
						color: '#00c6ae'
					}}
					onClick={handleMessageSend}
				/>
			</Stack>
		</Stack>
	)
}

export default MessageScreen
