import React from "react";
import SportLevel from "./step1/SportLevel";
import YearsOfPractice from "./step1/YearsOfPractice";
import FrequencyPlayed from "./step1/FrequencyPlayed";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Step1 = (props) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={4}
        // direction="column"
        alignItems="center"
        justifyContent="center"
        // style={{ minHeight: "100vh" }}
      >
        <Grid align="center" item xs={12}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="140"
              image={props.sport.avatar}
              alt={props.sport.name}
            />
            <CardContent>
              <Typography
                align="center"
                gutterBottom
                variant="h5"
                component="div"
              >
                {props.sport.name}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid align="center" item xs={12}>
          <Typography>Current Level</Typography>
          <Typography variant="caption" align="center">
            Choose the highest level you have in this sport category
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SportLevel />
        </Grid>
        <Grid item xs={8}>
          <YearsOfPractice />
        </Grid>
        <Grid item xs={12} align="center">
          <FrequencyPlayed />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Step1;
