import { FormControl, TextField } from '@mui/material'
import { useField } from 'formik'

const MyTextField = ({
	name,
	label,
	required = false,
	multiline = false,
	placeholder = null,
	disabled = false
}) => {
	const [field, meta, helpers] = useField(name)

	return (
		<FormControl sx={{ width: '60%' }} error={meta.touched && Boolean(meta.error)}>
			<TextField
				label={label}
				required={required}
				{...field}
				error={meta.touched && Boolean(meta.error)}
				helperText={meta.touched && meta.error}
				multiline={multiline}
				rows={multiline ? 4 : 1}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</FormControl>
	)
}

export default MyTextField
