import baseApi from './baseApiService'
import { GET_SPORT_LIST_URL, GET_SPORT_DETAIL_URL, PROFILE_URL } from '../constants/ApiUrls'

class SportService {
	getSportList() {
		return baseApi.get(GET_SPORT_LIST_URL)
	}

	getSportDetail(sportCode) {
		return baseApi.get(GET_SPORT_DETAIL_URL + `/${sportCode}`)
	}
	deletSportProfile(sportCode) {
		return baseApi.delete(PROFILE_URL + `/${sportCode}`)
	}

	QuestionCategory = {
		Social: 'Social',
		Skill: 'Skill',
		Goal: 'Goal'
	}

	QuestionType = {
		Text: 'Text',
		Tags: 'Tags'
	}

	Level = {
		Beginner: 'Beginner',
		Intermediate: 'Intermediate',
		Advanced: 'Advanced',
		Expert: 'Expert'
	}
}

export default new SportService()
