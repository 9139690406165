import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid, Button, Typography, Modal } from '@mui/material'
import createSportProfileInitialValues from './createSportProfileInitialValues'
import createSportProfileValidationSchema from './createSportProfileValidationSchema'
import ProfileService from '../../services/ProfileService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useNotification from '../../hooks/useNotification'
import JsonUtils from '../../shared/JsonUtils'
import { CloseOutlined, East, West } from '@mui/icons-material'
import CreateSportProfileRoot from './CreateSportProfile'
import { useAuth } from '../../store/auth-context'
import MyButton from '../../components/ui/MyButton'
import Loading from '../../components/ui/Loading'
import congrats from '../../assets/icon/congrats.png'
import UserService from '../../services/UserService'
import editSportProfileValidationSchema from './editSportProfileValidationSchema'
import { updateNotificationSetting } from '../NotificationPreferences/updateNotificationSetting'

const CreateSportProfile = ({ mode }) => {
	const editProfileSteps = [
		'Sport Name',
		'Practicing Since',
		'Level',
		'Styles',
		'Question1',
		'Question2',
		'Question3',
		'Sport Times',
		'Sport Places',
		'sessionTypes'
	]
	const { user } = useAuth()
	const navigate = useNavigate()
	const { sportCode } = useParams()
	const location = useLocation()
	const refer = new URLSearchParams(location.search).get('refer')

	const [, sendNotification] = useNotification()
	const [createNewProfileSteps, setCreateNewProfileSteps] = useState([])
	const [initialFormValues, setInitialFormValues] = useState(createSportProfileInitialValues)

	const [editSportCode] = useState(mode === 'edit' && sportCode)
	const [isLoading, setIsLoading] = useState(editSportCode)
	// const [activeStep, setActiveStep] = useState(editSportCode ? 1 : 0)
	const initialStep = refer === 'completeSportProfile' ? 4 : editSportCode ? 1 : 0
	const [activeStep, setActiveStep] = useState(initialStep)

	// const currentValidationSchema = createSportProfileValidationSchema[activeStep]
	const [currentValidationSchema, setCurrentValidationSchema] = useState(null)
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
	const [createSportCode, setCreateSportCode] = useState(null)
	const [userDetails, setUserDetails] = useState(null)

	useEffect(() => {
		UserService.getMe()
			.then(response => {
				setUserDetails(response.data) // For getting length of existing sports
				if (response.data?.sports?.length === 0) {
					// means user comes first time to add sport (considering during on board flow)
					setCreateNewProfileSteps([
						'Sport Name',
						'Practicing Since',
						'Level',
						'Styles',
						'Sport Times',
						'Sport Places',
						'sessionTypes',
						'pushNotifications'
					])
				} else {
					setCreateNewProfileSteps([
						'Sport Name',
						'Practicing Since',
						'Level',
						'Styles',
						'Sport Times',
						'Sport Places',
						'sessionTypes'
					])
				}
			})
			.catch(error => {
				console.log('error', error)
			})
	}, [])

	useEffect(() => {
		if (mode === 'create') {
			setCurrentValidationSchema(createSportProfileValidationSchema[activeStep])
		} else {
			setCurrentValidationSchema(editSportProfileValidationSchema[activeStep])
		}
	}, [activeStep])

	useEffect(() => {
		if (mode === 'edit' && sportCode) {
			setIsLoading(true)
			ProfileService.getUsersProfile(user.alias, sportCode)
				.then(response => {
					// Assuming 'response.data' holds the fetched profile data
					// Create an array to store questions
					let questionsArray = [
						{ answer: '', tags: [], sportQuestion: { id: 0 } },
						{ answer: '', tags: [], sportQuestion: { id: 0 } },
						{ answer: '', tags: [], sportQuestion: { id: 0 } }
					] // Initialize with null values					// Populate the array with questions from the API
					const categories = ['Social', 'Skill', 'Goal']
					categories.forEach((category, index) => {
						const checkquestions =
							response?.data?.questions?.length > 0 &&
							response?.data?.questions?.find(que => que.sportQuestion.category === category)
						if (checkquestions) {
							questionsArray[index] = checkquestions
						}
					})
					// setInitialFormValues(response.data)
					setInitialFormValues({ ...response.data, questions: questionsArray })
					setIsLoading(false)
				})
				.catch(error => {
					console.log('error', error)
					setIsLoading(false)
				})
		}
	}, [mode, sportCode, user.alias])

	const handleSuccessModalClose = () => {
		setIsSuccessModalOpen(false)
		// navigate(`/p/${user.alias}/${createSportCode.code}`, {
		// 	state: { refer: 'editSport' }
		// })
		navigate(`/p/${user.alias}/actionphotos`, {
			state: { refer: 'editSport', sportCode: createSportCode?.code }
		})
	}

	const handleBack = () => {
		if (activeStep === 4 && createSportCode.styles.length === 0) {
			setActiveStep(prevStep => prevStep - 2)
		} else {
			setActiveStep(prevStep => prevStep - 1)
		}
	}

	async function submitForm(values, actions) {
		const removeBlankQuestions = values.questions.filter(
			que =>
				que.sportQuestion.id !== 0 &&
				((que.answer && que.answer?.trim()?.length > 0) || que.tags.length > 0)
		)
		const clean = JsonUtils.pruneEmpty({ ...values, questions: removeBlankQuestions })
		setIsLoading(true)
		if (mode === 'create') {
			ProfileService.postProfile(clean)
				.then(response => {
					setIsSuccessModalOpen(true)
					setIsLoading(false)

					if (userDetails.sports.length === 0) {
						// when user comes first time (when no sport is added)
						updateNotificationSetting(
							values.emailNotifications,
							values.pushNotifications,
							false,
							values.newFeatureNotification,
							sendNotification
						)
					}
				})
				.catch(error => {
					console.log('ERROR', error.response)
					sendNotification({
						msg:
							error.response?.data?.message !== undefined
								? error.response.data.message
								: 'An Error Occurred',
						variant: 'error'
					})
					setIsLoading(false)
				})
		} else {
			ProfileService.updateProfile(clean)
				.then(response => {
					// navigate(`/p/${user.alias}/${createSportCode.code}`, {
					// 	state: { refer: 'editSport' }
					// })
					navigate(`/p/${user.alias}/actionphotos`, {
						state: { refer: 'editSport', sportCode: createSportCode?.code }
					})
					setIsLoading(false)
				})
				.catch(error => {
					console.log('ERROR', error.response)
					sendNotification({
						msg:
							error.response?.data?.message !== undefined
								? error.response.data.message
								: 'An Error Occurred',
						variant: 'error'
					})
					setIsLoading(false)
				})
		}
		actions.setSubmitting(false)
	}

	function handleSubmit(values, actions) {
		if (
			activeStep ===
			(mode === 'create' ? createNewProfileSteps.length - 1 : editProfileSteps.length - 1)
		) {
			submitForm(values, actions)
		} else {
			if (activeStep === 2 && createSportCode.styles.length === 0) {
				setActiveStep(prevActiveStep => prevActiveStep + 2)
			} else {
				setActiveStep(prevActiveStep => prevActiveStep + 1)
			}
			actions.setTouched({})
			actions.setSubmitting(false)
		}
	}

	return (
		<>
			{!isLoading ? (
				<Formik
					initialValues={initialFormValues}
					validationSchema={currentValidationSchema}
					onSubmit={handleSubmit}
				>
					{({ handleSubmit, isSubmitting }) => (
						<Form id={'createSportProfile'}>
							<Box
								sx={{
									paddingX: 2,
									marginTop: 3
								}}
							>
								<CreateSportProfileRoot
									activeStep={activeStep}
									handleSubmit={handleSubmit}
									editSportCode={editSportCode}
									mode={mode}
									setCreateSportCode={setCreateSportCode}
									// ALL THE BELOW TO BE REMOVED
									userDetails={userDetails}
								/>
								<Grid
									container
									sx={{
										position: 'fixed',
										bottom: '0px',
										padding: '10px 20px',
										left: '0px',
										zIndex: '9',
										backgroundColor: 'white'
									}}
								>
									<Grid item xs={5} sx={{ textAlign: 'start' }}>
										{mode === 'edit' && activeStep === 1 ? (
											''
										) : (
											<MyButton
												disabled={activeStep === 0}
												onClick={handleBack}
												type="button"
												variant="blGrey"
												fullWidth
												style={{ display: activeStep === 0 ? 'none' : '' }}
												sx={{ width: '140px' }}
											>
												<West sx={{ color: '#fff', height: '40px', width: '100%' }}></West>
											</MyButton>
										)}
									</Grid>
									<Grid item xs={2}></Grid>
									<Grid item xs={5} sx={{ textAlign: 'end' }}>
										<MyButton
											type="submit"
											// disabled={isSubmitting}
											variant="blGreen"
											fullWidth
											sx={{ color: '#fff', height: '50px', width: '140px' }}
										>
											{activeStep <
											(mode === 'create'
												? createNewProfileSteps.length - 1
												: editProfileSteps.length - 1) ? (
												<East sx={{ height: '40px', width: '100%' }}></East>
											) : (
												'Done'
											)}
										</MyButton>
									</Grid>
									{/* <Grid item xs={12} sx={{ position: 'absolute', bottom: '-15px', right: '25px' }}> */}
									<Grid
										item
										xs={12}
										sx={{ margin: '10px 0 20px 0', display: 'flex', justifyContent: 'end' }}
									>
										<Typography variant="subtitle1" textAlign="right">
											You can always change later in “Edit”
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Modal
								open={isSuccessModalOpen}
								onClose={handleSuccessModalClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box sx={style}>
									<Box
										sx={{
											position: 'absolute',
											top: '10px',
											right: '18px',
											color: 'gray'
										}}
									>
										<CloseOutlined onClick={handleSuccessModalClose} />
									</Box>
									<Box sx={{ position: 'absolute', top: '23px', right: '49px' }}>
										<img src={congrats} style={{ width: '69px' }} />
									</Box>
									<Box
										sx={{
											fontSize: '20px',
											fontWeight: '600',
											marginLeft: '-70px',
											marginTop: '25px'
										}}
									>
										You've <br />
										successfully <br /> created your <br /> sport profile!
									</Box>
								</Box>
							</Modal>
						</Form>
					)}
				</Formik>
			) : (
				<Loading />
			)}
		</>
	)
}

export default CreateSportProfile

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	minWidth: '286px',
	gap: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '205px'
}
