import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { prepareDataForValidation } from 'formik'
import { useEffect, useState } from 'react'
import useNotification from '../../hooks/useNotification'
import CommentService from '../../services/CommentService'
import CommentInput from './CommentInput'
import CommentItem from './CommentItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const CommentContainer = ({ alias, enableSubmission }) => {
	const [, sendNotification] = useNotification()

	const [comments, setComments] = useState([])
	const [commentToSubmit, setCommentToSubmit] = useState('')
	const [enableSubmitButton, setEnableSubmitButton] = useState(true)

	const [numCommentsToShow, setNumCommentsToShow] = useState(3)

	useEffect(() => {
		CommentService.getComments(alias).then(response => {
			setComments(response.data)
		})
	}, [])

	const handleCommentSubmit = () => {
		setEnableSubmitButton(false)
		CommentService.createComment(alias, { content: commentToSubmit })
			.then(response => {
				setComments(prev => [response.data, ...prev])
				setNumCommentsToShow(prev => prev + 1)
				setCommentToSubmit('')
			})
			.finally(() => {
				setEnableSubmitButton(true)
			})
			.catch(error => {
				sendNotification({
					msg: error.response.data?.message || 'Unable to submit progess',
					variant: 'error'
				})
			})
	}

	const commentItems = comments
		? comments
				.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
				.map(comment => <CommentItem comment={comment} key={comment.id} />)
		: null

	return (
		<Box
			sx={{
				background: '#474A57',
				border: ' 5px solid #474A57',
				borderRadius: '16px',
				margin: '10px'
			}}
		>
			<Stack spacing="15px">
				<Typography variant="h2" sx={{ color: '#FFFFFF' }}>
					Progress Updates
				</Typography>

				{enableSubmission && (
					<CommentInput
						enableSubmitButton={enableSubmitButton}
						value={commentToSubmit}
						handleCommentToSubmitChange={setCommentToSubmit}
						handleCommentSubmit={handleCommentSubmit}
					/>
				)}

				<Divider variant="middle" sx={{ border: '1px solid #98A3A3', margin: '0px' }} />

				{comments.length > 0 && (
					<Stack spacing="6px">
						{commentItems.splice(0, numCommentsToShow)}
						{comments.length > numCommentsToShow && (
							<IconButton onClick={() => setNumCommentsToShow(prev => prev + 3)}>
								<MoreHorizIcon sx={{ color: '#FFFFFF' }} fontSize="large" />
							</IconButton>
						)}
					</Stack>
				)}
			</Stack>
		</Box>
	)
}

export default CommentContainer
