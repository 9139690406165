import React from "react";
import { Checkbox, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import Playground from "./step5/Playground";
import { useField } from "formik";

const Step5 = () => {

  const [fieldOpenToPlaces] = useField({ name: "profile.openToPlaces", type: "checkbox"});
  const [fieldTimeWeekdayDay] = useField({ name: "profile.sportTimeWeekdayDay", type: "checkbox"});
  const [fieldTimeWeekdayEvening] = useField({ name: "profile.sportTimeWeekdayEvening", type: "checkbox"});
  const [fieldTimeWeekends] = useField({ name: "profile.sportTimeWeekend", type: "checkbox"});

  return (
    <React.Fragment>
      <Stack spacing={1}>
        <Typography align="center">Your Playground</Typography>
        <Playground />
        <FormControlLabel control={<Checkbox />} label="I'm open to other places" {...fieldOpenToPlaces} />

        <Divider style={{margin: "2em"}}/>

        <Typography align="center">Your Usual Sports Time</Typography>
        <FormControlLabel control={<Checkbox />} label="Weekday Daytime" {...fieldTimeWeekdayDay} />
        <FormControlLabel control={<Checkbox />} label="Weekday Evening" {...fieldTimeWeekdayEvening} />
        <FormControlLabel control={<Checkbox />} label="Weekend" {...fieldTimeWeekends} />
      </Stack>
    </React.Fragment>
  );
};

export default Step5;
