import { Avatar, Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import DateUtils from '../../shared/DateUtils'

const CommentItem = ({ comment }) => {
	return (
		<React.Fragment>
			<Grid container wrap="nowrap">
				<Grid item>
					<Stack alignItems="center">
						<Avatar alt={comment.userName} src={comment.userImage?.viewUrl || '/broken-img.jpg'} />
						<Typography
							variant="note"
							sx={{
								color: '#F4F5F7'
							}}
						>
							{comment.userName.split(' ')[0]}
						</Typography>
					</Stack>
				</Grid>
				<Grid justifyContent="left" item xs zeroMinWidth>
					<Box
						sx={{
							background: '#F4F5F7',
							border: '2px solid #F4F5F7',
							borderRadius: '16px',
							padding: '10px 10px',
							margin: '0px 0px 0px 10px'
						}}
					>
						<Typography variant="body2" sx={{ textAlign: 'left' }}>
							{comment.content}
						</Typography>
					</Box>
					<Typography
						variant="body2"
						sx={{ textAlign: 'right', marginRight: '10px', color: '#FFFFFF' }}
					>
						{DateUtils.displayWeekDayMonthDay(comment.createdAt)}
					</Typography>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

export default CommentItem
