import {
	Avatar,
	Badge,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import ChallengeStakeCard from '../../components/ChallangeStakeCard'
import { useNavigate, useParams } from 'react-router-dom'

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import ChallengeService from '../../services/ChallengeService'
import { useAuth } from '../../store/auth-context'
import ChallengeAcceptedDialog from './ChallengeAcceptedDialog'
import MetaData from '../../components/MetaData'
import { ReactComponent as WinnerImg } from '../../assets/challenge/challenge-winner.svg'
import completedImg from '../../assets/challenge/completed.png'
import Image from 'mui-image'
import LoadingPage from '../LoadingPage'
import ShareButton from '../../components/ui/ShareButton'
import DateUtils from '../../shared/DateUtils'
import CommentContainer from '../../components/comments/CommentContainer'
import AddToCalendarButton from '../../components/ui/AddToCalendarButton'
import { useConfirm } from 'material-ui-confirm'

const ChallengeConfirmationPage = () => {
	const [challenge, setChallenge] = useState()
	const { alias } = useParams()
	const navigate = useNavigate()
	const { user, isLoggedIn } = useAuth()
	const confirm = useConfirm()

	const [acceptDialogShow, setAcceptDialogShow] = useState(false)
	const [manageDrawerShow, setManageDrawerShow] = useState(false)

	const getChallengeDetails = useCallback(() => {
		ChallengeService.getChallengeByAlias(alias).then(response => {
			setChallenge(response.data)
		})
	}, [alias])

	useEffect(() => {
		getChallengeDetails()
	}, [getChallengeDetails])

	const handleAcceptDialogClose = () => {
		setAcceptDialogShow(false)
		getChallengeDetails()
	}

	const [isParticipant, setIsParticipant] = useState(false)
	const [isOrganiser, setIsOrganiser] = useState(false)
	const [isActive, setIsActive] = useState(true)
	const [hasWinners, setHasWinners] = useState(false)

	useEffect(() => {
		if (challenge && user) {
			setIsParticipant(challenge.participants.filter(val => val.alias === user.alias).length > 0)
			setIsOrganiser(challenge.organiser.alias === user.alias)
		}
		if (challenge) {
			setIsActive(challenge.status === 'ACTIVE')
			setHasWinners(challenge.winners.length > 0)
		}
	}, [challenge, user])

	const handleAcceptInvite = () => {
		// if currently not signed in -> direct to cognito sign in page
		// if (!isLoggedIn) awsSignIn(location.pathname)

		// if currently not sign in -> direct to why sign up page
		if (!isLoggedIn) navigate(`/c/${alias}/whysignup/`)
		else {
			ChallengeService.updateParticipation(alias, ChallengeService.Participation.JOIN).then(
				response => {
					setAcceptDialogShow(true)
				}
			)
		}
	}
	const handleQuitChallenge = () => {
		confirm({ description: 'Are you sure you want to quit the challenge?' }).then(() => {
			ChallengeService.updateParticipation(alias, ChallengeService.Participation.SURRENDER).then(
				response => {
					getChallengeDetails()
				}
			)
		})
	}

	const handleEndChallenge = () => {
		confirm({ description: 'Are you sure you want to end the challenge?' }).then(() => {
			ChallengeService.updateChallangeAction(alias, ChallengeService.Action.END).then(response => {
				getChallengeDetails()
			})
		})
	}

	return challenge ? (
		<React.Fragment>
			<MetaData title={`BelayLink - ${challenge.name}`} description={challenge.description} />
			<Box sx={{ background: '#474A57', borderRadius: '16px' }}>
				<Stack spacing={2}>
					<Box bgcolor="#FFFFFF" sx={{ border: '3px solid #474A57', borderRadius: '16px' }}>
						<Box
							bgcolor="#FFD465"
							sx={{ borderRadius: '16px 16px 0px 0px', padding: '20px 0px 20px 0px' }}
						>
							<Typography textAlign={'center'} variant="h1" fontWeight={700}>
								{challenge.name}
							</Typography>
							<Typography variant="body1" margin="0px 40px 0px 40px">
								{challenge.description}
							</Typography>
						</Box>

						<Grid container direction="row" alignItems="center" margin="5px">
							<Grid item xs={2} margin="5px">
								<CalendarTodayOutlinedIcon />
							</Grid>
							<Grid item xs={8}>
								<Typography>
									{DateUtils.displayMonthDay(challenge.startDate)} -{' '}
									{DateUtils.displayMonthDay(challenge.endDate)}
								</Typography>
							</Grid>
						</Grid>

						<ChallengeStakeCard title={challenge.stake.name} image={challenge.stake.avatar} />
						<Grid
							direction="row"
							container
							// spacing={1}
							// justifyContent="center"
							sx={{ mt: 1, mb: 1 }}
						>
							{/* <Grid container item xs={12} direction="row" alignItems="center" margin="5px"> */}
							<Grid item xs={2} margin="5px">
								<GroupOutlinedIcon fontSize="large" />
							</Grid>
							<Grid item xs={8}>
								<Grid container direction="row" spacing={2}>
									{/* <AvatarGroup max={10}> */}
									{challenge.participants
										.sort(a => (challenge.organiser.alias === a.alias ? -1 : 1))
										.map(p => {
											const participantIsOrganiser = challenge.organiser.alias === p.alias
											return (
												<Grid item xs={3.5} md={3} key={p.alias}>
													<Badge
														overlap="circular"
														anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
														sx={{ justifyContent: 'center' }}
														badgeContent={
															challenge.winners.filter(val => val.alias === p.alias).length > 0 ? (
																<WinnerImg />
															) : null
														}
													>
														<Stack alignItems="center">
															<Avatar
																alt={p.name}
																src={p.userImage?.viewUrl || '/broken-img.jpg'}
																sx={{ margin: '5px', width: '59px', height: '59px' }}
															/>
															<Typography
																sx={{
																	fontSize: participantIsOrganiser ? ' 12px' : '11px',
																	lineHeight: '16px',
																	color: participantIsOrganiser ? '#18191F' : '#474A57',
																	fontWeight: participantIsOrganiser ? 700 : 500
																}}
															>
																{p.name.split(' ')[0]}
															</Typography>
														</Stack>
													</Badge>
												</Grid>
											)
										})}
								</Grid>
							</Grid>
							{/* </AvatarGroup> */}
						</Grid>
						{/* <Grid
							container
							direction="row"
							justifyContent="center"
							textAlign="center"
							fullWidth
							marginBottom={'10px'}
						>
							<Grid item xs={6}>
								{isOrganiser && isActive && (
									<Button variant="blGrey" onClick={handleEndChallenge}>
										End Challenge
									</Button>
								)}
								{!isOrganiser && isParticipant && isActive && (
									<Button variant="blGrey" onClick={handleQuitChallenge}>
										Quit
									</Button>
								)}
							</Grid>
						</Grid> */}
						{isActive && isParticipant && (
							<Grid container direction="row" fullWidth>
								<Grid item xs={6}>
									<Dialog
										anchor={'bottom'}
										open={manageDrawerShow}
										onClose={() => setManageDrawerShow(false)}
										onOpen={() => setManageDrawerShow(true)}
										fullWidth
									>
										<DialogTitle textAlign="center" sx={{ bgcolor: 'grey.main' }}>
											Manage Challenge
										</DialogTitle>
										<DialogContent
											sx={{ padding: '0px 20px 0px 20px' }}
											onClick={() => setManageDrawerShow(false)}
										>
											<List>
												{isOrganiser && (
													<>
														<ListItem
															key="end-challenge"
															onClick={handleEndChallenge}
															disablePadding
														>
															<ListItemText
																primary={'End Challenge'}
																sx={{ textAlign: 'center' }}
															/>
														</ListItem>
														<Divider />
													</>
												)}
												{!isOrganiser && isParticipant && (
													<>
														<ListItem key="quit" onClick={handleQuitChallenge} disablePadding>
															<ListItemText primary={'Quit'} sx={{ textAlign: 'center' }} />
														</ListItem>
														<Divider />
													</>
												)}
											</List>
										</DialogContent>
										<DialogActions>
											<Button onClick={() => setManageDrawerShow(false)}>Close</Button>
										</DialogActions>
									</Dialog>
									<Button
										variant="contained"
										sx={{
											bgcolor: 'grey.main',
											color: 'black',
											textTransform: 'none',
											borderRadius: '0px 0px 0px 10px'
										}}
										onClick={() => setManageDrawerShow(true)}
										startIcon={<BuildOutlinedIcon />}
										fullWidth
									>
										Manage
									</Button>
								</Grid>
								<Grid item xs={6}>
									<AddToCalendarButton
										challenge={challenge}
										variant="contained"
										sx={{
											bgcolor: 'grey.main',
											color: 'black',
											textTransform: 'none',
											borderRadius: '0px 0px 10px 0px'
										}}
										startIcon={<CalendarTodayOutlinedIcon />}
										fullWidth
									>
										Add to Calendar
									</AddToCalendarButton>
								</Grid>
							</Grid>
						)}
					</Box>
					{!isActive && (
						<div style={{ marginTop: '-4em' }} fullWidth>
							<Image
								src={completedImg}
								duration={null}
								height="100px"
								width="240px"
								fit="contain"
								wrapperStyle={{ float: 'right' }}
							/>
						</div>
					)}
				</Stack>
				<CommentContainer alias={challenge.alias} enableSubmission={isParticipant} />
			</Box>

			<Grid container direction="row" justifyContent="center" fullWidth marginTop="20px">
				{challenge.status === 'ACTIVE' ? (
					isParticipant ? (
						<Grid item xs={8}>
							{/* <Link to={`/c/${alias}/invite/`} state={challenge} component/> */}
							<Button
								variant="blGreen"
								fullWidth
								onClick={() => navigate(`/c/${alias}/invite/`, { state: challenge })}
							>
								Invite
							</Button>
							{/* <Button variant="blGreen" fullWidth onClick={() => setShowInvitePage(true)}>
									Invite
								</Button> */}
						</Grid>
					) : (
						<React.Fragment>
							<Grid container direction="row">
								<Grid item xs={6}>
									<Button variant="blGrey" fullWidth>
										Umm...
									</Button>
								</Grid>
								<Grid item xs={6} onClick={handleAcceptInvite}>
									<Button variant="blGreen" fullWidth>
										Accept
									</Button>
								</Grid>
							</Grid>
							<ChallengeAcceptedDialog
								open={acceptDialogShow}
								handleClose={handleAcceptDialogClose}
							/>
						</React.Fragment>
					)
				) : (
					<div style={{ marginTop: '-xx' }}>
						{isOrganiser && !hasWinners && (
							<Button variant="blGrey" onClick={() => navigate(`/c/${alias}/winner/`)}>
								Select Winner(s)
							</Button>
						)}
						{((isParticipant && !isOrganiser) || (isOrganiser && hasWinners)) && (
							<ShareButton
								buttonText="Share My Achievement"
								title={challenge.name}
								text="Check out my challenge achievement"
								extension={`c/${challenge.alias}`}
							/>
						)}
					</div>
				)}
			</Grid>
		</React.Fragment>
	) : (
		<LoadingPage />
	)
}

export default ChallengeConfirmationPage
