import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from '../../store/auth-context'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { ReactComponent as EyeIcon } from '../../assets/header/eyes-black.svg'
import TutorialService from '../../services/TutorialService'
import {
	Badge,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from '@mui/material'
import AlertDialog from './AlertDialog'
import { CloseOutlined, ShareOutlined } from '@mui/icons-material'
import useUnreadCount from '../../hooks/useUnreadCount'
import UserService from '../../services/UserService'
import { LoadingContext } from '../../store/loading-context'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import ShareSportProfile from '../../pages/ShareSportsProfile'
import StatusImg from '../../assets/header/statusDark.png'
import useShare from '../../hooks/useShare'
import { ShareContext } from '../ShareContext'

export const CAN_JOIN = process.env.REACT_APP_CAN_JOIN

const pages = [
	{ label: 'Sign-Up', to: '/signup' },
	{ label: 'Sign-In', to: '/login' }
]

const drawerWidth = 240

const Header = props => {
	const { logout, isLoggedIn, hasCompletedSignup, user, awsSignIn } = useAuth()
	const location = useLocation()
	const [showLogo, setShowLogo] = useState(location.pathname !== '/')

	useEffect(() => {
		setShowLogo(location.pathname !== '/')
	}, [location])

	const [anchorElNav, setAnchorElNav] = useState(null)
	const [anchorElUser, setAnchorElUser] = useState(null)
	const [anchorElShare, setAnchorElShare] = useState(null)

	const handleOpenShareMenu = event => {
		setAnchorElShare(event.currentTarget)
	}

	const handleCloseShareMenu = () => {
		setAnchorElShare(null)
	}

	const [mobileOpen, setMobileOpen] = useState(false)

	const [showAlert, setShowAlert] = useState(false)

	const { unreadNotifyCount, unreadMsgCount } = useUnreadCount()

	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useContext(LoadingContext)
	const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)

	const handleCloseAlert = () => {
		setShowAlert(false)
	}

	const { window } = props

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	const handleOpenUserMenu = event => {
		setAnchorElUser(event.currentTarget)
	}

	const handleCloseUserMenu = e => {
		setAnchorElUser(null)
	}

	const styles = {
		appBar: {
			// borderRadius: '0px 0px 10px 10px',
			// width: { sm: `calc(100% - ${drawerWidth}px)` },
			width: '100%',
			ml: { sm: `${drawerWidth}px` }
		}
	}

	const StyledListItem = ({ children, ...props }) => (
		<ListItem
			disablePadding
			sx={{
				padding: '0px 0px 0px 0px'
			}}
			{...props}
		>
			{children}
		</ListItem>
	)

	const DrawerItem = ({ to, icon: Icon, text, onClick }) => (
		<StyledListItem>
			<ListItemButton component={to && Link} to={to} onClick={onClick}>
				{Icon ? (
					text !== 'Update My Status' ? (
						<ListItemIcon sx={{ minWidth: '32px' }}>
							<Icon sx={{ width: '28px', height: '28px', color: '#202020' }} />
						</ListItemIcon>
					) : (
						<Box sx={{ minWidth: '32px', marginBottom: '-10px' }}>
							<img src={StatusImg} alt="icon" style={{ width: '28px', height: '28px' }} />
						</Box>
					)
				) : (
					''
				)}
				<ListItemText primary={<Typography variant="pb">{text}</Typography>} />
			</ListItemButton>
		</StyledListItem>
	)

	const handleNavigteToLookAround = () => {
		const isVisited = TutorialService.isTutorialViewed(
			TutorialService.TutorialType.LOOK_AROUND,
			user
			// updateUser
		)
		if (!isVisited) {
			setIsLoading(true)
			navigate(`/welcome/${user?.sports[0]?.code}/${user?.alias}`)
			setIsLoading(false)
		} else {
			navigate(`/look-around/${user.alias}`)
		}
		handleDrawerToggle()
	}

	const drawerLoggedIn = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				<DrawerItem
					to="/home"
					onClick={handleDrawerToggle}
					icon={AddCircleIcon}
					text="Update My Status"
				/>
				<DrawerItem onClick={handleNavigteToLookAround} icon={EyeIcon} text="Look Around" />
				<DrawerItem
					to={`/buddies/p/${user?.alias}/`}
					onClick={handleDrawerToggle}
					icon={PeopleAltOutlinedIcon}
					text="My Sports Partners"
				/>
			</List>
			<Divider />
			<List className="sb-setting">
				<DrawerItem to="/notifypref" onClick={handleDrawerToggle} text="Notification Settings" />
			</List>
			<Divider />
			<List className="sb-setting">
				<DrawerItem to="/about" onClick={handleDrawerToggle} text="About Us" />
				<DrawerItem to="/contact" onClick={handleDrawerToggle} text="Contact Us" />
			</List>
			<Divider />
			<AlertDialog title="Coming Soon!" open={showAlert} handleClose={handleCloseAlert} />
		</div>
	)

	const drawerNotLoggedIn = (
		<div>
			<Toolbar />
			<List>
				<DrawerItem to="/" onClick={handleDrawerToggle} text="Home" />
				{CAN_JOIN === 'true' && <DrawerItem onClick={awsSignIn} text="Sign-Up / Sign-In" />}
				<DrawerItem to="/about" onClick={handleDrawerToggle} text="About Us" />
				<DrawerItem to="/contact" onClick={handleDrawerToggle} text="Contact Us" />
			</List>
		</div>
	)

	const drawerOnSignUp = (
		<div>
			<Toolbar />
			<List>
				<DrawerItem to="/about" onClick={handleDrawerToggle} text="About Us" />
				<DrawerItem to="/contact" onClick={handleDrawerToggle} text="Contact Us" />
			</List>
		</div>
	)

	const container = window !== undefined ? () => window().document.body : undefined

	const handleViewMyProfile = mode => {
		handleCloseUserMenu()
		if (user.sports && user.sports?.length > 0) {
			navigate(`/p/${user?.alias}/${user?.sports[0]?.code}`, {
				state: { refer: 'viewProfile' }
			})
		} else {
			UserService.getUserByAlias(user?.alias).then(response => {
				navigate(`/p/${response.data?.alias}/${response.data?.sports[0]?.code}`, {
					state: { refer: 'viewProfile' }
				})
			})
		}
		localStorage.removeItem('look-around-data')
		if (mode === 'view') {
			localStorage.setItem('showEditIcon', 'hide')
		} else {
			localStorage.setItem('showEditIcon', 'show')
		}
	}

	const handleShareMyProfile = () => {
		handleCloseShareMenu()
		setIsShareDialogOpen(true)
	}

	const handleCloseShareDialog = () => {
		setIsShareDialogOpen(false)
	}

	const { shareDetails } = useContext(ShareContext)
	const shareContent = useShare(shareDetails)

	const handleShareThisPage = () => {
		handleCloseShareMenu()
		shareContent()
	}

	const handleLogoClick = () => {
		if (location.pathname !== '/signup') {
			localStorage.removeItem('look-around-data')
			navigate(`/home`)
		} else {
			logout()
			window.location.href = '/'
		}
	}
	return (
		<AppBar position="fixed" style={styles.appBar}>
			<Container maxWidth="sm">
				<Toolbar disableGutters>
					<Box>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleDrawerToggle}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
					</Box>

					{showLogo && (
						<Typography
							variant="h6"
							noWrap
							component={Link}
							to="/"
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
								fontFamily: 'Permanent Marker',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'white',
								textDecoration: 'none'
							}}
						>
							BelayLink
						</Typography>
					)}

					<Box component="nav" sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}>
						<Drawer
							container={container}
							variant="temporary"
							open={mobileOpen}
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true
							}}
							sx={{
								'& .MuiDrawer-paper': {
									boxSizing: 'border-box',
									width: drawerWidth,
									bgcolor: '#F4F5F7'
								}
							}}
						>
							{isLoggedIn
								? location.pathname === '/signup'
									? drawerOnSignUp
									: drawerLoggedIn
								: drawerNotLoggedIn}
						</Drawer>
					</Box>
					{showLogo && (
						<Typography
							variant="h5"
							noWrap
							onClick={handleLogoClick}
							sx={{
								mr: '6px',
								display: { xs: 'flex', md: 'none' },
								flexGrow: 1,
								fontFamily: 'Permanent Marker',
								fontWeight: 700,
								letterSpacing: '.3rem',
								color: 'white',
								textDecoration: 'none',
								fontSize: '1.125rem'
							}}
						>
							BelayLink
						</Typography>
					)}
					{!isLoggedIn && (
						<Box
							sx={{
								flexGrow: 1,
								display: { xs: 'flex', md: 'flex' },
								justifyContent: 'flex-end'
							}}
						>
							{CAN_JOIN === 'true' ? (
								<Button
									startIcon={<AccountCircleOutlinedIcon />}
									onClick={() => awsSignIn()}
									sx={{ my: 0, color: 'white', display: 'flex' }}
									component="a"
								>
									Sign-In
								</Button>
							) : (
								<Button
									onClick={() => navigate('/join-waitlist')}
									sx={{ my: 0, color: 'white', display: 'flex' }}
									component="a"
								>
									Get Early Access
								</Button>
							)}
						</Box>
					)}

					{hasCompletedSignup && (
						<Box display="flex" gap={'15px'} ml="auto" justifyContent="flex-end">
							<Box sx={{ flexGrow: 0 }}>
								<Tooltip height="26px" title="Open settings">
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<Avatar
											sx={{ height: '26px', width: '26px', fontSize: '16px' }}
											alt={user?.name || 'Test'}
											src={user?.userImage?.viewUrl || '/broken-img.jpg'}
										/>
									</IconButton>
								</Tooltip>

								<Menu
									sx={{ mt: '45px' }}
									id="menu-appbar"
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									<MenuItem onClick={() => handleViewMyProfile('view')}>
										<Typography textAlign="center">View my Profile</Typography>
									</MenuItem>
									<MenuItem onClick={() => handleViewMyProfile('edit')}>
										<Typography textAlign="center">Edit my Profile</Typography>
									</MenuItem>

									<MenuItem onClick={logout}>
										<Typography textAlign="center">Sign-Out</Typography>
									</MenuItem>
								</Menu>
							</Box>

							{/* Share Button with Menu */}
							<IconButton
								sx={{ height: '26px', aspectRatio: '1/1', padding: 0 }}
								onClick={handleOpenShareMenu}
							>
								<ShareOutlined sx={{ color: '#FFF' }} />
							</IconButton>

							<Menu
								sx={{ mt: '45px' }}
								id="menu-share"
								anchorEl={anchorElShare}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={Boolean(anchorElShare)}
								onClose={handleCloseShareMenu}
							>
								<MenuItem onClick={handleShareMyProfile}>
									<Typography textAlign="center">Share my Profile</Typography>
								</MenuItem>
								<MenuItem onClick={handleShareThisPage}>
									<Typography textAlign="center">Share this Page</Typography>
								</MenuItem>
							</Menu>

							<IconButton
								sx={{ height: '26px', aspectRatio: '1/1', padding: 0 }}
								onClick={() => navigate('/notifications')}
							>
								<Badge badgeContent={unreadNotifyCount} color="error">
									<NotificationsNoneOutlinedIcon sx={{ color: '#FFF' }} />
								</Badge>
							</IconButton>
						</Box>
					)}
				</Toolbar>
			</Container>
			<Dialog open={isShareDialogOpen} onClose={handleCloseShareDialog}>
				<DialogTitle>
					{/* Optional: Title Text Here */}
					<IconButton
						aria-label="close"
						onClick={handleCloseShareDialog}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.grey[500]
						}}
					>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<ShareSportProfile user={user} message="" />
				</DialogContent>
				{/* DialogActions if any */}
			</Dialog>
		</AppBar>
	)
}
export default Header
