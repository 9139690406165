import React from "react";
import Grid from "@mui/material/Grid";
import { FieldArray } from "formik";
import SportCardInput from "../../ui/SportCardInput";

const SportGots = (props) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <FieldArray
          name="profile.sportGots"
          render={(arrayHelpers) => {
            return props.gots.map((got, index) => (
              <Grid item xs={6} lg={4} key={got.id}>
                <SportCardInput
                  arrayHelpers={arrayHelpers}
                  item={got}
                  name="profile.sportGots"
                  index={index}
                />
              </Grid>
            ));
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

export default SportGots;
