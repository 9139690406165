import {
	Chip,
	Grid,
	InputAdornment,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useField } from 'formik'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { DatePicker } from '@mui/x-date-pickers'
import challengeTypeDetails from './challangeTypeDetails'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import MetaData from '../../../components/MetaData'

const ChallengeDetailsPage = props => {
	const [typeField] = useField('type')

	const [typeSelected, setTypeSelected] = useState('')
	const [goalSelected, setGoalSelected] = useState('')

	const [nameField, , nameHelper] = useField('name')
	const [descField, , descHelper] = useField('description')
	const [startDateField, , startDateHelpers] = useField('startDate')
	const [endDateField, , endDateHelpers] = useField('endDate')

	// if (nameField.value !== "" && typeSelected === "") {
	//   const type = typeDetails
	//     .filter((i) => i.type === typeField.value)[0]
	//     .findIndex((t) => t.challenge === nameField.name);
	//   setTypeSelected(type);
	// }
	// if (descField.value !== "" && typeSelected !== "") {
	//   const goal = typeDetails
	//     .filter((i) => i.type === typeField.value)[0]
	//     .challenge[typeSelected].goal.findIndex((g) => g.name === descField.name);
	//   setGoalSelected(goal);
	// }

	return (
		<React.Fragment>
			<MetaData title={`BelayLink - Challenge Details`} description={'Challenge Details'} />
			<Stack spacing={2}>
				<Typography
					sx={{
						fontSize: '21px',
						lineHeight: '28px',
						/* identical to box height, or 133% */
						textAlign: 'center'
					}}
				>
					Create the challenge
				</Typography>

				<Typography
					sx={{
						fontWeight: '700',
						fontSize: '17px',
						lineHeight: '24px',
						/* identical to box height, or 141% */
						textAlign: 'center'
					}}
				>
					{typeField.value === 'sport' ? 'Select a sport' : 'Select a category'}
				</Typography>

				<Select
					defaultValue={''}
					value={typeSelected}
					onChange={event => {
						setTypeSelected(event.target.value)
						setGoalSelected('')
					}}
				>
					{challengeTypeDetails
						.filter(i => i.type === typeField.value)[0]
						.challenge.map((c, index) => (
							<MenuItem value={index} key={index}>
								{c.name}
							</MenuItem>
						))}
				</Select>

				<Stack direction="row" alignSelf="center">
					<Typography
						sx={{
							fontWeight: '700',
							fontSize: '17px',
							lineHeight: '24px',
							/* identical to box height, or 141% */
							textAlign: 'center'
						}}
					>
						Select a challenge
					</Typography>
					<Tooltip
						title="Do a challenge that matters to you!
                  Will it help you do what you have been wanting to do?
                  Will it make you feel successful when you do it?"
					>
						<InfoOutlinedIcon />
					</Tooltip>
				</Stack>

				<Select
					defaultValue={''}
					value={goalSelected}
					onChange={event => {
						setGoalSelected(event.target.value)

						let goal = challengeTypeDetails.filter(i => i.type === typeField.value)[0].challenge[
							typeSelected
						].goal[event.target.value]
						nameHelper.setValue(goal.name)
						descHelper.setValue(goal.desc)
					}}
				>
					{typeSelected !== '' &&
						challengeTypeDetails
							.filter(i => i.type === typeField.value)[0]
							.challenge[typeSelected].goal.map((g, index) => (
								<MenuItem value={index} key={g.name}>
									{g.display}
								</MenuItem>
							))}
				</Select>

				{goalSelected !== '' && (
					<div>
						<Typography
							sx={{
								fontWeight: '700',
								fontSize: '17px',
								lineHeight: '24px',
								/* identical to box height, or 141% */
								textAlign: 'center'
							}}
						>
							Give your touch to the challenge
						</Typography>

						<Box sx={{ border: '3px solid #474A57', borderRadius: '16px' }}>
							<Grid
								direction="row"
								container
								spacing={1}
								justifyContent="center"
								sx={{ mt: 1, mb: 1 }}
							>
								<Grid item xs={10}>
									<TextField
										id="title"
										label="Title"
										variant="filled"
										sx={{ bgcolor: '#FFD465' }}
										fullWidth
										{...nameField}
									/>
								</Grid>
								<Grid item xs={10}>
									<TextField
										id="desc"
										label="Description"
										variant="filled"
										multiline
										rows={4}
										fullWidth
										sx={{ bgcolor: '#FFD465' }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Tooltip
														title="Pro Tip: Make it actionable!
										Actionable plans have 10x higher chance to success.
										Example: “Get 6 packs” is a goal but not an actionable plan, but “Do 20 push-ups everyday for 1 month” is!"
													>
														<InfoOutlinedIcon />
													</Tooltip>
												</InputAdornment>
											)
										}}
										{...descField}
									/>
								</Grid>

								<Grid item xs={10}>
									<Chip
										variant="outlined"
										label="You can edit it"
										icon={<ArrowUpwardOutlinedIcon />}
										sx={{ bgcolor: 'white', float: 'right' }}
									/>
								</Grid>

								<Grid container item xs={6} direction="row" alignItems="center">
									<CalendarTodayOutlinedIcon />
									<Typography variant="subtitle2">Start</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2">End</Typography>
								</Grid>
								<Grid item xs={5}>
									<DatePicker
										views={['month', 'day']}
										inputFormat="MMM. DD"
										{...startDateField}
										onChange={newValue => {
											console.log(newValue.startOf('date').toISOString())
											startDateHelpers.setValue(newValue.startOf('date').toISOString())
										}}
										renderInput={params => {
											return <TextField {...params} helperText={null} />
										}}
									/>
								</Grid>
								<Grid item xs={1}>
									<Typography textAlign="center">-</Typography>
								</Grid>
								<Grid item xs={5}>
									<DatePicker
										views={['month', 'day']}
										inputFormat="MMM. DD"
										{...endDateField}
										onChange={newValue => {
											console.log(newValue.endOf('date').toISOString())
											endDateHelpers.setValue(newValue.endOf('date').toISOString())
										}}
										renderInput={params => {
											return <TextField {...params} helperText={null} />
										}}
									/>
								</Grid>

								{/* <Grid xs="10">
                  <FormControlLabel
                    sx={{ fontSize: "12px" }}
                    control={<Checkbox />}
                    label={
                      <Typography fontSize={"12px"}>
                        Start as soon as challenge is accepted
                      </Typography>
                    }
                  />
                </Grid>
                <Grid xs="10">
                  <FormControlLabel
                    sx={{ fontSize: "12px" }}
                    control={<Checkbox />}
                    label={
                      <Typography fontSize={"12px"}>
                        End as soon as someone hits the target
                      </Typography>
                    }
                  />
                </Grid> */}
							</Grid>
						</Box>
					</div>
				)}
			</Stack>
		</React.Fragment>
	)
}

export default ChallengeDetailsPage
