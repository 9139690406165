import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { AuthContextProvider, useAuth } from './store/auth-context'
import { ThemeProvider, Container, Box, CssBaseline } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import theme from './styles/theme'
// pages
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import AwsSignInPage from './pages/AwsSignupPage'
import CreateUserPage from './pages/CreateUserPage'
import CreateSportProfile from './pages/CreateSportProfile'
import EditSportProfile from './pages/EditSportProfilePage'
import ChallengeCreatePage from './pages/challenge/create/ChallengeCreatePage'
import ChallengesListPage from './pages/challenge/list/ChallengesListPage'
import ChallengeWinnerPage from './pages/challenge/winner/ChallengeWinnerPage'
import ChallengeResultPage from './pages/challenge/ChallengeResultPage'
import AboutUsPage from './pages/AboutUsPage'
// containers
import Navigation from './containers/Navigation'
import AuthRoute from './containers/AuthRoute'
// components
import Footer from './components/ui/Footer'
// images
// import background from './assets/background.jpg'
import ContactPage from './pages/ContactPage'
import ChallengeConfirmationPage from './pages/challenge/ChallengeConfirmationPage'
import ChallengeSendInvitePage from './pages/challenge/ChallengeSendInvitePage'
import WhySignUp from './pages/challenge/whysignup'
import { ConfirmProvider } from 'material-ui-confirm'
import ViewUser from './pages/ViewUser'
import NonAuthRoute from './containers/NonAuthRoute'
import EditUserCoverImages from './pages/EditUser/EditUserCoverImages'
import EditUserProfilePage from './pages/EditUser'
import BelayLinkHomePage from './pages/BelayLinkHomePage/Index'
import LookAroundWecome from './pages/LookAround/LookAroundWecome'
import Notifications from './pages/Notifications'
import BrowseProfiles from './pages/BrowseProfiles'
import YourSportsPartners from './pages/YourSportsPartners'
import GroupChat from './pages/Chat'
import MessageScreen from './pages/Chat/MessageScreen'
import { ChatProvider } from './store/chat-context'
import JoinWaitlistPage from './pages/JoinWaitlistPage'
import NotificationPreferences from './pages/NotificationPreferences'
import LookAround from './pages/LookAround/LookArround'
import { LoadingContextProvider } from './store/loading-context'
import ShareSportProfile from './pages/ShareSportsProfile'
import PageNotFound from './components/ui/PageNotFound'
import Notification from './components/firebase/PushNotification'
import { ShareProvider } from './components/ShareContext'
import PrivacyPage from './pages/Privacy'

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Notification />
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Box
					sx={{
						// backgroundImage: `url(${background})`,
						backgroundAttachment: 'fixed',
						minHeight: '100%',
						height: '100%'
					}}
				>
					<SnackbarProvider maxSnack={3}>
						<ConfirmProvider>
							{/* marginBottom should be equal to footer height in order to push up the space */}
							<Container
								maxWidth="sm"
								//sx={{ minHeight: '100vh' }}
								// maxHeight={false}
								disableGutters
							>
								<LoadingContextProvider>
									<AuthContextProvider>
										<ChatProvider>
											<ShareProvider>
												<Navigation>
													<Routes>
														{/* <Route path="/" element={<Navigate replace to="/signup" />} /> */}
														<Route path="/" element={<NonAuthRoute component={HomePage} />} />
														<Route path="/aws-signup" element={<AwsSignInPage />} />
														<Route path="/signup" element={<CreateUserPage />} />
														<Route path="/login" element={<LoginPage />} />
														<Route path="/contact" element={<ContactPage />} />
														<Route path="/join-waitlist" element={<JoinWaitlistPage />} />
														<Route path="/about" element={<AboutUsPage />} />
														<Route path="/c/:alias" element={<ChallengeConfirmationPage />} />
														<Route
															path="/home"
															element={<AuthRoute component={BelayLinkHomePage} />}
														/>

														<Route
															path="/notifications"
															element={<AuthRoute component={Notifications} />}
														/>
														<Route
															path="/notifypref"
															element={<AuthRoute component={NotificationPreferences} />}
														/>
														<Route
															path="/browse-profiles/p/:alias/:sportCode/*"
															element={<AuthRoute component={BrowseProfiles} />}
														/>
														<Route path="/buddies/p/:alias/*" element={<YourSportsPartners />} />
														{/* /p/w4tbsf/BMT */}
														<Route
															path="/p/:alias/createSportProfile"
															element={<AuthRoute component={CreateSportProfile} mode="create" />}
														/>
														<Route
															path="editSportProfile/:sportCode"
															element={<AuthRoute component={CreateSportProfile} mode="edit" />}
														/>

														<Route
															path="/p/:alias/:sportCode/edit"
															element={<AuthRoute component={EditSportProfile} />}
														/>
														<Route path="/p/*" element={<ViewUser />} />
														<Route path="/p/:alias/*" element={<ViewUser />} />

														<Route
															path="/p/:alias/edit"
															element={<AuthRoute component={EditUserProfilePage} />}
														/>
														<Route
															path="/p/:alias/actionphotos"
															element={<AuthRoute component={EditUserCoverImages} />}
														/>
														<Route
															path="/share"
															element={<AuthRoute component={ShareSportProfile} />}
														/>
														<Route path="/challenge/create" element={<ChallengeCreatePage />} />
														<Route
															path="/c/:alias/winner"
															element={<AuthRoute component={ChallengeWinnerPage} />}
														/>
														<Route path="/c/:alias/result" element={<ChallengeResultPage />} />
														<Route path="/c/:alias/invite" element={<ChallengeSendInvitePage />} />
														<Route path="/c/:alias/whysignup" element={<WhySignUp />} />
														<Route
															path="/challenges"
															element={<AuthRoute component={ChallengesListPage} />}
														/>
														<Route path="/messages" element={<AuthRoute component={GroupChat} />} />
														<Route
															path="/messages/:id"
															element={<AuthRoute component={MessageScreen} />}
														/>
														<Route
															path="/welcome/:sportCode/:alias"
															element={<AuthRoute component={LookAroundWecome} />}
														/>
														<Route
															path="/look-around/:alias/*"
															element={<AuthRoute component={LookAround} />}
														/>
														<Route path="/privacy*" element={<PrivacyPage />} />

														{/* <Route path="/sport/add" element={<RegistrationPage />} /> */}
														<Route path="*" element={<PageNotFound />} />
													</Routes>
												</Navigation>
											</ShareProvider>
										</ChatProvider>
									</AuthContextProvider>
								</LoadingContextProvider>
							</Container>
						</ConfirmProvider>
					</SnackbarProvider>
				</Box>
			</LocalizationProvider>
		</ThemeProvider>
	)
}

export default App
