const beginner = '#61E4C5'
const intermediate = '#FFBD12'
const advanced = '#F95A2C'
const expert = '#8094FF'

export const getLevelColor = level => {
	if (level) {
		if (level === 'Beginner') {
			return beginner
		} else if (level === 'Intermediate') {
			return intermediate
		} else if (level === 'Advanced') {
			return advanced
		} else if (level === 'Expert') {
			return expert
		}
	}
}

export const getSportStyleLevelColor = proficiency => {
	if (proficiency) {
		if (proficiency === 1) {
			return beginner
		} else if (proficiency === 2) {
			return intermediate
		} else if (proficiency === 3) {
			return advanced
		} else if (proficiency === 4) {
			return expert
		}
	}
}
