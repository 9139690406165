import { FormControl, Stack, Typography } from '@mui/material'
import { useField } from 'formik'
import MonthSelect from '../../components/ui/MonthSelect'
import YearSelect from '../../components/ui/YearSelect'
import { useState } from 'react'
import { useEffect } from 'react'

const SetPracticingSince = () => {
	const [fieldPracticingSince, , helperPracticingSince] = useField('practicingSince')
	const [selectedMonth, setSelectedMonth] = useState('')
	const [selectedYear, setSelectedYear] = useState('')

	useEffect(() => {
		const date = new Date(fieldPracticingSince.value)
		const year = date.getFullYear().toString()
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		setSelectedMonth(month)
		setSelectedYear(year)
	}, [fieldPracticingSince])

	const handleMonthChange = month => {
		setSelectedMonth(month)
		handleDateChange(month, selectedYear)
	}

	const handleYearChange = year => {
		setSelectedYear(year)
		handleDateChange(selectedMonth, year)
	}

	const handleDateChange = (month, year) => {
		const date = new Date(`${year}-${month}-01`)
		helperPracticingSince.setValue(date.toJSON())
	}

	return (
		<Stack direction="column" spacing={6}>
			<Typography variant="h1b">When did you start practicing?</Typography>

			<Stack direction="row" spacing={2} px={2}>
				<FormControl sx={{ width: '100%' }}>
					<YearSelect value={selectedYear} onChange={handleYearChange} />
				</FormControl>

				<FormControl sx={{ width: '100%' }}>
					<MonthSelect value={selectedMonth} onChange={handleMonthChange} />
				</FormControl>
			</Stack>

			<Typography variant="p" px={4}>
				Don’t remember exactly? Just roughly is fine!
			</Typography>
		</Stack>
	)
}

export default SetPracticingSince
