import React, { useState, useEffect } from 'react'
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useLocation, useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'
import notificationServiceInstance from '../services/NotificationService'
import FriendService from '../services/FriendService'

export default function Notifications() {
	const navigate = useNavigate()
	const location = useLocation()

	const goBack = () => {
		navigate(location.key ? -1 : '/home')
	}
	const [notifications, setNotifications] = useState([])

	const UNREAD_NOTIFICATION_COLOR = 'rgba(97, 228, 197, 0.50)'

	useEffect(() => {
		fetchNotifications()
	}, [])
	const fetchNotifications = async () => {
		try {
			const data = await notificationServiceInstance.getNotifications()
			setNotifications(data)
			notificationServiceInstance.putMarkAllAsRead()
		} catch (error) {
			console.error('Error fetching notifications:', error)
		}
	}

	const getRouteForNotificationType = notification => {
		switch (notification.type) {
			case notificationServiceInstance.NOTIFICATION_TYPES.FRIEND_REQUEST:
				return `/p/${notification.content.alias}`
			case notificationServiceInstance.NOTIFICATION_TYPES.NEW_FRIEND:
				return `/p/${notification.content.alias}`
			case notificationServiceInstance.NOTIFICATION_TYPES.PROFILE_STATUS_UPDATED:
				return `/p/${notification.content.alias}/${notification.content.sportCode}`
			case notificationServiceInstance.NOTIFICATION_TYPES.PROFILE_STATUS_EXPIRED:
				return `/home`
			case notificationServiceInstance.NOTIFICATION_TYPES.FRIEND_RECOMMENDATION:
				return `/p/${notification.content.alias}/${notification.content.sportCode}`
			default:
		}
	}

	const showAvatarForNotification = notification => {
		switch (notification.type) {
			case notificationServiceInstance.NOTIFICATION_TYPES.FRIEND_REQUEST:
				return true
			case notificationServiceInstance.NOTIFICATION_TYPES.NEW_FRIEND:
				return true
			case notificationServiceInstance.NOTIFICATION_TYPES.PROFILE_STATUS_UPDATED:
				return true
			case notificationServiceInstance.NOTIFICATION_TYPES.FRIEND_RECOMMENDATION:
				return true
			default:
				return false
		}
	}

	const handleRequest = (e, notification, requestType) => {
		e.stopPropagation()
		if (requestType === 'CONFIRM') {
			FriendService.acceptFriendRequest(notification?.content?.alias).then(response => {
				if (response.status === 200) {
					fetchNotifications()
					notificationServiceInstance.putMarkAllAsRead()
				}
			})
		} else {
			FriendService.rejectFriendRequest(notification?.content.alias).then(response => {
				if (response.status === 200) {
					fetchNotifications()
					notificationServiceInstance.putMarkAllAsRead()
				}
			})
		}
	}

	return (
		<>
			<Box>
				<Box
					sx={{
						py: '20px',
						px: '23px',
						display: 'flex',
						alignItems: 'center',
						background: 'white'
					}}
				>
					<Box sx={{ width: '100%', display: 'flex', gap: '6px', alignItems: 'center' }}>
						<IconButton sx={{ p: 0 }} onClick={goBack}>
							<ChevronLeftIcon />
						</IconButton>
						<Typography className="fw-700 f-18" sx={{ margin: 'auto' }}>
							Notifications
						</Typography>
					</Box>
				</Box>

				{notifications.map((notification, index) => {
					const sanitizedHTML = DOMPurify.sanitize(notification.content.message)
					const handleNotificationClick = () => {
						const route = getRouteForNotificationType(notification)
						if (route === '/home') {
							navigate(route, { state: { sportCode: notification?.content?.sportCode } }) //navigate to notification related sport
						} else {
							navigate(route)
						}
					}

					return (
						<Box
							key={notification.id}
							onClick={handleNotificationClick}
							sx={{
								py: '20px',
								px: '23px',
								borderBottom: '1px solid #9FA4B4',
								display: 'flex',
								alignItems: 'flex-start',
								gap: '16px',
								backgroundColor: !notification.isRead ? UNREAD_NOTIFICATION_COLOR : 'transparent',
								'&:hover': {
									backgroundColor: 'rgba(97, 228, 197, 0.50)'
								}
							}}
						>
							{showAvatarForNotification(notification) && (
								<Box sx={{ width: '35px', height: '35px' }}>
									<Avatar
										src={notification.content.avatar || '/static/images/avatar/default.jpg'}
									/>
								</Box>
							)}
							{notification?.type !== 'FRIEND_REQUEST' ? (
								<Box>
									<Typography variant="p" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
								</Box>
							) : (
								<Box>
									<Box>
										<Typography variant="p" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
									</Box>

									<Box
										sx={{
											display: 'flex',
											width: '100%',
											justifyContent: 'space-between',
											alignItems: 'center'
										}}
									>
										<Box sx={{ width: '90%' }}>
											<Button
												onClick={e => handleRequest(e, notification, 'CONFIRM')}
												variant={'contained'}
												sx={{
													color: '#FFF',
													padding: '4px 10px',
													'&:hover': {
														background: '#00C6AE'
													},
													textAlign: 'center',
													maxHeight: '24px',
													boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
													width: '90%'
												}}
											>
												<Typography
													sx={{
														paddingLeft: '4px',
														color: 'white'
													}}
													variant="h4"
												>
													Confirm
												</Typography>
											</Button>
										</Box>
										<Box sx={{ width: '90%' }}>
											<Button
												onClick={e => handleRequest(e, notification, 'DELETE')}
												variant={'outline'}
												sx={{
													color: '#FFF',
													padding: '4px 10px',
													'&:hover': {
														background: '#00C6AE'
													},
													border: '1px solid #00C6AE',
													textAlign: 'center',
													maxHeight: '24px',
													boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
													width: '90%'
												}}
											>
												<Typography
													sx={{
														paddingLeft: '4px',
														color: '#00C6AE'
													}}
													variant="h4"
												>
													Delete
												</Typography>
											</Button>
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					)
				})}
			</Box>
		</>
	)
}
