import React, { useEffect } from 'react'
import {
	Typography,
	Stack,
	Box,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	FormControl
} from '@mui/material'
import { ArrowDropDownCircleRounded, Check } from '@mui/icons-material'
import { useField } from 'formik'
import ErrorMessageBL from '../../components/ui/ErrorMessageBL'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'

const SetSportCode = ({ sportList, handleSportChange }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [fieldSportCode, ,] = useField('sportCode')
	const containerRef = React.useRef(null)
	const { user } = useAuth()

	useEffect(() => {
		if (location.state?.refer === 'signUp') {
			// prevent to go back when came from
			const disableBackButton = () => {
				window.onpopstate = event => {
					navigate(1)
				}
			}
			// Call the disableBackButton function when the component mounts
			disableBackButton()
			// Add an event listener
			window.addEventListener('popstate', disableBackButton)
			return () => {
				//Remove the event listene
				window.removeEventListener('popstate', disableBackButton)
			}
		}
	}, [])

	const scrollToBottom = () => {
		// Calculate the target position (e.g., 100 pixels from the bottom)
		// const box = document.getElementById('code-container')
		if (containerRef.current) {
			const container = containerRef.current
			container.scrollTo({
				top: container.scrollHeight,
				behavior: 'smooth'
			})
		}
	}

	return (
		<Stack direction="column" spacing={6}>
			<Typography variant="h1b">What sport do you do?</Typography>

			<FormControl sx={{ px: 2, width: '100%' }}>
				<Box
					id="code-container"
					ref={containerRef}
					sx={{
						marginTop: '-30px',
						height: '380px',
						overflowY: 'scroll',
						'&::-webkit-scrollbar': {
							width: '5px',
							display: 'block',
							background: 'lightgray',
							scrollBehavior: 'smooth'
						},
						'&::-webkit-scrollbar-track': {
							background: 'lightgray'
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'gray',
							borderRadius: '3px',
							'&:hover': {
								backgroundColor: '#aaa'
							}
						}
					}}
				>
					<List>
						{sportList
							.filter(sport => !user.sports?.some(userSport => userSport.code === sport.code))
							.map((sport, index) => (
								<React.Fragment key={sport.code}>
									<ListItemButton
										sx={{
											borderRadius: '4px',
											'&:hover': {
												backgroundColor: 'rgba(0, 0, 0, 0.04)'
											}
										}}
										selected={fieldSportCode.value === sport.code}
										onClick={() => handleSportChange(sport.code)}
									>
										<ListItemText primary={sport.name} />
										{fieldSportCode.value === sport.code && (
											<ListItemIcon>
												<Check />
											</ListItemIcon>
										)}
									</ListItemButton>
									{index !== sportList.length - 1 && <Divider />}
								</React.Fragment>
							))}
					</List>
					<Box
						sx={{
							position: 'absolute',
							bottom: '0px',
							right: '18px',
							cursor: 'pointer',
							width: '37px',
							height: '37px'
						}}
						onClick={scrollToBottom}
					>
						<ArrowDropDownCircleRounded
							style={{ width: '100%', height: '100%', color: 'lightgray	' }}
						/>
					</Box>
				</Box>
				<ErrorMessageBL name={fieldSportCode.name} />
			</FormControl>

			<div style={{ fontSize: '14px', fontWeight: '500', color: '#202020', marginTop: '20px' }}>
				Let’s start with one sport! We’ll get to the other sports soon :)
			</div>
		</Stack>
	)
}

export default SetSportCode
