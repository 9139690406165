import React from 'react'
import { atcb_action, atcb_init } from 'add-to-calendar-button'
import { Button } from '@mui/material'
import { useEffect } from 'react'
import 'add-to-calendar-button/assets/css/atcb.css'
import DateUtils from '../../shared/DateUtils'
import moment from 'moment-timezone'

const AddToCalendarButton = ({ challenge, startIcon, sx, variant }) => {
	const challengeUrl = `https://belaylink.com/c/${challenge.alias}`

	// var zone_name = moment.tz.guess()
	// var timezone = moment.tz(zone_name).zoneAbbr()
	const calendarEvent = {
		name: `${challenge.name}`,
		description: `${challenge.description}`,
		startDate: new Date(challenge.startDate).toLocaleDateString('en-CA').substring(0, 10),
		endDate: new Date(challenge.endDate).toLocaleDateString('en-CA').substring(0, 10),
		// startTime: challenge.startDate.substring(11, 16),
		// endTime: challenge.endDate.substring(11, 16),
		location: challengeUrl,
		options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
		iCalFileName: 'Challenge-Reminder'
		// timeZone: moment.tz.guess()
	}

	console.log(calendarEvent)

	// useEffect(atcb_init, []);
	return (
		<Button
			className="atcb"
			sx={sx}
			variant={variant}
			fullWidth
			startIcon={startIcon}
			onClick={e => {
				e.preventDefault()
				atcb_action(calendarEvent, this)
			}}
		>
			Add To Calendar
		</Button>
	)
}

export default AddToCalendarButton
