import { useState } from 'react'
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Modal
} from '@mui/material'
import UserService from '../../services/UserService'
import { useLocation, useNavigate } from 'react-router-dom'
import useNotification from '../../hooks/useNotification'
import { useEffect } from 'react'
import ActionPhotos from '../../components/user/ActionPhotos'
import { useAuth } from '../../store/auth-context'
import congrats from '../../assets/icon/congrats.png'
import { CloseOutlined } from '@mui/icons-material'
import imageCompression from 'browser-image-compression'

const EditUserCoverImages = () => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true
	}
	// Initialize selectedImages with id and viewUrl properties
	const [selectedImages, setSelectedImages] = useState(
		Array(7)
			.fill(null)
			.map(() => ({ id: null, viewUrl: null }))
	)
	const [isLoading, setIsLoading] = useState(false)
	// Initialize removedImagesIds with an empty array
	const [removedImagesIds, setRemovedImagesIds] = useState([])
	const navigate = useNavigate()
	const [, sendNotification] = useNotification()
	const location = useLocation()
	const { user, updateUser } = useAuth()
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

	const handleSubmit = () => {
		const checkForImages =
			selectedImages.length > 0 && selectedImages?.some(img => img?.viewUrl && img?.viewUrl !== '')
		if (location?.state?.actionPhotoRequired && !checkForImages) {
			sendNotification({
				msg: 'Please add atleast one photo!',
				variant: 'error'
			})
		} else {
			setIsLoading(true)

			// Filter out null and already uploaded images
			const nonNullImages = selectedImages.filter(image => image !== null && !image.id)

			// Define a recursive function to upload one file at a time
			const uploadFile = async (index, removedImageIds) => {
				if (index >= nonNullImages.length) {
					// All files have been uploaded, update the cover photos using the removed image IDs
					UserService.updateCoverPhoto(null, removedImageIds)
						.then(response => {
							setIsLoading(false)
							if (location?.state?.refer === 'browse-profiles') {
								// success pop up only open for first time look around
								setIsSuccessModalOpen(true)
							} else {
								if (location?.state && location?.state?.sportCode) {
									navigate(`/p/${user?.alias}/${location.state.sportCode}`)
								} else {
									navigate(`/p/${user?.alias}`)
								}
								localStorage.setItem('showEditIcon', 'hide')
								// if (location?.state?.refer === 'browse-profiles') {
								// 	navigate(`/look-around/${user.alias}/${location?.state?.sportCode}`)
								// } else {
								// 	navigate(`/p/${user?.alias}`)
								// 	localStorage.setItem('showEditIcon', 'hide')
								// }
							}
							updateUser({
								...user,
								coverImages: response.data.coverImages,
								metaData: user.metaData
							})
						})
						.catch(error => {
							console.error(error)
							setIsLoading(false)
							sendNotification({
								msg: error?.response?.data?.message || 'An Error Occurred',
								variant: 'error'
							})
						})
				} else {
					// Compress the image before uploading
					const compressedFile = await compressImage(nonNullImages[index].file)

					const removeImageIds = index === 0 ? removedImagesIds : null
					UserService.updateCoverPhoto(compressedFile, removeImageIds)
						.then(response => {
							if (removeImageIds) {
								// Update the removed image IDs with the new image ID
								removedImageIds.push(response.data.id)
							}
							// Upload the next file
							uploadFile(index + 1, removedImageIds)
						})
						.catch(error => {
							console.error(error)
							setIsLoading(false)
							sendNotification({
								msg: error?.response?.data?.message || 'An Error Occurred',
								variant: 'error'
							})
						})
				}
			}
			// Start uploading the files
			uploadFile(0, removedImagesIds)
		}
	}

	useEffect(() => {
		UserService.getMe()
			.then(response => {
				const { coverImages } = response.data

				const newImages = Array(7).fill(null)
				if (Array.isArray(coverImages) && coverImages.length > 0) {
					coverImages.forEach((image, index) => {
						newImages[index] = { id: image.id, viewUrl: image.viewUrl }
					})
				}
				setSelectedImages(newImages)
			})
			.catch(error => {
				if (error?.response?.status === 404) {
					// User has not completed their registration yet, no action needed
				} else {
					sendNotification({
						msg: error?.response?.data?.message || 'An Error Occurred',
						variant: 'error'
					})
				}
			})
	}, [sendNotification])

	const handleSuccessModalClose = () => {
		setIsSuccessModalOpen(false)
		if (user?.sports?.length > 0) {
			if (location?.state?.refer === 'browse-profiles') {
				navigate(`/look-around/${user.alias}/${location?.state?.sportCode}`)
			} else {
				navigate(`/p/${user?.alias}/${user?.sports[0]?.code}`)
				localStorage.setItem('showEditIcon', 'hide')
			}
		} else {
			if (location?.state?.refer === 'browse-profiles') {
				navigate(`/look-around/${user.alias}/${location?.state?.sportCode}`)
			} else {
				navigate(`/p/${user?.alias}/${location?.state?.sportCode}`)
				localStorage.setItem('showEditIcon', 'hide')
			}
		}
	}

	const compressImage = async file => {
		try {
			const compressedFile = await imageCompression(file, options)
			return compressedFile
		} catch (error) {
			console.error('Error during image compression:', error)
		}
	}

	return (
		<>
			<ActionPhotos
				selectedImages={selectedImages}
				setSelectedImages={setSelectedImages}
				setRemovedImagesIds={setRemovedImagesIds}
			/>
			<Box
				sx={{
					textAlign: 'center',
					marginTop: '-80px',
					bottom: '0',
					width: '100%',
					left: '0'
				}}
			>
				<Box>
					<Button
						variant="blGreen"
						onClick={handleSubmit}
						sx={{
							width: '140px',
							height: '50px',
							fontSize: '17px',
							textTransform: 'capitalize',
							marginBottom: '30px',
							'&:hover': {
								opacity: '0.5',
								backgroundColor: '#00C6AE'
							}
						}}
					>
						Done
					</Button>
				</Box>
				<Dialog open={isLoading}>
					<DialogTitle>
						Loading... <CircularProgress size={20} style={{ marginLeft: '10px' }} />
					</DialogTitle>
					<DialogContent>Please wait while we upload your images.</DialogContent>
				</Dialog>
			</Box>
			<Modal
				open={isSuccessModalOpen}
				onClose={handleSuccessModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							position: 'absolute',
							top: '10px',
							right: '18px',
							color: 'gray'
						}}
					>
						<CloseOutlined onClick={handleSuccessModalClose} />
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'baseline',
							width: '100%'
						}}
					>
						<Box sx={{ fontSize: '25px', fontWeight: '700' }}>Done!</Box>
						<img src={congrats} style={{ width: '85px' }} />
					</Box>
					<Box
						sx={{
							fontSize: '18px',
							fontWeight: '600',
							marginLeft: '-20px'
						}}
					>
						Now see who’s also <br /> looking for people to
						<br /> do sports together!
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default EditUserCoverImages

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	minWidth: '286px',
	gap: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '245px'
}
