import { Button } from '@mui/material'

const MyButton = ({ sx, variant = 'blGreen', ...props }) => {
	let bgColor

	if (sx?.backgroundColor) {
		bgColor = sx.backgroundColor
	} else if (variant === 'blGreen') {
		bgColor = '#00C6AE'
	} else if (variant === 'blGrey') {
		bgColor = '#CECECE'
	}

	return (
		<Button
			{...props}
			variant={variant}
			sx={{
				...sx,
				backgroundColor: bgColor,
				'&:hover': {
					backgroundColor: bgColor
				}
			}}
		>
			{props.children}
		</Button>
	)
}

export default MyButton
