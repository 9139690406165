import { Box, Button } from '@mui/material'
import { Form, Formik, useField } from 'formik'
import React, { useState } from 'react'
import ChallengeStakesPage from './ChallengeStakesPage'
import ChallengeTypePage from './ChallengeTypePage'
import createChallengeFormModel from './createChallengeFormModel'
import createChallengeInitialValues from './createChallengeInitialValues'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import ChallengeDetailsPage from './ChallengeDetailsPage'
import ChallengeService from '../../../services/ChallengeService'
import JsonUtils from '../../../shared/JsonUtils'
import { useAuth } from '../../../store/auth-context'
import useNotification from '../../../hooks/useNotification'
import { useNavigate } from 'react-router-dom'

const ChallengeCreatePage = () => {
	const [activeStep, setActiveStep] = useState(0)
	const { formId, formField } = createChallengeFormModel
	const lastStep = 2
	const { isLoggedIn, awsSignIn } = useAuth()

	const navigate = useNavigate()

	const [, sendNotification] = useNotification()

	const handleTypeClick = () => {
		// typeHelpers.setValue(value);
		setActiveStep(1)
	}

	async function submitForm(values, actions) {
		const clean = JsonUtils.pruneEmpty(values)
		ChallengeService.createChallenge(clean)
			.then(response => {
				console.log(response)
				navigate(`/c/${response.data.alias}`, { replace: true })
			})
			.catch(error => {
				console.log('ERROR', error.response)
				sendNotification({
					msg:
						error.response.data.message !== undefined
							? error.response.data.message
							: 'An Error Occurred',
					variant: 'error'
				})
			})
		actions.setSubmitting(false)
	}

	function handleSubmit(values, actions) {
		if (activeStep === lastStep) {
			if (isLoggedIn) submitForm(values, actions)
			else awsSignIn()
		} else {
			setActiveStep(prevActiveStep => prevActiveStep + 1)
			actions.setTouched({})
			actions.setSubmitting(false)
		}
	}

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	return (
		<Formik
			initialValues={createChallengeInitialValues}
			// validationSchema={registrationValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ errors, isSubmitting, values }) => (
				<Form id={formId}>
					<Box sx={{ overflowY: 'auto', flex: 1 }}>
						{activeStep === 0 && <ChallengeTypePage onClick={handleTypeClick} />}
						{activeStep === 1 && <ChallengeDetailsPage />}
						{activeStep === 2 && <ChallengeStakesPage />}
					</Box>
					{activeStep > 0 && (
						<Box marginBottom={'40px'} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Button
								color="inherit"
								disabled={activeStep === 0 || isSubmitting}
								onClick={handleBack}
								sx={{ mr: 1, width: '120px' }}
								variant="blYellow"
							>
								<ArrowBack htmlcolor="white" fontSize="large" />
							</Button>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button
								sx={{ mr: 1, width: '120px', height: '53px' }}
								variant="blYellow"
								disabled={isSubmitting}
								type="submit"
								htmlcolor="white"
							>
								{activeStep === lastStep ? (
									'Submit'
								) : (
									<ArrowForward htmlcolor="white" fontSize="large" />
								)}
							</Button>
						</Box>
					)}
				</Form>
			)}
		</Formik>
	)
}

export default ChallengeCreatePage
