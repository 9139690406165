import useNotification from './useNotification'

const useShare = ({ url, title, text }) => {
	const [, sendNotification] = useNotification()

	const handleShareButton = () => {
		const shareDetails = {
			url,
			title,
			text
		}

		if (navigator.share) {
			navigator
				.share(shareDetails)
				.then(() => {
					console.log('Sharing successful')
					// Optionally use sendNotification here
				})
				.catch(() => {
					console.log('Sharing failed')
					// Handle sharing failure
				})
		} else {
			console.log('Sorry! Your browser does not support the Web Share API')
			navigator.clipboard.writeText(url)
			sendNotification({
				msg: 'Copied to clipboard',
				variant: 'info'
			})
		}
	}

	return handleShareButton
}

export default useShare
