import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import MessageBubble from './MessageBubble'
import { isEqual } from 'lodash'

const MessageListByDates = React.memo(
	({ messagesByDateGroup, date }) => {
		return (
			<React.Fragment>
				<Stack sx={{ alignItems: 'center', margin: '10px 0px' }}>
					<Typography
						variant="pregular"
						sx={{
							backgroundColor: 'beige',
							width: 'fit-content',
							padding: '3px 10px',
							borderRadius: '5px'
						}}
					>
						{new Date(date).toLocaleDateString()}
					</Typography>
				</Stack>
				{messagesByDateGroup.map((list, index) => (
					<MessageBubble key={list.messageId} message={list} />
				))}
			</React.Fragment>
		)
	},
	(pp, np) => isEqual(pp, np)
)

export default MessageListByDates
