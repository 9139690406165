import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { getLevelColor } from '../../constants/levelColors'
import DateUtils from '../../shared/DateUtils'
import SportStyleRating from '../user/SportStyleRating'

const BuddySportProfile = ({ friend, sportCode }) => {
	const practicingSince = date => {
		let yearsSince = DateUtils.yearsSince(date)
		if (yearsSince === 0) {
			const monthsSince = DateUtils.monthsSince(date)
			return { value: monthsSince, text: `mt${yearsSince > 1 ? 's' : ''}` }
		}
		return { value: yearsSince, text: `yr${yearsSince > 1 ? 's' : ''}` }
	}

	return (
		<Box>
			{friend?.sportProfiles.map(sport => (
				<Box>
					{sport.sport.code === sportCode && (
						<>
							<Box
								sx={{
									borderRadius: '10px',
									margin: '0px',
									backgroundColor: 'background.paper'
								}}
							>
								<Grid container direction="row" justifyContent="center">
									<Grid item xs={4} textAlign="center">
										<div>
											<Chip
												label={<Typography variant="h4">{sport.level}</Typography>}
												sx={{ backgroundColor: getLevelColor(sport.level) }}
											/>
										</div>
										<Typography
											display="inline"
											sx={{ fontWeight: '800', fontSize: '27px', lineHeight: '32px' }}
										>
											{practicingSince(sport.practicingSince).value}
										</Typography>
										<Typography
											variant="p"
											sx={{
												pl: '10px'
											}}
										>
											{practicingSince(sport.practicingSince).text}
										</Typography>
									</Grid>

									{/* profile styles */}
									<Grid item xs={8}>
										{sport.sportStyles.map((style, index) => (
											<Stack direction="row" key={`style-${index}`}>
												<Typography
													variant="h4"
													backgroundColor="primary.light"
													padding="0px 5px"
													margin="2px 5px"
													display="inline-block"
												>
													{style.name}
												</Typography>
												<SportStyleRating proficiency={style.proficiency} />
											</Stack>
										))}
									</Grid>
								</Grid>
							</Box>
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', pt: '5px' }}>
								{sport.sportTimeWeekdayDay && (
									<Typography variant="h4" sx={{ background: '#EEEFF4' }}>
										Weekday DayTime
									</Typography>
								)}
								{sport.sportTimeWeekdayEvening && (
									<Typography variant="h4" sx={{ background: '#EEEFF4' }}>
										Weekday evening
									</Typography>
								)}
								{sport.sportTimeWeekend && (
									<Typography variant="h4" sx={{ background: '#EEEFF4' }}>
										Weekend
									</Typography>
								)}
							</Box>
						</>
					)}
				</Box>
			))}
		</Box>
	)
}
export default BuddySportProfile
