import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Stack, Typography, Tabs, Tab, Button } from '@mui/material'
import PlaceOutlined from '@mui/icons-material/PlaceOutlined'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import moment from 'moment'
import UserService from '../../services/UserService'
import LookAroundService from '../../services/LookAroundService'
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
import SportView from '../../components/user/SportView'
import BottomNavigation from '../../components/ui/BottomNavigation'
import { LoadingContext } from '../../store/loading-context'
import Loading from '../../components/ui/Loading'
import LookAroundShimmer from './LookAroundShimmer'
import SportSessionTypes from '../../components/user/SportSessionTypes'
import BrowseProfileLRButtons from '../../components/ui/BrowseProfileLRButtons'
import TutorialService from '../../services/TutorialService'
import Tour from 'reactour'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import NonBetaIcon from '../../assets/icon/non-beta.png'
import ProfileService from '../../services/ProfileService'
import BetaRadar from './BetaRadar'
import SportLocationTiming from '../../components/ui/SportLocationTiming'
import { Add } from '@mui/icons-material'
import radarImg from '../../assets/icon/radar.png'

moment.updateLocale('en', {
	week: {
		dow: 1 // Monday
	}
})

const LookArround = () => {
	const tabsRef = useRef(null)
	const tourRef = useRef(null)
	const currentStep = useRef(0)
	const { user, updateUser } = useAuth()
	const params = useParams()
	const navigate = useNavigate()
	const routeMatch = useMatch('/look-around/:alias/:sportCode')
	const size = 10
	const [sportCode, setSportCode] = useState(routeMatch?.params.sportCode || false)
	const [page, setPage] = useState(0)
	const [userDetails, setUserDetails] = useState(null)
	const [sport, setSport] = useState(null)
	const [sportsProfiles, setSportsProfiles] = useState()
	const [showProfilesIndex, setShowProfilesIndex] = useState(0)
	const [currentSportView, setCurrentSportView] = useState({})
	const [isLoading, setIsLoading] = useContext(LoadingContext)
	const [showTour, setShowTour] = useState(false)
	const [userSportProfile, seTuserSportProfile] = useState({})
	const [showShimmer, setShowShimmer] = useState(false)

	// const fadeLookAround =
	// 	userDetails?.coverImages?.length === 0 &&
	// 	!userSportProfile?.questions?.some(
	// 		que =>
	// 			(que?.answer != null || que?.tags?.length > 0) && que?.sportQuestion?.category === 'Skill'
	// 	)
	const fadeLookAround =
		userDetails?.coverImages?.length === 0 ||
		!userSportProfile?.questions?.some(
			que =>
				(que?.answer != null || que?.tags?.length > 0) && que?.sportQuestion?.category === 'Skill'
		)
	useEffect(() => {
		if (!params.alias) {
			navigate(`/look-around/${user?.alias}`, { replace: true })
		}
	}, [params.alias, user?.alias, navigate])

	useEffect(() => {
		const getData = localStorage.getItem('look-around-data')
		const getLookAroundData = getData && JSON.parse(getData)
		if (params.alias && !userDetails) {
			setIsLoading(true)
			UserService.getUserByAlias(params.alias).then(response => {
				const setBetaSportAtLast = response.data.sports.sort((a, b) => b.released - a.released)
				setUserDetails({ ...response.data, sports: setBetaSportAtLast })
				if (!sportCode && response.data.sports.length > 0) {
					navigate(
						getLookAroundData?.sportCode
							? getLookAroundData?.sportCode
							: params['*'] // if route contain sport code
							? params['*']
							: setBetaSportAtLast[0]?.code
					)

					setSportCode(
						getLookAroundData?.sportCode
							? getLookAroundData?.sportCode
							: params['*']
							? params['*']
							: setBetaSportAtLast[0]?.code
					)
				}
				setIsLoading(false)
			})
		}
	}, [params.alias, userDetails, setUserDetails, sportCode, navigate])

	// useEffect(() => {
	// 	if (!userDetails) {
	// 		UserService.getMe().then(response => {
	// 			setUserDetails(response.data)
	// 			if (!sport && response.data.sports.length > 0) {
	// 				setSport(response.data.sports[0])
	// 			}
	// 		})
	// 	}
	// }, [userDetails, sport])

	useEffect(() => {
		if (sportCode) {
			ProfileService.getUsersProfile(params.alias, sportCode).then(response => {
				setIsLoading(false)
				seTuserSportProfile(response.data)
				setSport(response.data.sport)
			})
		}
	}, [sportCode])

	useEffect(() => {
		const getData = localStorage.getItem('look-around-data')
		const getLookAroundData = getData && JSON.parse(getData)

		const findSportInProfile =
			userDetails?.sports?.length > 0 &&
			getLookAroundData &&
			userDetails?.sports?.some(sport => sport.code === getLookAroundData?.sportCode)

		const newSportCode = findSportInProfile
			? sportCode
			: userDetails?.sports?.length > 0 && userDetails?.sports[0]?.code
		if (sportCode) {
			if (getLookAroundData) {
				getBrowseProfile(
					newSportCode,
					getLookAroundData.page,
					size,
					'next',
					getLookAroundData.index
				)
				setSportCode(newSportCode)
			} else {
				getBrowseProfile(sportCode, page, size, 'next', 0)
			}
		}
	}, [sportCode])

	useEffect(() => {
		if (userSportProfile?.sport?.released && !fadeLookAround) {
			setTimeout(() => {
				// FIXME this will keep showing until the user logs out and in again
				const isViewed = TutorialService.isTutorialViewed(
					TutorialService.TutorialType.LOOK_AROUND,
					user,
					updateUser
				)
				if (!isViewed) {
					setShowTour(true)
				}
			}, 3000)
		}
	}, [user, userSportProfile])

	useEffect(() => {
		if (user?.sports?.length === 0) {
			setIsLoading(false)
			navigate('/p')
		}
	}, [user])

	const getBrowseProfile = (sportCode, page, size, clickType = 'next', msgIndex = 0) => {
		setShowShimmer(true)
		LookAroundService.getBrowseProfile(sportCode, page, size)
			.then(response => {
				setSportsProfiles(response.data)
				setShowShimmer(false)
				if (response.data.content?.length > 0) {
					if (clickType === 'next') {
						setCurrentSportView(
							msgIndex > 0 && response.data.content?.length >= msgIndex
								? response.data.content[msgIndex]
								: response.data.content[0]
						)
						setPage(page)
						setShowProfilesIndex(msgIndex)
					} else {
						setCurrentSportView(response.data.content[response.data.content?.length - 1])
						setShowProfilesIndex(response.data.content?.length - 1)
					}
				} else {
					setCurrentSportView(null)
				}
				setIsLoading(false)
			})
			.catch(e => {
				setShowShimmer(false)
			})
	}

	const nextSport = () => {
		var pageCount = page
		var newIndex = Number(showProfilesIndex) + 1

		if (sportsProfiles.totalPages >= pageCount) {
			if (sportsProfiles?.content?.length <= size) {
				if (sportsProfiles?.content?.length > newIndex) {
					setShowProfilesIndex(newIndex)
					const newIndexSport = sportsProfiles?.content[Number(newIndex)]
					setCurrentSportView(newIndexSport)
				} else {
					pageCount = pageCount + 1
					setPage(pageCount)
					setShowProfilesIndex(0)
					if (sportsProfiles.totalPages > pageCount) {
						getBrowseProfile(sportCode, pageCount, size, 'next')
					} else {
						getBrowseProfile(sportCode, 0, size)
						setPage(0)
					}
				}
			} else {
				getBrowseProfile(sportCode, 0, size)
				setPage(0)
			}
		} else {
			getBrowseProfile(sportCode, 0, size)
			setPage(0)
		}
	}

	const prevSport = () => {
		var pageCount = page
		var newIndex = Number(showProfilesIndex) - 1
		if (newIndex >= 0) {
			setShowProfilesIndex(newIndex)
			const newIndexSport = sportsProfiles.content[newIndex]
			setCurrentSportView(newIndexSport)
		} else if (pageCount > 0) {
			pageCount = pageCount - 1
			setPage(pageCount)
			setShowProfilesIndex(sportsProfiles.content.length - 1)
			getBrowseProfile(sportCode, pageCount, size, 'prev')
		} else {
			getBrowseProfile(sportCode, sportsProfiles.totalPages, size, 'prev')
		}
	}

	const handleSportCodeChange = (event, newValue) => {
		setIsLoading(true)
		setSportCode(newValue)
		setShowProfilesIndex(0)
		setPage(0)
		localStorage.setItem('look-around-data', JSON.stringify({ index: 0, page: 0 }))
	}

	const handleSportProfile = () => {
		navigate(`/browse-profiles/p/${currentSportView.alias}/${sportCode}`, {
			state: { refer: 'look-around' }
		})
	}

	const handleTabClick = () => {
		if (tabsRef.current) {
			const container = document.querySelector('.MuiTabs-scroller')
			if (container) {
				container.scrollTo({
					left: 0,
					behavior: 'smooth'
				})
			}
		}
	}

	const onRequestClose = () => {
		setShowTour(false)
	}

	const handleAfterOpen = () => {
		const container = document.getElementById('lookAround')
		document.body.classList.add('no-overflow')
		container.classList.add('no-overflow')
	}

	const handleBeforeClose = () => {
		const container = document.getElementById('lookAround')
		document.body.classList.remove('no-overflow')
		container.classList.remove('no-overflow')
	}

	const handleAddInfo = e => {
		e.stopPropagation()
		navigate(`/welcome/${sport.code}/${user.alias}`, {
			state: { refer: 'BelayHomePage' }
		})
	}

	return (
		<>
			<Stack
				alignItems="center"
				spacing={6}
				id="lookAround"
				sx={{
					backgroundImage: ``,
					backgroundAttachment: 'fixed',
					minHeight: 'calc(100dvh - 56px)',
					backgroundSize: 'cover',
					padding: '40px 9px 200px 9px'
				}}
			>
				<Box sx={{ maxWidth: '100%', mt: '29px', minWidth: '100%' }}>
					<Box id="update-sport-status">
						<Box>
							{userDetails?.sports?.length > 0 ? (
								<Tabs
									ref={tabsRef}
									value={sportCode}
									onChange={handleSportCodeChange}
									variant="scrollable"
									scrollButtons="auto"
									indicatorColor="transparent"
								>
									{userDetails?.sports
										.sort(a => (a.code === params['*'] ? -1 : 1))
										.map(sport => (
											<Tab
												key={sport.code}
												value={sport.code}
												label={<Typography variant="h4">{sport.name}</Typography>}
												component={Link}
												to={sport.code}
												onClick={handleTabClick}
											/>
										))}

									<Tab
										label={
											<Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
												Other Sports <Add style={{ fontSize: '19px', marginLeft: '4px' }} />
											</Typography>
										}
										component={Link}
										to={`/p/${user?.alias}/createSportProfile`}
									/>
								</Tabs>
							) : (
								<Box mt="30px">
									<Tabs variant="scrollable" scrollButtons="auto" indicatorColor="transparent">
										<Tab
											label={
												<Typography
													sx={{
														width: '55px',
														height: '11px',
														borderRadius: '20px',
														backgroundColor: 'lightgray'
													}}
													className="shimmer-effect"
												>
													{''}
												</Typography>
											}
										/>
										<Tab
											label={
												<Typography
													sx={{
														width: '55px',
														height: '11px',
														borderRadius: '20px',
														backgroundColor: 'lightgray'
													}}
													className="shimmer-effect"
												>
													{''}
												</Typography>
											}
										/>
										<Tab
											label={
												<Typography
													sx={{
														width: '55px',
														height: '11px',
														borderRadius: '20px',
														backgroundColor: 'lightgray'
													}}
													className="shimmer-effect"
												>
													{''}
												</Typography>
											}
										/>
									</Tabs>
								</Box>
							)}
						</Box>
						<Box
							sx={{
								p: '15px 10px 40px 10px',
								background: '#B0F1E1',
								borderRadius: '0 0 30px 30px',
								marginTop: '-1px'
							}}
							onClick={() => {
								!fadeLookAround && handleSportProfile()
								!fadeLookAround &&
									localStorage.setItem(
										'look-around-data',
										JSON.stringify({
											index: showProfilesIndex,
											page: page,
											sportCode: sportCode
										})
									)
							}}
						>
							{!showShimmer && !isLoading ? (
								userSportProfile?.sport?.released ? (
									<Box
										sx={{
											pt: '15px',
											pb: '15px',
											background: 'rgba(255, 255, 255, 0.90)',
											borderRadius: '10px',
											minHeight: !userSportProfile?.sport?.released
												? '440px'
												: fadeLookAround
												? '300px'
												: '125px',
											// minHeight: '300px',
											position: 'relative'
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												px: '12px',
												alignItems: 'center'
											}}
										>
											<Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
												{currentSportView?.name}
											</Typography>
											{currentSportView?.location?.name && (
												<Box
													sx={{
														color: 'gray',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center'
													}}
												>
													<PlaceOutlined sx={{ width: '16px', height: '16px' }} />
													<Typography
														sx={{ marginRight: '30px', fontWeight: '500', fontSize: '13px' }}
													>
														{currentSportView?.location?.name}
													</Typography>
												</Box>
											)}
										</Box>
										{currentSportView?.alias ? (
											<SportView
												alias={currentSportView?.alias}
												sportCode={sportCode}
												minimalView={false}
												showStatus={true}
												showEdit={false}
												showUserImage={true}
												userImageUrl={currentSportView?.userImage?.viewUrl}
												userName={currentSportView?.name}
												profileDetails={currentSportView}
												showPencilIcon={true}
												indexPosition={{
													index: showProfilesIndex,
													page: page,
													sportCode: sportCode
												}}
											/>
										) : (
											<Box>
												{sportsProfiles?.content?.length === 0 ? (
													<Box
														sx={{
															p: '15px 10px 40px 10px',
															background: '#B0F1E1',
															borderRadius: '0 0 30px 30px',
															marginTop: '-25px',
															marginBottom: '-60px'
														}}
													>
														<Box
															sx={{
																p: '21px 32px',
																background: 'rgba(255, 255, 255, 0.90)',
																borderRadius: '0 0 30px 30px',
																position: 'relative'
															}}
														>
															<Box
																sx={{
																	textAlign: 'center',
																	fontSize: '16px',
																	fontWeight: '700',
																	lineHeight: '22px',
																	marginTop: '12px',
																	marginBottom: '23px',
																	marginLeft: '20px',
																	marginRight: '20px'
																}}
															>
																You beat everyone to it!
															</Box>
															<Box
																sx={{
																	textAlign: 'center',
																	marginTop: '25px',
																	marginBottom: '10px'
																}}
															>
																<img src={radarImg} alt={'Radar'} loading="lazy" />
															</Box>
															<Box
																sx={{
																	fontSize: '19px',
																	fontWeight: '700',
																	lineHeight: '22px',
																	color: '#00C6AE',
																	textAlign: 'center'
																}}
															>
																Your Radar Is On
															</Box>
															<Box
																sx={{
																	fontWeight: '500',
																	fontSize: '16px',
																	lineHeight: '22px',
																	marginTop: '15px',
																	padding: '0 20px'
																}}
															>
																You will know when a potential {sport?.name} partner comes by
															</Box>
														</Box>
													</Box>
												) : (
													<LookAroundShimmer />
												)}
											</Box>
										)}
										<Box sx={{ marginTop: '-15px', px: '5px' }}>
											<SportSessionTypes
												biography={
													currentSportView?.sportProfiles?.length > 0 &&
													currentSportView?.sportProfiles[0]?.biography
												}
												sessionTypes={
													currentSportView?.sportProfiles?.length > 0 &&
													currentSportView?.sportProfiles[0]?.sessionTypes
												}
											/>
										</Box>

										{fadeLookAround ? (
											<Box sx={{ borderRadius: '20px' }}>
												<Box
													sx={{
														position: 'absolute',
														top: '0',
														background: 'rgba(255, 255, 255, 0.5)',
														backdropFilter: 'blur(5px)',
														width: '100%',
														height: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														flexDirection: 'column',
														borderRadius: '20px',
														filter: 'blur(0)'
													}}
												>
													<img src={NonBetaIcon} style={{ width: '105px' }} />
													<Button
														onClick={handleAddInfo}
														sx={{
															color: 'white',
															borderRadius: '5px',
															border: '1px solid #00C6AE',
															paddingX: '15px',
															background: 'white',
															'&:hover': {
																background: '#FFF'
															},
															margin: '30px',
															display: 'flex',
															justifyContent: 'center',
															maxWidth: '330px',
															maxWidth: 'fit-content',
															boxShadow:
																'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
														}}
														disableElevation
													>
														<Typography
															variant="h2"
															sx={{
																color: '#00C6AE',
																whiteSpace: 'nowrap',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																width: '80%'
															}}
														>
															Complete My {userSportProfile?.sport?.name} Profile
														</Typography>
													</Button>
												</Box>
											</Box>
										) : (
											''
										)}
									</Box>
								) : (
									<Box
										sx={{
											background: 'rgba(255, 255, 255, 0.90)',
											borderRadius: '10px',
											minHeight: !userSportProfile?.sport?.released
												? '385px'
												: fadeLookAround
												? '300px'
												: '125px',
											// minHeight: '300px',
											position: 'relative',
											marginBottom: '-25px'
										}}
									>
										<Box
											sx={{
												position: 'absolute',
												top: '0',
												background: 'white',
												width: '100%',
												height: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												flexDirection: 'column',
												borderRadius: '50px'
											}}
										>
											<BetaRadar sport={userSportProfile?.sport} />
										</Box>
									</Box>
								)
							) : (
								<LookAroundShimmer />
							)}

							{userSportProfile?.sport?.released && currentSportView?.sportProfiles?.length > 0 && (
								<SportLocationTiming sportProfile={currentSportView?.sportProfiles[0]} />
							)}
						</Box>
					</Box>
				</Box>

				<BrowseProfileLRButtons
					page={page}
					showProfilesIndex={showProfilesIndex}
					prevSport={prevSport}
					sportsProfiles={sportsProfiles}
					nextSport={nextSport}
					sportCode={sportCode}
					currentSportView={currentSportView}
					disableButtons={!userSportProfile?.sport?.released ? true : fadeLookAround}
				/>

				{/*  Profile Navigation */}
				<BottomNavigation lookArround={true} addStatus={false} message={false} />

				<Tour
					ref={tourRef}
					onRequestClose={onRequestClose}
					showButtons={false}
					showNavigationNumber={false}
					showCloseButton={false}
					getCurrentStep={cs => (currentStep.current = cs)}
					showNumber={false}
					closeWithMask={true}
					showNavigation={false}
					steps={steps}
					isOpen={showTour}
					badgeContent={null}
					maskSpace={1}
					rounded={10}
					onAfterOpen={handleAfterOpen}
					onBeforeClose={handleBeforeClose}
					disableInteraction
				/>
			</Stack>
			{isLoading && <Loading />}
		</>
	)
}
export default LookArround

const steps = [
	{
		position: 'top',
		selector: '#profile-navigation',
		style: {
			minWidth: `320px`,
			maxWidth: '320px',
			padding: 0
		},
		content: (
			<Box sx={{ position: 'relative' }}>
				<Box
					sx={{
						pt: '15px',
						borderRadius: '0 0 30px 30px',
						position: 'absolute',
						bottom: '0',
						right: '-20px',
						left: '35px'
					}}
				>
					<Box
						sx={{
							mx: '15px',
							background: '#61E4C5',
							px: '12px',
							py: '22px',
							borderRadius: '20px'
						}}
					>
						<Typography as="span" className="fw-500">
							See who’s also looking to form an action squad! <br />
							Use
							<KeyboardArrowRightOutlinedIcon style={{ marginBottom: '-6px' }} />
							to see the next person, and <b>Message </b>
							to plan a session!
						</Typography>
					</Box>
					<Box className="anchor-bottom">
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								background: '#61E4C5',
								height: '100%',
								marginTop: -0.5
							}}
						></Box>
					</Box>
				</Box>
			</Box>
		)
	}
]
