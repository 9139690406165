import baseApi from './baseApiService'
import { CHALLENGE_URL } from '../constants/ApiUrls'

class CommentService {
	getComments(alias) {
		return baseApi.get(CHALLENGE_URL + `/alias/${alias}/comment`)
	}

	createComment(alias, request) {
		return baseApi.post(CHALLENGE_URL + `/alias/${alias}/comment`, request)
	}
}

export default new CommentService()
