import { Box, Typography } from '@mui/material'
import { ReactComponent as TargetImg } from '../../..//assets/challenge/target.svg'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import DateUtils from '../../../shared/DateUtils'
import { useNavigate } from 'react-router-dom'

const ChallengeItem = ({ challenge, overrideRouteToDetails = false }) => {
	const { alias, status, name, description, startDate, endDate, stake, winners } = challenge

	const minBoxHeight = '160px'
	// const isInPast = DateUtils.isInThePast(challenge.endDate);
	const navigate = useNavigate()

	const handleChallengeClick = () => {
		if (winners.length > 0 && !overrideRouteToDetails) {
			navigate(`/c/${alias}/result`)
		} else {
			navigate(`/c/${alias}`)
		}
	}

	return (
		<Box
			sx={{
				border: '3px solid #474A57',
				borderRadius: '16px',
				padding: '5px',
				background: 'white'
			}}
			minHeight={minBoxHeight}
		>
			<Box
				sx={{
					background: '#F2F2F2',
					borderRadius: '10px',
					opacity: status === 'ENDED' ? 0.5 : 1
				}}
				minHeight={minBoxHeight}
				onClick={handleChallengeClick}
			>
				<Box sx={{ display: 'flex' }}>
					{/* Target Icon */}
					<Box paddingTop={1} paddingLeft={1}>
						<TargetImg width="48px" height="48px" />
					</Box>

					{/* Challenge Info */}
					<Box
						sx={{
							display: 'flex',
							flex: 1,
							flexDirection: 'column',
							alignItems: 'flex-start',
							padding: 1
						}}
					>
						<Typography variant="h1" component="span" fontWeight={700}>
							{name}
						</Typography>
						<Typography sx={{ flex: 1, textAlign: 'left' }}>
							{description?.length > 60 ? description.substr(0, 60) + '...' : description}
						</Typography>
						<Box sx={{ display: 'flex' }}>
							<CalendarTodayOutlinedIcon sx={{ marginRight: 1 }} />
							<Typography>
								{DateUtils.displayMonthDay(startDate)} - {DateUtils.displayMonthDay(endDate)}
							</Typography>
						</Box>
					</Box>

					{/* Stake */}
					<Box
						sx={{
							width: '100px',
							display: '-webkit-flex' /* NEW */,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							padding: 1,
							gap: 1,
							background: '#FFD465',
							borderRadius: '0px 10px 10px 0px',
							minHeight: minBoxHeight
						}}
					>
						<EmojiEventsOutlinedIcon />
						<img src={stake.avatar} alt={stake.name} style={{ width: '100px', height: '80px' }} />
						<Typography textAlign="center">{stake.name}</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ChallengeItem
