import baseApi from './baseApiService'
import { CHAT_URL } from '../constants/ApiUrls'
class ChatService {
	getAllChannels() {
		return baseApi.get(`${CHAT_URL}/channel/list/`)
	}

	getMessagesForChannel(request, channelName, page) {
		return baseApi.get(`${CHAT_URL}/message/list/${channelName}/?page=${page}`, request)
	}

	sendMessage(request) {
		return baseApi.post(`${CHAT_URL}/message/send`, request)
	}

	reactToMessage(request) {
		return baseApi.post(CHAT_URL, request)
	}

	createChannel(request) {
		return baseApi.post(`${CHAT_URL}/channel/create`, request)
	}

	countUnreadMessages() {
		return baseApi.get(`${CHAT_URL}/unread-count`)
	}

	async markMessagesRead(channelName) {
		return baseApi.put(`${CHAT_URL}/mark-read/${channelName}`)
	}
}

const chatServiceInstance = new ChatService()
export default chatServiceInstance
