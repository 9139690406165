import Slider from '@mui/material/Slider'
import { useField } from 'formik'

const marks = [
	{
		value: 0,
		label: 'Beginner'
	},
	{
		value: 33,
		label: 'Intermediate'
	},
	{
		value: 66,
		label: 'Advanced'
	},
	{
		value: 100,
		label: 'Expert'
	}
]

const SportLevel = props => {
	const [field, , helper] = useField('profile.level')

	const mapValueToLabel = (e, val) => {
		if (mapLabelToValue() === val) return
		helper.setValue(marks.find(m => m.value === val).label)
	}

	const mapLabelToValue = () => {
		return marks.find(m => m.label === field.value).value
	}

	return (
		<div style={{ marginLeft: '2em', marginRight: '2em' }}>
			<Slider
				aria-label="Custom marks"
				defaultValue={0}
				// getAriaValueText={value}
				step={null}
				// valueLabelDisplay="auto"
				marks={marks}
				{...field}
				onChange={mapValueToLabel}
				value={mapLabelToValue()}
			/>
		</div>
	)
}

export default SportLevel
