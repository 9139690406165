import React from 'react'

const EyeIcon = props => {
	const { sx } = props
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="28" height="19" viewBox="0 0 28 19" fill="none">
			<path
				d="M7 0C3.13443 0 0 4.05597 0 9.05882C0 14.0617 3.13383 18.1176 7 18.1176C10.866 18.1176 14 14.0617 14 9.05882C14 4.05597 10.8662 0 7 0ZM7 16.2864C4.23592 16.2864 1.90123 12.9764 1.90123 9.05825C1.90123 5.14008 4.2364 1.8301 7 1.8301C8.96661 1.8301 10.7155 3.50694 11.5611 5.88561C11.1294 5.70813 10.6546 5.60925 10.1554 5.60925C8.17734 5.60925 6.57396 7.15365 6.57396 9.05842C6.57396 10.9632 8.17758 12.5076 10.1554 12.5076C10.6547 12.5076 11.1297 12.4087 11.5611 12.2312C10.7155 14.609 8.96709 16.2858 7 16.2858V16.2864Z"
				fill={sx.color ? sx.color : '#9FA4B4'}
			/>
			<path
				d="M21 0C17.134 0 14 4.05597 14 9.05882C14 14.0617 17.1338 18.1176 21 18.1176C24.8656 18.1176 28 14.0619 28 9.05882C28 4.05597 24.8657 0 21 0ZM21 16.2864C18.2359 16.2864 15.9012 12.9764 15.9012 9.05825C15.9012 5.14008 18.2364 1.8301 21 1.8301C22.943 1.8301 24.6729 3.4671 25.5298 5.80005C25.1638 5.67842 24.772 5.60928 24.363 5.60928C22.385 5.60928 20.7816 7.15368 20.7816 9.05844C20.7816 10.9632 22.3852 12.5076 24.363 12.5076C24.772 12.5076 25.1635 12.4382 25.5298 12.3168C24.6729 14.6491 22.943 16.2859 21 16.2859V16.2864Z"
				fill={sx.color ? sx.color : '#9FA4B4'}
			/>
		</svg>
	)
}

export default EyeIcon
