import { Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ChallengeService from '../../../services/ChallengeService'
import { useAuth } from '../../../store/auth-context'
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent
} from '@mui/lab'
import ChallengeItem from './ChallengeItem'
import DateUtils from '../../../shared/DateUtils'
import LoadingPage from '../../LoadingPage'

const ChallengesListPage = () => {
	const { user } = useAuth()

	const [challenges, setChallenges] = useState()

	useEffect(() => {
		ChallengeService.getChallenges().then(response => {
			setChallenges(response.data)
		})
	}, [])

	const [challengesWon, setChallengesWon] = useState(0)
	const [challengesFinished, setChallengesFinished] = useState(0)

	useEffect(() => {
		if (challenges && user) {
			setChallengesWon(
				challenges.filter(
					val =>
						val.status === 'ENDED' &&
						val.winners.filter(val2 => val2.alias === user.alias).length > 0
				).length
			)
			setChallengesFinished(
				challenges.filter(
					val =>
						val.status === 'ENDED' &&
						val.participants.filter(val2 => val2.alias === user.alias).length > 0
				).length
			)
		}
	}, [challenges])

	return challenges ? (
		<React.Fragment>
			<Stack alignItems="center">
				<Typography variant="h1" fontWeight={700}>
					MY CHALLENGES
				</Typography>

				<Stack direction="row" spacing={4}>
					<Stack direction="row" alignItems={'center'} spacing={1}>
						<Typography variant="h6">Won:</Typography>
						<Typography variant="h6" color="primary.main" fontSize="30px">
							{challengesWon}
						</Typography>
					</Stack>
					<Stack direction="row" alignItems={'center'} spacing={1}>
						<Typography variant="h6">Finished:</Typography>
						<Typography variant="h6" color="primary.main" fontSize="30px">
							{challengesFinished}
						</Typography>
					</Stack>
				</Stack>

				<Timeline
					align="left"
					sx={{
						'& .MuiTimelineItem-root:before': {
							flex: 0,
							content: 'none'
						},
						'& .MuiTimelineContent-root': {
							padding: 0,
							paddingBottom: '10px'
						},
						width: '100%'
					}}
				>
					{challenges
						.sort((a, b) => {
							var aEndDate = new Date(a.endDate)
							if (DateUtils.isInThePast(aEndDate)) {
								aEndDate.setFullYear(aEndDate.getFullYear() + 1)
							}
							var bEndDate = new Date(b.endDate)
							if (DateUtils.isInThePast(bEndDate)) {
								bEndDate.setFullYear(bEndDate.getFullYear() + 1)
							}
							return new Date(aEndDate).getTime() - new Date(bEndDate).getTime()
						})
						.map(c => (
							<TimelineItem key={c.alias}>
								<TimelineSeparator>
									<TimelineConnector
										align="left"
										sx={{ bgcolor: '#00C6AE', border: '5px solid #00C6AE' }}
									/>
								</TimelineSeparator>
								<TimelineContent>
									<ChallengeItem challenge={c} />
								</TimelineContent>
							</TimelineItem>
						))}
				</Timeline>
				<Button variant="blGreen" component={Link} to="/challenge/create">
					Create Challenge
				</Button>
			</Stack>
		</React.Fragment>
	) : (
		<LoadingPage />
	)
}

export default ChallengesListPage
