export function useDeviceInfo() {
	let userAgent = navigator.userAgent

	let browserMatch = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)

	let browserName = browserMatch && browserMatch[1] ? browserMatch[1] : 'Unknown'

	let deviceType = /Mobile|tablet|iPad|iPhone|Android|Windows Phone/i.test(userAgent)
		? 'Mobile'
		: 'Desktop'
	return {
		browserName: browserName,
		deviceType: deviceType
	}
}
