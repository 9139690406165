import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'

const TagItem = ({ children }) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const truncatedText = children?.length > 20 ? children?.substring(0, 20) + '...' : children

	const expandText = e => {
		e.stopPropagation()
		setIsExpanded(!isExpanded)
	}

	return (
		<Typography
			onClick={expandText}
			sx={{
				backgroundColor: '#EEEFF4',
				textTransform: 'capitalize',
				padding: '0 5px',
				textAlign: 'center',
				marginBottom: '8px',
				marginRight: '10px'
			}}
			variant="pb"
		>
			{isExpanded ? children : truncatedText}
		</Typography>
	)
}

const SportLocationTiming = ({ sportProfile }) => {
	return (
		<Box sx={{ mt: '15px' }}>
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'start' }}>
				{(sportProfile?.sportTimeWeekdayDay ||
					sportProfile?.sportTimeWeekdayEvening ||
					sportProfile?.sportTimeWeekend) && <CalendarMonthOutlinedIcon />}
				<Box sx={{ display: 'flex', gap: '7px', flexWrap: 'wrap' }}>
					{sportProfile?.sportTimeWeekdayDay && (
						<Typography variant="pb" sx={{ bgcolor: '#EEEFF4', px: '5px' }}>
							Weekday daytime
						</Typography>
					)}
					{sportProfile?.sportTimeWeekdayEvening && (
						<Typography variant="pb" sx={{ bgcolor: '#EEEFF4', px: '5px' }}>
							Weekday evening
						</Typography>
					)}
					{sportProfile?.sportTimeWeekend && (
						<Typography variant="pb" sx={{ bgcolor: '#EEEFF4', px: '5px' }}>
							Weekends
						</Typography>
					)}
				</Box>
			</Box>
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'start', mt: '10px' }}>
				{(sportProfile?.playground?.length > 0 || sportProfile?.openToPlaces) && (
					<FmdGoodOutlinedIcon />
				)}
				<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
					{sportProfile?.playground?.length > 0 &&
						[...new Set(sportProfile.playground.map(item => item.placeId))].map(
							(placeId, index) => {
								const uniqueLocation = sportProfile.playground.find(
									item => item.placeId === placeId
								)
								return <TagItem key={index}>{uniqueLocation.name}</TagItem>
							}
						)}
					{sportProfile?.openToPlaces && (
						<Typography
							variant="pb"
							sx={{
								bgcolor: '#EEEFF4',
								px: '5px',
								marginRight: '10px',
								whiteSpace: 'nowrap',
								mb: '8px'
							}}
						>
							Flexible
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default SportLocationTiming
