import SportCardInput from "../../ui/SportCardInput";

const SportSkill = (props) => {
  const { arrayHelpers, index, skill } = props;

  return (
    <SportCardInput
      arrayHelpers={arrayHelpers}
      item={skill}
      name="profile.sportSkills"
      index={index}
    />
  );
};

export default SportSkill;
