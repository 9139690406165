import baseApi from './baseApiService'
import { CHALLENGE_URL } from '../constants/ApiUrls'

class ChallengeService {
  getStakes() {
    return baseApi.get(CHALLENGE_URL + '/stakes')
  }

  createChallenge(request) {
    return baseApi.post(CHALLENGE_URL, request)
  }

  getChallengeById(id) {
    return baseApi.get(`${CHALLENGE_URL}/${id}`)
  }

  getChallengeByAlias(alias) {
    return baseApi.get(CHALLENGE_URL + `/alias/${alias}`)
  }

  Participation = {
    JOIN: 'JOIN',
    SURRENDER: 'SURRENDER'
  }

  Action = {
    END: 'END'
  }

  updateParticipation(alias, participation) {
    return baseApi.post(CHALLENGE_URL + '/participation', {
      alias: alias,
      participation: participation
    })
  }

  getChallenges() {
    return baseApi.get(CHALLENGE_URL)
  }

  updateChallangeAction(alias, action) {
    return baseApi.post(CHALLENGE_URL + `/alias/${alias}/action`, {
      action: action
    })
  }

  updateChallengeWinners(alias, request) {
    return baseApi.post(CHALLENGE_URL + `/alias/${alias}/winners`, request);
  }
}

export default new ChallengeService()
