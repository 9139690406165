import moment from 'moment'

class DateUtils {
	static getAge(dateString) {
		var today = new Date()
		var birthDate = new Date(dateString)
		var age = today.getFullYear() - birthDate.getFullYear()
		var m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}
		return age
	}

	static yearsSince(dateString) {
		const now = new Date()
		const date = new Date(dateString)
		const yearDifference = now.getFullYear() - date.getFullYear()
		const monthDifference = now.getMonth() - date.getMonth()
		const totalDifference =
			yearDifference +
			(monthDifference < 0 || (monthDifference === 0 && now.getDate() < date.getDate()) ? -1 : 0)
		return totalDifference
	}

	static monthsSince(dateString) {
		const now = new Date()
		const date = new Date(dateString)
		const yearDifference = now.getFullYear() - date.getFullYear()
		const monthDifference = now.getMonth() - date.getMonth()
		const totalDifference =
			yearDifference * 12 + monthDifference + (now.getDate() < date.getDate() ? -1 : 0)
		return totalDifference
	}

	/**
	 * @param {Date} date
	 * @return {string}
	 */
	static displayDate(date) {
		if (!date) return ''
		return date.toLocaleDateString()
	}

	/**
	 * @param {Date} date
	 * @return {string}
	 */
	static displayTime(date) {
		if (!date) return ''
		return date.toLocaleTimeString()
	}

	/**
	 * @param {Date} date
	 * @return {string}
	 */
	static displayDateTime(date) {
		if (!date) return ''
		return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
	}

	static isInThePast(dateString) {
		const today = new Date()
		const compare = new Date(dateString)

		// 👇️ OPTIONAL!
		// This line sets the hour of the current date to midnight
		// so the comparison only returns `true` if the passed in date
		// is at least yesterday
		// today.setHours(0, 0, 0, 0)

		return compare < today
	}

	static isInThePastOrToday(dateString) {
		const today = new Date()
		const compare = new Date(dateString)

		// 👇️ OPTIONAL!
		// This line sets the hour of the current date to midnight
		// so the comparison only returns `true` if the passed in date
		// is at least yesterday
		today.setHours(0, 0, 0, 0)
		compare.setHours(0, 0, 0, 0)

		return compare <= today
	}

	static displayMonthDay(dateString) {
		const date = new Date(dateString)
		var options = { month: 'short', day: '2-digit' }
		return date.toLocaleDateString(undefined, options)
	}

	static displayDateOnly(dateString) {
		const date = new Date(dateString)
		var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
		return date.toLocaleDateString(undefined, options)
	}

	static displayHourMinute(dateString) {
		const date = new Date(dateString)
		var options = { hour: '2-digit' }
		return date.toLocaleDateString(undefined, options)
	}

	static displayWeekDayMonthDay(dateString) {
		const date = new Date(dateString)
		var options = { weekday: 'short', month: 'short', day: '2-digit' }
		return date.toLocaleDateString(undefined, options)
	}

	static addDaysFromToday(days) {
		return moment(new Date()).add(days, 'days')
	}

	static getLabelForDateRange(startDate, endDate) {
		const today = moment().startOf('day')
		const tomorrow = today.clone().add(1, 'days')
		const startOfWeek = moment().startOf('isoWeek') // Monday
		const friday = startOfWeek.clone().add(4, 'days') // Friday
		const saturday = startOfWeek.clone().add(5, 'days') // Saturday
		const sunday = startOfWeek.clone().add(6, 'days') // Sunday

		startDate = moment(startDate).startOf('day')
		endDate = moment(endDate).startOf('day')

		// Check for Today and Tomorrow
		if (startDate.isSame(today)) {
			return endDate.isSame(today) ? 'Today' : 'This Weekend'
		} else if (startDate.isSame(tomorrow)) {
			return endDate.isSame(tomorrow) ? 'Tomorrow' : 'This Weekend'
		}

		// Adjust "This Week" to next week if today is Saturday or Sunday
		if (today.isSameOrAfter(saturday)) {
			startOfWeek.add(1, 'week')
			friday.add(1, 'week')
		}

		// Check for This Week and This Weekend
		if (startDate.isSame(startOfWeek) && endDate.isSame(friday)) {
			return 'This Week'
		} else if (
			(startDate.isSame(saturday) && endDate.isSame(sunday)) ||
			(startDate.isSame(saturday) && endDate.isSameOrBefore(sunday))
		) {
			return 'This Weekend'
		}

		// Default Date Range
		return startDate.isSame(endDate)
			? startDate.format('D MMM')
			: `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`
	}

	static showDateOfLastMessage(timestamp) {
		const now = new Date()
		const isoNow = moment(now.toISOString()).startOf('day')
		const messageTime = moment(`${timestamp}Z`).startOf('day')
		const diffInDays = isoNow.diff(messageTime, 'days')

		if (diffInDays === 0) {
			return moment(`${timestamp}Z`).format('LT')
		} else if (diffInDays === 1) {
			return 'Yesterday'
		} else if (diffInDays > 1 && diffInDays < 7) {
			return moment(`${timestamp}Z`).format('dddd')
		} else {
			return new Date(messageTime).toLocaleDateString()
		}
	}
}

export default DateUtils
