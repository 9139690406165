import React from 'react'

const LookAroundShimmer = () => {
	return (
		<div>
			<div
				style={{
					width: '100%',
					height: '130px',
					backgroundColor: 'white',
					borderRadius: '8px'
				}}
			>
				<div className="d-flex jcsb" style={{ padding: '10px 12px' }}>
					<div className="shimmer-effect" style={{ width: '85px', height: '15px' }}></div>
					<div
						className="shimmer-effect"
						style={{ width: '85px', height: '15px', marginRight: '30px' }}
					></div>
				</div>
				<div className="d-flex">
					<div
						style={{
							width: '50px',
							height: '50px',
							borderRadius: '50%',
							backgroundColor: 'lightgray',
							margin: '20px'
						}}
						className="shimmer-effect"
					></div>
					<div>
						<div
							style={{
								width: '110px',
								height: '22px',
								borderRadius: '20px',
								backgroundColor: 'lightgray',
								marginTop: '20px'
							}}
							className="shimmer-effect"
						></div>
						<div
							style={{
								width: '80px',
								height: '25px',
								borderRadius: '5px',
								backgroundColor: 'lightgray',
								marginTop: '10px'
							}}
							className="shimmer-effect"
						></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LookAroundShimmer
