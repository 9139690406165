import React, { useEffect, useState } from 'react'
import { Divider, Stack, Switch, Typography } from '@mui/material'
import MyButton from '../../components/ui/MyButton'
import notificationServiceInstance from '../../services/NotificationService'
import useNotification from '../../hooks/useNotification'
import { updateNotificationSetting } from './updateNotificationSetting'

const NotificationPreferences = () => {
	// Assuming you will have some logic to set these values from your backend.
	const [pushNotification, setPushNotification] = useState(false)
	const [emailNotification, setEmailNotification] = useState(false)
	const [friendsActivities, setFriendsActivities] = useState(false)
	const [newFeatures, setNewFeatures] = useState(false)
	const [, sendNotification] = useNotification()

	useEffect(() => {
		const fetchPreferences = async () => {
			const response = await notificationServiceInstance.fetchPreferences()
			const { methods, types } = response

			methods.forEach(method => {
				if (method.name === notificationServiceInstance.PREFERENCE_METHODS.EMAIL) {
					setEmailNotification(method.isActive)
				} else if (method.name === notificationServiceInstance.PREFERENCE_METHODS.PUSH) {
					setPushNotification(method.isActive)
				}
			})

			types.forEach(type => {
				if (type.name === notificationServiceInstance.PREFERENCE_TYPES.FRIENDS_ACTIVITIES) {
					setFriendsActivities(type.isActive)
				} else if (
					type.name === notificationServiceInstance.PREFERENCE_TYPES.NEW_FEATURES_PROMOTIONS_EVENTS
				) {
					setNewFeatures(type.isActive)
				}
			})
		}

		fetchPreferences()
	}, [])

	return (
		<Stack spacing={3} margin={4}>
			<Typography variant="h2">Notification Settings</Typography>
			<Typography variant="h3">
				Get notified for potential sports buddies, and don’t miss out on friends’ activities!
			</Typography>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="p">Push Notification</Typography>
				<Switch checked={pushNotification} onChange={e => setPushNotification(e.target.checked)} />
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="p">Email Notification</Typography>
				<Switch
					checked={emailNotification}
					onChange={e => setEmailNotification(e.target.checked)}
				/>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="p">Only notify me about my friends’ activities</Typography>
				<Switch
					checked={friendsActivities}
					onChange={e => setFriendsActivities(e.target.checked)}
				/>
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="p">Let me know about new features, promotions, and events.</Typography>
				<Switch checked={newFeatures} onChange={e => setNewFeatures(e.target.checked)} />
			</Stack>
			<Typography variant="p" color="secondary">
				***For iPhone users, we are having issues with Push Notifications currently. Please use
				Email Notifications while we are working on it.
			</Typography>
			<MyButton
				onClick={() =>
					updateNotificationSetting(
						emailNotification,
						pushNotification,
						friendsActivities,
						newFeatures,
						sendNotification
					)
				}
			>
				Save
			</MyButton>
		</Stack>
	)
}

export default NotificationPreferences
