import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PlaceService from "../../../services/PlaceService";
import { useField } from "formik";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Playground = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [fieldPlayground, , fieldHelper] = useField("profile.playground");

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        PlaceService.getPlaceList("RCL").then((response) => {
          setOptions(response.data);
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, options]);

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        id="tags-standard"
        {...fieldPlayground}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        loading={loading}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        getOptionLabel={(option) =>  option.name}
        onChange={(e, values) => {
          fieldHelper.setValue(values);
        }}
        
        // defaultValue={[top100Films[13]]}
        // renderTags={(tagValue, getTagProps) =>
        //   tagValue.map((option, index) => (
        //     <Chip
        //       label={option.name}
        //       {...getTagProps({ index })}
        //     />
        //   ))
        // }
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Places"
            placeholder="Search your gym/spots ..."
          />
        )}
      />
    </React.Fragment>
  );
};

export default Playground;
