import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useAuth } from '../store/auth-context'
import useNotification from '../hooks/useNotification'
import UserService from '../services/UserService'
import { useNavigate } from 'react-router-dom'

const validationSchema = yup.object({
	email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
	password: yup
		.string('Enter your password')
		.min(8, 'Password should be of minimum 8 characters length')
		.required('Password is required')
})

const LoginPage = () => {
	const [, sendNotification] = useNotification()
	const navigate = useNavigate()

	async function loginUser(credentials) {
		return UserService.login(credentials)
			.then(response => {
				// if (response.status === 200) {
				sendNotification({ msg: 'Logged In!', variant: 'success' })
				return response.data
				// } else {
				//   return null;
				// }
			})
			.catch(error => {
				sendNotification({
					msg: error.response.data.message,
					variant: 'error'
				})
			})
	}

	const { login } = useAuth()

	const submitHandler = async values => {
		const userData = await loginUser({
			email: values.email,
			password: values.password
		})
		if (userData) {
			login(userData)
			navigate(`/p/${userData.user.alias}`, { replace: true })
		}
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema: validationSchema,
		onSubmit: submitHandler
	})

	return (
		<React.Fragment>
			<Typography variant="h5" align="center" marginBottom={4}>
				Login
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={4}>
					<TextField
						fullWidth
						id="email"
						name="email"
						placeholder="Email"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>
					<TextField
						fullWidth
						id="password"
						name="password"
						placeholder="Password"
						type="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={formik.touched.password && formik.errors.password}
					/>
					<Button color="primary" variant="contained" fullWidth type="submit">
						Submit
					</Button>
				</Stack>
			</form>
		</React.Fragment>
	)
}

export default LoginPage
