import { Grid, Typography, FormControlLabel } from "@mui/material";
import React from "react";
import SportStyles from "./step2/SportStyles";
import CheckBox from "@mui/material/Checkbox";
import { useField } from "formik";

const Step2 = (props) => {
  const [fieldIndoor] = useField("profile.placesIndoor");
  const [fieldOutdoor] = useField("profile.placesOutdoor");

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center">Style</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <SportStyles styles={props.sport.sportStyles} />
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <Typography align="center">Places</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <FormControlLabel
            control={<CheckBox color="secondary" />}
            label="Indoor"
            {...fieldIndoor}
          />
          <FormControlLabel
            control={<CheckBox color="secondary" />}
            label="Outdoor"
            {...fieldOutdoor}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Step2;
