import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'
import { useRef } from 'react'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

function loadScript(src, position, id) {
	if (!position) {
		return
	}

	const script = document.createElement('script')
	script.setAttribute('async', '')
	script.setAttribute('id', id)
	script.src = src
	position.appendChild(script)
}

const autocompleteService = { current: null }

const GoogleMaps = ({
	onSelect,
	isClearValueOnClick = true,
	label = 'Find your gyms or places',
	initialValue = null,
	fieldPlaces,
	filterSelectedLocation
}) => {
	const [value, setValue] = React.useState(initialValue ? initialValue.name : null)
	const [inputValue, setInputValue] = React.useState('')
	const [options, setOptions] = React.useState([])
	const loaded = React.useRef(false)
	const autoCompleteRef = useRef(null)

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
				document.querySelector('head'),
				'google-maps'
			)
		}

		loaded.current = true
	}

	const fetch = React.useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback)
			}, 400),
		[]
	)

	React.useEffect(() => {
		if (isClearValueOnClick) {
			setValue(null)
		}
	}, [options])

	React.useEffect(() => {
		let active = true

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue === '') {
			setOptions(value ? [value] : [])
			return undefined
		}

		fetch(
			{
				input: inputValue
			},
			results => {
				if (active) {
					let newOptions = []

					if (value) {
						newOptions = [value]
					}

					if (results) {
						newOptions = [...newOptions, ...results]
					}

					if (
						newOptions?.length > 0 &&
						filterSelectedLocation &&
						fieldPlaces &&
						fieldPlaces?.value?.length > 0
					) {
						const filteredArray = newOptions.filter(
							place =>
								!fieldPlaces?.value.some(selectedPlace => selectedPlace.placeId === place.place_id)
						)
						setOptions(filteredArray)
					} else {
						setOptions(newOptions)
					}
				}
			}
		)

		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	return (
		<Autocomplete
			id="google-maps"
			ref={autoCompleteRef}
			sx={{ width: 300 }}
			getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
			filterOptions={x => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			noOptionsText="Type to search"
			onChange={(event, newValue) => {
				// setOptions(newValue ? [newValue, ...options] : options)
				onSelect(newValue.place_id, newValue.structured_formatting.main_text)
				// TODO see how to resolve warning on
				if (isClearValueOnClick) {
					setValue('')
				} else {
					setValue(newValue.structured_formatting.main_text)
				}
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue)
			}}
			renderInput={params => <TextField {...params} label={label} fullWidth />}
			renderOption={(props, option) => {
				const matches = option.structured_formatting.main_text_matched_substrings || []

				const parts = parse(
					option.structured_formatting.main_text,
					matches.map(match => [match.offset, match.offset + match.length])
				)

				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item sx={{ display: 'flex', width: 44 }}>
								<LocationOnIcon sx={{ color: 'text.secondary' }} />
							</Grid>
							<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								{parts.map((part, index) => (
									<Box
										key={index}
										component="span"
										sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
									>
										{part.text}
									</Box>
								))}

								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				)
			}}
		/>
	)
}

export default GoogleMaps
