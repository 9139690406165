import { Box, Button, IconButton, Typography } from '@mui/material'
import React from 'react'
import treeImg from '../../assets/icon/tree.png'
import groupChat from '../../assets/icon/groupChat.png'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useNavigate } from 'react-router-dom'

const NoGroupChatFound = () => {
	const navigate = useNavigate()

	return (
		<Box sx={{ textAlign: 'center', height: '100%', width: '100%', position: 'relative' }}>
			<Box
				sx={{
					px: '23px',
					pb: '10px',
					display: 'flex',
					alignItems: 'center',
					background: 'white',
					boxShadow: '0 4px 4px -2px rgba(0, 0, 0, 0.2)'
				}}
			>
				<Box sx={{ width: '100%', display: 'flex', gap: '6px', alignItems: 'center' }}>
					<IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
						<ChevronLeftIcon />
					</IconButton>
					<Typography className="fw-700 f-18" sx={{ margin: 'auto' }}>
						Chats
					</Typography>
				</Box>
			</Box>
			<Box sx={{ ml: '-90px' }}>
				<Box sx={{ mt: '100px' }}>
					<img src={treeImg} />
				</Box>
				<Box>
					<img
						src={groupChat}
						style={{ marginTop: '-45px', marginLeft: '150px', width: '105px' }}
					/>
				</Box>
			</Box>
			<Box sx={{ fontWeight: '500', fontSize: '16px', marginTop: '20px' }}>
				You haven’t talked to anyone yet.
				<br /> Wilson the volleyball is here for you
				<br /> for the time being.
			</Box>
		</Box>
	)
}

export default NoGroupChatFound
