import { Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import useNotification from '../../hooks/useNotification'
import ICalendarLink from 'react-icalendar-link'

const ChallengeSendInvitePage = () => {
	const location = useLocation()
	const challenge = location.state
	// const navigate = useNavigate()
	const { alias } = useParams()
	const [, sendNotification] = useNotification()

	// useEffect(() => {
	if (alias && !challenge) {
		// navigate(`/c/${alias}`, { replace: true })
		return <Navigate to={`/c/${alias}`} replace />
	}
	// }, [challenge, alias])

	const challengeUrl = `https://belaylink.com/c/${challenge.alias}`
	const shareDetails = {
		url: challengeUrl,
		title: `${challenge.organiser.name}'s Challenge`,
		text: `Come join the ${challenge.name}`
	}

	const calendarEvent = {
		title: `${challenge.name}`,
		description: `Your challenge "${challenge.name}"`,
		startTime: challenge.startDate,
		endTime: challenge.endDate,
		location: challengeUrl
	}

	// FIXME duplicated code from share profile
	const handleShareButton = () => {
		// Check if navigator.share is supported by the browser
		if (navigator.share) {
			// console.log("Congrats! Your browser supports Web Share API");
			navigator
				.share(shareDetails)
				.then(() => {
					console.log('Sharing successfull')
				})
				.catch(() => {
					console.log('Sharing failed')
				})
		} else {
			console.log('Sorry! Your browser does not support Web Share API')
		}
	}

	return (
		<React.Fragment>
			<Grid container alignItems="center" direction={'row'} rowSpacing={4}>
				<Grid item xs={12}>
					<Typography variant="h1">Send the challenge to your friends!</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h1" fontWeight={700}>
						{challenge.organiser.name}'s
						<br />
						{challenge.name}
					</Typography>
				</Grid>

				<Grid item xs={8}>
					<TextField contentEditable={false} fullWidth value={challengeUrl} />
				</Grid>
				<Grid item xs={4}>
					<Button
						variant="blGreen"
						fullWidth
						onClick={() => {
							navigator.clipboard.writeText(challengeUrl)
							sendNotification({
								msg: 'Copied to clipboard',
								variant: 'info'
							})
						}}
					>
						Copy Link
					</Button>
				</Grid>

				<Grid container direction="row" justifyContent="center" sx={{ mt: 5 }}>
					<Grid item xs={8}>
						<Button variant="blGreen" fullWidth onClick={handleShareButton}>
							{' '}
							Invite
						</Button>
					</Grid>
				</Grid>
				{/* <Grid container direction="row" justifyContent="center" sx={{ mt: 5 }}>
					<Grid item xs={8}>
						<Button variant="blGreen" fullWidth>
							<ICalendarLink event={calendarEvent} filename="challenge.ics">
								Add to Calendar
							</ICalendarLink>
						</Button>
					</Grid>
				</Grid> */}
			</Grid>
		</React.Fragment>
	)
}

export default ChallengeSendInvitePage
