import { Box, Button } from '@mui/material'
import React from 'react'
import radarImg from '../../assets/icon/radar.png'
import betaIcon from '../../assets/icon/beta-message-icon.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
const BetaRadar = ({ sport }) => {
	const navigate = useNavigate()
	const { user } = useAuth()

	const handleAddInfo = e => {
		e.stopPropagation()
		navigate(`/welcome/${sport.code}/${user.alias}`, {
			state: { refer: 'BelayHomePage' }
		})
	}
	return (
		<Box>
			<Box
				sx={{
					p: '21px 32px',
					background: 'rgba(255, 255, 255, 0.90)',
					borderRadius: '0 0 30px 30px',
					position: 'relative'
				}}
			>
				<Box className="beta-po-css">BETA</Box>
				<Box
					sx={{
						textAlign: 'center',
						fontSize: '16px',
						fontWeight: '700',
						lineHeight: '22px',
						marginTop: '12px',
						marginBottom: '23px',
						marginLeft: '30px',
						marginRight: '30px'
					}}
				>
					{sport?.name} Is Coming Soon
				</Box>
				<Box sx={{ textAlign: 'center', marginTop: '25px', marginBottom: '10px' }}>
					<img src={radarImg} alt={'Radar'} loading="lazy" />
				</Box>
				<Box
					sx={{
						fontSize: '16px',
						fontWeight: '700',
						lineHeight: '22px',
						color: '#00C6AE',
						textAlign: 'center'
					}}
				>
					Your Radar Is On
				</Box>
				<Box
					sx={{
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '22px',
						textAlign: 'center',
						marginTop: '15px'
					}}
				>
					We will notify you when a <br /> potential partner comes by.
				</Box>

				<Box sx={{ textAlign: 'center', margin: '20px 0' }}>
					<Button
						sx={{
							width: '188px',
							height: '36px',
							borderRadius: '4px',
							border: '1px solid #00C6AE',
							fontWeight: '700',
							fontSize: '16px'
						}}
						onClick={handleAddInfo}
					>
						Add Information
						<img src={betaIcon} alt="beta-message-icon" style={{ marginLeft: '7px' }} />
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default BetaRadar
