const createSportProfileFormModel = {
	formId: 'createSportProfileForm',
	formField: {
		sportCode: {
			name: 'sportCode',
			label: 'sport code',
			requiredErrorMsg: 'Please select a sport'
		},
		sportName: {
			name: 'name',
			label: 'sport name'
		},
		sportReleased: {
			name: 'released',
			label: 'sport released'
		},
		sportActive: {
			name: 'active',
			label: 'sport active'
		},
		practicingSince: {
			name: 'practicingSince',
			label: 'practicing since'
		},
		frequency: {
			name: 'frequency',
			label: 'frequency'
		},
		level: {
			name: 'level',
			label: 'level',
			requiredErrorMsg: 'Please select a level'
		},
		sportStyles: { name: 'sportStyles', label: 'sportStyles' },
		sportTimeWeekdayDay: {
			name: 'sportTimeWeekdayDay',
			label: 'sport time weekday day'
		},
		sportTimeWeekdayEvening: {
			name: 'sportTimeWeekdayEvening',
			label: 'sport time weekday evening'
		},
		sportTimeWeekend: {
			name: 'sportTimeWeekend',
			label: 'sport time weekend'
		},
		openToPlaces: {
			name: 'openToPlaces',
			label: 'open to places'
		},
		placesIndoor: {
			name: 'placesIndoor',
			label: 'places indoor'
		},
		placesOutdoor: {
			name: 'placesOutdoor',
			label: 'places outdoor'
		},
		playground: {
			name: 'playground',
			label: 'playground'
		},
		questions: {
			name: 'questions',
			label: 'questions'
		},
		sessionTypes: {
			name: 'sessionTypes',
			label: 'sessionTypes',
			requiredErrorMsg: 'Please select a session'
		},
		biography: {
			name: 'biography',
			label: 'biography'
		},
		pushNotifications: {
			name: 'pushNotifications',
			label: 'push Notifications'
		},
		emailNotifications: {
			name: 'emailNotifications',
			label: 'email Notifications'
		},
		newFeatureNotification: {
			name: 'newFeatureNotification',
			label: 'New Feature'
		}
	}
}

export default createSportProfileFormModel
