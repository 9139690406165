import { Avatar, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import { ChatContext } from '../../store/chat-context'
import { useParams } from 'react-router'
import { isEqual } from 'lodash'
import { getChatNameAndPhoto } from '../../shared/ChatUtils'
import { useAuth } from '../../store/auth-context'

const MessageBubble = React.memo(
	({ message }) => {
		const params = useParams()
		const { getChannelDetailsByName } = useContext(ChatContext)
		const { user } = useAuth()
		const channel = getChannelDetailsByName(params.id)

		const { isIamTheSender } = useMemo(() => {
			const isIamTheSender = message.senderAlias === user.alias
			return {
				isIamTheSender
			}
		}, [message])

		return (
			<Stack
				sx={{
					flexDirection: isIamTheSender ? 'row-revese' : 'row',
					gap: '5px',
					maxWidth: '100%'
				}}
				id={'message_item_' + message.messageId}
			>
				{channel && !isIamTheSender && (
					<Avatar
						alt={getChatNameAndPhoto(channel.participants, user)?.name}
						src={getChatNameAndPhoto(channel.participants, user)?.photo}
					/>
				)}
				<Box
					sx={{
						alignSelf: isIamTheSender ? 'end' : 'start',
						textAlign: isIamTheSender ? 'right' : 'left',
						borderRadius: isIamTheSender ? '7px 7px 0px 7px' : '7px 7px 7px 0px',
						display: 'flex',
						flexDirection: 'column',
						backgroundColor: message.senderAlias === user.alias ? '#61E4C5' : '#e7e5e5',
						padding: '5px 10px',
						maxWidth: '80%',
						marginBottom: '10px'
					}}
				>
					<Typography
						className="fw-500"
						sx={{
							width: 'fit-content',
							color: 'black',
							textAlign: 'left'
						}}
					>
						{message.content.split('\n').map((word, index) => (
							<React.Fragment key={index}>
								<span>{word}</span>
								<br />
							</React.Fragment>
						))}
					</Typography>
					<Typography
						variant="note"
						sx={{
							color: 'black'
						}}
					>
						{moment(`${message.timestamp}Z`).format('LT')}
					</Typography>
				</Box>
			</Stack>
		)
	},
	(pp, np) => isEqual(pp, np)
)

export default MessageBubble
