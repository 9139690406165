import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import logoImg from '../../assets/logo-green.svg'

const Loading = ({ loadingText, backgroundTransparent = false }) => {
	return (
		<>
			<Box
				style={{
					background: backgroundTransparent ? 'white' : 'rgba(0, 0, 0, 0.5)',
					width: '100%',
					height: document.documentElement.scrollHeight + 100,
					paddingTop: '130px',
					position: 'absolute',
					top: '0',
					left: '0'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column'
					}}
				>
					<img src={logoImg} alt="BelayLink" />
					<CircularProgress
						size={35}
						style={{ color: '#00C6AE', marginTop: '10px', marginBottom: '10px' }}
					/>
					<Box sx={{ color: 'white', marginTop: '15px' }}>
						{loadingText ? loadingText : 'Loading...'}
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Loading
