import baseApi from './baseApiService'
import { BROWSE_PROFILE_URL, PROFILE_URL, USER_URL } from '../constants/ApiUrls'
class LookAroundService {
	updateProfile(request) {
		return baseApi.put(PROFILE_URL, request)
	}

	postProfile(request) {
		return baseApi.post(PROFILE_URL, request)
	}

	// getUsersProfile(alias, sportCode) {
	// 	return baseApi.get(`${USER_URL}/${alias}/profile/${sportCode}`)
	// }
	getUsersProfile(sportCode) {
		return baseApi.get(`${PROFILE_URL}/${sportCode}`)
	}
	getBrowseProfile(sportCode, page, size) {
		return baseApi.get(`${BROWSE_PROFILE_URL}/?page=${page}&size=${size}&sportCode=${sportCode}`)
	}

	postStatus(sportCode, request) {
		return baseApi.post(`${PROFILE_URL}/${sportCode}/status`, request)
	}
}

export default new LookAroundService()
