import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
	Box,
	Button,
	ClickAwayListener,
	Modal,
	Stack,
	Typography,
	Avatar,
	Tabs,
	Tab,
	DialogTitle,
	IconButton
} from '@mui/material'
import GoogleMaps from '../../components/GoogleMaps'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Tour from 'reactour'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import styled from '@emotion/styled'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import { useAuth } from '../../store/auth-context'
import moment from 'moment'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UserService from '../../services/UserService'
import TutorialService from '../../services/TutorialService'
import ProfileService from '../../services/ProfileService'
import useNotification from '../../hooks/useNotification'
import DateUtils from '../../shared/DateUtils'
import DatePicker from 'react-multi-date-picker'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Add, CloseOutlined, SendOutlined } from '@mui/icons-material'
import BottomNavigation from '../../components/ui/BottomNavigation'
import Loading from '../../components/ui/Loading'
import { LoadingContext } from '../../store/loading-context'

import './index.css'
import Close from '@mui/icons-material/Close'
import EyeIcon from '../../assets/icon/EyeIcon'
import LocationUpdateModal from '../../components/StatusUpdate/LocationUpdateModal'
import congrats from '../../assets/icon/congrats.png'
import BetaRadar from '../LookAround/BetaRadar'
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ShareSportProfile from '../ShareSportsProfile'

moment.updateLocale('en', {
	week: {
		dow: 1 // Monday
	}
})

const today = moment()

const TIMERANGES = [
	{
		start: moment().startOf('day'),
		end: moment().endOf('day'),
		label: 'Today'
	},
	{
		start: moment().add(1, 'day').startOf('day'),
		end: moment().add(1, 'day').endOf('day'),
		label: 'Tomorrow'
	},
	{
		start: today.day() > 5 ? moment().add(2, 'days').startOf('week') : moment().startOf('week'),
		end:
			today.day() > 5
				? moment().add(2, 'days').startOf('week').add(4, 'days')
				: moment().startOf('week').add(4, 'days'),
		label: 'This Week'
	},
	{
		start: moment().endOf('week').subtract(1, 'day'),
		end: moment().endOf('week'),
		label: 'This Weekend'
	},
	{
		label: 'custom',
		start: undefined,
		end: undefined
	}
]

const BelayLinkHomePage = () => {
	const tabsRef = useRef(null)
	const dateInputRef = useRef(null)
	const navigate = useNavigate()
	const tourRef = useRef(null)
	const currentStep = useRef(0)
	const location = useLocation()
	const { user, updateUser } = useAuth()
	const [dateRangeSelection, setSelections] = useState(TIMERANGES)
	const [isTooltipOpen, setTooltip] = useState(false)
	const [isLocationOpen, setLocationOpen] = useState(false)
	const [isDateSelectOpen, setDateSelect] = useState(false)
	const [isSportsLocationOpen, toggleSportsLocation] = useState(false)
	const [isOtherSportOpen, setOtherSport] = useState(false)
	const [statusText, setStatus] = useState('')
	const [shouldShowTour, setShowTour] = useState(false)
	const [sportsLocationList, setSportsLocationList] = useState(null)
	const [sportsLocation, setSportsLocation] = useState([])
	const [newLocation, setNewLocation] = useState(null)
	const [userDetailsUpdated, setUserDetailsUpdated] = useState(false)
	const [sport, setSport] = useState(null)
	const [, sendNotification] = useNotification()
	const [profileStatus, setProfileStatus] = useState(null)
	const [datevalue, setDatevalue] = useState()
	const [selected_individual_dates, setSelected_individual_dates] = useState([])
	const [selectedDateRange, setSelectedDateRange] = useState([])
	const [showDeleteSuccessfullScreen, setShowDeleteSuccessfullScreen] = useState(false)
	const [isCustomDateSatted, setIsCustomDateSatted] = useState(false)
	const [customRangeDates, setCustomRangeDates] = useState([])
	const [isLoading, setIsLoading] = useContext(LoadingContext)
	const [bgImageLoaded, setBgImageLoaded] = useState(false)
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
	const [sportProfile, setSportProfile] = useState(null)
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)

	const openDialog = useCallback(() => setIsDialogOpen(true), [])
	const closeDialog = useCallback(() => setIsDialogOpen(false), [])

	// Delete Status States
	const [anchorEl, setAnchorEl] = useState(null)
	const deletPopupOpen = Boolean(anchorEl)
	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	// delete status Modal states
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const handleDeleteOpen = () => setIsDeleteModalOpen(true)
	const handleDeleteClose = () => {
		setIsDeleteModalOpen(false)
		getUserProfileDetails()
		setShowDeleteSuccessfullScreen(false)
	}

	useEffect(() => {
		if (sport?.background) {
			const img = new Image()
			img.src = sport?.background

			img.onload = () => {
				setBgImageLoaded(true)
			}
		} else {
			setBgImageLoaded(true)
		}
	}, [sport?.background])

	useEffect(() => {
		if (user?.sports?.length === 0) {
			setIsLoading(false)
			setBgImageLoaded(true)
			navigate('/p')
		}
	}, [user])

	useEffect(() => {
		if (!userDetailsUpdated || !user.sports) {
			// first time just after onborad user's sports are null
			setIsLoading(true)
			UserService.getMe()
				.then(response => {
					updateUser({ ...response.data, metaData: user.metaData })
					setUserDetailsUpdated(true)
					const setBetaSportAtLast = response.data.sports.sort((a, b) => b.released - a.released)
					if (!sport && response.data.sports.length > 0) {
						if (location?.state?.sportCode) {
							// if user click on status notification
							const findSport = response.data.sports.find(
								sport => sport.code === location?.state?.sportCode
							)
							if (findSport) {
								//if status is still in user's profile
								setSport(findSport)
							} else {
								// if user delete that status related sport from profile
								setSport(setBetaSportAtLast[0])
								openDialog()
							}
						} else {
							setSport(setBetaSportAtLast[0])
						}
						setIsLoading(false)
					}

					if (setBetaSportAtLast[0].released) {
						const isViewed = TutorialService.isTutorialViewed(
							TutorialService.TutorialType.PROFILE_STATUS,
							user,
							updateUser
						)
						if (!isViewed) {
							setTimeout(() => {
								setShowTour(true)
							}, 1000)
						}
					}
				})
				.catch(() => {
					setIsLoading(false)
				})
		} else {
			setIsLoading(false)
		}
	}, [userDetailsUpdated, sport])

	const handleTabClick = () => {
		if (tabsRef.current) {
			const container = document.querySelector('.MuiTabs-scroller')
			if (container) {
				container.scrollTo({
					left: 0,
					behavior: 'smooth'
				})
			}
		}
	}

	useEffect(() => {
		function handleClickOutside(event) {
			const inputElement = dateInputRef.current.querySelector('.rmdp-input')
			const inputValue = inputElement.value
			if (
				inputValue &&
				dateInputRef.current.value != '' &&
				!dateInputRef.current.contains(event.target)
			) {
				setIsCustomDateSatted(true)
				const container = document.querySelector('.css-scroll-firstC')
				container.scrollLeft = 0
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [dateInputRef])

	useEffect(() => {
		if (sport) {
			getUserProfileDetails()
		}
	}, [sport, user?.alias])

	useEffect(() => {
		if (profileStatus) {
			setSportsLocation(profileStatus.playground)
			setStatus(profileStatus.message)
			const LABELS = ['Today', 'Tomorrow', 'This Week', 'This Weekend']
			const transformDate = date => {
				const dateLabel = DateUtils.getLabelForDateRange(
					moment(date.startDate),
					moment(date.endDate)
				)
				return {
					start: moment(date.startDate),
					end: moment(date.endDate),
					label: LABELS.includes(dateLabel) ? dateLabel : 'custom'
				}
			}
			// Transform dates and set the date range value
			const transformedDates = profileStatus.dates.map(transformDate)
			const customDatesArr = transformedDates.filter(dateObj => dateObj.label === 'custom')
			if (customDatesArr && customDatesArr?.length > 0) {
				const manageDateValues = customDatesArr.map(date => date.start._d)
				const manageDateFormat = customDatesArr.map(date => date.start._i)
				setDatevalue(manageDateValues)
				setSelected_individual_dates(manageDateFormat)
				setIsCustomDateSatted(true)
			}
			const result = []
			// Sorting based on start Date
			customDatesArr?.length > 0 &&
				customDatesArr?.sort((a, b) => {
					{
						const dateA = new Date(a.start._i)
						const dateB = new Date(b.start._i)
						if (dateA < dateB) {
							return -1
						} else if (dateA > dateB) {
							return 1
						} else {
							return 0
						}
					}
				})
			// create date range
			for (let i = 0; i < customDatesArr.length; i++) {
				const startDate = new Date(customDatesArr[i].start._d)
				const endDate = new Date(startDate)
				while (
					i < customDatesArr.length - 1 &&
					new Date(customDatesArr[i + 1]?.start?._d) - endDate === 24 * 60 * 60 * 1000
				) {
					i++
					endDate.setDate(endDate.getDate() + 1)
				}
				result.push({ startDate: startDate.toString(), endDate: endDate.toString() })
			}
			setCustomRangeDates(result)
			const dateRange = transformedDates.filter(item => item.label !== 'custom')
			if (dateRange?.length > 0) {
				const validFormat = dateRange.map(obj => {
					return {
						startDate: obj.start._i,
						endDate: obj.end._i,
						label: obj.label
					}
				})
				setSelectedDateRange(validFormat)
			}
		} else {
			setSelections(TIMERANGES)
			setSelectedDateRange([])
			setSportsLocation([])
			setStatus('')
		}
	}, [profileStatus])

	const getUserProfileDetails = () => {
		ProfileService.getUsersProfile(user?.alias, sport.code)
			.then(response => {
				const playgrounds = response.data.playground || []
				const statusPlaygrounds = response.data.status?.playground || []

				// Combine the lists
				const combinedPlaygrounds = [...playgrounds, ...statusPlaygrounds]

				// Filter out duplicates based on the name property
				const uniquePlaygrounds = combinedPlaygrounds.filter(
					(playground, index, self) => index === self.findIndex(pg => pg.name === playground.name)
				)

				setSportsLocationList(uniquePlaygrounds)
				setSportProfile(response.data)

				setProfileStatus(response.data.status)
				if (response.data.status === null) {
					setSelected_individual_dates([])
					setSelectedDateRange([])
					setDatevalue([])
				}
				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
			})
	}

	const handleSportChange = (event, newValue) => {
		setSport(newValue)
		setSelected_individual_dates([])
		setSelectedDateRange([])
		setCustomRangeDates([])
		setSelections(TIMERANGES)
		if (newValue.released) {
			const isViewed = TutorialService.isTutorialViewed(
				TutorialService.TutorialType.PROFILE_STATUS,
				user,
				updateUser
			)
			if (!isViewed) {
				setTimeout(() => {
					setShowTour(true)
				}, 1000)
			}
		}
	}

	const handleSportLocationSelect = location => {
		const locationExists = sportsLocation.some(loc => loc.placeId === location.placeId)

		if (locationExists) {
			setSportsLocation(prevLocations =>
				prevLocations.filter(loc => loc.placeId !== location.placeId)
			)
		} else {
			setSportsLocation(prevLocations => [...prevLocations, location])
		}
	}

	const selectCustomDateAndClose = () => {
		setDateSelect(false)
		if (selected_individual_dates?.length === 0) {
			setSelected_individual_dates([new Date()])
		}
	}

	const customDateRangeLabel = useMemo(() => {
		const custom = dateRangeSelection.find(item => item.label === 'custom')
		if (moment(custom.start).isSame(moment(custom.end), 'days'))
			return moment(custom.start).format('D MMM')

		return `${moment(custom.start).format('D MMM')} - ${moment(custom.end).format('D MMM')}`
	}, [dateRangeSelection])

	const hasCustomDates = useMemo(() => {
		const custom = dateRangeSelection.find(item => item.label === 'custom')
		if (custom.start && custom.end) return true

		return false
	}, [dateRangeSelection])

	const handleAddLocation = () => {
		if (newLocation && !sportsLocationList.includes(newLocation)) {
			setSportsLocationList(prevLocations => [...prevLocations, newLocation])
			handleSportLocationSelect(newLocation)
		}
		toggleSportsLocation(false)
		setNewLocation(null)
	}

	const onRequestClose = () => {
		if (currentStep.current < 1) {
			tourRef.current.nextStep()
		} else {
			setShowTour(false)
			window.location.reload()
		}
	}

	const handleDateSelection = date => {
		if (date.label !== 'custom') {
			if (selectedDateRange.some(selectedDate => selectedDate.label === date.label)) {
				setSelectedDateRange(prevRange => prevRange.filter(r => r.label !== date.label))
			} else {
				const dateObj = {
					startDate: moment(date.start._d).format('YYYY-MM-DD'),
					endDate: moment(date.end._d).format('YYYY-MM-DD'),
					label: date.label
				}
				setSelectedDateRange(PrevRange => [...PrevRange, dateObj])
			}
		}
		const container = document.querySelector('.css-scroll-firstC')
		container.scrollLeft = 0
	}

	const getDateChipLabel = useCallback(date => {
		if (date.label === 'custom') {
			if (hasCustomDates) return customDateRangeLabel
			else return 'Select Date'
		} else {
			return date.label
		}
	})

	const handleAfterOpen = () => {
		const container = document.getElementById('homePageStack')
		document.body.classList.add('no-overflow')
		container.classList.add('no-overflow')
	}
	const handleBeforeClose = () => {
		const container = document.getElementById('homePageStack')
		document.body.classList.remove('no-overflow')
		container.classList.remove('no-overflow')
	}

	// on change date from date picker
	const handleDateSelect = e => {
		setDatevalue(e)

		const getDates = e.map(date =>
			moment.unix(date.unix).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')
		)
		setSelected_individual_dates(getDates)
		if (getDates?.length === 0) {
			setIsCustomDateSatted(false)
		}

		let dddd = getDates?.sort((a, b) => {
			{
				const dateA = new Date(a)
				const dateB = new Date(b)

				if (dateA < dateB) {
					return -1
				} else if (dateA > dateB) {
					return 1
				} else {
					return 0
				}
			}
		})
		const result = []

		for (let i = 0; i < dddd.length; i++) {
			const startDate = new Date(dddd[i])
			const endDate = new Date(startDate)
			while (i < dddd.length - 1 && new Date(dddd[i + 1]) - endDate === 24 * 60 * 60 * 1000) {
				i++
				endDate.setDate(endDate.getDate() + 1)
			}
			result.push({ startDate: startDate.toString(), endDate: endDate.toString() })
		}
		setCustomRangeDates(result)
	}

	const handleSubmitStatus = () => {
		if (
			selected_individual_dates?.length > 0 ||
			(statusText && statusText.trim().length > 0) ||
			selectedDateRange?.length > 0
		) {
			const single_dates = selected_individual_dates.map(dateObj => ({
				startDate: moment(dateObj).format('YYYY-MM-DD'),
				endDate: moment(dateObj).format('YYYY-MM-DD')
			}))
			const date_range =
				selectedDateRange?.length > 0
					? selectedDateRange.map(dateObj => ({
							startDate: dateObj.startDate,
							endDate: dateObj.endDate
					  }))
					: []
			// const dates = selected_individual_dates.map(dateObj => moment(dateObj).format('YYYY-MM-DD'))

			const status = {
				playground: [...sportsLocation],
				dates: single_dates.concat(date_range),
				message: statusText ? statusText.trim() : ''
			}

			ProfileService.postStatus(sport.code, status)
				.then(response => {
					// navigate(`/p/${user.alias}/${sport.code}`, { replace: true })
					setIsSuccessModalOpen(true)
				})
				.catch(error => {
					console.log('ERROR', error.response)
					sendNotification({
						msg:
							error.response?.data?.message !== undefined
								? error.response.data.message
								: 'An Error Occurred',
						variant: 'error'
					})
				})
		}
	}

	const handleDeletStatus = () => {
		ProfileService.deleteStatus(sport?.code)
			.then(response => {
				setShowDeleteSuccessfullScreen(true)
				setSelected_individual_dates([])
				setSelectedDateRange([])
				setDatevalue([])
				setCustomRangeDates([])
			})
			.catch(error => {
				console.log('ERROR', error.response)
				sendNotification({
					msg:
						error.response?.data?.message !== undefined
							? error.response.data.message
							: 'An Error Occurred',
					variant: 'error'
				})
			})
	}

	const handleNavigteToLookAround = () => {
		const isVisited = TutorialService.isTutorialViewed(
			TutorialService.TutorialType.LOOK_AROUND,
			user
			// updateUser
		)
		if (!isVisited) {
			setIsLoading(true)
			if (user?.sports?.length > 0) {
				navigate(`/welcome/${user?.sports[0]?.code}/${user?.alias}`)
				setIsLoading(false)
			} else {
				UserService.getMe().then(response => {
					navigate(`/welcome/${response?.data?.sports[0].code}/${response?.data.alias}`)
					setIsLoading(false)
				})
			}
		} else {
			if (user?.coverImages?.length === 0 && sportProfile?.questions?.length === 0) {
				navigate(`/welcome/${sportProfile?.sport?.code}/${user.alias}`, {
					state: { refer: 'BelayHomePage' }
				})
			} else {
				navigate(`/look-around/${user.alias}/${sport.code}`)
				localStorage.removeItem('look-around-data')
			}
		}
	}

	const handleSuccessModalClose = () => {
		setIsSuccessModalOpen(false)
		handleNavigteToLookAround()
	}

	const handleEditMyProfile = mode => {
		navigate(`/p/${user?.alias}/${user?.sports[0]?.code}`, {
			state: { refer: 'viewProfile' }
		})
		if (mode === 'view') {
			localStorage.setItem('showEditIcon', 'hide')
		} else {
			localStorage.setItem('showEditIcon', 'show')
		}
	}

	return (
		<>
			<Stack
				alignItems="center"
				spacing={6}
				id="homePageStack"
				sx={{
					backgroundImage: sport?.released ? `url(${sport?.background})` : '',
					backgroundAttachment: 'fixed',
					minHeight: 'calc(100dvh - 56px)',
					backgroundSize: 'cover',
					padding: '20px',
					marginBottom: '60px',
					position: 'relative'
				}}
			>
				<Box
					className="d-flex jcc aic home-stack-info"
					sx={{ boxShadow: '0px 0px 10px rgb(0 0 0 / 40%)', padding: '0 20px' }}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							width: '100%',
							paddingLeft: '15px'
						}}
						onClick={() => handleEditMyProfile('view')}
					>
						<Avatar
							sx={{ height: '55px', width: '55px' }}
							alt={user?.name}
							src={user?.userImage?.viewUrl || '/broken-img.jpg'}
						/>
						<Box onClick={() => handleEditMyProfile('view')}>
							<Typography variant="pb" onClick={() => handleEditMyProfile('view')}>
								{user?.name}
							</Typography>
							<Box sx={{ display: 'flex', alignItems: 'center', height: 'fit-content', mt: '5px' }}>
								<FmdGoodOutlinedIcon
									sx={{ color: 'gray', height: '15px', width: '15px', mr: '3px' }}
								/>
								<Typography
									variant="p"
									sx={{ color: '#474A57', cursor: 'pointer' }}
									onClick={e => {
										e.stopPropagation()
										setLocationOpen(true)
									}}
								>
									{user?.location.name}
								</Typography>
							</Box>
							{/* below box only for redirect to view profile, it's not showing because of white color*/}
							<Box
								onClick={() => handleEditMyProfile('view')}
								sx={{ height: '100%', width: '100%', color: 'white' }}
							>
								{user?.location.name}
							</Box>
						</Box>
					</Box>

					<Box
						style={{
							// position: 'absolute',
							// right: '0',
							borderLeft: '1px solid gray',
							color: 'gray',
							marginRight: '-15px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: '95%'
						}}
					>
						<Box
							sx={{
								padding: '15px',
								borderBottom: '1px solid gray',
								height: '50%',
								cursor: 'pointer'
							}}
							onClick={e => {
								e.stopPropagation()
								setIsShareDialogOpen(!isShareDialogOpen)
							}}
						>
							<SendOutlined />
						</Box>
						<Box
							sx={{ padding: '15px', height: '50%', cursor: 'pointer' }}
							onClick={e => {
								e.stopPropagation()
								handleEditMyProfile('edit')
							}}
						>
							<EditIcon />
						</Box>
					</Box>
				</Box>
				{/* ------------------------------------------------------- Location Modal  */}
				{isLocationOpen && (
					<LocationUpdateModal
						handleLocationClose={() => {
							setLocationOpen(false)
						}}
						style={style}
					/>
				)}
				<Box
					sx={{
						maxWidth: '100%',
						mt: '29px',
						boxShadow: '0px 8px 10px rgb(0 0 0 / 40%)',
						borderRadius: '15px'
					}}
				>
					<Box id="update-sport-status">
						{/* ------------------------------------------------------- Top Tags  */}
						<Box>
							<Tabs
								ref={tabsRef}
								value={sport}
								onChange={handleSportChange}
								variant="scrollable"
								scrollButtons="auto"
								indicatorColor="transparent"
							>
								{user?.sports
									?.sort(a => (a.name === sport?.name ? -1 : 1))
									// ?.sort((a, b) => b.released - a.released) // to set beta sport at last
									.map((sport, i) => (
										<Tab
											key={sport.code}
											value={sport}
											label={
												<Typography variant="h4" sx={{ color: i > 0 && 'gray' }}>
													{sport.name}
												</Typography>
											}
											onClick={handleTabClick}
										/>
									))}
								{user?.alias === user?.alias && (
									<Tab
										label={
											<Typography
												variant="h4"
												sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}
											>
												Other Sports{' '}
												<Add sx={{ fontSize: '19px', marginLeft: '5px', fontWeight: '900' }} />
											</Typography>
										}
										component={Link}
										to={`/p/${user?.alias}/createSportProfile`}
									/>
								)}
							</Tabs>
						</Box>

						{sport ? (
							sport?.released ? (
								<Box
									sx={{
										pt: '15px',
										background: '#B0F1E1',
										borderRadius: '0 0 13px 13px'
									}}
								>
									{/* ------------------------------------------------------- delete status  */}

									<Box>
										<Box className="anchor">
											<Box
												sx={{
													position: 'relative',
													width: '100%',
													background: '#FFD465',
													height: '100%'
												}}
											></Box>
										</Box>
										<Box
											sx={{
												mx: '15px',
												background: '#FFD465',
												px: '12px',
												minHeight: '204px',
												borderRadius: '20px'
											}}
										>
											{profileStatus && (
												<Box sx={{ float: 'right' }}>
													<Box className="d-flex aic jce w-100">
														<Button
															sx={{ minWidth: '0', padding: '5px 0px', marginBottom: '-15px' }}
															id="basic-button"
															aria-controls={deletPopupOpen ? 'basic-menu' : undefined}
															aria-haspopup="true"
															aria-expanded={deletPopupOpen ? 'true' : undefined}
															onClick={handleClick}
														>
															<Close
																sx={{ fontSize: '18px', color: '#515151', marginTop: '5px' }}
															/>
														</Button>
													</Box>
													<Menu
														id="basic-menu"
														anchorEl={anchorEl}
														open={deletPopupOpen}
														onClose={handleClose}
														MenuListProps={{
															'aria-labelledby': 'basic-button'
														}}
													>
														<MenuItem
															onClick={() => {
																handleDeleteOpen()
																handleClose()
															}}
														>
															X Delete my current status
														</MenuItem>
													</Menu>
													<Modal
														open={isDeleteModalOpen}
														onClose={handleDeleteClose}
														aria-labelledby="modal-modal-title"
														aria-describedby="modal-modal-description"
													>
														{!showDeleteSuccessfullScreen ? (
															<Box sx={{ ...style, fontSize: '20px', textAlign: 'center' }}>
																<Typography
																	id="modal-modal-title"
																	sx={{ fontWeight: '600', fontSize: '20px' }}
																>
																	Are you sure you want to delete the current status?
																</Typography>
																<Box
																	id="modal-modal-description"
																	sx={{
																		mt: 2,
																		width: '122%',
																		display: 'flex',
																		justifyContent: 'space-around',
																		borderTop: '1px solid #8080804d',
																		marginBottom: '-32px'
																	}}
																>
																	<Button
																		sx={{ width: '100%', p: '12px 0', fontSize: '17px' }}
																		onClick={handleDeleteClose}
																	>
																		Cancel
																	</Button>
																	<Button
																		sx={{
																			width: '100%',
																			p: '12px 0',
																			fontSize: '17px',
																			borderLeft: '1px solid #8080804d'
																		}}
																		onClick={handleDeletStatus}
																	>
																		Delete
																	</Button>
																</Box>
															</Box>
														) : (
															<Box
																sx={{
																	...style,

																	height: '163px'
																}}
															>
																<Box
																	sx={{
																		position: 'absolute',
																		top: '10px',
																		right: '18px',
																		color: 'gray'
																	}}
																>
																	<CloseOutlined onClick={handleDeleteClose} />
																</Box>
																<Box
																	sx={{
																		fontSize: '20px',
																		fontWeight: '600',
																		textAlign: 'center'
																	}}
																>
																	Your Status has been deleted
																</Box>
															</Box>
														)}
													</Modal>
												</Box>
											)}
											<Box
												sx={{
													display: 'flex',
													gap: '10px',
													paddingTop: '15px',
													width: '100%',
													alignItems: 'center'
												}}
											>
												<CalendarMonthOutlinedIcon
													sx={{ width: '32px', height: '32px', color: 'gray' }}
												/>

												<Box
													sx={{
														width: '240px',
														display: 'flex',
														gap: '10px',
														flex: '1',
														scrollBehavior: 'smooth'
													}}
													overflow="scroll"
													className="scrollbarhidden css-scroll-firstC"
												>
													{isCustomDateSatted &&
														(selected_individual_dates?.length > 0 ||
															customRangeDates?.length > 0) && (
															<Box
																sx={{
																	// background: '#61E4C5',
																	borderRadius: '11px',
																	display: 'flex',
																	width: '100%',
																	minWidth: 'max-content'
																}}
															>
																{customRangeDates.map((c_date, i) => {
																	return (
																		<Box
																			key={i}
																			className="cus-date-b"
																			onClick={() => dateInputRef.current.openCalendar()}
																		>
																			<Typography
																				sx={{
																					textTransform: 'capitalize',
																					lineHeight: 'inherit'
																				}}
																				variant="h4"
																			>
																				{c_date.startDate === c_date.endDate
																					? moment(c_date.startDate).format('D MMM')
																					: moment(c_date.startDate).format('D MMM') +
																					  '-' +
																					  moment(c_date.endDate).format('D MMM')}
																			</Typography>
																		</Box>
																	)
																})}
															</Box>
														)}
													<Box
														sx={{
															display: 'flex',
															marginLeft: selected_individual_dates?.length > 0 ? '-11px' : '0px'
														}}
													>
														{dateRangeSelection
															?.sort((a, b) => {
																// Check if the items are selected
																const isSelectedA = selectedDateRange.some(
																	item => item.label === a.label
																)
																const isSelectedB = selectedDateRange.some(
																	item => item.label === b.label
																)

																if (isSelectedA && !isSelectedB) return -1
																if (!isSelectedA && isSelectedB) return 1

																// If neither is selected and one of them has label "custom", put it to the end
																if (!isSelectedA && a.label === 'custom') return 1
																if (!isSelectedB && b.label === 'custom') return -1

																// If both are either selected or not, then sort by the start date
																return moment(a.start).isBefore(moment(b.start)) ? -1 : 1
															})
															.map(
																(
																	dateRange,
																	i // Now we are not showing custom dates in individual tags
																) =>
																	dateRange.label !== 'custom' && (
																		<Box
																			key={i}
																			sx={{
																				background: selectedDateRange.some(
																					item => item.label === dateRange.label
																				)
																					? '#61E4C5'
																					: '#EEEFF4'
																			}}
																			className="date-r-label"
																			onClick={() => handleDateSelection(dateRange)}
																		>
																			<Typography
																				sx={{ textTransform: 'capitalize', lineHeight: 'inherit' }}
																				variant="h4"
																			>
																				{getDateChipLabel(dateRange)}
																			</Typography>
																		</Box>
																	)
															)}
														<Box
															className="mystatusdatepicker"
															onClick={() => dateInputRef.current.openCalendar()}
														>
															<Typography
																sx={{
																	fontSize: '12px',
																	fontWeight: '700',
																	margin: '0 10px'
																}}
															>
																Select Date
															</Typography>
														</Box>
													</Box>
												</Box>
											</Box>
											<Box sx={{ marginTop: '-23px' }}>
												<DatePicker
													ref={dateInputRef}
													value={datevalue}
													onChange={handleDateSelect}
													multiple
													dateSeparator=" & "
													// plugins={[<DatePanel />]}
													minDate={new Date()}
													placeholder="Select Date"
													format="D MMM"
													style={{ display: 'none' }}
												/>
											</Box>
											<Box sx={{ display: 'flex', py: '10px', gap: '10px' }}>
												<MapOutlinedIcon sx={{ width: '32px', height: '32px', color: 'gray' }} />

												<Box
													sx={{
														width: '240px',
														display: 'flex',
														gap: '10px',
														flex: '1',
														alignItems: 'center'
													}}
													overflow="scroll"
													className="scrollbarhidden"
												>
													{sportsLocationList?.map((location, i) => (
														<Box
															key={i}
															sx={{
																background: sportsLocation.some(
																	loc => loc.placeId === location.placeId
																)
																	? '#61E4C5'
																	: '#EEEFF4',
																color: 'black',
																borderRadius: '20px',
																padding: '4px 15px',
																cursor: 'pointer',
																minWidth: 'fit-content'
															}}
															onClick={() => handleSportLocationSelect(location)}
														>
															<Typography variant="h4">{location.name}</Typography>
														</Box>
													))}
													<Box
														sx={{
															background: isSportsLocationOpen === 'custom' ? '#61E4C5' : '#EEEFF4',
															color: 'black',
															minWidth: 'fit-content',
															borderRadius: '20px',
															padding: '4px 15px',
															cursor: 'pointer'
														}}
														onClick={() => toggleSportsLocation(true)}
													>
														<Typography variant="h4">Select location</Typography>
													</Box>
												</Box>
											</Box>
											<Box sx={{ pb: '10px', position: 'relative' }}>
												<textarea
													className="status-input"
													value={statusText}
													onChange={({ target: { value } }) => setStatus(value.slice(0, 250))}
													color="danger"
												/>
												{!statusText && (
													<span
														style={{
															position: 'absolute',
															top: 10,
															left: 10,
															right: 10,
															fontWeight: '500',
															fontSize: 12,
															userSelect: 'none',
															color: '#969BAB',
															pointerEvents: 'none'
														}}
													>
														<span
															style={{
																fontWeight: '700',
																fontSize: 12
															}}
														>
															Update your status to let people know what you are up to!
														</span>
													</span>
												)}
												{statusText && (
													<Typography
														variant="subtitle1"
														sx={{
															textAlign: 'end',
															fontStyle: 'italic',
															color: '#969BAB'
														}}
													>
														{statusText.length} / 250
													</Typography>
												)}
											</Box>
										</Box>
									</Box>

									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											mt: '16px',
											flexDirection: 'column'
										}}
									>
										<Button
											onClick={handleSubmitStatus}
											sx={{
												background: '#00C6AE',
												borderRadius: '5px',
												textTransform: 'capitalize',
												paddingX: '20px',
												boxShadow:
													'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
												'&:hover': {
													backgroundColor: '#00C6AE',
													color: '#3c52b2'
												}
											}}
											disableElevation
										>
											<Typography variant="h2" color="white" sx={{ whiteSpace: 'nowrap' }}>
												Update my Status
											</Typography>
										</Button>
									</Box>

									<ClickAwayListener onClickAway={() => setTooltip(false)}>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'end',
												justifyContent: 'end',
												px: '10px',
												pb: '10px'
											}}
										>
											<LightTooltip
												PopperProps={{
													disablePortal: true
												}}
												onClose={() => setTooltip(false)}
												placement="bottom-end"
												open={isTooltipOpen}
												disableFocusListener
												disableHoverListener
												disableTouchListener
												title="Your status will appear on your profile. Share what you want to do so people are more likely to reach out to you!"
											>
												<InfoOutlinedIcon onClick={() => setTooltip(true)} sx={{ color: 'gray' }} />
											</LightTooltip>
										</Box>
									</ClickAwayListener>
								</Box>
							) : (
								<BetaRadar sport={sport} />
							)
						) : (
							''
						)}
					</Box>
				</Box>
				<Modal
					open={isDateSelectOpen}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={styleDate} className="select-date-css1-status">
						<CalendarMonthOutlinedIcon sx={{ width: '40px', height: '40px', color: 'gray' }} />

						<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
							<Button
								sx={{
									width: '100px',
									background: 'white',
									borderRadius: '5px',
									border: '1px solid #00C6AE',
									textTransform: 'capitalize',
									'&:hover': {
										backgroundColor: 'white',
										color: '#3c52b2'
									}
								}}
								onClick={() => setDateSelect(false)}
								variant="contained"
								disableElevation
							>
								<Typography variant="h2" color="#00C6AE">
									Close
								</Typography>{' '}
							</Button>
							<Button
								sx={{
									width: '100px',
									background: '#00C6AE',
									borderRadius: '5px',
									textTransform: 'capitalize',
									boxShadow:
										'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
									'&:hover': {
										backgroundColor: '#00C6AE',
										color: '#3c52b2'
									}
								}}
								onClick={selectCustomDateAndClose}
								variant="contained"
								disableElevation
							>
								<Typography variant="h2" color="white">
									Select
								</Typography>{' '}
							</Button>
						</Box>
					</Box>
				</Modal>

				<Modal
					open={isSportsLocationOpen}
					onClose={() => toggleSportsLocation(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<MapOutlinedIcon sx={{ width: '40px', height: '40px', color: 'gray' }} />
						<GoogleMaps
							onSelect={(placeId, name) => setNewLocation({ placeId: placeId, name: name })}
							isClearValueOnClick={false}
						/>
						<Button
							sx={{
								width: '100px',
								background: '#00C6AE',
								borderRadius: '5px',
								textTransform: 'capitalize',
								boxShadow:
									'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
								'&:hover': {
									backgroundColor: '#00C6AE',
									color: '#3c52b2'
								}
							}}
							variant="contained"
							onClick={handleAddLocation}
							disableElevation
						>
							<Typography variant="h2" color="white">
								Select
							</Typography>{' '}
						</Button>
					</Box>
				</Modal>

				<Modal
					open={isOtherSportOpen}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={othersportsstyle}>
						<Box sx={{ minWidth: '270px' }}>
							<Box sx={{ padding: '15px' }}>
								<Typography
									id="modal-modal-title"
									variant="h6"
									className="f-17"
									component="h2"
									sx={{ textAlign: 'center', textTransform: 'capitalize' }}
								>
									Add a new sport?
								</Typography>
								<Typography
									className="f-13 fw-400"
									id="modal-modal-description"
									sx={{ mt: '10px', textAlign: 'center', textTransform: 'capitalize' }}
								>
									You can add up to 3 sports to your profile.
								</Typography>
							</Box>
							<Box sx={{ borderTop: '1px solid #C8C7CC' }}>
								<Button
									sx={{
										width: '50%',
										height: '50%',
										borderRight: '1px solid #C8C7CC',
										borderRadius: '0',
										color: '#007AFF',
										textTransform: 'capitalize'
									}}
									onClick={() => setOtherSport(false)}
								>
									<Typography variant="h2l">Cancel</Typography>
								</Button>
								<Button
									sx={{
										width: '50%',
										height: '50%',
										color: '#007AFF',
										textTransform: 'capitalize'
									}}
								>
									{' '}
									<Typography variant="h2l">Go</Typography>
								</Button>
							</Box>
						</Box>
					</Box>
				</Modal>

				{/*  update status success pop up  */}
				<Modal
					open={isSuccessModalOpen}
					onClose={handleSuccessModalClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={successModalStyle}>
						<Box
							sx={{
								position: 'absolute',
								top: '10px',
								right: '18px',
								color: 'gray'
							}}
						>
							<CloseOutlined onClick={handleSuccessModalClose} />
						</Box>
						<Box sx={{ position: 'absolute', top: '23px', right: '49px' }}>
							<img src={congrats} style={{ width: '69px' }} />
						</Box>

						<Box
							sx={{
								fontSize: '16px',
								fontWeight: '600',
								marginLeft: '-40px',
								marginTop: '25px'
							}}
						>
							<Box sx={{ fontWeight: '700', fontSize: '23px', marginBottom: '10px' }}>
								Status <br /> updated!
							</Box>
							Your friends and <br />
							people who are <br />
							available will get <br /> notified.
						</Box>
						<Box sx={{ fontSize: '14px', marginBottom: '-13px' }}>
							See who else may be available?
						</Box>
						<Box
							id="modal-modal-description"
							sx={{
								width: '150%',
								display: 'flex',
								justifyContent: 'space-around',
								borderTop: '1px solid #8080804d',
								marginBottom: '-32px'
							}}
						>
							<Button
								sx={{
									width: '100%',
									p: '12px 0',
									fontSize: '17px',
									borderLeft: '1px solid #8080804d'
								}}
								onClick={handleNavigteToLookAround}
							>
								Go to Look Around &nbsp;
								<EyeIcon sx={{ color: '#00C6AE' }} />
							</Button>
						</Box>
					</Box>
				</Modal>
				<Dialog
					open={isDialogOpen}
					onClose={closeDialog}
					aria-labelledby="redirect-dialog"
					aria-describedby="redirect-dialog-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Hey, It seems you deleted that sport from your profile.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => closeDialog()} autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>

				{/* share Profile dialog  */}
				<Dialog open={isShareDialogOpen} onClose={() => setIsShareDialogOpen(false)}>
					<DialogTitle>
						{/* Optional: Title Text Here */}
						<IconButton
							aria-label="close"
							onClick={() => setIsShareDialogOpen(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: theme => theme.palette.grey[500]
							}}
						>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<ShareSportProfile user={user} message="" />
					</DialogContent>
					{/* DialogActions if any */}
				</Dialog>
				<Tour
					ref={tourRef}
					onRequestClose={onRequestClose}
					showButtons={false}
					showNavigationNumber={false}
					showCloseButton={false}
					getCurrentStep={cs => (currentStep.current = cs)}
					showNumber={false}
					closeWithMask={true}
					showNavigation={false}
					steps={steps}
					isOpen={shouldShowTour}
					badgeContent={null}
					maskSpace={0}
					rounded={10}
					onAfterOpen={handleAfterOpen}
					onBeforeClose={handleBeforeClose}
					disableInteraction
				/>

				<BottomNavigation lookArround={false} addStatus={true} message={false} />
			</Stack>
			{(isLoading || !bgImageLoaded) && <Loading backgroundTransparent={true} />}
		</>
	)
}
export default BelayLinkHomePage
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	minWidth: '338px',
	gap: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const othersportsstyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: '10px',
	gap: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const styleDate = {
	position: 'absolute',
	top: '50%',
	maxWidth: '541px',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	gap: '20px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const successModalStyle = {
	position: 'absolute',
	top: '50%',
	maxWidth: '541px',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	width: '290px',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	gap: '20px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: '12px',
		padding: '15px',
		borderRadius: '10px',
		fontWeight: '500',
		marginRight: '20px',
		marginTop: '5px'
	}
}))

const steps = [
	{
		position: 'top',
		selector: '#update-sport-status',
		style: {
			minWidth: `320px`,
			maxWidth: '320px',
			padding: 0
		},
		content: (
			<Box sx={{ position: 'relative' }}>
				<Box
					sx={{
						pt: '15px',
						borderRadius: '0 0 30px 30px',
						position: 'absolute',
						bottom: '10px'
					}}
				>
					<Box
						sx={{
							mx: '15px',
							background: '#61E4C5',
							px: '12px',
							py: '22px',
							borderRadius: '20px'
						}}
					>
						<Typography as="span" className="fw-700">
							Update your activity status{' '}
						</Typography>
						<Typography as="span" className="fw-500">
							to let friends or others know what you are up to!
						</Typography>
					</Box>
					<Box className="anchor-bottom">
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								background: '#61E4C5',
								height: '100%',
								marginTop: -0.5
							}}
						></Box>
					</Box>
				</Box>
			</Box>
		)
	},
	{
		selector: '#look-around-btn',
		position: 'top',
		style: {
			minWidth: `320px`,
			maxWidth: '320px',
			padding: 0
		},
		action: node => {
			var targetDiv = document.getElementById('look-around-btn')
			if (targetDiv) {
				targetDiv.scrollIntoView({
					behavior: 'smooth'
				})
			}
		},
		content: (
			<Box sx={{ position: 'relative' }}>
				<Box
					sx={{
						pt: '15px',
						borderRadius: '0 0 30px 30px',
						position: 'absolute',
						bottom: '10px'
					}}
				>
					<Box
						sx={{
							mx: '15px',
							background: '#61E4C5',
							px: '12px',
							py: '22px',
							borderRadius: '20px'
						}}
					>
						<Typography className="fw-700">
							Look around{' '}
							<Typography as="span" className="fw-500">
								to find people who are also looking for sports buddies!
							</Typography>{' '}
						</Typography>
					</Box>
					<Box className="anchor-bottom">
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								background: '#61E4C5',
								height: '100%',
								marginTop: -0.5
							}}
						></Box>
					</Box>
				</Box>
			</Box>
		)
	}
]
