import { Button, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import MetaData from '../components/MetaData'
import { ReactComponent as CarouselImage } from '../assets/home/carousel-img.svg'
import { ReactComponent as CarouselImage1 } from '../assets/home/carousel-img-1.svg'
import { ReactComponent as CarouselImage2 } from '../assets/home/carousel-img-2.svg'
import { ReactComponent as CarouselImage3 } from '../assets/home/carousel-img-3.svg'
import { ReactComponent as CarouselImage4 } from '../assets/home/carousel-img-4.svg'
import { Box } from '@mui/system'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../store/auth-context'
import backgroundImage from '../assets/home/home-bg-image.webp'
import homeItem1 from '../assets/home/home-item-1.png'
import MyButton from '../components/ui/MyButton'
export const CAN_JOIN = process.env.REACT_APP_CAN_JOIN

const HomePage = () => {
	const navigate = useNavigate()
	const { awsSignIn } = useAuth()

	const Title = ({ children, fontColor }) => (
		<Typography
			variant="h1"
			textAlign={'left'}
			sx={{
				color: fontColor,
				background: 'rgba(255, 255, 255, 0.6)',
				backdropFilter: 'blur(2px)',
				padding: '5px'
			}}
			className="fw-700"
		>
			{children}
		</Typography>
	)

	const Description = ({ children }) => (
		<Typography
			variant="body2"
			sx={{ background: 'rgba(255, 255, 255, 0.6)', backdropFilter: 'blur(2px)', padding: '5px' }}
		>
			{children}
		</Typography>
	)

	const CarouselItem = ({ title, description, Image }) => {
		return (
			<Grid container direction="row" alignItems="flex_start" paddingX="15px">
				<Grid item xs={6}>
					<Image />
				</Grid>
				<Grid item xs={6}>
					<Stack direction="column" spacing={1} minHeight="200px" marginTop="40px">
						<Divider />
						<Description>{description}</Description>
						<Title fontColor="#474A57">{title}</Title>
					</Stack>
				</Grid>
			</Grid>
		)
	}

	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Stack direction="column" spacing={4}>
			<Stack
				alignItems="center"
				spacing={2}
				margin="0px 0px"
				paddingBottom={2}
				sx={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					minHeight: '80vh'
				}}
			>
				<Box
					textAlign="center"
					sx={{
						backgroundColor: '#00C6AE',
						borderRadius: '0px 0px 32px 32px',
						top: '-18px',
						// height: '100px',
						zIndex: 1200,
						position: 'relative'
					}}
					margin="0px"
					paddingBottom="40px"
					width="100vw"
				>
					<div>
						<Typography
							variant="h4"
							noWrap
							sx={{
								fontFamily: 'Permanent Marker',
								fontWeight: 400,
								letterSpacing: '.3rem',
								color: 'white',
								textDecoration: 'none',
								textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
								fontSize: '45px',
								lineHeight: '54px'
							}}
						>
							BelayLink
						</Typography>
						<Typography
							noWrap
							className="fw-700"
							sx={{
								color: 'white',
								textDecoration: 'none'
							}}
						>
							Connect to do sports
						</Typography>
					</div>
				</Box>
				<MetaData title={`BelayLink`} description={'BelayLink'} />
				<Typography variant="title">
					The best
					<br /> moments in
					<br /> sports are shared.
				</Typography>
				<Box sx={{ height: '220px' }} />
				<Typography variant="title">
					Find your sports
					<br /> crew
				</Typography>

				{CAN_JOIN === 'true' ? (
					<MyButton variant="blGreen" onClick={() => awsSignIn('/', true)} sx={{ width: '35%' }}>
						JOIN
					</MyButton>
				) : (
					<MyButton
						variant="blGreen"
						onClick={() => navigate('/join-waitlist')}
						sx={{ width: '50%' }}
					>
						Get Early Access
					</MyButton>
				)}
			</Stack>
			<Carousel
				animation="fade"
				sx={{ width: '100%', height: '100%' }}
				autoPlay={true}
				indicators={false}
				swipe={false}
			>
				<CarouselItem
					title="Create your sports profile and find your stoke"
					description="Looking for a chill squad or bad-ass buddies for some action?"
					Image={CarouselImage1}
				/>
				<CarouselItem
					title="Update what you are up to, and let people reach out to you"
					description="Scratching your head wondering who to ask, or tired of opening dozens of chats?"
					Image={CarouselImage2}
				/>
				<CarouselItem
					title="Connect and do sports together"
					description="Looking for new sports buddies?"
					Image={CarouselImage3}
				/>
				<CarouselItem
					title="Follow friends and stay posted for their activities"
					description="Can’t remember everyone’s schedule?"
					Image={CarouselImage4}
				/>
			</Carousel>
			<Typography variant="subtitle1">More features coming soon! Stay tuned.</Typography>
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				{CAN_JOIN === 'true' ? (
					<MyButton variant="blGreen" onClick={() => awsSignIn('/', true)} sx={{ width: '35%' }}>
						JOIN
					</MyButton>
				) : (
					<MyButton
						variant="blGreen"
						onClick={() => navigate('/join-waitlist')}
						sx={{ width: '50%' }}
					>
						Get Early Access
					</MyButton>
				)}
			</Box>
		</Stack>
	)
}

export default HomePage
