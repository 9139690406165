import React from 'react'
import Grid from '@mui/material/Grid'
import SportStyle from './SportStyle'
import { FieldArray } from 'formik'

const SportStyles = props => {
	return (
		<React.Fragment>
			<Grid container spacing={2}>
				<FieldArray
					name="profile.sportStyles"
					render={arrayHelpers => {
						return props.styles.map((style, index) => (
							<SportStyle key={style.id} style={style} index={index} arrayHelpers={arrayHelpers} />
						))
					}}
				/>
			</Grid>
		</React.Fragment>
	)
}

export default SportStyles
