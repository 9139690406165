import { FormHelperText, Grid } from '@mui/material'
import { useField, useFormik, useFormikContext } from 'formik'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import SportService from '../../services/SportService'
import SetLevel from './SetLevel'
import SetSportCode from './SetSportCode'
import SetSportQuestions from './SetSportQuestions'
import SetSportStyles from './SetSportStyles'
import SetSportTime from './SetSportTime'
import SetSportPlaces from './SetSportPlaces'
import SetPracticingSince from './SetPracticingSince'
import SetSessionType from './SetSessionType'
import NotificationSetting from './NotificationSetting'
import ActionPhotos from '../../components/user/ActionPhotos'

const CreateSportProfileRoot = ({
	activeStep,
	editSportCode,
	mode,
	setCreateSportCode,
	// REMOVE ALL BELOW
	userDetails
}) => {
	const { setValues, errors } = useFormikContext()

	const [sportList, setSportList] = useState([])
	const [sportDetails, setSportDetails] = useState()
	const [fieldSportCode, ,] = useField('sportCode')

	const { initialValues } = useFormikContext()

	const handleSportChange = useCallback(value => {
		if (fieldSportCode.value !== value) {
			SportService.getSportDetail(value).then(response => {
				setSportDetails(response.data)
				setCreateSportCode(response.data)
				setValues({ ...initialValues, sportCode: value })
			})
		}
	})

	useEffect(() => {
		if (editSportCode) {
			SportService.getSportDetail(editSportCode).then(response => {
				setSportDetails(response.data)
				handleSportChange(editSportCode)
			})
		} else {
			SportService.getSportList().then(response => {
				setSportList(response.data)
			})
		}
	}, [editSportCode])

	const formContent = step => {
		if (mode === 'create' && userDetails?.sports?.length === 0) {
			switch (step) {
				case 0:
					return <SetSportCode sportList={sportList} handleSportChange={handleSportChange} />
				case 1:
					return <SetPracticingSince />
				case 2:
					return <SetLevel />
				case 3:
					return <SetSportStyles styles={sportDetails?.styles} />
				case 4:
					return <SetSportTime />
				case 5:
					return <SetSportPlaces />
				case 6:
					return <SetSessionType />
				case 7:
					return <NotificationSetting />

				default:
					return <div>404: Not Found</div>
			}
		} else if (mode === 'create' && userDetails?.sports?.length > 0) {
			switch (step) {
				case 0:
					return <SetSportCode sportList={sportList} handleSportChange={handleSportChange} />
				case 1:
					return <SetPracticingSince />
				case 2:
					return <SetLevel />
				case 3:
					return <SetSportStyles styles={sportDetails?.styles} />
				case 4:
					return <SetSportTime />
				case 5:
					return <SetSportPlaces />
				case 6:
					return <SetSessionType />

				default:
					return <div>404: Not Found</div>
			}
		} else {
			switch (step) {
				case 0:
					return <SetSportCode sportList={sportList} handleSportChange={handleSportChange} />
				case 1:
					return <SetPracticingSince />
				case 2:
					return <SetLevel />
				case 3:
					return <SetSportStyles styles={sportDetails?.styles} />
				case 4:
					return (
						<SetSportQuestions
							questionNo={1}
							questions={sportDetails?.questions ? sportDetails?.questions : []}
						/>
					)
				case 5:
					return <SetSportQuestions questionNo={2} questions={sportDetails?.questions} />
				case 6:
					return <SetSportQuestions questionNo={3} questions={sportDetails?.questions} />
				case 7:
					return <SetSportTime />
				case 8:
					return <SetSportPlaces />
				case 9:
					return <SetSessionType initialFormValues={null} />

				default:
					return <div>404: Not Found</div>
			}
		}
	}

	return (
		<Grid container sx={{ height: '545px', overflow: 'auto' }}>
			<Grid item xs={12}>
				{formContent(activeStep)}
			</Grid>
			{errors.submit && (
				<Grid item xs={12}>
					<FormHelperText error>{errors.submit}</FormHelperText>
				</Grid>
			)}
		</Grid>
	)
}

export default CreateSportProfileRoot
