import { Typography } from '@mui/material'

const MyChip = ({ children }) => {
	return (
		<Typography
			variant="pb"
			backgroundColor="#EEEFF4"
			padding="0px 10px"
			margin="2px 10px 2px 0px"
			display="inline-block"
		>
			{children}
		</Typography>
	)
}

export default MyChip
