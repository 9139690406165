import { Button, Grid, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const ChallengeTypeCard = ({ bgcolor, text, ChallengeImage }) => {
	// const navigation = useNavigate();

	return (
		// <Button
		//   onClick={() =>
		//     navigation.navigate("details", {
		//       Selection: "0",
		//     })
		//   }
		// >
		<Paper
			sx={{
				bgcolor: bgcolor,
				minHeight: '166px',
				flexGrow: 1,
				border: '2px solid #18191F',
				borderRadius: '16px'
			}}
		>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				sx={{ minHeight: '166px' }}
			>
				<Grid item xs={12}>
					<Typography variant="h6" sx={{ fontWeight: '700' }} textAlign="center">
						{text}
					</Typography>
				</Grid>
				{/* {ChallengeImage && (
          <Grid item xs={4}>
            <ChallengeImage sx={{ height: "512px", width: "512px" }} />
          </Grid>
        )} */}
			</Grid>
		</Paper>
		// </Button>
	)
}

export default ChallengeTypeCard
