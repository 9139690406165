import { Typography } from "@mui/material";
import React from "react";
import SportGots from "./step4/SportGots";

const Step4 = (props) => {
  return (
    <React.Fragment>
      <Typography align="center">GOT</Typography>
      <SportGots gots={props.sport.sportGots} />
    </React.Fragment>
  );
};

export default Step4;
