import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import React, { useCallback, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ChatService from '../../services/ChatService'
import { ChatContext } from '../../store/chat-context'
import { useAuth } from '../../store/auth-context'

const MessageButton = ({
	sportCode,
	friendAlias,
	size,
	showIcon = true,
	refer = false,
	userDetails = false,
	disableButtons = false
}) => {
	const { user, awsSignIn } = useAuth()
	const location = useLocation()
	const pathName = window.location.pathname
	const { chatState, getChannels } = useContext(ChatContext)
	const [isDialogOpen, setIsDialogOpen] = useState(false)

	const navigate = useNavigate()
	const redirectToChannel = () => {
		if (user) {
			const findChannel = chatState.groups?.find(channel => {
				if (channel.name.includes(friendAlias)) {
					return true
				}
			})
			if (findChannel) {
				if (refer) {
					if (pathName.split('/')[1] === 'look-around') {
						navigate(`/messages/${findChannel.name}`, { state: { refer: 'look-around' } })
					} else if (pathName.split('/')[1] === 'browse-profiles') {
						navigate(`/messages/${findChannel.name}`, {
							state: { sportCode: sportCode, refer: 'browse-profiles' }
						})
					}
				} else {
					navigate(`/messages/${findChannel.name}`)
				}
			} else {
				const payload = {
					type: 'direct',
					userAlias: friendAlias
				}
				ChatService.createChannel(payload).then(response => {
					if (refer) {
						if (pathName.split('/')[1] === 'look-around') {
							navigate(`/messages/${response.data}`, { state: { refer: 'look-around' } })
						} else if (pathName.split('/')[1] === 'browse-profiles') {
							navigate(`/messages/${response.data}`, {
								state: { sportCode: sportCode, refer: 'browse-profiles' }
							})
						} else {
							navigate(`/messages/${response.data}`)
						}
					} else {
						navigate(`/messages/${response.data}`)
					}
					getChannels()
				})
			}
		} else {
			openDialog()
		}
	}

	const openDialog = useCallback(() => setIsDialogOpen(true), [])
	const closeDialog = useCallback((e, shouldRedirect = false) => {
		setIsDialogOpen(false)
		if (shouldRedirect) awsSignIn(location.pathname)
	}, [])
	return (
		<Box
			sx={{
				mt: sportCode === 'All' ? '-36px' : '0px',
				pt: size && size !== 'large' && '11px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'end'
			}}
		>
			<Button
				sx={{
					marginBottom: sportCode === 'All' && '15px',
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					background: disableButtons ? '#9FA4B4' : '#00C6AE',
					color: 'white',
					fontSize: size && size === 'large' ? '16px' : '12px',
					fontWeight: '700',
					height: '24px',
					'&:hover': {
						backgroundColor: disableButtons ? 'gray' : '#00C6AE'
					},
					padding: size && size === 'large' && '15px'
				}}
				onClick={() => !disableButtons && redirectToChannel()}
			>
				{showIcon && (
					<ChatBubbleOutlineOutlinedIcon sx={{ width: '13px', height: '13px', mr: '5px' }} />
				)}
				<Box mt={'-2px'}>Message</Box>
			</Button>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				aria-labelledby="redirect-dialog"
				aria-describedby="redirect-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Sign-in to message {userDetails?.name}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>Cancel</Button>
					<Button onClick={() => closeDialog(null, true)} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}

export default MessageButton
