import React, { createContext, useEffect, useState, useCallback } from 'react'
import { uniqBy, orderBy } from 'lodash'
import Pubnub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'

import { useAuth } from './auth-context'
import ChatService from '../services/ChatService'

export const ChatContext = createContext({})

const pubnubClient = new Pubnub({
	publishKey: process.env.REACT_APP_PUBLISH_KEY_PUBNUB,
	subscribeKey: process.env.REACT_APP_SUBSCRIBE_KEY_PUBNUB,
	uuid: 'anonymous'
})

export const ChatProvider = props => {
	const { user, isLoggedIn } = useAuth()
	const [chatState, setChatState] = useState({
		groups: [],
		chats: {},
		activeChannelId: undefined,
		gotNewMessage: false
	})
	const [loaders, setLoaders] = useState({
		isGroupLoading: false
	})
	const [channelList, setChannelList] = useState([])

	const getChannelDetailsByName = useCallback(
		channelId => {
			const findChannel = chatState.groups.find(channel => channel.name === channelId)

			return findChannel
		},
		[chatState]
	)

	useEffect(() => {
		const listener = {
			status: statusEvent => {
				// console.log('statusEvent', statusEvent)
				if (statusEvent.category === 'PNConnectedCategory') {
					console.log('Connected')
				}
			},
			presence: presenceEvent => {
				const { channel, action, uuid } = presenceEvent
				console.log(`User ${uuid} ${action}ed channel ${channel}`)
			},
			message: messageEvent => {
				const timeStamp = messageEvent.message.timestamp.replace(' ', 'T')
				const updatedMessage = {
					...messageEvent.message,
					timestamp: timeStamp
				}

				setChatState(oldState => ({ ...oldState, gotNewMessage: true }))

				addChatsToChannel({
					chats: [(messageEvent.message = updatedMessage)],
					channel: messageEvent.channel
				})
				return
			}
		}
		pubnubClient?.addListener?.(listener)

		return () => {
			pubnubClient?.removeListener?.(listener)
		}
	})

	useEffect(() => {
		if (user && isLoggedIn) {
			if (user?.metaData?.pubnubUUID) {
				window.pubnub = pubnubClient
				pubnubClient.setUUID(user.metaData.pubnubUUID)
			} else {
				pubnubClient.setUUID('anonymous')
			}
			getChannels()
		}
	}, [user, isLoggedIn])

	const getChannels = async () => {
		setLoaders(loaders => ({ ...loaders, isGroupLoading: true }))
		if (user?.alias !== undefined) {
			ChatService.getAllChannels()
				.then(response => {
					setChatState(state => ({ ...state, groups: response.data.content }))

					const newChannelNames = response.data.content.map(channel => channel.name)
					subscribeToChannels(newChannelNames)
				})
				.finally(() => {
					setLoaders(loaders => ({ ...loaders, isGroupLoading: false }))
				})
		}
	}

	const subscribeToChannels = channels => {
		const channelsToSubscribe = channels.filter(channelName => !channelList.includes(channelName))

		setChannelList(prevList => [...prevList, ...channelsToSubscribe])
		pubnubClient.subscribe({
			channels: channelsToSubscribe,
			withPresence: true
		})
	}

	const addChatsToChannel = useCallback(
		({ chats, channel }) => {
			const oldMessages = chatState.chats[channel] || []
			const newMessages = uniqBy([...oldMessages, ...chats], 'messageId')
			const orderDesc = orderBy(newMessages, 'timestamp', 'asc')
			setChatState(oldState => ({ ...oldState, chats: { [channel]: orderDesc } }))
		},
		[chatState, setChatState]
	)
	useEffect(() => {
		let timeout
		if (chatState.gotNewMessage) {
			timeout = setTimeout(() => {
				setChatState(oldState => ({ ...oldState, gotNewMessage: false }))
			}, 2000)
		}
		return () => {
			clearTimeout(timeout)
		}
	}, [chatState, setChatState])

	return (
		<PubNubProvider client={pubnubClient}>
			<ChatContext.Provider
				value={{
					chatState,
					setChatState,
					loaders,
					addChatsToChannel,
					getChannelDetailsByName,
					getChannels
				}}
			>
				{props.children}
			</ChatContext.Provider>
		</PubNubProvider>
	)
}
