import React, { useState, useEffect } from 'react'

import toast, { Toaster } from 'react-hot-toast'

import { Sendrequest } from './Sendrequest'
import { onMessageListener } from './firebase'
import { useNavigate } from 'react-router-dom'

import notificationServiceInstance from '../../services/NotificationService'

const PushNotification = () => {
	const [notification, setNotification] = useState({
		title: '',
		body: '',
		notificationType: null,
		metaData: null
	})

	useEffect(() => {
		onMessageListener()
			.then(payload => {
				setNotification({
					title: payload?.notification?.title,
					body: payload?.notification?.body,
					notificationType: payload?.data?.notificationType,
					metaData: JSON.parse(
						payload?.notification?.metaData ? payload?.notification?.metaData : null
					)
				})
			})
			.catch(err => console.log('failed: ', err))
	}, [])

	useEffect(() => {
		if (notification?.title) {
			notify()
		}
	}, [notification])

	const notify = () =>
		toast(<ToastDisplay notification={notification} setNotification={setNotification} />)

	if (notification.title || notification.body) {
		return <Toaster /> // The <Toaster /> should be inside the return block.
	}
}

function ToastDisplay({ notification, setNotification }) {
	const navigate = useNavigate()

	return (
		<div
			onClick={() => {
				if (
					notification.notificationType ===
					notificationServiceInstance.NOTIFICATION_TYPES.CHAT_MESSAGE
				) {
					navigate('/messages')
				} else {
					navigate('/notifications')
				}
				setNotification({ title: '', body: '' })
			}}
		>
			<p>
				<b>{notification?.title}</b>
			</p>

			<p>{notification?.body}</p>
		</div>
	)
}

export default PushNotification
