import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import SportSkills from "./step3/SportSkills";

const Step3 = (props) => {
  return (
    <React.Fragment>
      <Typography align="center">Proficient Skills</Typography>
      <SportSkills skills={props.sport.sportSkills} />
    </React.Fragment>
  );
};

export default Step3;
