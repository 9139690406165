import { Box, Stack, TextField, Typography } from '@mui/material'
import { ErrorMessage, FieldArray, useField } from 'formik'
import { useEffect, useState } from 'react'
import ErrorMessageBL from '../../components/ui/ErrorMessageBL'

const SetSessionType = ({ initialFormValues }) => {
	const defaultSessions = [
		{ name: 'Easy and chill 😌', selected: false },
		{ name: 'Social 🗣', selected: false },
		{ name: 'Push hard 💪🏼', selected: false },
		{ name: 'Competition 🏅', selected: false },
		{ name: 'Training 🏋️', selected: false },
		{ name: 'Join a club/team 🤝', selected: false },
		{ name: 'Take a class 🧙🏼', selected: false },
		{ name: 'Take guided trips 🗺️', selected: false }
	]
	const [sessions, setSessions] = useState(defaultSessions)
	const [shareMoreText, setShareMoreText] = useState('')
	const [fieldSession, metaSession, helperSession] = useField('sessionTypes')

	const [fieldshareMore, metashareMore, helpershareMore] = useField('biography')

	useEffect(() => {
		if (fieldSession?.value?.length > 0) {
			const updatedSessions = defaultSessions.map(session => {
				if (fieldSession?.value.includes(session.name)) {
					return { ...session, selected: true }
				} else {
					return { ...session, selected: false }
				}
			})
			setSessions(updatedSessions)
		} else if (initialFormValues?.sessionTypes?.length > 0) {
			const updatedSessions = defaultSessions.map(session => {
				if (initialFormValues?.sessionTypes.includes(session.name)) {
					return { ...session, selected: true }
				} else {
					return { ...session, selected: false }
				}
			})
			setSessions(updatedSessions)
			const activeSession = updatedSessions.filter(obj => obj.selected === true)
			const getSessionName =
				activeSession?.length > 0 ? activeSession.map(act_session => act_session.name) : []

			helperSession.setValue(getSessionName)
		}
		if (fieldshareMore?.value && fieldshareMore?.value !== '') {
			setShareMoreText(fieldshareMore.value)
		} else if (initialFormValues?.biography) {
			setShareMoreText(initialFormValues.biography)
			helpershareMore.setValue(initialFormValues.biography)
		}
	}, [initialFormValues])

	const handleSelectSession = (session, i, arrayHelpers) => {
		var sessionObj
		if (session.selected) {
			sessionObj = { ...session, selected: false }
		} else {
			sessionObj = { ...session, selected: true }
		}
		sessions.splice(i, 1, sessionObj)
		setSessions([...sessions])
		const activeSession = sessions.filter(obj => obj.selected === true)
		const getSessionName =
			activeSession?.length > 0 ? activeSession.map(act_session => act_session.name) : []

		helperSession.setValue(getSessionName)
	}
	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="h1b">What are the types of sessions you want to find?</Typography>
			<Box className="d-flex" sx={{ flexWrap: 'wrap' }}>
				<FieldArray
					name="sessionTypes"
					render={arrayHelpers => {
						return sessions.map((session, index) => (
							<Box
								key={index}
								className="d-flex aic"
								sx={{
									background: session.selected ? '#61e4c5' : '#EEEFF4',
									borderRadius: '20px',
									cursor: 'pointer',
									margin: '7px'
								}}
								px="15px"
								py="3px"
							>
								<Box
									sx={{
										color: '#000000',
										fontWeight: '700',
										fontSize: '13px',
										borderRadius: '20px',
										width: 'fit-content'
									}}
									onClick={() => handleSelectSession(session, index, arrayHelpers)}
								>
									{session.name}
								</Box>
							</Box>
						))
					}}
				/>
			</Box>
			{metaSession.touched && metaSession.error && (
				<Typography color="error" variant="caption" sx={{ paddingLeft: '10px' }}>
					{metaSession.error}
				</Typography>
			)}

			<Stack>
				<TextField
					multiline
					rows={2}
					placeholder="Share more about you and what you look for (optional)"
					inputProps={{ maxLength: 250 }}
					onChange={e => {
						setShareMoreText(e.target.value)
						helpershareMore.setValue(e.target.value.trim())
					}}
					value={shareMoreText}
				/>
				<Box sx={{ textAlign: 'end', color: 'gray', fontSize: '14px', marginTop: '5px' }}>
					({shareMoreText.length}/250)
				</Box>
			</Stack>
		</Stack>
	)
}

export default SetSessionType
