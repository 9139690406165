import { useState, useEffect, useCallback } from 'react'
import notificationServiceInstance from '../services/NotificationService'
import { useAuth } from '../store/auth-context'
import ChatService from '../services/ChatService'

const useUnreadCount = () => {
	const [unreadNotifyCount, setUnreadNotifyCount] = useState(0)
	const [unreadMsgCount, setUnreadMsgCount] = useState(0)
	const { isLoggedIn } = useAuth()

	const fetchUnreadNotifyCount = useCallback(async () => {
		if (!isLoggedIn) {
			return // Exit if not logged in
		}

		try {
			const response = await notificationServiceInstance.countUnreadNotifications()
			setUnreadNotifyCount(response)
		} catch (error) {
			console.error('Failed to fetch unread count:', error)
		}
	}, [isLoggedIn])

	const fetchUnreadMsgCount = useCallback(async () => {
		if (!isLoggedIn) {
			return // Exit if not logged in
		}

		try {
			const response = await ChatService.countUnreadMessages()
			setUnreadMsgCount(response)
		} catch (error) {
			console.error('Failed to fetch unread count:', error)
		}
	}, [isLoggedIn])
	useEffect(() => {
		fetchUnreadNotifyCount()

		if (isLoggedIn) {
			// Set an interval to fetch the unread count every 2 minutes
			const intervalNotifyId = setInterval(fetchUnreadNotifyCount, 120000) // 120000 milliseconds = 2 minutes

			// Cleanup interval on component unmount
			return () => {
				clearInterval(intervalNotifyId)
			}
		}
	}, [isLoggedIn, fetchUnreadNotifyCount])

	useEffect(() => {
		fetchUnreadMsgCount()

		if (isLoggedIn) {
			// Set an interval to fetch the unread count every 2 minutes
			const intervalmsgId = setInterval(fetchUnreadMsgCount, 120000) // 120000 milliseconds = 2 minutes

			// Cleanup interval on component unmount
			return () => {
				clearInterval(intervalmsgId)
			}
		}
	}, [isLoggedIn, fetchUnreadMsgCount])

	return {
		unreadNotifyCount,
		unreadMsgCount,
		refetchNotify: fetchUnreadNotifyCount,
		refetchMsgCount: fetchUnreadMsgCount
	}
}

export default useUnreadCount
