import { Button, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as TickIcon } from '../../assets/comment/tick.svg'

const CommentInput = ({
	handleCommentSubmit,
	handleCommentToSubmitChange,
	enableSubmitButton,
	value
}) => {
	const maxLength = 100
	return (
		<div>
			<OutlinedInput
				fullWidth
				placeholder="What did you do today?"
				multiline
				maxRows={2}
				inputProps={{ maxLength: maxLength }}
				endAdornment={
					<InputAdornment position="end">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								handleCommentSubmit()
							}}
							disabled={!value || !enableSubmitButton}
							sx={{ background: '#00C6AE', padding: '5px 16px' }}
						>
							<TickIcon />
						</Button>
					</InputAdornment>
				}
				size="small"
				sx={{ background: '#FFFFFF', borderRadius: '100px', paddingRight: '0px' }}
				value={value}
				disabled={!enableSubmitButton}
				onChange={e => handleCommentToSubmitChange(e.target.value)}
			/>
			<Typography
				variant="note"
				color="#FFFFFF"
				sx={{ float: 'right', margin: '5px 20px 0px 0px' }}
			>
				{value.length}/{maxLength}
			</Typography>
		</div>
	)
}

export default CommentInput
