import React, { useContext } from 'react'
import { get, last, sortBy } from 'lodash'
import moment from 'moment'
import { Avatar, Box, Stack, Typography, CircularProgress, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ChatContext } from '../../store/chat-context'
import { useAuth } from '../../store/auth-context'
import { getChatNameAndPhoto } from '../../shared/ChatUtils'
import DateUtils from '../../shared/DateUtils'
import { loadingButtonClasses } from '@mui/lab'
import BottomNavigation from '../../components/ui/BottomNavigation'
import NoGroupChatFound from './NoGroupChatFound'
import { ArrowBackIos } from '@mui/icons-material'

const UNREAD_MESSAGE_COLOR = 'rgba(97, 228, 197, 0.50)'

const lastMessageByUser = (participants, lastMessage) => {
	const sendBy = participants.find(participant => participant?.alias === lastMessage?.senderAlias)

	return sendBy?.name
}

const GroupChat = () => {
	const { chatState, loaders } = useContext(ChatContext)
	const { user } = useAuth()
	const navigate = useNavigate()

	const handleOnclick = channelId => {
		navigate('/messages/' + channelId)
	}

	const lastMessageInChat = group => {
		let lastMessage = group?.lastMessage

		if (chatState.chats?.[group.name]) {
			lastMessage = last(chatState.chats?.[group.name])
		}

		return lastMessage
	}

	const sortedGroups = sortBy(chatState.groups, group => {
		const lastMessage = lastMessageInChat(group)
		return lastMessage ? -moment(lastMessage.timestamp).valueOf() : 0
	})

	// console.log(' -*-*-** ', sortedGroups)

	return (
		<Box
			sx={{
				paddingTop: '20px',
				paddingBottom: '70px',
				position: 'relative',
				maxWidth: '552px',
				margin: 'auto'
			}}
		>
			{loaders.isGroupLoading ? (
				<Box display={'flex'} alignItems="center" justifyContent="center">
					<CircularProgress />
				</Box>
			) : sortedGroups?.length > 0 &&
			  sortedGroups?.filter(message => message?.lastMessage).length > 0 ? (
				sortedGroups
					?.filter(message => message?.lastMessage) // to remove blank chat user's
					.map(group => (
						<Stack
							flexDirection="row"
							gap={2}
							key={'chat_list_' + group.name}
							sx={{
								borderBottom: '1px solid #bebebe',
								padding: '10px',
								cursor: 'pointer',
								backgroundColor: group.isUnread ? UNREAD_MESSAGE_COLOR : 'transparent',
								'&:hover': {
									backgroundColor: 'rgba(97, 228, 197, 0.50)'
								}
							}}
							onClick={() => handleOnclick(group.name)}
						>
							<Avatar
								alt={getChatNameAndPhoto(group.participants, user)?.name}
								src={getChatNameAndPhoto(group.participants, user)?.photo}
							/>
							<Stack
								sx={{
									width: '100%'
								}}
							>
								<Typography variant="h3" className="fw-700">
									{getChatNameAndPhoto(group.participants, user)?.name || 'N/A'}
								</Typography>
								<Stack
									sx={{
										flexDirection: 'row',
										justifyContent: 'space-between',
										gap: '10px',
										width: '100%'
									}}
								>
									<Typography
										variant="subtitle1"
										sx={{
											fontStyle: 'normal',
											flex: 1,
											textAlign: 'left',
											width: '156px'
										}}
										noWrap
									>
										{lastMessageByUser(group.participants, lastMessageInChat(group))}:{' '}
										{get(lastMessageInChat(group), 'content', '')}
									</Typography>
									<Typography
										variant="subtitle1"
										sx={{
											fontStyle: 'normal'
										}}
									>
										{lastMessageInChat(group) &&
											DateUtils.showDateOfLastMessage(
												get(lastMessageInChat(group), 'timestamp', undefined)
											)}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					))
			) : (
				<Box sx={{ width: '100%', height: '100vh' }}>
					<NoGroupChatFound />
				</Box>
			)}
			{/* {!loaders.isGroupLoading && } */}
			<BottomNavigation lookArround={false} addStatus={false} message={true} />
		</Box>
	)
}

export default GroupChat
