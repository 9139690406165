import editUserFormModel from './editUserFormModel'

const {
	formField: { biography, location, name, userImage, hometown }
} = editUserFormModel

const editUserInitialValues = {
	[biography.name]: '',
	[location.name]: null,
	[name.name]: '',
	[userImage.name]: null,
	[hometown.name]: null
}

export default editUserInitialValues
