import React from 'react'
import { Box, Stack } from '@mui/system'
import { isEqual } from 'lodash'
import MessageListByDates from './MessageListByDates'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Typography } from '@mui/material'

const MessageListContainer = React.memo(
	({ messages, messagesLength, handleScroll, hasMore, chatContainerRef }) => {
		return (
			<Box
				id="scroller"
				ref={chatContainerRef}
				sx={{
					overflow: 'auto',
					display: 'flex',
					flexDirection: 'column-reverse',
					height: 'calc(100dvh - 191px)',
					backgroundColor: 'white'
				}}
			>
				<InfiniteScroll
					dataLength={messagesLength}
					next={handleScroll}
					hasMore={hasMore}
					inverse={true}
					style={{ display: 'flex', flexDirection: 'column-reverse' }}
					scrollableTarget="scroller"
					loader={
						<Typography variant="p" style={{ textAlign: 'center', marginTop: '10px' }}>
							Loading...
						</Typography>
					}
				>
					<Stack
						sx={{
							justifyContent: 'flex-end',
							padding: '10px'
						}}
					>
						{Object.keys(messages).map(date => (
							<MessageListByDates key={date} messagesByDateGroup={messages[date]} date={date} />
						))}
					</Stack>
				</InfiniteScroll>
			</Box>
		)
	},
	(pp, np) => isEqual(pp, np)
)

export default MessageListContainer
