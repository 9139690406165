import { Avatar, Box, Button, IconButton, MenuItem, Select, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import useNotification from '../../hooks/useNotification'
import { ContentCopy } from '@mui/icons-material'
import { useAuth } from '../../store/auth-context'
import useShare from '../../hooks/useShare'

const ShareSportProfile = ({ user: userProp, message }) => {
	const { user: authUser } = useAuth()
	const [user, setUser] = useState(userProp || authUser)
	const [sportCode, setSportCode] = useState(user?.sports[0]?.code)

	useEffect(() => {
		if (userProp) {
			setUser(userProp)
		} else {
			setUser(authUser)
		}
	}, [userProp, authUser])

	const profileUrl = `${process.env.REACT_APP_URL}/p/${user.alias}/${sportCode}`

	const [, sendNotification] = useNotification()

	const handleShareButton = useShare({
		url: profileUrl,
		title: `${user.name}'s Profile`,
		text: message
	})

	return (
		<Box sx={{ padding: '20px 2px' }}>
			<Box align="center" marginBottom={2} fontWeight={'700'} fontSize={'21px'}>
				{`${user.name}'s Sports Profile`}
				{/*  -${user?.sports[0]?.name}`} */}
			</Box>
			<Stack container="true" alignItems={'center'} spacing={3} direction="column">
				<Select
					value={sportCode}
					sx={{ width: '100%' }}
					onChange={event => setSportCode(event.target.value)}
				>
					{user.sports.map(sport => (
						<MenuItem key={sport.code} value={sport.code}>
							{sport.name}
						</MenuItem>
					))}
				</Select>
				<Avatar sx={{ width: 200, height: 200, bgcolor: '#474A57' }}>
					<div style={{ background: 'white', padding: '2px' }}>
						<QRCode size={100} value={profileUrl}></QRCode>
					</div>
				</Avatar>
				<TextField
					helperText="Anyone with the link will be able to see your profile"
					contentEditable={false}
					value={profileUrl}
					InputProps={{
						endAdornment: (
							<IconButton
								edge="end"
								onClick={() => {
									navigator.clipboard.writeText(profileUrl)
									sendNotification({
										msg: 'Copied to clipboard',
										variant: 'info'
									})
								}}
							>
								<ContentCopy style={{ color: '#00C6AE' }} />
							</IconButton>
						)
					}}
				/>
				<Stack direction="row" spacing={4}>
					<Button
						variant="contained"
						sx={{ background: '#00C6AE', fontWeight: '700', fontSize: '15px' }}
						onClick={handleShareButton}
					>
						Share
					</Button>
				</Stack>
			</Stack>
		</Box>
	)
}

export default ShareSportProfile
