import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import './ImageSlider.css'
import MyImg from '../ui/MyImg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useAuth } from '../../store/auth-context'

const ImageSlider = props => {
	const { showPencilIcon } = props
	const photoRef = useRef(null)
	const [nav1, setNav1] = useState(null)
	const [nav2, setNav2] = useState(null)
	const navigate = useNavigate()
	const { user } = useAuth()
	const params = useParams()
	const urlLocation = useLocation()
	const pathName = urlLocation?.pathname?.split('/')[1]

	useEffect(() => {
		setNav1(slider1)
		setNav2(slider2)
	}, [])

	let slider1 = null
	let slider2 = null

	const top_images_settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: props.SliderData?.length >= 3 ? 3 : props.SliderData?.length,
		slidesToScroll: 1,
		cssEase: 'linear',
		autoplaySpeed: 2000,
		pauseOnHover: true,
		swipeToSlide: true,
		focusOnSelect: true
	}
	const big_image_settings = {
		adaptiveHeight: true
	}

	const handlePhotoClick = () => {
		const photoRect = photoRef.current.getBoundingClientRect()

		const targetScrollY = window.scrollY + photoRect.top - 70
		const scrollAnimation = () => {
			const duration = 100
			let start

			function step(timestamp) {
				if (!start) start = timestamp
				const progress = (timestamp - start) / duration

				if (progress < 1) {
					window.scrollTo({
						top: targetScrollY,
						behavior: 'smooth'
					})
					window.requestAnimationFrame(step)
				} else {
					window.scrollTo(0, targetScrollY)
				}
			}

			window.requestAnimationFrame(step)
		}
		scrollAnimation()
	}

	return (
		<div ref={photoRef} onClick={handlePhotoClick} style={{ position: 'relative' }}>
			{showPencilIcon && pathName !== 'browse-profiles' && params?.alias === user?.alias && (
				<Button
					id="basic-button"
					sx={{ position: 'absolute', top: '0', right: '-10px', zIndex: '1' }}
					aria-haspopup="true"
					onClick={() => navigate(`/p/${user?.alias}/actionphotos`)}
				>
					<EditIcon />
				</Button>
			)}
			<Slider asNavFor={nav1} ref={slider => (slider2 = slider)} {...top_images_settings}>
				{props.SliderData?.length > 1 &&
					props.SliderData.map((img, i) => {
						return (
							<div key={i}>
								<MyImg
									src={img.viewUrl}
									className="cssTopTmages"
									alt={`slider-top`}
									width="100%"
									height="158px"
								/>
							</div>
						)
					})}
			</Slider>
			{showPencilIcon && pathName !== 'browse-profiles' && params?.alias === user?.alias && (
				<Button
					id="basic-button"
					sx={{ position: 'absolute', top: '10', right: '-10px', zIndex: '1' }}
					aria-haspopup="true"
					onClick={() => navigate(`/p/${user?.alias}/actionphotos`)}
				>
					<EditIcon />
				</Button>
			)}
			<Slider asNavFor={nav2} ref={slider => (slider1 = slider)} {...big_image_settings}>
				{props.SliderData.map((img, i) => {
					return (
						<div key={i}>
							<MyImg
								src={img.viewUrl}
								className="cssBigImage"
								alt={`slider-top`}
								width="-webkit-fill-available"
								height="265px"
								marginBottom={'30px'}
							/>
						</div>
					)
				})}
			</Slider>
		</div>
	)
}

export default ImageSlider
