import { Box, Checkbox, Divider, Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import radarImg from '../../assets/icon/radar.png'
import { useField } from 'formik'

const NotificationSetting = () => {
	const [fieldpushNotifications, , helperpushNotification] = useField('pushNotifications')
	const [fieldEmailNotifications, , helperEmailNotifications] = useField('emailNotifications')
	const [fieldNewFeatureNotification, , helperNewFeatureNotification] =
		useField('newFeatureNotification')

	return (
		<Stack direction="column" spacing={3}>
			<Box>
				<Typography
					sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: '45px', fontSize: '20px' }}
				>
					Your buddy Radar is on!
				</Typography>
			</Box>
			<Box sx={{ textAlign: 'center', marginTop: '22px' }}>
				<img src={radarImg} alt={'Radar'} loading="lazy" width="90px" />
			</Box>
			<Box sx={{ fontSize: '16px', padding: '10px 40px 0 40px' }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="p" fontSize="16px">
						Push Notification
					</Typography>
					<Switch
						name="pushNotification"
						checked={fieldpushNotifications.value}
						onChange={e => helperpushNotification.setValue(e.target.checked)}
					/>
				</Stack>
				<Stack direction="row" justifyContent="space-between" alignItems="center" mt="12px">
					<Typography variant="p" fontSize="16px">
						Email Notification
					</Typography>
					<Switch
						checked={fieldEmailNotifications.value}
						onChange={e => helperEmailNotifications.setValue(e.target.checked)}
					/>
				</Stack>
			</Box>
			<Typography variant="p" sx={{ padding: '0 26px 15px', textAlign: 'center' }}>
				You will be notified when people are going at your preferred time or places.
			</Typography>
			<Divider />
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
				padding="10px 25px"
			>
				<Checkbox
					checked={fieldNewFeatureNotification.value}
					onChange={e => helperNewFeatureNotification.setValue(e.target.checked)}
				/>
				<Typography variant="p">Let me know about new features, promotions, or events.</Typography>
			</Box>
		</Stack>
	)
}

export default NotificationSetting
