import React from 'react'
import { Typography, Grid, Button, Stack } from '@mui/material'
import { useField } from 'formik'
import SportService from '../../services/SportService'
import BeginnerImage from '../../assets/sport/beginner.png'
import IntermediateImage from '../../assets/sport/intermediate.png'
import AdvancedImage from '../../assets/sport/advanced.png'
import ExpertImage from '../../assets/sport/expert.png'
import { getSportStyleLevelColor } from '../../constants/levelColors'
import MyButton from '../../components/ui/MyButton'

const levelImages = {
	Beginner: BeginnerImage,
	Intermediate: IntermediateImage,
	Advanced: AdvancedImage,
	Expert: ExpertImage
}

const SetLevel = () => {
	const [fieldLevel, metaLevel, helperLevel] = useField('level')

	const handleLevelChange = level => {
		helperLevel.setValue(level)
	}

	const isSelected = level => fieldLevel.value === level // Check if a button has been selected

	return (
		<Stack direction="column" spacing={3} mb={'120px'}>
			<Typography variant="h1b" sx={{ fontWeight: 'bold' }}>
				Choose a level that best describes you
			</Typography>
			<Grid
				container
				columnGap={1}
				justifyContent="center"
				flexDirection={'column'}
				alignItems={'center'}
			>
				{Object.keys(SportService.Level).map((key, index) => (
					<Grid key={key} item xs={6} sm={3} style={{ maxWidth: '100%' }}>
						<MyButton
							onClick={() => handleLevelChange(key)}
							variant={isSelected(key) ? 'contained' : 'outlined'}
							sx={{
								width: '200px',
								marginBottom: '20px',
								backgroundColor: isSelected(key) ? getSportStyleLevelColor(index + 1) : 'white',
								color: isSelected(key) ? 'white' : 'black'
							}}
						>
							<div style={{ marginRight: '10px' }}>
								<img src={levelImages[key]} alt={`${key} level`} width={60} height={60} />
							</div>
							<div>{key}</div>
						</MyButton>
					</Grid>
				))}
			</Grid>
			{metaLevel.touched && metaLevel.error && (
				<Typography color="error" variant="caption" style={{ marginTop: '-15px' }}>
					{metaLevel.error}
				</Typography>
			)}

			<Typography variant="p">
				This just gives people a general idea about your level! We will be more specific in the
				following steps.{' '}
			</Typography>
		</Stack>
	)
}

export default SetLevel
