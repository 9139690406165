import { Avatar, Button, Stack, Typography, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ChallengeService from '../../../services/ChallengeService'
import ChallengeItem from '../list/ChallengeItem'

const ChallengeWinnerPage = () => {
	const { alias } = useParams()
	const navigate = useNavigate()
	const [challenge, setChallenge] = useState()
	const [selectedWinners, setSelectedWinners] = useState([])

	// fetch challenge data on init
	useEffect(() => {
		ChallengeService.getChallengeByAlias(alias).then(response => setChallenge(response.data))
	}, [alias])

	const handleSelectWinner = winner => {
		setSelectedWinners(prev => {
			if (prev.includes(winner)) {
				prev.splice(prev.indexOf(winner), 1)
			} else {
				prev.push(winner)
			}
			return [...prev]
		})
	}

	const handleConfirm = () => {
		ChallengeService.updateChallengeWinners(alias, { aliases: selectedWinners }).then(() => {
			navigate(`/c/${alias}/result`)
		})
	}

	return (
		<Stack textAlign="center" alignItems="center" spacing={4}>
			<Typography variant="h1" fontWeight="bold">
				WHO WON
				<br />
				THE CHALLENGE?
			</Typography>

			{challenge && <ChallengeItem challenge={challenge} overrideRouteToDetails={true} />}

			<Grid container justifyContent="center" space={2}>
				{challenge?.participants.map(p => {
					const { name, userImage, alias } = p
					const isSelected = selectedWinners.includes(alias)
					return (
						<Grid
							key={alias}
							item
							xs={4}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Avatar
								alt={name}
								src={userImage?.uploadBase64Data || userImage?.viewUrl}
								sx={{
									margin: '5px',
									width: '59px',
									height: '59px',
									boxShadow: isSelected ? '0 0 0 3px #29b4ab' : ''
								}}
							/>
							<Typography sx={{ margin: '4px 0 16px 0' }}>{name}</Typography>
							<Button
								variant={isSelected ? 'blGrey' : 'blGreen'}
								onClick={() => handleSelectWinner(alias)}
							>
								{isSelected ? 'Cancel' : 'Select'}
							</Button>
						</Grid>
					)
				})}
			</Grid>

			<Button
				variant="blGreen"
				disabled={selectedWinners.length === 0}
				sx={{ width: '186px' }}
				onClick={handleConfirm}
			>
				Confirm
			</Button>
		</Stack>
	)
}

export default ChallengeWinnerPage
