import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { FieldArray, useField, useFormikContext } from 'formik'
import SportStyleRating from '../../components/user/SportStyleRating'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { grey } from '@mui/material/colors'
import { useEffect } from 'react'

const SetSportStyles = ({ styles }) => {
	// Access Formik's helper methods
	const { setFieldValue, values } = useFormikContext()

	// Use an effect to reorder the Formik array once when the component mounts
	// or when 'styles' changes.
	useEffect(() => {
		// Create a new ordered array of sportStyles based on the order of 'styles'
		const orderedSportStyles = styles.map(
			style => values.sportStyles.find(sportStyle => sportStyle.id === style.id) || style
		)

		// Update the Formik state with the new ordered array
		setFieldValue('sportStyles', orderedSportStyles)
	}, [styles])
	return (
		<Stack direction="column" spacing={2} pb={'90px'}>
			<Typography variant="h1b">
				Which of the following do you practice and your proficiency?
			</Typography>

			<FieldArray
				name="sportStyles"
				render={arrayHelpers => {
					return styles.sort().map((style, index) => (
						<Grid container direction="row" key={`style-${index}`} alignItems="center">
							<Grid item xs={8} width="100%">
								<SportStyleTypography style={style} index={index} />
							</Grid>
							<Grid item xs={4}>
								<Stack direction="row">
									<SportStyle style={style} index={index} arrayHelpers={arrayHelpers} />
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
						</Grid>
					))
				}}
			/>

			<Typography variant="subtitle1">Something is missing for your sport?</Typography>

			<Typography variant="link1">Make a suggestion</Typography>
		</Stack>
	)
}
const SportStyleTypography = ({ style, index }) => {
	const [field, ,] = useField(`sportStyles.${index}.proficiency`)

	return (
		<Typography
			variant="h4"
			backgroundColor={field.value ? 'primary.light' : grey[200]}
			color={!field.value ? grey[500] : 'black'}
			padding="0px 5px"
			margin="2px 5px"
			display="inline-block"
		>
			{style.name}
		</Typography>
	)
}

const SportStyle = ({ style, index, arrayHelpers }) => {
	const [field, ,] = useField(`sportStyles[${index}]proficiency`)

	const handlePlus = () => {
		const newProfiency = field.value !== undefined ? field.value + 1 : 1
		if (!inRange(newProfiency)) {
			return
		}
		arrayHelpers.replace(index, { id: style.id, proficiency: newProfiency })
	}

	const handleMinus = () => {
		const newProfiency = field.value !== undefined ? field.value - 1 : 0
		if (!inRange(newProfiency)) {
			return
		}
		arrayHelpers.replace(index, { id: style.id, proficiency: newProfiency })
	}

	const inRange = number => {
		if (number >= 0 && number <= 4) {
			return true
		}
	}

	return (
		<React.Fragment>
			<IconButton aria-label="minus" onClick={handleMinus} disabled={field.value === 0}>
				<RemoveIcon />
			</IconButton>
			<SportStyleRating proficiency={field.value} />
			<IconButton aria-label="plus" onClick={handlePlus} disabled={field.value === 4}>
				<AddIcon />
			</IconButton>
		</React.Fragment>
	)
}

export default SetSportStyles
