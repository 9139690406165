import {
	Avatar,
	Box,
	Button,
	Chip,
	CircularProgress,
	Grid,
	Menu,
	MenuItem,
	Modal,
	Stack,
	Typography
} from '@mui/material'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getLevelColor } from '../../constants/levelColors'
import SportStyleRating from './SportStyleRating'
import ProfileService from '../../services/ProfileService'
import DateUtils from '../../shared/DateUtils'
import { useMemo } from 'react'
import UserQuestionnaire from './UserQuestionnaire'
import SportStatus from '../sport/SportStatus'
import EditIcon from '@mui/icons-material/Edit'
import Clear from '@mui/icons-material/Clear'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../store/auth-context'
import { Close, CloseOutlined } from '@mui/icons-material'
import SportService from '../../services/SportService'
import useNotification from '../../hooks/useNotification'
import UserService from '../../services/UserService'
import SportSessionTypes from './SportSessionTypes'
import { LoadingContext } from '../../store/loading-context'
import Loading from '../ui/Loading'

const SportView = ({
	alias,
	sportCode,
	hasPaddedView = true,
	minimalView = false,
	showStatus = true,
	showEdit = true,
	showUserImage = false,
	userName,
	userImageUrl,
	profileDetails = false,
	showSessionTypes = false,
	sportProfile = false,
	showPencilIcon,
	indexPosition = false
}) => {
	const [profile, setProfile] = useState(null)
	const navigate = useNavigate()
	const { isLoggedIn, user } = useAuth()
	const [anchorEl, setAnchorEl] = useState(null)
	const deletPopupOpen = Boolean(anchorEl)
	const [, sendNotification] = useNotification()
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [showDeleteSuccessfullScreen, setShowDeleteSuccessfullScreen] = useState(false)
	const [isLoading, setIsLoading] = useContext(LoadingContext)
	const [bgImageLoaded, setBgImageLoaded] = useState(false)
	const [sportBgLoaded, setSportBgLoaded] = useState(false)

	useEffect(() => {
		if (userImageUrl) {
			setBgImageLoaded(false)
			const img = new Image()
			img.src = userImageUrl
			if (userImageUrl) {
				img.onload = () => {
					setBgImageLoaded(true)
				}
			} else {
				setBgImageLoaded(true)
			}
			setTimeout(() => {
				setBgImageLoaded(true)
			}, 1500)
		} else {
			setBgImageLoaded(true)
		}
	}, [userImageUrl])

	useEffect(() => {
		setSportBgLoaded(true)
		if (profile?.sport.background) {
			const img = new Image()
			img.src = profile?.sport.background
			if (profile?.sport && profile?.sport?.background) {
				img.onload = () => {
					setSportBgLoaded(true)
				}
			} else {
				setSportBgLoaded(true)
			}
		} else {
			setSportBgLoaded(true)
		}
	}, [profile?.sport?.background])

	const handleDeleteOpen = () => setIsDeleteModalOpen(true)
	const handleDeleteClose = deleteStatus => {
		if (deleteStatus == 1) {
			UserService.getUserByAlias(alias).then(response => {
				if (response.data.sports?.length > 0) {
					navigate(`/p/${response.data.alias}/${response.data.sports[0]?.code}?refer=deleteSport`)
				} else {
					navigate(`/p/${response.data.alias}?refer=deleteSport`)
					// setSportCode(null)
				}
			})
		}
		setIsDeleteModalOpen(false)
		setShowDeleteSuccessfullScreen(false)
	}
	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {
		if (!sportProfile) {
			if (profileDetails) {
				const sportProfile =
					profileDetails?.sportProfiles?.length > 0 ? profileDetails?.sportProfiles[0] : {}
				setProfile({ ...profileDetails, ...sportProfile })
			} else if (alias && sportCode) {
				// setIsLoading(true) // dont open it
				ProfileService.getUsersProfile(alias, sportCode).then(response => {
					setIsLoading(false)
					setProfile(response.data)
				})
			}
		} else {
			setProfile(sportProfile)
		}
	}, [alias, sportCode, setProfile, profileDetails])

	let levelColor
	if (profile) {
		levelColor = getLevelColor(profile.level)
	}

	const practicingSince = useMemo(() => {
		if (profile != null) {
			let yearsSince = DateUtils.yearsSince(profile.practicingSince)
			if (yearsSince === 0) {
				const monthsSince = DateUtils.monthsSince(profile.practicingSince)
				return { value: monthsSince, text: `mt${yearsSince > 1 ? 's' : ''}` }
			}
			return { value: yearsSince, text: `yr${yearsSince > 1 ? 's' : ''}` }
		}
	}, [profile])

	const MyChip = ({ children }) => {
		return (
			<Typography
				variant="pb"
				backgroundColor="grey.main"
				padding="0px 5px"
				margin="2px 5px"
				display="inline-block"
			>
				{children}
			</Typography>
		)
	}

	const handleDeleteSport = () => {
		setShowDeleteSuccessfullScreen(true)
		SportService.deletSportProfile(sportCode)
			.then(response => {
				setShowDeleteSuccessfullScreen(true)
			})
			.catch(error => {
				console.log('ERROR', error.response)
				sendNotification({
					msg:
						error.response?.data?.message !== undefined
							? error.response.data.message
							: 'An Error Occurred',
					variant: 'error'
				})
			})
	}

	return profile != null ? (
		<Stack spacing={2}>
			<Box
				sx={{
					padding: hasPaddedView ? '10px' : '0px',
					borderRadius: '10px',
					margin: '0px',
					backgroundColor: 'background.paper',
					position: 'relative'
				}}
			>
				{/* IconButton for editing profile */}
				{showEdit && user?.alias === alias && isLoggedIn && (
					<Box>
						<Box sx={{ float: 'right' }}>
							{showPencilIcon && (
								<Box className="d-flex aic jce w-100">
									<Button
										sx={{ minWidth: '0', padding: '5px 0px', marginBottom: '-15px' }}
										id="basic-button"
										aria-controls={deletPopupOpen ? 'basic-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={deletPopupOpen ? 'true' : undefined}
										onClick={handleClick}
									>
										<EditIcon sx={{ color: '#00C6AE' }} />
									</Button>
								</Box>
							)}
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={deletPopupOpen}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button'
								}}
								// sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
							>
								<MenuItem
									onClick={() => {
										navigate(`/editSportProfile/${sportCode}`)
									}}
									sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
								>
									<EditIcon sx={{ color: 'black' }} /> &nbsp;Edit {profile?.sport?.name}
								</MenuItem>
								<MenuItem
									onClick={() => {
										handleDeleteOpen()
										handleClose()
									}}
									sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
								>
									<Clear sx={{ color: 'black' }} /> &nbsp;Remove {profile?.sport?.name}
								</MenuItem>
							</Menu>
							<Modal
								open={isDeleteModalOpen}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								{!showDeleteSuccessfullScreen ? (
									<Box sx={{ ...style, fontSize: '20px' }}>
										<Box
											id="modal-modal-title"
											sx={{ fontWeight: '600', fontSize: '20px', textAlign: 'center' }}
										>
											Are you sure you want to remove
											<span style={{ color: '#00C6AE' }}>&nbsp;{profile?.sport?.name}</span> from
											your profile?
										</Box>
										<Box
											id="modal-modal-description"
											sx={{
												mt: 2,
												width: '122%',
												display: 'flex',
												justifyContent: 'space-around',
												borderTop: '1px solid #8080804d',
												marginBottom: '-32px'
											}}
										>
											<Button
												sx={{ width: '100%', p: '12px 0', fontSize: '17px' }}
												onClick={handleDeleteClose}
											>
												Cancel
											</Button>
											<Button
												sx={{
													width: '100%',
													p: '12px 0',
													fontSize: '17px',
													borderLeft: '1px solid #8080804d'
												}}
												onClick={handleDeleteSport}
											>
												Delete
											</Button>
										</Box>
									</Box>
								) : (
									<Box
										sx={{
											...style,

											height: '163px'
										}}
									>
										<Box
											sx={{
												position: 'absolute',
												top: '10px',
												right: '18px',
												color: 'gray'
											}}
										>
											<CloseOutlined onClick={() => handleDeleteClose(1)} />
										</Box>
										<Box
											sx={{
												fontSize: '20px',
												fontWeight: '600',
												textAlign: 'center'
											}}
										>
											<span style={{ color: '#00C6AE' }}>{profile?.sport?.name}</span>&nbsp; has
											been removed from your profile
										</Box>
									</Box>
								)}
							</Modal>
						</Box>
					</Box>
				)}
				<Grid container direction="row" justifyContent="center" mt={'5px'}>
					{showUserImage && (
						<Grid xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
							{bgImageLoaded ? (
								<Avatar
									sx={{ height: '65px', width: '65px' }}
									alt={userName}
									src={userImageUrl ? userImageUrl : '/broken-img.jpg'}
									loading="lazy"
								/>
							) : (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}
								>
									<CircularProgress style={{ width: '20px', height: '20px' }} />
								</Box>
							)}
						</Grid>
					)}

					<Grid
						item
						xs={3}
						textAlign="center"
						sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'top' }}
					>
						{!isLoading ? (
							<div>
								<Chip
									label={<Typography variant="h4">{profile.level}</Typography>}
									sx={{ backgroundColor: levelColor, width: '92px', maxWidth: '92px' }}
								/>
							</div>
						) : (
							<Box
								sx={{
									backgroundColor: 'lightgray',
									width: '92px',
									maxWidth: '92px',
									height: '20px',
									borderRadius: '30px'
								}}
							></Box>
						)}
						{!isLoading ? (
							<div>
								<Typography
									display="inline"
									sx={{ fontWeight: '800', fontSize: '27px', lineHeight: '32px' }}
								>
									{practicingSince?.value}&nbsp;
								</Typography>
								<Typography variant="p">{practicingSince?.text}</Typography>
							</div>
						) : (
							<Box display="flex" justifyContent="center" width="92px" mt={1}>
								<Box
									sx={{
										backgroundColor: 'lightgray',
										width: '20px',
										maxWidth: '92px',
										height: '25px',
										borderRadius: '5px'
									}}
								></Box>
							</Box>
						)}
					</Grid>

					<Grid item xs={showUserImage ? 6 : 8}>
						<Grid sx={{ ml: '20px' }}>
							{profile?.sportStyles?.map((style, index) => (
								<Stack direction="row" key={`style-${index}`}>
									<Typography
										variant="h4"
										backgroundColor="primary.light"
										padding="0px 5px"
										margin="2px 5px"
										display="inline-block"
									>
										{style.name}
									</Typography>
									<SportStyleRating proficiency={style.proficiency} />
								</Stack>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid sx={{ marginTop: '5px' }}>
					{showStatus &&
						profile?.status &&
						(profile?.status?.dates?.length > 0 ||
							profile?.status?.message !== '' ||
							profile?.status?.playground.length > 0) && (
							<SportStatus
								status={profile?.status}
								showInterestedButton={true}
								sportUser={profile}
								indexPosition={indexPosition}
							/>
						)}
				</Grid>

				{showSessionTypes && (
					<SportSessionTypes biography={profile?.biography} sessionTypes={profile?.sessionTypes} />
				)}

				{/* profile questions */}
				<Box
					sx={{
						px: '10px',
						pt: showSessionTypes ? '0px' : '10px'
					}}
				>
					{!minimalView &&
						(profile?.questions?.filter(
							que => (que.answer && que.answer.trim().length > 0) || que.tags.length > 0
						)?.length > 0 ||
							profile?.sportTimeWeekdayDay ||
							profile?.sportTimeWeekdayEvening ||
							profile?.sportTimeWeekend) && (
							<UserQuestionnaire profile={profile} showLocationTime={!showPencilIcon} />
						)}
				</Box>
				{!sportBgLoaded && <Loading />}
			</Box>
		</Stack>
	) : (
		''
	)
}

export default SportView

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	minWidth: '338px',
	gap: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
