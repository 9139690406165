import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useField } from "formik";
import ErrorMessageBL from "../../ui/ErrorMessageBL";
import { FormLabel } from "@mui/material";

const YearsOfPractice = (props) => {

  const [field, meta] = useField("profile.yearsOfExperience");

  return (
    <FormControl fullWidth error={!!meta.error && meta.touched}>
      <FormLabel id="years-of-practice-label"># of years practiced</FormLabel>
      <Select
        labelId="years-of-practice-label"
        id="years-of-practice"
        // value={age}
        label="# of years practiced"
        defaultValue=""
        {...field}
      >
        <MenuItem key={0} value={0}>
          &lt;1
        </MenuItem>
        {Array.from(Array(19).keys()).map((i) => (
          <MenuItem key={i + 1} value={i + 1}>
            {i + 1}
          </MenuItem>
        ))}
        <MenuItem key={20} value={20}>
          &gt;20
        </MenuItem>
      </Select>
      <ErrorMessageBL name={field.name}/>
    </FormControl>
  );
};

export default YearsOfPractice;