const challengeTypeDetails = [
	{
		type: 'sport',
		challenge: [
			{
				name: 'Badminton',
				goal: [
					{
						name: 'Badminton for fun',
						display: 'Just for fun!',
						desc: "Just play badminton for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Badminton Challenge',
						display: 'Play badminton sometimes',
						desc: 'Play badminton once a week this month'
					},
					{
						name: 'the Avid Badminton Challenge',
						display: 'Play badminton regularly',
						desc: 'Play badminton twice a week this month'
					},
					{
						name: 'the Intensive Badminton Challenge',
						display: 'Play badminton a whole lot',
						desc: 'Play badminton 3 times a week this month'
					},
					{
						name: 'the ___ Badminton Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Basketball',
				goal: [
					{
						name: 'Basketball for fun',
						display: 'Just for fun!',
						desc: "Just play basketball for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Basketball Challenge',
						display: 'Play basketball sometimes',
						desc: 'Play basketball ____ times this month'
					},
					{
						name: 'the Avid Basketball Challenge',
						display: 'Play basketball regularly',
						desc: 'Play basketball ____ times a week this month'
					},
					{
						name: 'the Intensive Basketball Challenge',
						display: 'Play basketball a whole lot',
						desc: 'Play basketball ____ times a week this month'
					},
					{
						name: 'the ___ Basketball Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Bowling',
				goal: [
					{
						name: 'Bowling for fun',
						display: 'Just for fun!',
						desc: "Just play bowling for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Bowling Challenge',
						display: 'Play bowling sometimes',
						desc: 'Play bowling ____ times this month'
					},
					{
						name: 'the Avid Bowling Challenge',
						display: 'Play bowling regularly',
						desc: 'Play bowling ____ times this month'
					},
					{
						name: 'the Intensive Bowling Challenge',
						display: 'Play bowling a whole lot',
						desc: 'Play bowling ____ times this month'
					},
					{
						name: 'the ___ Bowling Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Boxing',
				goal: [
					{
						name: 'Boxing for fun',
						display: 'Just for fun!',
						desc: "Just do boxing for fun! Let's have some tea afterwards!"
					},
					{
						name: 'the Casual Boxing Challenge',
						display: 'Box Sometimes',
						desc: 'Box ____ times this month'
					},
					{
						name: 'the Avid Boxing Challenge',
						display: 'Box Regularly',
						desc: 'Box ____ times this month'
					},
					{
						name: 'the Intensive Boxing Challenge',
						display: 'Box A Whole Lot',
						desc: 'Box ____ times this month'
					},
					{
						name: 'the ___ Boxing Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Cycling',
				goal: [
					{
						name: 'Cycling for fun',
						display: 'Just for fun!',
						desc: "Just cycle for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Cycling Challenge',
						display: 'Cycle sometimes',
						desc: 'Cycle once a week this month'
					},
					{
						name: 'the Avid Cycling Challenge',
						display: 'Cycle regularly',
						desc: 'Cycle 2-3 times a week this month'
					},
					{
						name: 'the Intensive Cycling Challenge',
						display: 'Cycle a whole lot',
						desc: 'Cycle 4 times a week this month'
					},
					{
						name: 'the ____ Route Challenge',
						display: 'Complete a route',
						desc: "Let's cycle from ____ to ____ via Route ____. Whoever completes it wins!"
					},
					{
						name: 'the ____ Race',
						display: 'Speed',
						desc: "Let's cycle from ____ to ____ via Route ____. Whoever arrives first wins!"
					},
					{ name: 'the ___ Cycling Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Darts',
				goal: [
					{
						name: 'Dart for fun',
						display: 'Just for fun!',
						desc: "Just play darts for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Darts Challenge',
						display: 'Play Darts Sometimes',
						desc: 'Play darts ____ times this month'
					},
					{
						name: 'the Avid Darts Challenge',
						display: 'Play Darts Regularly',
						desc: 'Play darts ____ times this month'
					},
					{
						name: 'the Intensive Darts Challenge',
						display: 'Play Darts A Whole Lot',
						desc: 'Play darts ____ times this month'
					},
					{
						name: 'the ___ Darts Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Free diving',
				goal: [
					{
						name: 'Free diving for fun',
						display: 'Just for fun!',
						desc: "Just free dive for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Free diving Challenge',
						display: 'Free Dive Sometimes',
						desc: 'Free dive 4 days this month'
					},
					{
						name: 'the Avid Free diving Challenge',
						display: 'Free Dive Regularly',
						desc: 'Free dive twice a week this month'
					},
					{
						name: 'the Intensive Free diving Challenge',
						display: 'Free Dive A Whole Lot',
						desc: 'Free dive 4 times a week this month'
					},
					{
						name: 'the Depth Challenge',
						display: 'Push the Depth',
						desc: "Let's go deep. You will go __m and I will go __m."
					},
					{
						name: 'the Longest Breath Challenge',
						display: 'Hold your breath!',
						desc: 'Whoever that can hold their breath the longest wins the bet.'
					},
					{
						name: 'the ___ Free diving Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Golf',
				goal: [
					{
						name: 'Golf for fun',
						display: 'Just for fun!',
						desc: "Just play golf for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Golf Challenge',
						display: 'Play Golf Sometimes',
						desc: 'Play golf ____ times this month'
					},
					{
						name: 'the Avid Golf Challenge',
						display: 'Play Golf Regularly',
						desc: 'Play golf ____ times this month'
					},
					{
						name: 'the Intensive Golf Challenge',
						display: 'Play Golf A Whole Lot',
						desc: 'Play golf ____ times this month'
					},
					{
						name: 'the ___ Golf Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Hiking',
				goal: [
					{
						name: 'Hiking for fun',
						display: 'Just for fun!',
						desc: "Just hike for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Hiking Challenge',
						display: 'Hike sometimes',
						desc: 'Hike easy day-hike every other week for 2 months'
					},
					{
						name: 'the Avid Hiking Challenge',
						display: 'Hike regularly',
						desc: 'Hike ____ times this month'
					},
					{
						name: 'the Intensive Hiking Challenge',
						display: 'Hike a whole lot',
						desc: 'Hike ____ times this month'
					},
					{
						name: 'the Stairs Challenge',
						display: 'Stair climbing',
						desc: "Let's prepare for our hike by doing some stair climbing! Let's do ___ stories a day, ___ times a week for ____ months!"
					},
					{
						name: 'the ____ Mountain Hiking Challenge',
						display: 'Spefic mountain',
						desc: "Let's hike ____ Mountain. Whoever completes it wins the bet."
					},
					{
						name: 'the ____ Trail Hiking Challenge',
						display: 'Spefic trail',
						desc: "Let's go hiking and do the ____ Trail. Whoever completes it wins the bet."
					},
					{ name: 'the ___ Hiking Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Rock climbing',
				goal: [
					{
						name: 'The Beerlayer Challenge',
						display: 'Just for fun!',
						desc: 'Let’s do 6 climbs each today, and celebrate with a 6-pack! '
					},
					{
						name: 'Rock climbing for fun',
						display: 'Just for fun!',
						desc: "Just climb for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Climber Challenge',
						display: 'Casual climber',
						desc: 'Climb once a week this month'
					},
					{
						name: 'the Avid Climber Challenge',
						display: 'Avid climber',
						desc: 'Climb 2-3 times a week this month'
					},
					{
						name: 'the Obsessed Climber Challenge',
						display: 'Obsessed climber',
						desc: 'Climb 4 times a week this month'
					},
					{ name: 'the Monkey Challenge', display: 'Monkey', desc: "Let's climb for __ hrs today" },
					{
						name: 'the Crusher Challenge',
						display: 'Crusher',
						desc: "Let's see who can climb the most routes today! P.S. Gotta make it to the top without taking for it to count."
					},
					{
						name: 'the Wall-Sticker Challenge',
						display: 'Wall-sticker',
						desc: "Let's see who can stay on the wall for 30min without coming down."
					},
					{
						name: 'the Route Sweeper Challenge',
						display: 'Route sweeper',
						desc: "Let's sweep all _5.10s_ routes in the gym in one session"
					},
					{
						name: 'the Push-Beyond-Limit Challenge',
						display: 'Push beyond limits',
						desc: "Let's climb at our max +1 grade. Whoever gives the best effort wins the bet."
					},
					{
						name: 'the Sendinator Challenge',
						display: 'Sendinator',
						desc: "Let's see who can send a route first at their max grade."
					},
					{
						name: 'the No Take Warrior Challenge',
						display: 'No take',
						desc: 'Let\'s climb until we fall, no taking. In fact, feed more slack for anyone who shouts "TAKE"!'
					},
					{
						name: 'the Falling Angel Challenge',
						display: 'Fall practice',
						desc: "Let's take 3 practice falls each when we start climbing every time"
					},
					{
						name: 'the More The Merrier Challenge',
						display: 'The more the merrier',
						desc: "Let's climb as many routes as we can in one session. Whoever climbs the most routes wins the bet."
					},
					{
						name: 'the Earn the Rest Challenge',
						display: 'Earn the rest',
						desc: "Let's spend as much time on the wall to get as much rest on the ground... and repeat 3 times!"
					},
					{
						name: 'the Route Setter Challenge',
						display: 'Route setter',
						desc: "Let's take turns to add on a hold everytime when it's your turn to climb. When it's your turn, you have 3 attempts to climb it. Keep repeating the process until the last person standing."
					},
					{
						name: 'the No Feet Challenge',
						display: 'No feet',
						desc: "Let's climb a route without using feet. Campus up!"
					},
					{
						name: 'the Slab Master Challenge',
						display: 'Slab master',
						desc: "Let's pick a route and see who can climb it without using hands; if no one makes it then it's whoever that uses it the least wins."
					},
					{ name: 'the ___ Rock climbing Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Running',
				goal: [
					{
						name: 'Running for fun',
						display: 'Just for fun!',
						desc: "Just run for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Running Challenge',
						display: 'Run sometimes',
						desc: "Let's run once a week this month, and _2k each time."
					},
					{
						name: 'the Avid Running Challenge',
						display: 'Run regularly',
						desc: "Let's run 2-3 times a week this month, and __k each time."
					},
					{
						name: 'the Intensive Running Challenge',
						display: 'Run a whole lot',
						desc: "Let's run 4 times a week this month, and __k each time."
					},

					{ name: 'the ___ Running Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Scavenger Hunt',
				goal: [
					{
						name: 'The Scavenger Hunt Challenge',
						display: 'Scavenger hunt',
						desc: "Let's do a scavenger hunt and find the list of items: a fountain, a copy machine, an orange cat, a man with a beard, an expired poster. The challengers have to take a picture together as proof. Whoever finds the most items wins."
					}
				]
			},
			{
				name: 'Triathlon',
				goal: [
					{
						name: 'the ___ Race Challenge',
						display: 'Race',
						desc: "Let's sign up for the ____ Race. Whoever completes it wins. "
					},
					{
						name: 'the Ironman Challenge',
						display: 'Ironman',
						desc: "Let's sign up for the ____ Race. Whoever completes it is ironman and wins the bet!"
					}
				]
			},
			{
				name: 'Scuba diving',
				goal: [
					{
						name: 'Scuba diving for fun',
						display: 'Just for fun!',
						desc: "Just dive for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Scuba diving Challenge',
						display: 'Dive Sometimes',
						desc: 'Dive 4 days this month'
					},
					{
						name: 'the Avid Scuba diving Challenge',
						display: 'Dive Regularly',
						desc: 'Dive twice a week this month'
					},
					{
						name: 'the Intensive Scuba diving Challenge',
						display: 'Dive A Whole Lot',
						desc: 'Dive 4 times a week this month'
					},
					{
						name: 'the ___ Scuba diving Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Soccer',
				goal: [
					{
						name: 'Soccer for fun',
						display: 'Just for fun!',
						desc: "Just play soccer for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Soccer Challenge',
						display: 'Play Soccer Sometimes',
						desc: 'Play soccer ____ times this month'
					},
					{
						name: 'the Avid Soccer Challenge',
						display: 'Play Soccer Regularly',
						desc: 'Play soccer ____ times a week this month'
					},
					{
						name: 'the Intensive Soccer Challenge',
						display: 'Play Soccer A Whole Lot',
						desc: 'Play soccer ____ times a week this month'
					},
					{
						name: 'the ___ Soccer Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Squash',
				goal: [
					{
						name: 'Squash for fun',
						display: 'Just for fun!',
						desc: "Just play squash for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Squash Challenge',
						display: 'Play Squash Sometimes',
						desc: 'Play squash once a week this month'
					},
					{
						name: 'the Avid Squash Challenge',
						display: 'Play Squash Regularly',
						desc: 'Play squash twice a week this month'
					},
					{
						name: 'the Intensive Squash Challenge',
						display: 'Play Squash A Whole Lot',
						desc: 'Play squash 3 times a week this month'
					},
					{
						name: 'the ___ Squash Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Surfing',
				goal: [
					{
						name: 'Surfing for fun',
						display: 'Just for fun!',
						desc: "Just surf for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Surfer Challenge',
						display: 'Casual surfer',
						desc: 'Surf 4 days this month'
					},
					{
						name: 'the Avid Surfer Challenge',
						display: 'Avid surfer',
						desc: 'Surf twice a week this month'
					},
					{
						name: 'the Obsessed Surfer Challenge',
						display: 'Obsessed surfer',
						desc: 'Surf 4 times a week this month'
					},
					{ name: 'the Aquaman Challenge', display: 'Aquaman', desc: 'Surf for __ hrs today' },
					{
						name: 'the Wave Catcher Challenge',
						display: 'Wave catcher',
						desc: "Let's see whoever that catches the most waves today wins the bet."
					},
					{
						name: 'the Party Wave Challenge',
						display: 'Party wave everyone!',
						desc: "Let's party waves everyone! We all surf the same wave and take a photo!"
					},
					{
						name: 'the Pop-up Challenge',
						display: 'Pop-up',
						desc: "Let's see who can pop-up at least 3 times today"
					},
					{
						name: 'the Paddle Machine Challenge',
						display: 'Paddle machine',
						desc: "Let's see who paddles the most total distance today. (Need a Garmin/Apple watch)"
					},
					{
						name: 'the Hang-Ten Challenge',
						display: 'Hang-Ten',
						desc: "Let's see who can do the coolest hang-ten today"
					},
					{
						name: 'the Squirtle Challenge',
						display: 'Splash',
						desc: "Let's see who can make the most impressive splash today"
					},
					{ name: 'the ___ Surfing Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Swimming',
				goal: [
					{
						name: 'Swimming for fun',
						display: 'Just for fun!',
						desc: "Just swim for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Swimming Challenge',
						display: 'Swim sometimes',
						desc: 'Swim once a week this month'
					},
					{
						name: 'the Avid Swimming Challenge',
						display: 'Swim regularly',
						desc: 'Swim 2-3 times a week this month'
					},
					{
						name: 'the Intensive Swimming Challenge',
						display: 'Swim a whole lot',
						desc: 'Swim 4 times a week this month'
					},
					{
						name: 'the Long Distance Swimmer Challenge',
						display: 'Long distance',
						desc: "Let's see who can swim for the longest distance today."
					},
					{ name: 'the ___ Swimming Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Table tennis',
				goal: [
					{
						name: 'Table tennis for fun',
						display: 'Just for fun!',
						desc: "Just play table tennis for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Table tennis Challenge',
						display: 'Play Table Tennis Sometimes',
						desc: 'Play table tennis once a week this month'
					},
					{
						name: 'the Avid Table tennis Challenge',
						display: 'Play Table Tennis Regularly',
						desc: 'Play table tennis twice a week this month'
					},
					{
						name: 'the Intensive Table tennis Challenge',
						display: 'Play Table Tennis A Whole Lot',
						desc: 'Play table tennis 3 times a week this month'
					},
					{
						name: 'the ___ Table tennis Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Tennis',
				goal: [
					{
						name: 'Tennis for fun',
						display: 'Just for fun!',
						desc: "Just play tennis for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Tennis Challenge',
						display: 'Play Tennis Sometimes',
						desc: 'Play tennis once a week this month'
					},
					{
						name: 'the Avid Tennis Challenge',
						display: 'Play Tennis Regularly',
						desc: 'Play tennis twice a week this month'
					},
					{
						name: 'the Intensive Tennis Challenge',
						display: 'Play Tennis A Whole Lot',
						desc: 'Play tennis 3 times a week this month'
					},
					{
						name: 'the ___ Tennis Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Volleyball',
				goal: [
					{
						name: 'Volleyball for fun',
						display: 'Just for fun!',
						desc: "Just play volleyball for fun! Let's get a beer afterwards!"
					},
					{
						name: 'the Casual Volleyball Challenge',
						display: 'Play Volleyball Sometimes',
						desc: 'Play volleyball ____ times this month'
					},
					{
						name: 'the Avid Volleyball Challenge',
						display: 'Play Volleyball Regularly',
						desc: 'Play volleyball ____ times a week this month'
					},
					{
						name: 'the Intensive Volleyball Challenge',
						display: 'Play Volleyball A Whole Lot',
						desc: 'Play volleyball ____ times a week this month'
					},
					{
						name: 'the ___ Volleyball Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Walking',
				goal: [
					{
						name: 'the Casual Walking Challenge',
						display: 'Walk sometimes',
						desc: 'Walk ____ times this month'
					},
					{
						name: 'the Avid Walking Challenge',
						display: 'Walk regularly',
						desc: 'Walk ____ times this month'
					},
					{
						name: 'the Intensive Walking Challenge',
						display: 'Walk a whole lot',
						desc: 'Walk ____ times this month'
					},
					{
						name: 'The Graffity Hunter Challenge',
						display: 'Photo walking',
						desc: 'Let’s do a photo walk, take pics of all the _graffitis_ on the street, and see who is able to find the most _graffities_.'
					},
					{
						name: 'The Scavenger Hunt Challenge',
						display: 'Scavenger hunt',
						desc: "Let's do a scavenger hunt and find the list of items: a fountain, a copy machine, an orange cat, a man with a beard, an expired poster. The challengers have to take a picture together as proof. Whoever finds the most items wins."
					},
					{ name: 'the ___ Walking Challenge', display: 'Custom', desc: "Let's do ..." }
				]
			},
			{
				name: 'Yoga',
				goal: [
					{
						name: 'Yoga for fun',
						display: 'Just for fun!',
						desc: "Just do yoga for fun! Let's have some tea afterwards!"
					},
					{
						name: 'the Casual Yoga Challenge',
						display: 'Practice Yoga Sometimes',
						desc: 'Practice yoga once a week for this month'
					},
					{
						name: 'the Avid Yoga Challenge',
						display: 'Practice Yoga Regularly',
						desc: 'Practice yoga 3 times a week this month'
					},
					{
						name: 'the Intensive Yoga Challenge',
						display: 'Practice Yoga A Whole Lot',
						desc: 'Practice yoga everyday this month'
					},
					{
						name: 'the ___ Yoga Challenge',
						display: 'Custom',
						desc: "Let's do ..."
					}
				]
			},
			{
				name: 'Custom',
				goal: [
					{
						name: 'the ____ Challenge',
						display: 'Make your own challenge',
						desc: "Let's do..."
					}
				]
			}
		]
	},
	{
		type: 'fitness',
		challenge: [
			{
				name: 'Fitness',
				goal: [
					{
						name: 'the 6-Pack Challenge',
						display: 'Push-up',
						desc: "Let's do push-ups everyday for a week! I will do ___/day, and you will do ____/day."
					},
					{
						name: 'the Pushup Master Challenge',
						display: 'Push-up master',
						desc: "Let's see who can do more push-ups in one go!"
					},
					{
						name: 'the Sit-Up Master Challenge',
						display: 'Sit-up master',
						desc: "Let's see who can do more sit-ups in one go!"
					},
					{
						name: 'the Sit-Up Challenge',
						display: 'Sit-up',
						desc: "Let's do sit-ups everyday for a week! I will do ___/day, and you will do ____/day."
					},
					{
						name: 'the Sit-Up Time-Limit Challenge',
						display: 'Sit-up time-limited',
						desc: 'Whoever that can do the most sit-ups in one minute wins'
					},
					{
						name: 'the Arm Wrestling Challenge',
						display: 'Arm wrestling',
						desc: "Let's do an arm-wrestling match. Winnter gets the bet."
					},
					{
						name: 'the Max Jump Rope Challenge',
						display: 'Jump rope max',
						desc: "Let's how many times you can jump rope in one minute."
					},
					{
						name: 'the Jump Rope Challenge',
						display: 'Jump rope regularly',
						desc: "Let's jump rope ___ times everyday for a week. Whoever completes it wins."
					},
					{
						name: 'the Hula-hoop Challenge',
						display: 'Hula-hoop',
						desc: "Let's see who can hula-hoop the longest."
					},
					{
						name: 'the One-Arm Pushup Challenge',
						display: '(Pro) One-arm push-up',
						desc: "Let's see who can do an one-arm push-up in a month"
					},
					{
						name: 'the Close Pushup Challenge',
						display: '(Pro) Close push-up',
						desc: "Let's do ___ close pushups everyday for a week"
					},
					{
						name: 'the One-Arm Pull-up Challenge',
						display: '(Pro) One arm pull-up',
						desc: "Let's see who can do an one-arm pull-up in a month"
					},
					{
						name: 'the Head-bridge Challenge',
						display: 'Head-bridge',
						desc: "Let's see who can do a head-bridge in a week"
					},
					{
						name: 'the Full-Bridge Challenge',
						display: '(Pro) Full-bridge',
						desc: "Let's see who can hold a full-bridge longer"
					},
					{
						name: 'the Upside-Down Challenge',
						display: 'Head-stand',
						desc: "Let's see who can hold a head-stand longer than 3s"
					},
					{
						name: 'the Upside-Down Challenge',
						display: 'Wall head-stand',
						desc: "Let's see who can hold it longer than 3s with your head down against the wall?"
					},
					{
						name: 'the Crow-Stand Challenge',
						display: 'Crow-stand',
						desc: "Let's see who can hold a crow-stand longer than 3s"
					},
					{
						name: 'the Hand-Stand Challenge',
						display: 'Hand-stand',
						desc: "Let's see who can hold a hand-stand longer"
					},
					{
						name: 'the Plank-of-Wood Challenge',
						display: 'Plank',
						desc: "Let's see who can plank longer! Keep your perky ass down!"
					},
					{ name: 'the ____ Challenge', display: 'Custom', desc: "Let's do..." }
				]
			}
		]
	},
	{
		type: 'diet',
		challenge: [
			{
				name: 'Diet',
				goal: [
					{
						name: 'The 168 Fasting Challenge',
						display: 'Intermittent fasting',
						desc: "Let's choose our window of 8 hours per day for eating, no food for the remaining 16 hours. No-cal beverages (like coffee) or tea are allowed."
					},
					{
						name: 'the Goodbye My Sugar Challenge',
						display: 'Cut sweets and sugar',
						desc: "Let's not have any sugary sweets for 3 days."
					},
					{
						name: 'the Low Carb Diet Challenge',
						display: 'Low carb',
						desc: "Let's not have any carb-rich foods like grains, baked goods, and potatoes... for 3 days."
					},
					{ name: 'the ____ Challenge', display: 'Make your own challenge', desc: "Let's do..." }
				]
			}
		]
	}
]

export default challengeTypeDetails
