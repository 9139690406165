import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const blGreen = '#00C6AE'
const blGreen800 = '#61E4C5'
const blGreen300 = '#B0F1E1'
const blOrange = '#F95A2C'
const blPink = '#FFC7DE'
const blYellow = '#FFBD12'
const blGrey = '#CECECE'

export default createTheme({
	palette: {
		type: 'light',
		primary: {
			main: blGreen,
			light: blGreen800,
			offlight: blGreen300,
			contrastText: '#fff' //button text white instead of black
		},
		secondary: {
			main: blOrange
		},
		grey: {
			main: blGrey
		}
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'blYellow' },
					style: {
						background: blYellow,
						border: '2px solid #18191F',
						borderRadius: '32px',
						fontWeight: 700
					}
				},
				{
					props: { variant: 'blGreen' },
					style: {
						background: blGreen,
						//border: '2px solid #18191F',
						borderRadius: '32px',
						//boxShadow: '0px 4px 0px #18191F',
						fontWeight: 700,
						color: '#fff'
					}
				},
				{
					props: { variant: 'blGrey' },
					style: {
						background: blGrey,
						//border: '2px solid #18191F',
						borderRadius: '32px',
						//boxShadow: '0px 4px 0px #18191F',
						fontWeight: 700
					}
				},
				{
					props: { variant: 'blWhite' },
					style: {
						borderRadius: '32px',
						fontWeight: 500,
						color: blGreen,
						border: `1px solid ${blGreen}`
					}
				}
			],
			styleOverrides: {
				root: {
					borderRadius: '4px'
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				root: {
					backgroundColor: grey[200],
					height: '24px'
				},
				label: {
					paddingLeft: '6px',
					paddingRight: '6px'
				}
			},
			variants: [
				{
					props: { variant: 'blGreen' },
					style: {
						background: blGreen
					}
				}
			]
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: blGreen800
					}
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: blOrange
					}
				}
			}
		},
		MuiSelect: {
			defaultProps: {
				// IconComponent: <ArrowDropDownIcon />,
			},
			styleOverrides: {
				root: {
					// border: "2px solid #18191F",
					borderRadius: '16px',
					backgroundColor: 'white'
				}
				// icon: {
				//   backgroundColor: blOrange,
				//   height: "100%",
				//   margin: "0, 0, 0, 0",
				// },
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					// backgroundColor: "#FFFFFF"
					borderRadius: '16px'
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				inputProps: {
					notchedOutline: {
						specialOutline: {
							borderColor: 'blue',
							borderWidth: 4
						}
					}
				}
			},
			styleOverrides: {
				root: {
					backgroundColor: '#FFFFFF',
					border: '2px #18191F',
					borderRadius: '16px',
					notchedOutline: {
						specialOutline: {
							borderColor: 'blue',
							borderWidth: 4
						}
					}
				}
			}
		},
		MuiImageListItemBar: {
			styleOverrides: {
				title: {
					// overflowWrap: "break-word",
					// whiteSpace: "normal",
				}
			}
		},
		ArrowDropDownIcon: {
			styleOverrides: {
				root: {
					backgroundColor: blOrange
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					minHeight: '36px'
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: '#9FA4B4',
					backgroundColor: 'white',
					border: '1px solid #9FA4B4',
					borderBottom: '0',
					minWidth: '125px',
					borderRadius: '16px 16px 0px 0px',
					fontSize: '14px',
					fontWeight: '700',
					padding: '6px 8px',
					minHeight: '36px',

					'&.Mui-selected': {
						backgroundColor: blGreen300,
						color: 'black',
						border: '0',
						borderTop: '1px solid var(--colors-green-800, #61E4C5)',
						borderRight: '1px solid var(--colors-green-800, #61E4C5)',
						borderLeft: '1px solid var(--colors-green-800, #61E4C5)'
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					fontSize: '16px'
				}
			}
		}
	},
	typography: {
		fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
		title: {
			fontWeight: '700',
			fontSize: '30px',
			lineHeight: '40px',
			textAlign: 'center',
			color: '#FFFFFF'
		},
		h1: {
			fontSize: '21px',
			fontWeight: 500,
			lineHeight: '28px',
			letterSpacing: '0px',
			textAlign: 'center',
			color: '#18191F'
		},
		h1b: {
			fontSize: '21px',
			fontWeight: 700,
			lineHeight: '28px',
			letterSpacing: '0px',
			textAlign: 'left',
			color: '#18191F'
		},
		h2: {
			fontSize: '17px',
			fontWeight: 700,
			lineHeight: '24px',
			textAlign: 'center',
			color: '#202020'
		},
		h2l: {
			fontSize: '17px',
			fontWeight: 400
		},
		h3: {
			fontSize: '15px',
			fontWeight: 500,
			lineHeight: '22px',
			// textAlign: 'center',
			color: '#474A57'
		},
		pregular: {
			fontSize: '14px',
			fontWeight: 400
		},
		h4: {
			fontSize: '12px',
			fontWeight: 700,
			lineHeight: '16px',
			textAlign: 'center',
			color: '#000000'
		},
		subtitle1: {
			fontSize: '12px',
			fontWeight: 500,
			fontStyle: 'italic',
			lineHeight: '16px',
			textAlign: 'center',
			color: '#000000'
		},
		link1: {
			fontSize: '12px',
			fontWeight: 500,
			fontStyle: 'normal',
			lineHeight: '16px',
			textAlign: 'center',
			textDecorationLine: 'underline',
			color: '#000000'
		},
		note: {
			fontSize: '11px',
			fontWeight: 500,
			fontStyle: 'normal',
			lineHeight: '16px',
			color: '#202020'
		},
		p: {
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '14px',
			lineHeight: '16px',
			color: '#202020'
		},
		pb: {
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '14px',
			lineHeight: '20px',
			color: '#202020'
		},
		link: {
			fontFamily: 'Montserrat',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '12px',
			lineHeight: '16px',
			textAlign: 'center',
			textDecorationLine: 'underline'
		},
		button: {
			textTransform: 'none'
		}
	}
})
