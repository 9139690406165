import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'

const MyImg = ({
	src,
	alt = 'img',
	width = '100%',
	className = '',
	height = 'auto',
	marginBottom = '0px'
}) => {
	const [imageLoaded, setImageLoaded] = useState(false)

	useEffect(() => {
		if (src) {
			setImageLoaded(false)
			const img = new Image()
			img.src = src

			img.onload = () => {
				setImageLoaded(true)
			}
		} else {
			setImageLoaded(true)
		}
	}, [src])
	return (
		<div>
			{imageLoaded ? (
				<img
					src={src}
					className={className}
					alt={alt}
					style={{ width: width, marginBottom: marginBottom }}
				/>
			) : (
				// <img src={imageLoadar} className={className} alt={alt} width={width} />
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: width,
						height: height
					}}
				>
					<CircularProgress style={{ width: '20px', height: '20px' }} />
				</Box>
			)}
		</div>
	)
}

export default MyImg
