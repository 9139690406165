import { FormControl, FormLabel, TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { useField } from 'formik'

const DateOfBirthField = ({ name, disabled, disableFuture = false, required = false }) => {
	const [field, meta, helper] = useField(name)

	return (
		<FormControl sx={{ width: '60%' }}>
			<MobileDatePicker
				label="Cake Day"
				inputFormat="yyyy/MM/DD"
				renderInput={params => (
					<TextField
						{...params}
						error={meta.touched && Boolean(meta.error)}
						helperText={meta.touched && 'Enter Date of Birth'}
						required={required}
					/>
				)}
				{...field}
				selected={(field.value && new Date(field.value)) || null}
				disabled={disabled}
				onChange={val => helper.setValue(val.toJSON())}
				disableFuture={disableFuture}
			/>
		</FormControl>
	)
}

export default DateOfBirthField
