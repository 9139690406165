import { Box, Stack, Typography } from '@mui/material'
import ImageInputBox from './ImageInputBox'

const ActionPhotos = ({ selectedImages, setSelectedImages, setRemovedImagesIds }) => {
	const width = 350
	const height = 250

	const onChange = (file, index) => {
		setSelectedImages(prevImages => {
			const newImages = [...prevImages]
			newImages[index] = { id: null, viewUrl: URL.createObjectURL(file), file: file }
			return newImages
		})
	}

	const handleSwap = swapIndex => {
		setSelectedImages(prevImages => {
			const newImages = [...prevImages]
			if (swapIndex !== 0) {
				;[newImages[swapIndex], newImages[0]] = [newImages[0], newImages[swapIndex]]
			}
			return newImages
		})
	}

	const handleRemove = (index, id) => {
		if (id) {
			setRemovedImagesIds(prevIds => [...prevIds, id])
		}
		setSelectedImages(prevImages => {
			const newImages = [...prevImages]
			newImages[index] = null
			return newImages
		})
	}

	const suggestedText = [
		'“If I am featured on a sports magazine cover” 🤸🏽‍🤾🏻️😎 ',
		'This is my happy face😄🤤',
		'With my action crew👭👬',
		'A funny moment🤪',
		'Me in the wild 🐵',
		'Moment of glory 🏅🥳',
		'My precious 🎿🤩'
	]
	return (
		<Stack direction="column" spacing={2} marginX="20px">
			<Box sx={{ height: '700px' }}>
				<Box mt={1} sx={{ fontSize: '21px', fontWeight: '700', lineHeight: '28px' }}>
					Upload your action photos
				</Box>
				<div>
					<div style={{ marginTop: '20px' }}>
						<ImageInputBox
							onChange={file => onChange(file, 0)}
							onSwap={() => {}}
							handleRemove={() => handleRemove(0, selectedImages[0]?.id)}
							width={'100%'}
							height={'250px'}
							suggestionText={suggestedText[0]}
							inputSize={'large'}
							image={selectedImages[0]?.viewUrl}
							index={0}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '3px'
						}}
						className="row"
					>
						{selectedImages.slice(1, 4).map((image, index) => (
							<Box
								className="col-md-4"
								style={{ display: 'flex', justifyContent: 'center', width: '33%', height: '105px' }}
							>
								<ImageInputBox
									key={index}
									onChange={file => onChange(file, index + 1)}
									// onSwap={() => handleSwap(index + 1)} // comented because some time old deleted photo comes in that place duting removing photo
									handleRemove={() => handleRemove(index + 1, selectedImages[index + 1]?.id)}
									// width={`${width / 3 - 5}px`}
									// height={height / 3 - 5}
									width={`100%`}
									height={`100%`}
									suggestionText={suggestedText[index + 1]}
									image={image?.viewUrl}
									index={index + 1}
								/>
							</Box>
						))}
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '2px'
						}}
						className="row"
					>
						{selectedImages.slice(4, 7).map((image, index) => (
							<Box
								key={index}
								className="col-md-4"
								style={{ display: 'flex', justifyContent: 'center', width: '33%', height: '105px' }}
							>
								<ImageInputBox
									key={index + 4}
									onChange={file => onChange(file, index + 4)}
									// onSwap={() => handleSwap(index + 4)} // comented because some time old deleted photo comes in that place duting removing photo
									handleRemove={() => handleRemove(index + 4, selectedImages[index + 4]?.id)}
									// width={`${width / 3 - 5}px`}
									// height={height / 3 - 5}
									width={`100%`}
									height={`100%`}
									suggestionText={suggestedText[index + 4]}
									image={image?.viewUrl}
									index={index + 4}
								/>
							</Box>
						))}
					</div>
				</div>
				<Typography variant="p">
					<ul>
						<li style={{ marginTop: '20px' }}>You can upload up to 7 photos.</li>
						<li style={{ marginTop: '7px' }}>
							Follow the suggestions or make your own action album!
						</li>
					</ul>
				</Typography>
			</Box>
		</Stack>
	)
}

export default ActionPhotos
