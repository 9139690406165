import React from 'react'
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material'
import { useField } from 'formik'
import ErrorMessageBL from '../../ui/ErrorMessageBL'

const GenderSelect = () => {
	const [field, meta] = useField('gender')

	return (
		<FormControl required sx={{ width: '60%' }}>
			<InputLabel id="gender-select-label">Gender</InputLabel>
			<Select
				labelId="gender-select-label"
				id="gender-select"
				label="Gender"
				{...field}
				sx={{ textAlign: 'start' }}
			>
				<MenuItem value="Male">Male</MenuItem>
				<MenuItem value="Female">Female</MenuItem>
				<MenuItem value="NonBinery">Non-binery</MenuItem>
			</Select>
		</FormControl>
	)
}

/*
const GenderSelect = () => {
  const [field, meta] = useField("gender");

  return (
    <React.Fragment>
      <FormControl error={meta.touched && Boolean(meta.error)}>
        <RadioGroup
          aria-labelledby="gender-label"
          name="gender-group"
          {...field}
        >
          <Grid container>
            <Grid item xs={3.5}>
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
            </Grid>
            <Grid item xs={3.5}>
              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="Female"
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                value="NonBinery"
                control={<Radio />}
                label="Non-binery"
              />
            </Grid>
            {
             // <Grid item xs={6}>
             // <FormControlLabel
             //   value="PreferNotToSay"
             //   control={<Radio />}
             //   label="Perfer Not Say"
             // />
             //</Grid>
            }
          </Grid>
        </RadioGroup>
        <ErrorMessageBL name={field.name} />
      </FormControl>
    </React.Fragment>
  );
};
*/

export default GenderSelect
