import baseApi from './baseApiService'
import { FRIEND_URL } from '../constants/ApiUrls'

class FriendService {
	getFriendList() {
		return baseApi.get(`${FRIEND_URL}`)
	}
	acceptFriendRequest(requestId) {
		return baseApi.put(`${FRIEND_URL}/accept/${requestId}`)
	}
	getPendingFriendRequestList() {
		return baseApi.get(`${FRIEND_URL}/pending`)
	}
	rejectFriendRequest(requestId) {
		return baseApi.put(`${FRIEND_URL}/reject/${requestId}`)
	}
	removeFriend(friendId) {
		return baseApi.delete(`${FRIEND_URL}/remove/${friendId}`)
	}
	sendFriendRequest(userAlias) {
		return baseApi.post(`${FRIEND_URL}/request/${userAlias}`)
	}
	getListOfFriendsBySportsCode(sportCode) {
		return baseApi.get(`${FRIEND_URL}/sport/${sportCode}`)
	}
	cancelSentRequest(userAlias) {
		return baseApi.delete(`${FRIEND_URL}/cancel/${userAlias}`)
	}
}

export default new FriendService()
