import { Checkbox, Chip, FormControlLabel, Stack, Typography } from '@mui/material'
import GoogleMaps from '../../components/GoogleMaps'
import { FieldArray, useField } from 'formik'

const SetSportPlaces = () => {
	const [fieldPlaces, ,] = useField('playground')
	const [fieldOpenToPlaces] = useField({ name: 'openToPlaces', type: 'checkbox' })

	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="h1b">Do you have places you usually go, or want to go?</Typography>

			<FieldArray
				name="playground"
				render={arrayHelpers => (
					<Stack direction="column" spacing={2} alignItems={'center'}>
						<GoogleMaps
							onSelect={(placeId, name) => arrayHelpers.push({ placeId: placeId, name: name })}
							filterSelectedLocation={true}
							fieldPlaces={fieldPlaces}
						/>
						<Stack
							direction="row"
							rowGap={1}
							spacing={1}
							flexWrap={'wrap'}
							justifyContent={'center'}
						>
							{fieldPlaces.value && fieldPlaces.value.length > 0 ? (
								fieldPlaces.value.map((place, index) => (
									<Chip
										key={index}
										variant="blGreen"
										label={place.name}
										onDelete={() => arrayHelpers.remove(index)}
										sx={{
											color: 'white',
											fontWeight: '600',
											maxWidth: '300px',
											'& .MuiChip-deleteIcon': {
												color: 'white' // Change the color of the delete icon here
											}
										}}
									/>
								))
							) : (
								<Typography variant="subtitle1">No places added</Typography>
							)}
						</Stack>
						<FormControlLabel control={<Checkbox />} label="I'm Flexible" {...fieldOpenToPlaces} />
					</Stack>
				)}
			/>
		</Stack>
	)
}

export default SetSportPlaces
