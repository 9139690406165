import { MenuItem, Select } from '@mui/material'

const months = [
	{ value: '01', label: 'January' },
	{ value: '02', label: 'February' },
	{ value: '03', label: 'March' },
	{ value: '04', label: 'April' },
	{ value: '05', label: 'May' },
	{ value: '06', label: 'June' },
	{ value: '07', label: 'July' },
	{ value: '08', label: 'August' },
	{ value: '09', label: 'September' },
	{ value: '10', label: 'October' },
	{ value: '11', label: 'November' },
	{ value: '12', label: 'December' }
]

const MonthSelect = ({ value, onChange }) => {
	const handleMonthChange = event => {
		const month = event.target.value
		onChange(month)
	}

	return (
		<Select value={value} onChange={handleMonthChange}>
			{months.map(month => (
				<MenuItem key={month.value} value={month.value}>
					{month.label}
				</MenuItem>
			))}
		</Select>
	)
}

export default MonthSelect
