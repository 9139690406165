import { Autocomplete, Button, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { useField } from 'formik'
import { useEffect, useState } from 'react'
import SportService from '../../services/SportService'
import { createFilterOptions } from '@mui/material/Autocomplete'

const filter = createFilterOptions()

const SetSportQuestions = ({ questionNo, questions }) => {
	const [title, setTitle] = useState()
	const [desc, setDesc] = useState()
	const [filteredQuestions, setFilteredQuestions] = useState([])
	const [fieldQuestionId, ,] = useField(`questions[${questionNo - 1}].sportQuestion.id`)
	const [fieldQuestionAnswer, , helperQuestionAnswer] = useField(
		`questions[${questionNo - 1}].answer`
	)
	const [fieldTags, , helperTags] = useField(`questions[${questionNo - 1}].tags`)
	const [selectedQuestion, setSelectedQuestion] = useState()

	useEffect(() => {
		if (questionNo === 1) {
			setTitle('Choose a question to answer')
			setDesc(
				'People generally find those who share a bit about themselves more open and approachable.'
			)
			setFilteredQuestions(
				questions.filter(q => q.category === SportService.QuestionCategory.Social)
			)
		} else if (questionNo === 2) {
			setTitle('Share more about your sport experience')
			setDesc(
				'Most people find those who are open about their experience and ability more trust-worthy, regardless of their level.'
			)
			setFilteredQuestions(
				questions.filter(q => q.category === SportService.QuestionCategory.Skill)
			)
		} else if (questionNo === 3) {
			setTitle('Share more about your goal')
			setDesc(
				'Sharing what you are working on helps finding people who want to train towards the same goal!'
			)
			setFilteredQuestions(questions.filter(q => q.category === SportService.QuestionCategory.Goal))
		}
	}, [questionNo, questions])

	useEffect(() => {
		setSelectedQuestion(questions.find(question => question.id === fieldQuestionId.value))
	}, [fieldQuestionId.value, questions])

	const maxLength = 160

	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="h1b">{title}</Typography>

			<Select
				value={selectedQuestion}
				// value={fieldQuestionId.value}
				// onChange={event => helperQuestionId.setValue(event.target.value)}
				{...fieldQuestionId}
				// onChange={() => {
				// 	helperQuestionAnswer.setValue('')
				// 	helperTags.setValue(null)
				// }}
			>
				<MenuItem value={0}>
					<em>Select a question</em>
				</MenuItem>
				{filteredQuestions.map(question => (
					<MenuItem key={question.id} value={question.id}>
						{question.question}
					</MenuItem>
				))}
			</Select>

			{selectedQuestion &&
				(selectedQuestion.type === SportService.QuestionType.Text ? (
					<Stack>
						<TextField
							multiline
							rows={4}
							placeholder="Write your answer"
							inputProps={{ maxLength: maxLength }}
							{...fieldQuestionAnswer}
						/>
						<Typography variant="note" textAlign="right" sx={{ margin: '5px 20px 0px 0px' }}>
							{fieldQuestionAnswer.value?.length}/{maxLength}
						</Typography>
					</Stack>
				) : (
					<Autocomplete
						multiple
						id="tags-standard"
						value={fieldTags.value}
						options={selectedQuestion.tags}
						getOptionLabel={option => option}
						onChange={(e, values) => {
							helperTags.setValue(values)
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params)

							const { inputValue } = params
							// Suggest the creation of a new value
							const isExisting = options.some(option => inputValue === option)
							if (inputValue !== '' && !isExisting) {
								filtered.push(inputValue)
							}

							return filtered
						}}
						// defaultValue={[selectedQuestion.tag[0]]}
						renderInput={params => (
							<TextField {...params} variant="standard" label="Tags" placeholder="Tags" />
						)}
					/>
				))}

			<Typography variant="p" pt={4} textAlign="center">
				{questionNo}/3
			</Typography>

			<Typography variant="p" p={4}>
				{desc}
			</Typography>

			{questionNo > 1 && (
				<Button type="submit" variant="blWhite">
					SKIP FOR NOW
				</Button>
			)}
		</Stack>
	)
}

export default SetSportQuestions
