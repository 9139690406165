import { Box, Tab, Tabs, Typography, Button, Grid, Stack, Modal } from '@mui/material'
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import {
	Link,
	Route,
	Routes,
	useLocation,
	useMatch,
	useNavigate,
	useParams
} from 'react-router-dom'
import SportView from '../../components/user/SportView'
import UserHeader from '../../components/user/UserHeader'
import ImageSlider from '../../components/slider/ImageSlider'
import UserService from '../../services/UserService'
import { useAuth } from '../../store/auth-context'
import BottomNavigation from '../../components/ui/BottomNavigation'
import { Add } from '@mui/icons-material'
import Loading from '../../components/ui/Loading'
import { LoadingContext } from '../../store/loading-context'
import ProfileService from '../../services/ProfileService'
import SportStatus from '../../components/sport/SportStatus'
import Tour from 'reactour'
import TutorialService from '../../services/TutorialService'
import SportLocationTiming from '../../components/ui/SportLocationTiming'
import cameraImg from '../../assets/icon/camera.png'
import { ShareContext } from '../../components/ShareContext'

const ViewUser = () => {
	const tabsRef = useRef(null)
	const { user, updateUser } = useAuth()
	const params = useParams()
	const tourRef = useRef(null)
	const completeSportProfileRef = useRef(null)
	const currentStep = useRef(0)
	const navigate = useNavigate()
	const [userDetails, setUserDetails] = useState(null)
	const routeMatch = useMatch('/p/:alias/:sportCode')
	const [sportCode, setSportCode] = useState(routeMatch?.params.sportCode.toUpperCase() || false)
	const [isLoading, setIsLoading] = useContext(LoadingContext)
	const location = useLocation()
	var refer = new URLSearchParams(location.search).get('refer')
	const [sportProfile, setSportProfile] = useState(null)
	const [shouldShowTour, setShowTour] = useState(false)
	const [isAddSportModalOpen, setIsAddSportModalOpen] = useState(false)
	const [showPencilIcon, setShowPencilIcon] = useState(false)

	const isShowEditIcon = localStorage.getItem('showEditIcon')
	useEffect(() => {
		if (isShowEditIcon === 'show' && params.alias === user.alias) {
			setShowPencilIcon(true)
		} else {
			setShowPencilIcon(false)
		}
	}, [isShowEditIcon, params.alias])

	useEffect(() => {
		if (!params.alias || params.alias === 'undefined') {
			navigate(`/p/${user?.alias}`, { replace: true })
		}
	}, [params.alias, user?.alias, navigate])

	const { updateShareDetails } = useContext(ShareContext)

	const profileUrl = `${process.env.REACT_APP_URL}/p/${userDetails?.alias}/${sportCode}`

	const shareDetails = useMemo(
		() => ({
			url: profileUrl,
			title: `${userDetails?.name}'s Profile`,
			text: `Check out this ${sportProfile?.sport?.name} BelayLink profile.`
		}),
		[profileUrl, userDetails?.name, sportProfile?.sport?.name]
	)

	useEffect(() => {
		// Update with specific details for this page
		updateShareDetails(shareDetails)

		// Reset to default when the component unmounts
		return () => updateShareDetails()
	}, [shareDetails, updateShareDetails])

	useEffect(() => {
		if (
			(params.alias && !userDetails) ||
			refer === 'deleteSport' ||
			location?.state?.refer === 'editSport' ||
			location?.state?.refer === 'viewProfile'
		) {
			setIsLoading(true)
			UserService.getUserByAlias(params.alias)
				.then(response => {
					setUserDetails(response.data)
					// updateUser({ ...response.data, metaData: user.metaData })  //comented because when we look some other user's profile (via notification) then user's get updated with notification's user
					if (
						(!sportCode && response.data.sports.length > 0) ||
						refer === 'deleteSport' ||
						location?.state?.refer === 'editSport' ||
						location?.state?.refer === 'viewProfile'
					) {
						// setSportProfile(null)
						if (response.data.sports?.length > 0) {
							navigate(response.data.sports[0].code)
							setSportCode(response.data.sports[0].code)
						} else {
							setIsLoading(false)
						}
					} else {
						if (params.sportCode.toUpperCase()) {
							setSportCode(sportCode)
							navigate(sportCode)
						} else {
							setIsLoading(false)
						}
					}

					if (response.data.sports.length > 0 && user?.alias === response.data?.alias) {
						setTimeout(() => {
							const isViewed = TutorialService.isTutorialViewed(
								TutorialService.TutorialType.VIEW_PROFILE,
								user,
								// response.data, //comented because after tutorial is recent added sport is not showing is tab
								updateUser
							)
							if (!isViewed) {
								setShowTour(true)
							}
						}, 3000)
					}

					setIsLoading(false)
				})
				.catch(error => {
					console.log('error', error)
					setIsLoading(false)
				})
			setIsLoading(false)
		} else {
			//when going back from browser's tab
			setSportCode(routeMatch?.params.sportCode.toUpperCase())
		}
	}, [params.alias, sportCode, navigate, refer, location])

	useEffect(() => {
		setIsLoading(true)
		if (sportCode) {
			ProfileService.getUsersProfile(params.alias, sportCode)
				.then(response => {
					setSportProfile(response.data)
					setIsLoading(false)
				})
				.catch(err => {
					setIsLoading(false)
				})
		} else {
			setIsLoading(false)
			if (params?.alias) {
				// when user view someone other's profile (go to messeage screen and click on user's name)
				navigate(`/p/${params?.alias}`, { replace: true })
			} else {
				// when user not have any sport and click on header's logo (during sign up)
				navigate(`/p/${user?.alias}`, { replace: true })
			}
		}
	}, [sportCode])

	useEffect(() => {
		if (userDetails) {
			if (userDetails?.sports?.length === 0) {
				setIsAddSportModalOpen(true)
			} else {
				setIsAddSportModalOpen(false)
			}
		}
	}, [userDetails])

	const handleSportCodeChange = (event, newValue) => {
		setSportCode(newValue)
		setSportProfile(null)
	}

	const handleTabClick = () => {
		if (tabsRef.current) {
			const container = document.querySelector('.MuiTabs-scroller')
			if (container) {
				container.scrollTo({
					left: 0,
					behavior: 'smooth'
				})
			}
		}
	}

	const onRequestClose = () => {
		if (currentStep.current < 1) {
			tourRef.current.nextStep()
		} else {
			setShowTour(false)
		}
	}

	const handleAfterOpen = () => {
		const container = document.getElementById('homePageStack')
		document.body.classList.add('no-overflow')
		container.classList.add('no-overflow')
	}
	const handleBeforeClose = () => {
		const container = document.getElementById('homePageStack')
		document.body.classList.remove('no-overflow')
		container.classList.remove('no-overflow')
	}

	const showCompleteMySportProfileBtn =
		sportCode &&
		user?.alias === userDetails?.alias &&
		(sportProfile?.questions?.filter(que => que.answer || que.tags?.length > 0)?.length === 0 ||
			user?.coverImages?.length === 0)

	return (
		<Stack id="homePageStack">
			{userDetails && (
				<Box pt={'10px'} pr={'10'}>
					<UserHeader
						name={userDetails.name}
						image={userDetails.userImage?.viewUrl}
						age={userDetails.age}
						location={userDetails.location?.name}
						hometown={userDetails.hometown?.name}
						work={userDetails.work}
						noShowAge={userDetails.noShowAge}
						userDetails={userDetails}
						showPencilIcon={showPencilIcon}
					/>
				</Box>
			)}
			{!isLoading && userDetails && sportCode && (
				<Box
					sx={{
						backgroundColor: '#B0F1E1',
						pb: '31px',
						overflowX: 'hidden',
						marginTop: '15px'
					}}
				>
					<Box sx={{ backgroundColor: 'white' }}>
						<Tabs
							ref={tabsRef}
							value={sportCode}
							onChange={handleSportCodeChange}
							variant="scrollable"
							scrollButtons="auto"
							indicatorColor="transparent"
						>
							{userDetails?.sports
								.sort(a => (a.code === params['*'].toUpperCase() ? -1 : 1))
								.map(sport => (
									<Tab
										key={sport.code}
										value={sport.code}
										label={<Typography variant="h4">{sport.name}</Typography>}
										component={Link}
										to={sport.code}
										onClick={handleTabClick}
									/>
								))}
							{userDetails?.alias === user?.alias && (
								<Tab
									label={
										<Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
											Other Sports <Add style={{ fontSize: '19px', marginLeft: '4px' }} />
										</Typography>
									}
									component={Link}
									to="createSportProfile"
								/>
							)}
						</Tabs>
						<Grid sx={{ backgroundColor: '#B0F1E1', padding: '0 15px' }}>
							{sportProfile?.status && (
								<SportStatus
									status={sportProfile?.status}
									showInterestedButton={user?.alias !== userDetails?.alias}
									sportUser={{ ...sportProfile, name: userDetails.name, alias: userDetails.alias }}
								/>
							)}
						</Grid>

						<Box sx={{ backgroundColor: '#B0F1E1' }}>
							{sportCode && sportProfile ? (
								<Box sx={{ p: '10px 10px' }}>
									<Routes>
										<Route
											path=":sportCode"
											element={
												<SportView
													alias={params.alias}
													sportCode={sportCode}
													setSportCode={setSportCode}
													showSessionTypes={true}
													showStatus={false}
													minimalView={false} //show questions
													sportProfile={sportProfile}
													showPencilIcon={showPencilIcon}
												/>
											}
										/>
									</Routes>
								</Box>
							) : (
								<Box>
									{!isLoading && (
										<Typography sx={{ color: 'gray', paddingTop: '40px', textAlign: 'center' }}>
											{/* Sport not found! */}
										</Typography>
									)}
								</Box>
							)}
						</Box>
					</Box>

					<Box sx={{ backgroundColor: '#B0F1E1' }}>
						{showPencilIcon &&
							(sportProfile?.sportTimeWeekdayDay ||
								sportProfile?.sportTimeWeekdayEvening ||
								sportProfile?.sportTimeWeekend ||
								sportProfile?.playground?.length > 0 ||
								sportProfile?.openToPlaces) && ( // because in view profile mode it'll visible in SportView component in white background
								<Box sx={{ padding: '0px 15px 25px 15px' }}>
									<SportLocationTiming sportProfile={sportProfile} />
								</Box>
							)}
						{showCompleteMySportProfileBtn && (
							<Box
								mt={'15px'}
								sx={{ position: 'fixed', zIndex: '99', width: '100%', bottom: '50px', left: '0' }}
							>
								<Button
									id="complete-sport-profile"
									ref={completeSportProfileRef}
									onClick={() =>
										navigate(`/editSportProfile/${sportCode}/?refer=completeSportProfile`)
									}
									sx={{
										color: 'white',
										marginTop: '20px',
										borderRadius: '5px',
										border: '1px solid #00C6AE',
										textTransform: 'capitalize',
										paddingX: '20px',
										background: 'white',
										'&:hover': {
											background: '#FFF'
										},
										margin: 'auto',
										marginBottom: '25px',
										display: 'flex',
										justifyContent: 'center',
										boxShadow:
											'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
									}}
									disableElevation
								>
									<Typography
										variant="h2"
										sx={{
											color: '#00C6AE',
											whiteSpace: 'nowrap',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											width: '80%'
										}}
									>
										Complete my sport profile
									</Typography>
								</Button>
							</Box>
						)}

						{userDetails?.coverImages?.length > 0 ? (
							<ImageSlider SliderData={userDetails?.coverImages} showPencilIcon={showPencilIcon} />
						) : (
							<Box>
								{params.alias === user.alias && (
									<Box
										sx={{
											backgroundColor: '#B0F1E1',
											height: '20rem',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'baseline',
											marginBottom: '70px',
											marginTop: '25px'
										}}
									>
										<Box
											sx={{
												backgroundColor: 'rgb(255, 255, 255, 50%)',
												backgroundTransparent: '50%',
												height: '80%',
												width: '80%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												flexDirection: 'column',
												borderRadius: '10px'
											}}
										>
											<img src={cameraImg} />
											<Button
												onClick={() => navigate(`/p/${user.alias}/actionphotos`)}
												sx={{
													color: 'white',
													marginTop: '20px',
													borderRadius: '5px',
													border: '1px solid #00C6AE',
													textTransform: 'capitalize',
													paddingX: '20px',
													background: 'white',
													'&:hover': {
														background: '#FFF'
													},
													marginBottom: '25px',
													display: 'flex',
													justifyContent: 'center',
													boxShadow:
														'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
												}}
												disableElevation
											>
												<Typography
													variant="h2"
													sx={{
														color: '#00C6AE',
														whiteSpace: 'nowrap',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														width: '80%'
													}}
												>
													Add your action photos
												</Typography>
											</Button>
										</Box>
									</Box>
								)}
							</Box>
						)}
					</Box>

					<BottomNavigation
						lookArround={false}
						addStatus={false}
						message={false}
						sportCode={sportCode}
						alias={params.alias}
					/>

					<Tour
						ref={tourRef}
						onRequestClose={onRequestClose}
						showButtons={false}
						showNavigationNumber={false}
						showCloseButton={false}
						getCurrentStep={cs => (currentStep.current = cs)}
						showNumber={false}
						closeWithMask={true}
						showNavigation={false}
						steps={stepsWithCompleteMyProfileBtn}
						isOpen={shouldShowTour}
						badgeContent={null}
						maskSpace={0}
						rounded={10}
						onAfterOpen={handleAfterOpen}
						onBeforeClose={handleBeforeClose}
						disableInteraction
					/>
				</Box>
			)}
			<Modal
				open={isAddSportModalOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...style, fontSize: '20px' }}>
					<Box
						id="modal-modal-title"
						sx={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}
					>
						Hey looks like you don't have a sport profile yet. Let's make one!
					</Box>
					<Box
						id="modal-modal-description"
						sx={{
							mt: 2,
							width: '122%',
							display: 'flex',
							justifyContent: 'space-around',
							borderTop: '1px solid #8080804d',
							marginBottom: '-32px'
						}}
					>
						<Button
							sx={{
								width: '100%',
								p: '12px 0',
								fontSize: '17px',
								borderLeft: '1px solid #8080804d'
							}}
							onClick={() => navigate(`/p/${params.alias}/createSportProfile`)}
						>
							<Add style={{ fontSize: '19px', marginRight: '4px' }} /> Add Sport
						</Button>
					</Box>
				</Box>
			</Modal>
			{isLoading && <Loading backgroundTransparent={true} />}
		</Stack>
	)
}

export default ViewUser

const stepsWithCompleteMyProfileBtn = [
	{
		position: 'top',
		selector: '#complete-sport-profile',
		// ref: completeSportProfileRef,
		style: {
			minWidth: `320px`,
			maxWidth: '320px',
			padding: 0
		},
		// action: node => {
		// 	var targetDiv = document.getElementById('complete-sport-profile')
		// 	if (targetDiv) {
		// 		targetDiv.scrollIntoView({
		// 			behavior: 'smooth'
		// 		})
		// 	}
		// },
		action: node => {
			node?.focus()
		},
		content: (
			<Box sx={{ position: 'relative', background: 'transparent' }}>
				<Box
					sx={{
						pt: '15px',
						borderRadius: '0 0 30px 30px',
						position: 'absolute',
						bottom: '5px'
						// top: 0
					}}
				>
					<Box
						sx={{
							mx: '15px',
							background: '#61E4C5',
							px: '12px',
							py: '22px',
							borderRadius: '20px'
						}}
					>
						<Typography as="span" className="fw-700">
							You can make your sports{' '}
						</Typography>
						<Typography as="span" className="fw-500">
							profile look better later :)
						</Typography>
					</Box>
					<Box className="anchor-bottom">
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								background: '#61E4C5',
								height: '100%',
								marginTop: -0.5
							}}
						></Box>
					</Box>
				</Box>
			</Box>
		)
	},
	{
		position: 'top',
		selector: '#profile-navigation',
		style: {
			minWidth: `290px`,
			maxWidth: '290px',
			padding: 0
		},
		content: (
			<Box sx={{ position: 'relative' }}>
				<Box
					sx={{
						pt: '15px',
						borderRadius: '0 0 30px 30px',
						position: 'absolute',
						bottom: '8px'
					}}
				>
					<Box
						sx={{
							mx: '15px',
							background: '#61E4C5',
							px: '12px',
							py: '22px',
							borderRadius: '20px'
						}}
					>
						<Typography as="span" className="fw-500">
							Now let’s see how you can find{' '}
						</Typography>
						<Typography as="span" className="fw-700">
							people to do sports together!
						</Typography>
					</Box>
					<Box className="anchor-bottom">
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								background: '#61E4C5',
								height: '100%',
								marginTop: -0.5
							}}
						></Box>
					</Box>
				</Box>
			</Box>
		)
	}
]

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	flexDirection: 'column',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
	p: 4,
	minWidth: '338px',
	gap: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
