import baseApi from './baseApiService'
import { NOTIFICATION_URL } from '../constants/ApiUrls'

class NotificationService {
	NOTIFICATION_TYPES = {
		FRIEND_REQUEST: 'FRIEND_REQUEST',
		NEW_FRIEND: 'NEW_FRIEND',
		ANNOUNCEMENT: 'ANNOUNCEMENT',
		PROFILE_STATUS_UPDATED: 'PROFILE_STATUS_UPDATED',
		PROFILE_STATUS_EXPIRED: 'PROFILE_STATUS_EXPIRED',
		FRIEND_RECOMMENDATION: 'FRIEND_RECOMMENDATION',
		CHAT_MESSAGE: 'CHAT_MESSAGE'
	}

	PREFERENCE_METHODS = {
		EMAIL: 'EMAIL',
		PUSH: 'PUSH'
	}

	PREFERENCE_TYPES = {
		FRIENDS_ACTIVITIES: 'FRIENDS_ACTIVITIES',
		NEW_FEATURES_PROMOTIONS_EVENTS: 'NEW_FEATURES_PROMOTIONS_EVENTS'
	}

	async getNotifications() {
		try {
			const response = await baseApi.get(`${NOTIFICATION_URL}`)

			// Parse the content field for each notification
			return response.data.map(notification => ({
				...notification,
				content: JSON.parse(notification.content)
			}))
		} catch (error) {
			console.error('Failed to fetch notifications:', error)
			throw error
		}
	}

	async countUnreadNotifications() {
		try {
			const response = await baseApi.get(`${NOTIFICATION_URL}/unread-count`)
			return response.data
		} catch (error) {
			console.error('Failed to fetch unread notifications count:', error)
			//throw error
		}
	}

	async putMarkAllAsRead() {
		try {
			const response = await baseApi.put(`${NOTIFICATION_URL}/mark-all-read`)
			return response.data
		} catch (error) {
			console.error('Failed to mark all notifications as read:', error)
		}
	}

	async fetchPreferences() {
		try {
			const response = await baseApi.get(`${NOTIFICATION_URL}/preferences`)
			return response.data
		} catch (error) {
			console.error('Failed to get notification preferences:', error)
			throw error
		}
	}

	async updatePreferences(payload) {
		try {
			await baseApi.put(`${NOTIFICATION_URL}/preferences`, payload)
		} catch (error) {
			console.error('Failed to get notification preferences:', error)
			throw error
		}
	}
}

const notificationServiceInstance = new NotificationService()

export default notificationServiceInstance
