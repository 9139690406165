export const BASE_URL = process.env.REACT_APP_API_URL

export const GET_SPORT_LIST_URL = '/sport'
export const GET_SPORT_DETAIL_URL = '/sport'

export const SPORT_URL = '/sport'
export const PLACE_URL = '/place'
export const LOCATION_URL = '/location'
export const USER_URL = '/user'
export const PROFILE_URL = '/profile'
export const BROWSE_PROFILE_URL = '/profile/browse'
export const CHALLENGE_URL = '/challenge'

export const CHAT_URL = '/chat'
export const TUTORIAL_URL = '/tutorial'

export const FRIEND_URL = '/friend'

export const NOTIFICATION_URL = '/notification'
