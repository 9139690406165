import moment from 'moment'
import DateUtils from '../../../shared/DateUtils'
import createChallengeFormModel from './createChallengeFormModel'

const {
	formField: { type, name, description, startDate, endDate, stake }
} = createChallengeFormModel

const createChallengeInitialValues = {
	[type.name]: '',
	[name.name]: '',
	[description.name]: '',
	[startDate.name]: new moment().startOf('date').toISOString(),
	[endDate.name]: DateUtils.addDaysFromToday(1).endOf('date').toISOString(),
	[stake.name]: {
		id: 1,
		name: 'Bubble Tea',
		avatar: 'https://belayon.s3.ap-southeast-1.amazonaws.com/stakes/bubble-tea.jpg'
	}
}

export default createChallengeInitialValues
