import { Box, Button } from '@mui/material'
import React from 'react'
import MessageButton from '../Friends/MessageButton'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

function BrowseProfileLRButtons({
	page,
	showProfilesIndex,
	prevSport,
	sportsProfiles,
	nextSport,
	currentSportView,
	sportCode,
	showBackArrow,
	showNextPrevButtons = true,
	disableButtons = false
}) {
	return (
		<Box sx={{ position: 'fixed', bottom: '0', left: '0px', width: '100px' }}>
			<Box
				id="profile-navigation"
				sx={{
					position: 'fixed',
					width: '100%',
					background: 'transparent',
					zIndex: '5',
					bottom: '75px',
					display: 'flex',
					justifyContent: showNextPrevButtons ? 'space-between' : 'center',
					alignItems: 'center',
					py: '3px'
				}}
			>
				{showNextPrevButtons && (
					<Button>
						{(page > 0 || showProfilesIndex > 0) && (
							<KeyboardArrowLeftOutlinedIcon
								sx={{
									color: 'white',
									cursor: 'pointer',
									background: disableButtons ? '#9FA4B4' : '#00C6AE',
									borderRadius: '50%',
									width: '30px',
									height: '30px'
								}}
								onClick={!disableButtons && prevSport}
							/>
						)}
					</Button>
				)}
				<Box
					onClick={() =>
						localStorage.setItem(
							'look-around-data',
							JSON.stringify({ index: showProfilesIndex, page: page, sportCode: sportCode })
						)
					}
				>
					<MessageButton
						showIcon={false}
						size="large"
						sportCode={sportCode}
						friendAlias={currentSportView?.alias}
						refer={showBackArrow}
						disableButtons={disableButtons}
					/>
				</Box>
				{showNextPrevButtons && (
					<Button>
						{page === sportsProfiles?.totalPages - 1 &&
						showProfilesIndex === sportsProfiles?.content?.length - 1 ? (
							''
						) : (
							<KeyboardArrowRightOutlinedIcon
								sx={{
									color: 'white',
									cursor: 'pointer',
									background: disableButtons ? '#9FA4B4' : '#00C6AE',
									borderRadius: '50%',
									width: '30px',
									height: '30px'
								}}
								onClick={!disableButtons && nextSport}
							/>
						)}
					</Button>
				)}
			</Box>
		</Box>
	)
}

export default BrowseProfileLRButtons
