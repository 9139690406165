import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useNotification from '../hooks/useNotification'
import jwt_decode from 'jwt-decode'

const DOMAIN_NAME = process.env.REACT_APP_AWS_COGNITO_DOMAIN_NAME
const CLIENT_ID = process.env.REACT_APP_AWS_APP_CLIENT_ID
const REDIRECT_URI = process.env.REACT_APP_AWS_REDIRECT_URI
const AWS_COGNITO_LOGIN_LINK = `https://${DOMAIN_NAME}/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${REDIRECT_URI}`
const AWS_COGNITO_SIGNUP_LINK = `https://${DOMAIN_NAME}/signup?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${REDIRECT_URI}`

const AuthContext = React.createContext({
	token: '',
	isLoggedIn: false,
	hasCompletedSignup: false,
	user: '',
	awsSignIn: () => {},
	updateUser: () => {},
	login: () => {},
	logout: () => {},
	setRedirectToAfterSignIn: () => {},
	setExpireTime: () => {}
})

export const AuthContextProvider = props => {
	const userDataString = localStorage.getItem('userData')
	const navigate = useNavigate()
	const [, sendNotification] = useNotification()
	const [schedule, setSchedule] = useState(false)

	const getExpireTime = useCallback(() => {
		const exp = localStorage.getItem('exp')
		return exp ? JSON.parse(exp) : null
	}, [])

	const isTokenExpired = () => {
		const expireTime = getExpireTime()
		const currentTime = Math.floor(Date.now() / 1000)
		return currentTime > expireTime
	}

	const userData = JSON.parse(localStorage.getItem('userData') || '{}')
	const [token, setToken] = useState(localStorage.getItem('token'))
	const [user, setUser] = useState(userData.user)

	const userIsLoggedIn = !!token
	const userHasCompletedSignup = user?.alias

	const updateUser = newUserInfo => {
		const newUserData = { user: newUserInfo }
		localStorage.setItem('userData', JSON.stringify(newUserData))
		setUser(newUserData.user)
	}

	const setRedirectToAfterSignIn = pathname => {
		localStorage.setItem('redirectTo', pathname)
	}

	const setExpireTime = useCallback(exp => {
		localStorage.setItem('exp', JSON.stringify(exp))
	}, [])

	const revokeToken = async refreshToken => {
		const url = `https://${DOMAIN_NAME}/oauth2/revoke`
		const details = {
			token: refreshToken,
			client_id: CLIENT_ID
		}

		const formBody = Object.keys(details)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
			.join('&')

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: formBody
			})

			if (response.ok) {
				console.log('Token revoked successfully')
			} else {
				console.error('Failed to revoke token')
			}
		} catch (error) {
			console.error('Error revoking token:', error)
		}
	}

	const loginHandler = useCallback(
		userData => {
			console.log('login handler called')
			localStorage.setItem('userData', JSON.stringify({ user: userData.user }))
			localStorage.setItem('refreshToken', userData.refreshToken)
			localStorage.setItem('token', userData.token)
			const { exp } = jwt_decode(userData.token) || {}
			setExpireTime(exp)
			setUser(userData.user)
			setToken(userData.token)
			setSchedule(true)
		},
		[setToken, setUser]
	)

	const logoutHandler = useCallback(() => {
		console.log('logout handler called')
		const refreshToken = localStorage.getItem('refreshToken')
		if (refreshToken) {
			revokeToken(refreshToken)
		}
		localStorage.removeItem('userData')
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('exp')
		localStorage.removeItem('token')
		setToken(null)
		setUser(null)
		navigate('/', { replace: true })
	}, [setToken, setUser])

	const refreshToken = useCallback(async () => {
		const refreshTokenValue = localStorage.getItem('refreshToken')

		if (!refreshTokenValue) {
			console.log('No refresh token available')
			logoutHandler()
			return
		}

		const tokenUrl = `https://${DOMAIN_NAME}/oauth2/token`
		const details = {
			grant_type: 'refresh_token',
			client_id: CLIENT_ID,
			refresh_token: refreshTokenValue
		}

		const formBody = Object.keys(details)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
			.join('&')

		try {
			const response = await fetch(tokenUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: formBody
			})

			const data = await response.json()
			if (data.id_token) {
				localStorage.setItem('token', data.id_token)
				localStorage.setItem('refreshToken', data.refresh_token || refreshTokenValue)
				setToken(data.id_token)
				const { exp } = jwt_decode(data.id_token) || {}
				setExpireTime(exp)
				setSchedule(true)
			} else {
				console.error('Failed to refresh token')
				sendNotification({
					msg: 'Session has expired, please log in again',
					variant: 'error'
				})
				logoutHandler()
			}
		} catch (error) {
			console.error('Error refreshing token:', error)
			logoutHandler()
		}
	}, [logoutHandler, setExpireTime, sendNotification])

	useEffect(() => {
		if (refreshToken && userIsLoggedIn) {
			refreshToken()
		}
		// } else if (refreshToken && userIsLoggedIn && !schedule) {
		setSchedule(true)
		// }
	}, [refreshToken, userIsLoggedIn])

	const awsSignIn = (redirectTo, signup) => {
		localStorage.removeItem('redirectTo')
		if (redirectTo) setRedirectToAfterSignIn(redirectTo)
		const link = signup ? AWS_COGNITO_SIGNUP_LINK : AWS_COGNITO_LOGIN_LINK
		window.location.replace(link)
	}

	const exchangeCodeForToken = useCallback(async code => {
		const tokenUrl = `https://${DOMAIN_NAME}/oauth2/token`
		const details = {
			grant_type: 'authorization_code',
			client_id: CLIENT_ID,
			code: code,
			redirect_uri: REDIRECT_URI
		}

		const formBody = Object.keys(details)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
			.join('&')

		try {
			const response = await fetch(tokenUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: formBody
			})

			const data = await response.json()
			if (data.access_token) {
				return data // returning the tokens
			} else {
				throw new Error('Token exchange failed')
			}
		} catch (error) {
			console.error('Error exchanging code for token:', error)
			throw error
		}
	}, [])

	useEffect(() => {
		let timeoutId

		const scheduleTokenRefresh = () => {
			if (userIsLoggedIn) {
				console.log('scheduling a token refresh')
				const exp = getExpireTime()
				if (!exp) return

				const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
				const timeUntilExpiry = exp - currentTime

				if (timeUntilExpiry <= 300) {
					// 300 seconds = 5 minutes
					refreshToken()
				} else {
					// Set a timer to trigger refreshToken 5 minutes before expiry
					const timerDelay = (timeUntilExpiry - 300) * 1000 // Convert to milliseconds
					timeoutId = setTimeout(refreshToken, timerDelay)
					console.log('scheduled')
				}
			}
		}

		if (schedule) {
			setSchedule(false)
			scheduleTokenRefresh()
		}

		// Clear the timeout if the component unmounts
		return () => {
			if (timeoutId) clearTimeout(timeoutId)
		}
	}, [schedule])

	const contextValue = {
		token,
		isLoggedIn: userIsLoggedIn,
		hasCompletedSignup: userHasCompletedSignup,
		user: user,
		awsSignIn,
		updateUser,
		login: loginHandler,
		logout: logoutHandler,
		setRedirectToAfterSignIn,
		exchangeCodeForToken,
		setExpireTime,
		refreshToken
	}

	return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export const useAuth = () => React.useContext(AuthContext)
