import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ChallengeService from '../../services/ChallengeService'
import ChallengeItem from './list/ChallengeItem'

const ChallengeResultPage = () => {
	const { alias } = useParams()
	const navigate = useNavigate()
	const [challenge, setChallenge] = useState()

	useEffect(() => {
		ChallengeService.getChallengeByAlias(alias).then(response => setChallenge(response.data))
	}, [alias])

	const handleRematch = () => {
		// TODO
	}

	return (
		<Stack textAlign="center" alignItems="center" spacing={4}>
			<Typography variant="h1" fontWeight="bold">
				WE HAVE A WINNER!
			</Typography>

			<Grid container justifyContent="center" space={2}>
				{challenge?.winners.map(({ alias, userImage, name }) => (
					<Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Avatar
							key={alias}
							alt={name}
							src={userImage?.uploadBase64Data || userImage?.viewUrl}
							sx={{
								margin: '5px',
								width: '120px',
								height: '120px'
							}}
						/>
						<Typography sx={{ margin: '4px 0 16px 0' }}>{name}</Typography>
					</Grid>
				))}
			</Grid>

			{challenge && <ChallengeItem challenge={challenge} overrideRouteToDetails={true} />}

			<Box display="flex" flexDirection="column" gap={2}>
				{/* <Button variant="blGrey" onClick={handleRematch}>
					Rematch
				</Button> */}
				<Button variant="blGreen" onClick={() => navigate('/challenge/create')}>
					New Challenge
				</Button>
			</Box>
		</Stack>
	)
}

export default ChallengeResultPage
